export const TariffTypes = {
  carSwitch: 'Car-Switch',
  carSwitchGreen: 'Car-Switch-Green',
  comfort: 'Comfort',
  comfortPlus: 'Comfort+',
  compact: 'Compact',
  privateLiabilitySwitch: 'PRIVATE_LIABILITY_SWITCH',
  privateLiabilitySwitchGreen: 'PRIVATE_LIABILITY_SWITCH_GREEN',
  switchGreenHH: 'SWITCH_GREEN_HH',
  switchGreenPL: 'SWITCH_GREEN_PL',
  switchHH: 'SWITCH_HH',
  switchPL: 'SWITCH_PL'
};

export const TariffTypeFilters = {
  comfort: TariffTypes.comfort,
  comfortPlus: TariffTypes.comfortPlus,
  compact: TariffTypes.compact,
  switch: [TariffTypes.carSwitch, TariffTypes.privateLiabilitySwitch, TariffTypes.switchHH, TariffTypes.switchPL],
  switchGreen: [
    TariffTypes.switchGreenHH,
    TariffTypes.switchGreenPL,
    TariffTypes.carSwitchGreen,
    TariffTypes.privateLiabilitySwitchGreen
  ]
};
