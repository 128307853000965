import { Injectable } from '@angular/core';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import {
  ClaimsDetailsMapped,
  ClaimsDetailsResponse,
  ClaimsDetailsResponseIT
} from '@wefoxGroupOneBPPrivate/modules/claims/interfaces/claims-details.interface';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClaimsDetailsDataService } from './claims-details-data.service';

@Injectable()
export class ClaimsDetailsService {
  private _country: COUNTRIES;

  constructor(
    protected claimsDetailsData: ClaimsDetailsDataService,
    private _pageDetailsService: PageDetailsService,
    private _sessionQuery: SessionQuery
  ) {
    this._country = this._sessionQuery.getCountry();
  }

  // eslint-disable-next-line
  public getClaimsDetails$(id: string, dynamicConfig: any): Observable<ClaimsDetailsMapped> {
    return this.claimsDetailsData
      .getClaimsDetails$(id)
      .pipe(map(res => this._mapClaimsDetails(res, dynamicConfig))) as Observable<ClaimsDetailsMapped>;
  }

  public createClaim$(body) {
    return this.claimsDetailsData.createClaim(body);
  }

  private _mapClaimsDetails(
    response: ClaimsDetailsResponseIT | ClaimsDetailsResponse,
    dynamicConfig
  ): Partial<ClaimsDetailsMapped> {
    switch (this._country) {
      case COUNTRIES.it:
        return this._mapClaimIT(response);
      default:
        return this._mapClaimDefault(response, dynamicConfig);
    }
  }

  // eslint-disable-next-line
  public uploadClaimFile$(body: FormData, options?: RequestInit): Observable<any> {
    return this.claimsDetailsData.uploadClaimFile$(body, options);
  }

  private _mapClaimIT(response: Partial<ClaimsDetailsResponseIT>): Partial<ClaimsDetailsMapped> {
    const { first_name, last_name } = response.reporter;
    const { status, type, event_date, description, claim_id } = response;
    return {
      claim_number: claim_id,
      claim_summary: response.description,
      claimed_amount: 2500.75,
      damage_description: description,
      customer_name: `${first_name} ${last_name}`,
      deductibles: [],
      event_date,
      event_class: 'Event class',
      id: response.id,
      original_data: response,
      type,
      status
    };
  }

  private _mapClaimDefault(response: Partial<ClaimsDetailsResponse>, dynamicConfig): Partial<ClaimsDetailsMapped> {
    return { ...this._pageDetailsService.mapDetailsCards(response, dynamicConfig), original_data: response };
  }
}
