export const RoutePath = {
  contracts: '/contracts',
  customers: '/customers',
  drafts: '/drafts',
  offer: '/offer',
  products: '/products'
};

export const RouteParams = {
  contractId: 'contractId',
  customerId: 'customerId',
  previousInsurers: 'previousInsurers',
  leadId: 'leadId',
  appointmentId: 'appointmentId',
  riskAnalysisId: 'riskAnalysisId',
  token: 'token',
  country: 'country'
};
