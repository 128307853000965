import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import { CSuiteHomePermitExcludedCountries } from '@wefoxGroupOneBPPrivate/product/constants/c-suite-home.constant';
import { ProductService } from '@wefoxGroupOneBPCore/services/product.service';
import { WgTypeaheadComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const localeAsLanguageName = {
  de: 'german',
  en: 'english',
  fr: 'french',
  it: 'italian'
};

@Component({
  selector: 'one-countries-typeahead',
  templateUrl: './countries-typeahead.component.html'
})
export class CountriesTypeaheadComponent implements OnDestroy, OnInit {
  @Input() public controlName: string;
  @Input() public label: string;
  public optionsFiltered = [];
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public residenceTypeConfig: any; // eslint-disable-line
  public showPermitTypeDropdown = true;
  @ViewChild(WgTypeaheadComponent)
  public typeaheadComponent: WgTypeaheadComponent;
  private _control: AbstractControl;
  private _countries: any; // eslint-disable-line
  private _currentLocale = this._sessionQuery.getCurrentLocale() as string;
  private _optionsList: WgDropdownOption[];
  private _unsubscribe$: Subject<void> = new Subject();
  constructor(
    private _productService: ProductService,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _cdr: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this._control = this.parentGroup.get(this.controlName);
    this._getCountriesList();
    this._monitorLocaleChanges();
    this.monitorVariables();
  }

  public onSearch(keyword: string): void {
    this.optionsFiltered = this._optionsList?.filter((country: WgDropdownOption) => {
      return country.value.toUpperCase().includes(keyword.toUpperCase());
    });
  }

  private _formatList(list): WgDropdownOption[] {
    if (list) {
      const mappedList = list
        .map(country => {
          return {
            key: country.iso2,
            value: country[localeAsLanguageName[this._sessionQuery.getCurrentLocale()]]
          };
        })
        .reduce(
          (acc, a) => {
            if (this._getTopCountries().indexOf(a.key) !== -1) {
              acc.top.push(a);
            } else {
              const idx = acc.rest.findIndex(b => b.value.localeCompare(a.value) > 0);
              acc.rest.splice(idx === -1 ? acc.rest.length : idx, 0, a);
            }
            return acc;
          },
          { top: [], rest: [] }
        );

      this._sessionService.setCountriesList([...mappedList.top, ...mappedList.rest]);

      return [...mappedList.top, ...mappedList.rest];
    }
  }

  private _getCountriesList() {
    this._productService
      .getCountriesList()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(list => {
        this._countries = list;
        this._optionsList = this._formatList(list);
      });
  }

  private _getTopCountries(): string[] {
    switch (this._sessionQuery.getCountry()) {
      default:
        return ['ch', 'li'];
    }
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(locale => {
      if (locale !== this._currentLocale) {
        this._optionsList = this._formatList(this._countries);
        this._currentLocale = locale;
        this._updateTranslation();
      }
    });
  }

  private _updateTranslation(): void {
    const formValue = this._control.value;

    if (formValue) {
      const currentValue = this._countries.find(country => country.iso2 === formValue.key)[
        localeAsLanguageName[this._currentLocale]
      ];

      this.typeaheadComponent.currentOption = currentValue;
      this._control.patchValue({
        key: formValue.key,
        value: currentValue
      });
    }
  }

  private monitorVariables() {
    if (this.residenceTypeConfig) {
      const residentControl = this.parentGroup.get(this.residenceTypeConfig?.control_name);
      residentControl.setValidators(Validators.required);

      this.parentGroup.get(this.controlName).valueChanges.subscribe(value => {
        if (CSuiteHomePermitExcludedCountries.indexOf(value?.key) === -1) {
          this.showPermitTypeDropdown = true;
          residentControl.setValidators(Validators.required);
          this.parentGroup.updateValueAndValidity();
        } else {
          this.showPermitTypeDropdown = false;
          residentControl.clearValidators();
          residentControl.setValue(null);
        }
        this._cdr.detectChanges();
      });
    }
  }
}
