import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export class DateValidators {
  public static format(format: string): ValidatorFn {
    return (control: AbstractControl) => {
      const date = dayjs(control.value, format, true);
      return !date.isValid() ? { dateFormat: false } : null;
    };
  }

  public static greaterThan(format: string, dateCompared: dayjs.Dayjs): ValidatorFn {
    return (control: AbstractControl) => {
      const date = dayjs(control.value, format);
      return !(date >= dateCompared) ? { dateGreaterThan: false } : null;
    };
  }

  public static invalid(format: string, dateCompared: dayjs.Dayjs): ValidatorFn {
    return (control: AbstractControl) => {
      const date = dayjs(control.value, format);
      return !(date <= dateCompared) ? { dateInvalid: false } : null;
    };
  }

  public static lessThan(format: string, dateCompared: dayjs.Dayjs): ValidatorFn {
    return (control: AbstractControl) => {
      const date = dayjs(control.value, format);
      return !(date <= dateCompared) ? { dateLessThan: false } : null;
    };
  }
}
