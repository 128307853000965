// ng-barrel
export * from './button.interface';
export * from './card-action.interface';
export * from './customer-error-data.interface';
export * from './customer.interface';
export * from './draft.interface';
export * from './header-component.interface';
export * from './radio-option.interface';
export * from './search-bar.interface';
export * from './select-tariff.interface';
