import { Component, OnInit } from '@angular/core';
import { ConfigOptions, FooterConfigOptions } from '@wefoxGroupOneBPCore/interfaces';
import { CentralizedQuery, SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { ConfigQuery } from '@wefoxGroupOneBPCore/queries/config.query';
import { Observable } from 'rxjs';

declare global {
  interface Window {
    Cookiebot: any; // eslint-disable-line
  }
}

const Cookiebot = window.Cookiebot; // eslint-disable-line

@Component({
  selector: 'one-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  public config: ConfigOptions;
  public footerOptions: FooterConfigOptions;
  public locale$: Observable<string>;
  public locale: string;

  constructor(private _common: CentralizedQuery, private _configQuery: ConfigQuery, private _sessionQuery: SessionQuery) {}

  public ngOnInit(): void {
    this._sessionQuery.locale$.subscribe(locale => {
      this.locale = locale;
    });
    if (!this._sessionQuery.isLoggedIn()) {
      this.footerOptions = this._common.getFooterOptions();
    } else {
      this._configQuery.privateFooters$.subscribe(footers => (this.footerOptions = footers));
    }
  }

  public get showCookieSettings(): boolean {
    return !!(typeof window !== 'undefined' && window.Cookiebot);
  }
}
