import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AccountService } from '@wefoxGroupOneBP/app/private/modules/account/services/account.service';
import { BaseRouteName, RedirectionService } from '@wefoxGroupOneBPShared/services/redirection.service';
import { Subject, takeUntil } from 'rxjs';

export interface AvailableToolsConfig {
  class: string;
}
export interface AvailableToolsOptions {
  isLoggedIn: boolean;
  id: string;
  display_name: string;
  url: string;
  logo_icon: string;
  username: string;
  type: string;
  retrieve_auto_login_endpoint: string;
  has_auto_login: boolean;
}

@Component({
  selector: 'one-available-tools',
  templateUrl: './available-tools.component.html',
  styleUrls: ['./available-tools.component.scss']
})
export class AvailableToolsComponent implements OnInit, OnDestroy {
  @Input() config: AvailableToolsConfig;
  protected _unsubscribe$: Subject<void> = new Subject<void>();

  public availableToolsOptions: AvailableToolsOptions[];
  public loading = false;

  constructor(
    private _redirectionService: RedirectionService,
    private _accountService: AccountService
  ) { }

  public ngOnInit(): void {
    this._getAvailableToolsOptions();
    this._accountService.availableToolsChange.asObservable()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => this._getAvailableToolsOptions());
  }

  private _getAvailableToolsOptions(): void {
    this._accountService
      .getBrokerDeExternalSites$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(tools => {
        this.availableToolsOptions = tools.map(tool => ({
          isLoggedIn: 'id' in tool,
          id: tool.external_tool.id,
          display_name: tool.external_tool.display_name,
          url: tool.external_tool.url,
          logo_icon: tool.external_tool.logo_icon,
          username: tool.username || undefined,
          type: tool.external_tool.type === 'COMPARISON' ? '_DASH_comparison_tool' : '_DASH_portfolio_tool',
          retrieve_auto_login_endpoint: tool.external_tool.retrieve_auto_login_endpoint,
          has_auto_login: tool.external_tool.has_auto_login
        }));
      });
  }

  public navigateToProfilePage(): void {
    this._redirectionService.navigate(BaseRouteName.profile);
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
