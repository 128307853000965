import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { RouteParams } from '@wefoxGroupOneBPCore/constants/route.constant';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { StepState } from '@wefoxGroupOneBPShared/components';
import { DynamicFormModelService } from '@wefoxGroupOneBPShared/services/dynamic-form-model.service';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { InfoToasterService } from '@wefoxGroupOneBPShared/services/info-toaster.service';
import { StepModel, StepService } from '@wefoxGroupOneBPShared/services/step.service';
import { filter, Observable, switchMap, takeWhile, timer } from 'rxjs';
import { FINANCIAL_SECURITY_PREF_CHECKBOX_GROUP, HEALTHCARE_PREF_CHECKBOX_GROUP, INCOME_DEPENDING_CHECKBOX_GROUP, PET_CHECKBOX_GROUP, VEHICLE_CHECKBOX_GROUP_VALUE } from './constants/risk-analysis.constant';
import { Person, Residence, RiskAnalysisRequest, Vehicle } from './interfaces/risk-analysis.interface';
import { RISK_ANALYSIS_CUSTOMER_FLOW_MODEL, RISK_ANALYSIS_MODEL } from './risk-analysis.model';
import { RiskAnalysisService } from './services/risk-analysis.service';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'one-risk-analysis',
  templateUrl: './risk-analysis.component.html'
})
export class RiskAnalysisComponent implements OnInit {
  public currentStep$: Observable<StepModel>;
  public formGroup: FormGroup;
  public formSections;
  public riskData: any; // eslint-disable-line
  public loading = false;
  public isThirdParty: boolean;

  private _customerId: string;
  private _tokenId: string;
  private _country: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _formService: FormService,
    private _infoToasterService: InfoToasterService,
    private _modelService: DynamicFormModelService,
    private _riskAnalysisService: RiskAnalysisService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sessionQuery: SessionQuery,
    private _stepService: StepService,
    private _analytics: AnalyticsService,
    private _cd: ChangeDetectorRef,
    private translocoService: TranslocoService
  ) { }

  public ngOnInit(): void {
    this._customerId = this._route.snapshot.params[RouteParams.customerId];
    this._tokenId = this._route.snapshot.params[RouteParams.token];
    this._country = this._route.snapshot.params[RouteParams.country];
    this.isThirdParty = this._route.snapshot.data.isThirdParty;
    const form_config = this._tokenId ? RISK_ANALYSIS_CUSTOMER_FLOW_MODEL.form_config : RISK_ANALYSIS_MODEL.form_config;

    this.riskData = this._modelService.initDataModel(form_config);
    this.formGroup = this._formService.generateFormGroupFromControls(this.riskData);
    this.formSections = this.riskData.form_sections;
    this._setValidators();
    this._buildSteps();
  }

  public handleAction(action): void {
    switch (action.type) {
      case 'back':
        this._moveToBackStep();
        break;
      case 'next':
        this._moveToNextStep();
        break;
      default:
        break;
    }
  }

  public submit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.loading = true;
      this._analytics.event({
        GAEventCategory: this._tokenId ? GAEventCategories.CustomerFlowRiskAnalysis : GAEventCategories.BrokerFlowRiskAnalysis,
        GAEventAction: GAEventActions.Finish,
        GAEventType: AnalyticsCustomEvents.ctaEvent,
      });

      if (!this._tokenId) {
        this._riskAnalysisService.submitRiskAnalysis$(this._buildRiskAnalysisData(), this._sessionQuery.getCountry(), this._customerId, this.isThirdParty)
          .subscribe(() => {
            timer(0, 1000).pipe(
              takeWhile((index: number) => index < 15),
              switchMap(() => this._riskAnalysisService.getRiskAnalysisProfileByCustomerId$(this._customerId, this.isThirdParty)),
              takeWhile((response) => response?.custom_profile?.length === 0, true),
              filter((response) => response?.custom_profile?.length > 0)
            ).subscribe({
              next: (response) => {
                this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/risk-analysis/${this.isThirdParty ? 'third-party' : 'wefox-customers'}/${this._customerId}/profile/${response.id}`);
                this.loading = false;
                this._riskAnalysisService.resendCustomerEmail(this._customerId, this.isThirdParty).subscribe({
                  next: () =>
                    this._infoToasterService.successMessage('_FKS_send_customer_email_success_message'),
                  error: () => this._infoToasterService.errorMessage('_FKS_send_customer_email_error_message')
                });
              },
              error: () => {
                this.loading = false;
              }
            })
          });
      } else {
        const body = {
          ...this._buildRiskAnalysisData(),
          locale: this.translocoService.getActiveLang()
        };
        this._riskAnalysisService.submitRiskAnalysisPublic$(body, this._country.toUpperCase(), this._tokenId, this.isThirdParty)
          .subscribe(() => {
            this._router.navigateByUrl(`${this._country}/risk-analysis/success`);
            this.loading = false;
          });
      }

    }
  }

  private _buildRiskAnalysisData(): Partial<RiskAnalysisRequest> {
    const formValues = this.formGroup.getRawValue();

    const requestBody = {
      consent: formValues.legal_check_1,
      situation: {
        persons: this._getPersons(formValues),
        residences: this._getResidences(formValues),
        vehicles: formValues.vehicle_ownership_check ? this._getVehicles(formValues) : [],
        pets: formValues.pet_ownership_check ? this._getType(formValues, PET_CHECKBOX_GROUP) : []
      }
    };

    return requestBody;
  }

  private _buildSteps() {
    this._stepService.initSteps(this.formSections, '_RA_section_', undefined, true);
    this.currentStep$ = this._stepService.currentStep$;
    this._cd.detectChanges();
  }

  private _getFinancialSecurityPreferences(formValues): string[] {
    const financialSecurity: string[] = [];
    Object.keys(FINANCIAL_SECURITY_PREF_CHECKBOX_GROUP).forEach(key => {
      if (formValues[key]) {
        financialSecurity.push(FINANCIAL_SECURITY_PREF_CHECKBOX_GROUP[key]);
      }
    });

    return financialSecurity;
  }

  private _getHealthcarePreferences(formValues): string[] {
    const healthcare: string[] = [];
    Object.keys(HEALTHCARE_PREF_CHECKBOX_GROUP).forEach(key => {
      if (formValues[key]) {
        healthcare.push(HEALTHCARE_PREF_CHECKBOX_GROUP[key]);
      }
    });

    return healthcare;
  }

  private _getPersons(formValues): Person[] {
    const person: Person[] = [];

    person.push(
      {
        family_role: formValues.marital_status.key === 8 ? 'other' : 'main',
        marital_status: formValues.marital_status.key,
        profession_type: formValues.profession_type.key,
        gross_income_yearly: formValues.income_gross_yearly.key,
        healthcare_preferences: this._getHealthcarePreferences(formValues),
        financial_security_preferences: this._getFinancialSecurityPreferences(formValues)
      }
    );

    if (formValues.income_depending_check) {
      Object.keys(INCOME_DEPENDING_CHECKBOX_GROUP).forEach((key) => {
        if (formValues[key]) {
          person.push(
            {
              family_role: INCOME_DEPENDING_CHECKBOX_GROUP[key],
              family_income_dependent: formValues[key]
            }
          );
        }
      });
    }

    return person;
  }

  private _getResidences(formValues): Residence[] {
    const homeTypeLookup = {
      1: { owned: false, residence_type: 'apartment' },
      2: { owned: false, residence_type: 'house' },
      3: { owned: true, residence_type: 'apartment' },
      4: { owned: true, residence_type: 'house' },
      5: { owned: false, residence_type: 'house' },
    };

    const residenceValue = formValues.home_type.key;
    const residenceData = homeTypeLookup[residenceValue];

    return [residenceData];
  }

  private _getType(formValues, checkboxConst): { type: string }[] {
    const array = [];

    Object.keys(checkboxConst).forEach(key => {
      if (formValues[key]) {
        array.push({
          type: checkboxConst[key]
        });
      }
    });

    return array;
  }

  private _getVehicles(formValues): Vehicle[] {
    const vehicle: Vehicle[] = [];

    Object.keys(VEHICLE_CHECKBOX_GROUP_VALUE).forEach(key => {
      if (formValues[key]) {
        const vehicleEntry: Vehicle = {
          type: VEHICLE_CHECKBOX_GROUP_VALUE[key]
        };

        if (key === 'vehicle_type_PKW' && formValues.vehicle_value?.key) {
          vehicleEntry.value = formValues.vehicle_value.key;
        }

        vehicle.push(vehicleEntry);
      }
    });

    return vehicle;
  }

  private _isValidSection(currentStep?: StepModel): boolean {
    const step = currentStep || this._stepService.currentStep$.getValue();
    const activeSections = this.formSections.filter(section => section.content_data.step_count === step.stepIndex);
    let controls = [];
    activeSections.forEach(section => {
      controls = controls.concat(Object.keys(this._formService.getAttributeListFromSection(section)));
    });

    return controls.every(control => {
      const isHidden = document.querySelector(`[t-selector='${control}']`) === null;
      const formControl = this.formGroup.get(control);
      return isHidden || formControl.valid || formControl.disabled;
    });
  }

  private _moveToBackStep(): void {
    if (this._isValidSection()) {
      this._stepService.setStepStatus(StepState.complete);
    } else {
      this._stepService.setStepStatus(StepState.error);
    }
    this._stepService.moveToPreviousStep();
  }

  private _moveToNextStep(): void {
    const step = this._stepService.currentStep$.getValue();
    this._scrollTop();

    if (this._isValidSection()) {
      Object.keys(this.formGroup.controls).forEach(control => {
        this.formGroup.get(control)?.setErrors(null);
        this.formGroup.get(control)?.markAsUntouched();
        this.formGroup.get(control)?.markAsPristine();
      });
      this._stepService.moveToNextStep(StepState.complete);
      this._cdr.detectChanges();
    } else {
      this.formGroup.markAllAsTouched();
      step.status = StepState.error;
    }
  }

  private _setValidators(): void {
    if (this.formGroup.get('legal_check_1')) {
      this.formGroup.get('legal_check_1').setValidators([Validators.requiredTrue]);
    }
  }

  private _scrollTop(): void {
    window.scroll(0, 0);
  }

}
