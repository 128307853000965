import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { ThirdPartyDataService } from '@wefoxGroupOneBP/app/private/modules/carriers/services/third-party-data.service';
import { ThirdPartyService } from '@wefoxGroupOneBP/app/private/modules/carriers/services/third-party.service';
import { AppInitializerService, TokenStorageService } from '@wefoxGroupOneBPCore/services';
import { SessionStore } from '@wefoxGroupOneBPCore/stores';
import { sharedComponents } from '@wefoxGroupOneBPShared/components';
import { sharedDirectives } from '@wefoxGroupOneBPShared/directives';
import {
  WgInputModule,
  WgModalModule,
  WgPaginatorModule,
  WgTable2Module,
  WgTableModule,
  WgToasterModule,
  WgTooltipModule,
  WgUiIconModule
} from '@wefoxGroupOneBPShared/modules';
import { CurrencyTransform, IbanTransform } from '@wefoxGroupOneBPShared/pipes';
import { CookieService as ngxCookieService } from 'ngx-cookie-service';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { WgLoaderModule } from './components/loader/loader.module';
import { PhoneComponent } from './components/phone/phone.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { DynamicFormModelService } from './services/dynamic-form-model.service';
import { LaunchDarklyService } from './services/launchdarkly.service';
import { PerformanceService } from './services/performance.service';
import { PortfolioTransferDataService } from './services/portfolio-transfer-data.service';
import { PortfolioTransferService } from './services/portfolio-transfer.service';
import { PushNotificationService } from './services/push-notification.service';
import { StepService } from './services/step.service';
import { TariffService } from './services/tariff.service';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [...sharedComponents, ...sharedDirectives, CurrencyTransform, IbanTransform, PhoneComponent],
  exports: [
    ...sharedComponents,
    CurrencyTransform,
    DisableControlDirective,
    IbanTransform,
    WgInputModule,
    WgTableModule,
    WgTable2Module,
    WgPaginatorModule,
    WgTooltipModule,
    WgUiIconModule,
    RouterModule,
    ...sharedDirectives,
    WgLoaderModule
  ],
  imports: [
    CommonModule,
    WgToasterModule,
    WgModalModule,
    WgTableModule,
    WgTable2Module,
    WgPaginatorModule,
    WgTooltipModule,
    WgUiIconModule,
    WgInputModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslocoModule,
    WgLoaderModule
  ],
  providers: [
    IbanTransform,
    LaunchDarklyService,
    DynamicFormModelService,
    StepService,
    TariffService,
    PushNotificationService,
    ngxCookieService,
    SessionStore, // we should remove or move this one but for now we depend of the store
    AppInitializerService, // we should remove or move this one and create a simpler initializer for all the future apps
    TokenStorageService,
    ThirdPartyService,
    ThirdPartyDataService,
    PerformanceService,
    DatePipe,
    PortfolioTransferDataService,
    PortfolioTransferService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
