import { AfterViewInit, ChangeDetectorRef, Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[wgDisableControl]'
})
export class DisableControlDirective implements AfterViewInit {
  @Input() public toDisabled = true;

  constructor(private ngControl: NgControl, private _cdr: ChangeDetectorRef) {}
  public ngAfterViewInit(): void {
    if (this.toDisabled) {
      setTimeout(() => {
        this.ngControl.control.disable();
      });
    }
  }
}
