import { Validators } from '@angular/forms';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { ModelDefinition } from '@wefoxGroupOneBPCore/interfaces/model-definition.interface';
import { emailPattern } from '@wefoxGroupOneBPShared/constants';
import {
  COMMON_IN_ALL_FORM_MODEL,
  COMMON_SWITCH_MOTOR_COVERAGES_MODEL_DE,
  CUSTOMER_MODEL,
  DIRECT_DEBIT_MODEL,
  FILE_UPLOAD_MODEL,
  LEGAL_ENTITY_MODEL,
  VEHICLE_ID_MODEL_DE
} from '@wefoxGroupOneBPShared/models/common.models';
import { LicensePlateValidators } from '@wefoxGroupOneBPShared/validators/licence-plate-prefix.validator';
import { ValidatorService } from 'angular-iban';

// We need to keep this because it is used in the customer details page (until that is refactored)
export const SWITCH_MOTOR_MODEL_DEFINITION: ModelDefinition = {
  attributes: [],
  defaults: {},
  validators: {
    birthday: [Validators.required],
    direct_debit_iban: [Validators.required, ValidatorService.validateIban],
    direct_debit_iban_name_holder: [Validators.required, Validators.pattern(ProductValidations.notOnlySpacesPattern)],
    email: [Validators.maxLength(80), Validators.required, Validators.pattern(emailPattern)],
    firstname: [Validators.maxLength(20), Validators.required, Validators.pattern(ProductValidations.namePattern)],
    gender: [Validators.required],
    house_number: [Validators.required, Validators.pattern(ProductValidations.houseNumberPattern)],
    lastname: [Validators.maxLength(50), Validators.required, Validators.pattern(ProductValidations.namePattern)],
    legal_name: [
      Validators.maxLength(50),
      Validators.required,
      Validators.pattern(ProductValidations.legalNamePattern)
    ],
    license_plate: [
      Validators.required,
      LicensePlateValidators.licensePlatePrefix,
      Validators.pattern(ProductValidations.licensePlatePatternDE)
    ],
    mobile_phone: [Validators.pattern(ProductValidations.phonePattern)],
    payment_frequency: [Validators.required], // TODO ask
    phone_number: [Validators.required, Validators.pattern(ProductValidations.phonePattern)], // TODO and mobile?
    place: [Validators.maxLength(25), Validators.required, Validators.pattern(ProductValidations.placePattern)],
    policy_number_previous_insurance: [
      Validators.maxLength(20),
      Validators.required,
      Validators.pattern(ProductValidations.previousInsurerNumberPattern)
    ],
    postal_code: [
      Validators.maxLength(6),
      Validators.required,
      Validators.pattern(ProductValidations.postalCodePattern)
    ],
    previous_insurance_premium_with_tax: [Validators.required, Validators.min(1)],
    previous_insurer: [Validators.required],
    street: [
      Validators.required,
      Validators.maxLength(27),
      Validators.pattern(ProductValidations.notOnlySpacesPattern)
    ],
    vehicle_id: [Validators.required, Validators.pattern(ProductValidations.FINPattern)]
  }
};

export const common_switch_motor_model = {
  product_form_config: {
    calculation_config: {
      conclude_package: [{ target_data: 'currentPackage', wc_parameter: 'product.motor.package' }],
      inputs: [
        {
          form_control_name: 'coverage_type',
          transform_type: 'product_type',
          wc_parameter: 'product.motor.product-type'
        },
        {
          form_control_name: 'previous_insurance_premium_with_tax',
          transform_type: 'currency',
          wc_parameter: 'product.motor.previous-premium-amount'
        },
        {
          form_control_name: 'birthday',
          transform_type: 'date',
          wc_parameter: 'product.motor.policyholder.birthdate'
        },
        {
          form_control_name: 'postal_code',
          wc_parameter: 'product.motor.policyholder.address.postcode'
        },
        {
          form_control_name: 'discount_protection',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.discount-protection.is-included'
        },
        {
          form_control_name: 'gap_coverage',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.comprehensive.fully.gap-coverage.is-included'
        },
        {
          form_control_name: 'car_assistance_yes',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.vehicle-assistance.is-included'
        },
        {
          form_control_name: 'payment_frequency',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.previous-premium-payment-interval'
        },
        {
          target_data: 'brokerDiscount',
          transform_type: 'percentage',
          wc_parameter: 'product.motor.switch-discount'
        },
        {
          form_control_name: 'partial_comprehensive_deductible',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'partial_comprehensive_deductible'
              },
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.coverage.comprehensive.partial.deductible'
        },
        {
          form_control_name: 'fully_comprehensive_deductible',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.coverage.comprehensive.fully.deductible'
        },
        {
          form_control_name: 'sf_class_full',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.no-claims-discount-comprehensive.level'
        },
        {
          form_control_name: 'sf_class_liability',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.no-claims-discount-liability.level'
        }
      ],
      quote_packages: [
        {
          immediate_return: true,
          value: 'car-switch',
          wc_parameter: 'product.motor.package'
        },
        {
          immediate_return: true,
          value: 'car-switch-green',
          wc_parameter: 'product.motor.package'
        }
      ],
      required_calculate_fields: [
        'current_contract_confirmation',
        'payment_frequency',
        'previous_insurance_premium_with_tax',
        'postal_code',
        'sf_class_liability'
      ],
      required_to_calculate_if: [
        {
          depends_on: {
            control_input: 'coverage_type',
            value: 'fully_comprehensive_deductible'
          },
          control_name: 'sf_class_full'
        },
        {
          depends_on: {
            control_input: 'policy_holder',
            value: 'privatePerson'
          },
          control_name: 'birthday'
        }
      ],
      trigger_calculate_fields: [
        'current_contract_confirmation',
        'fully_comprehensive_deductible',
        'partial_comprehensive_deductible',
        'previous_insurance_premium_with_tax',
        'birthday',
        'postal_code',
        'payment_frequency',
        'sf_class_full',
        'sf_class_liability'
      ]
    },
    conclude_config: {
      insured_object_inputs: [
        {
          form_control_name: 'license_plate',
          wc_parameter: 'object.vehicle.license-plate'
        },
        {
          form_control_name: 'vehicle_id',
          transform_type: 'uppercase',

          wc_parameter: 'object.vehicle.id'
        },
        {
          form_control_name: 'previous_insurer',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.previous-insurer-id'
        },
        {
          form_control_name: 'policy_number_previous_insurance',
          wc_parameter: 'product.motor.previous-policy-number'
        }
      ],
      product_type: 'car'
    },
    form_sections: [
      {
        content_data: {
          config: {
            age: { greater_than: 25, less_than: 70 },
            age_margin: { max_age: 70, min_age: 25 },
            can_be_legal_entity: true,
            can_prefill_customer: true,
            dialog_options: {
              description: '_PROD_prefilling_dialog_not_valid_description',
              i18n_key: '_ST_MOTOR',
              title: '_PROD_prefilling_dialog_not_valid_title'
            },
            disabled_fields: [],
            has_disabled_customer_fields: false,
            i18n_age_range_key: '_ST_MOTOR',
            i18n_prefix_key: '_ST_MOTOR',
            is_prefilled: false,
            preferred_language: false
          },
          section_title: '_ST_MOTOR_customer_info_title'
        },
        type: 'customerSection'
      },
      {
        content_data: {
          config: {
            content_description: '_PROD_payment_description',
            content_title: '_PROD_payment_card_subtitle',
            i18n_prefix_key: '_ST_MOTOR',
            show_description: true,
            show_title: true
          },
          section_id: 'bank_info_title',
          section_title: '_ST_MOTOR_bank_info_title'
        },
        type: 'paymentSection'
      },
      {
        content_data: {
          config: {},
          section_id: 'car_info_title',
          section_title: '_ST_MOTOR_car_info_title'
        },
        type: 'vehicleIdSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      control_name: 'current_contract_confirmation',
                      error_key: '_ST_MOTOR_current_contract_checkbox_error_message',
                      label: '_ST_MOTOR_current_contract_confirmation_checkbox',
                      required: true
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'previous_insurer',
                      label: '_ST_MOTOR_previous_insurer_id',
                      required: true
                    },
                    type: 'typeahead'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'policy_number_previous_insurance',
                      error_keys: { max_length: '_ST_MOTOR_policy_number_previous_insurance_maxlength_error' },
                      label: '_ST_MOTOR_policy_number_previous_insurance',
                      placeholder: '',
                      required: true,
                      type: 'text'
                    },
                    type: 'input'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'previous_insurance_premium_with_tax',
                      i18n_prefix_key: '_ST_MOTOR_',
                      label: '_ST_MOTOR_previous_insurance_premium_with_tax',
                      required: true
                    },
                    type: 'amount'
                  },
                  {
                    options: {
                      control_name: 'payment_frequency',
                      label: '_PROD_payment_frequency_label',
                      required: true,
                      source_name: 'payment_frequency',
                      sources: [
                        {
                          name: 'payment_frequency',
                          values: [
                            {
                              key: 'yearly',
                              value: '_PROD_payment_frequency_yearly'
                            },
                            {
                              key: 'half-yearly',
                              value: '_PROD_payment_frequency_half_yearly'
                            },
                            {
                              key: 'quarterly',
                              value: '_PROD_payment_frequency_quarterly'
                            },
                            {
                              key: 'monthly',
                              value: '_PROD_payment_frequency_monthly'
                            }
                          ]
                        }
                      ],
                      translation_options: null
                    },
                    type: 'dropdown'
                  }
                ],
                columns_modifier: 'cols-tablet-2'
              },
              {
                columns: [
                  {
                    options: {
                      content_title: '_ST_MOTOR_start_date_title'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      greater_than: {
                        by_years: 25,
                        input_name: 'birthday'
                      },
                      less_than: {
                        by_years: 70,
                        input_name: 'birthday'
                      }
                    },
                    type: 'start-date'
                  }
                ]
              }
            ]
          },
          section_id: 'current_policy_info_title',
          section_title: '_ST_MOTOR_current_policy_info_title'
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      content_title: '_ST_MOTOR_existing_vehicle_coverage_title'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'coverage_type',
                      radio_options: [
                        {
                          checked: true,
                          label: '_NT_MOTOR_liability',
                          value: 'vehicle_liability_deductible'
                        },
                        {
                          label: '_NT_MOTOR_partial_comprehensive',
                          value: 'partial_comprehensive_deductible'
                        },
                        {
                          label: '_NT_MOTOR_fully_comprehensive',
                          value: 'fully_comprehensive_deductible'
                        }
                      ]
                    },
                    type: 'radio'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'partial_comprehensive_deductible',
                      default_value: '150',
                      label: '_NT_MOTOR_partial_comprehensive_deductible_label',
                      required: true,
                      source_name: 'partial_comprehensive_deductible',
                      sources: [
                        {
                          name: 'partial_comprehensive_deductible',
                          values: [
                            {
                              key: '0',
                              value: '0'
                            },
                            {
                              key: '150',
                              value: '150'
                            },
                            {
                              key: '300',
                              value: '300'
                            },
                            {
                              key: '500',
                              value: '500'
                            },
                            {
                              key: '1000',
                              value: '1000'
                            },
                            {
                              key: '2500',
                              value: '2500'
                            },
                            {
                              key: '5000',
                              value: '5000'
                            }
                          ]
                        }
                      ],
                      translation_options: {
                        additionalKey: '_NT_MOTOR_partial_comprehensive',
                        hasColon: true,
                        hasCurrency: true,
                        position: 'prefix'
                      }
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'fully_comprehensive_deductible',
                      default_value: '300',
                      label: '_NT_MOTOR_fully_comprehensive_deductible_label',
                      required: true,
                      source_name: 'fully_comprehensive_deductible',
                      sources: [
                        {
                          name: 'fully_comprehensive_deductible',
                          values: [
                            {
                              key: '0',
                              value: '0'
                            },
                            {
                              key: '150',
                              value: '150'
                            },
                            {
                              key: '300',
                              value: '300'
                            },
                            {
                              key: '500',
                              value: '500'
                            },
                            {
                              key: '1000',
                              value: '1000'
                            },
                            {
                              key: '2500',
                              value: '2500'
                            },
                            {
                              key: '5000',
                              value: '5000'
                            }
                          ]
                        }
                      ],
                      translation_options: {
                        additionalKey: '_NT_MOTOR_fully_comprehensive',
                        hasColon: true,
                        hasCurrency: true,
                        position: 'prefix'
                      }
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: { content: '_NT_MOTOR_SF_class_dropdowns', control_name: 'sf_class_banner', type: 'info' },
                    type: 'info-banner'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_NT_MOTOR_SF_class_dropdowns_label'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'sf_class_liability',
                      label: '_NT_MOTOR_sf_class_liability_label',
                      required: true,
                      source_name: 'sf_class_liability',
                      sources: [
                        {
                          name: 'sf_class_liability',
                          values: [
                            {
                              key: '0',
                              value: 'SF0'
                            },
                            {
                              key: 'SF1/2',
                              value: 'SF1/2'
                            },
                            {
                              key: 'SF1',
                              value: 'SF1'
                            },
                            {
                              key: 'SF2',
                              value: 'SF2'
                            },
                            {
                              key: 'SF3',
                              value: 'SF3'
                            },
                            {
                              key: 'SF4',
                              value: 'SF4'
                            },
                            {
                              key: 'SF5',
                              value: 'SF5'
                            },
                            {
                              key: 'SF6',
                              value: 'SF6'
                            },
                            {
                              key: 'SF7',
                              value: 'SF7'
                            },
                            {
                              key: 'SF8',
                              value: 'SF8'
                            },
                            {
                              key: 'SF9',
                              value: 'SF9'
                            },
                            {
                              key: 'SF10',
                              value: 'SF10'
                            },
                            {
                              key: 'SF11',
                              value: 'SF11'
                            },
                            {
                              key: 'SF12',
                              value: 'SF12'
                            },
                            {
                              key: 'SF13',
                              value: 'SF13'
                            },
                            {
                              key: 'SF14',
                              value: 'SF14'
                            },
                            {
                              key: 'SF15',
                              value: 'SF15'
                            },
                            {
                              key: 'SF16',
                              value: 'SF16'
                            },
                            {
                              key: 'SF17',
                              value: 'SF17'
                            },
                            {
                              key: 'SF18',
                              value: 'SF18'
                            },
                            {
                              key: 'SF19',
                              value: 'SF19'
                            },
                            {
                              key: 'SF20',
                              value: 'SF20'
                            },
                            {
                              key: 'SF21',
                              value: 'SF21'
                            },
                            {
                              key: 'SF22',
                              value: 'SF22'
                            },
                            {
                              key: 'SF23',
                              value: 'SF23'
                            },
                            {
                              key: 'SF24',
                              value: 'SF24'
                            },
                            {
                              key: 'SF25',
                              value: 'SF25'
                            },
                            {
                              key: 'SF26',
                              value: 'SF26'
                            },
                            {
                              key: 'SF27',
                              value: 'SF27'
                            },
                            {
                              key: 'SF28',
                              value: 'SF28'
                            },
                            {
                              key: 'SF29',
                              value: 'SF29'
                            },
                            {
                              key: 'SF30',
                              value: 'SF30'
                            },
                            {
                              key: 'SF31',
                              value: 'SF31'
                            },
                            {
                              key: 'SF32',
                              value: 'SF32'
                            },
                            {
                              key: 'SF33',
                              value: 'SF33'
                            },
                            {
                              key: 'SF34',
                              value: 'SF34'
                            },
                            {
                              key: 'SF35',
                              value: 'SF35'
                            },
                            {
                              key: 'SF36',
                              value: 'SF36'
                            },
                            {
                              key: 'SF37',
                              value: 'SF37'
                            },
                            {
                              key: 'SF38',
                              value: 'SF38'
                            },
                            {
                              key: 'SF39',
                              value: 'SF39'
                            },
                            {
                              key: 'SF40',
                              value: 'SF40'
                            },
                            {
                              key: 'SF41',
                              value: 'SF41'
                            },
                            {
                              key: 'SF42',
                              value: 'SF42'
                            },
                            {
                              key: 'SF43',
                              value: 'SF43'
                            },
                            {
                              key: 'SF44',
                              value: 'SF44'
                            },
                            {
                              key: 'SF45',
                              value: 'SF45'
                            },
                            {
                              key: 'SF46',
                              value: 'SF46'
                            },
                            {
                              key: 'SF47',
                              value: 'SF47'
                            },
                            {
                              key: 'SF48',
                              value: 'SF48'
                            },
                            {
                              key: 'SF49',
                              value: 'SF49'
                            },
                            {
                              key: 'SF50',
                              value: 'SF50'
                            },
                            {
                              key: 'S',
                              value: 'S'
                            },
                            {
                              key: 'M',
                              value: 'M'
                            }
                          ]
                        }
                      ]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'sf_class_full',
                      label: '_NT_MOTOR_sf_class_full_label',
                      required: true,
                      source_name: 'sf_class_full',
                      sources: [
                        {
                          name: 'sf_class_full',
                          values: [
                            {
                              key: '0',
                              value: 'SF0'
                            },
                            {
                              key: 'SF1/2',
                              value: 'SF1/2'
                            },
                            {
                              key: 'SF1',
                              value: 'SF1'
                            },
                            {
                              key: 'SF2',
                              value: 'SF2'
                            },
                            {
                              key: 'SF3',
                              value: 'SF3'
                            },
                            {
                              key: 'SF4',
                              value: 'SF4'
                            },
                            {
                              key: 'SF5',
                              value: 'SF5'
                            },
                            {
                              key: 'SF6',
                              value: 'SF6'
                            },
                            {
                              key: 'SF7',
                              value: 'SF7'
                            },
                            {
                              key: 'SF8',
                              value: 'SF8'
                            },
                            {
                              key: 'SF9',
                              value: 'SF9'
                            },
                            {
                              key: 'SF10',
                              value: 'SF10'
                            },
                            {
                              key: 'SF11',
                              value: 'SF11'
                            },
                            {
                              key: 'SF12',
                              value: 'SF12'
                            },
                            {
                              key: 'SF13',
                              value: 'SF13'
                            },
                            {
                              key: 'SF14',
                              value: 'SF14'
                            },
                            {
                              key: 'SF15',
                              value: 'SF15'
                            },
                            {
                              key: 'SF16',
                              value: 'SF16'
                            },
                            {
                              key: 'SF17',
                              value: 'SF17'
                            },
                            {
                              key: 'SF18',
                              value: 'SF18'
                            },
                            {
                              key: 'SF19',
                              value: 'SF19'
                            },
                            {
                              key: 'SF20',
                              value: 'SF20'
                            },
                            {
                              key: 'SF21',
                              value: 'SF21'
                            },
                            {
                              key: 'SF22',
                              value: 'SF22'
                            },
                            {
                              key: 'SF23',
                              value: 'SF23'
                            },
                            {
                              key: 'SF24',
                              value: 'SF24'
                            },
                            {
                              key: 'SF25',
                              value: 'SF25'
                            },
                            {
                              key: 'SF26',
                              value: 'SF26'
                            },
                            {
                              key: 'SF27',
                              value: 'SF27'
                            },
                            {
                              key: 'SF28',
                              value: 'SF28'
                            },
                            {
                              key: 'SF29',
                              value: 'SF29'
                            },
                            {
                              key: 'SF30',
                              value: 'SF30'
                            },
                            {
                              key: 'SF31',
                              value: 'SF31'
                            },
                            {
                              key: 'SF32',
                              value: 'SF32'
                            },
                            {
                              key: 'SF33',
                              value: 'SF33'
                            },
                            {
                              key: 'SF34',
                              value: 'SF34'
                            },
                            {
                              key: 'SF35',
                              value: 'SF35'
                            },
                            {
                              key: 'SF36',
                              value: 'SF36'
                            },
                            {
                              key: 'SF37',
                              value: 'SF37'
                            },
                            {
                              key: 'SF38',
                              value: 'SF38'
                            },
                            {
                              key: 'SF39',
                              value: 'SF39'
                            },
                            {
                              key: 'SF40',
                              value: 'SF40'
                            },
                            {
                              key: 'SF41',
                              value: 'SF41'
                            },
                            {
                              key: 'SF42',
                              value: 'SF42'
                            },
                            {
                              key: 'SF43',
                              value: 'SF43'
                            },
                            {
                              key: 'SF44',
                              value: 'SF44'
                            },
                            {
                              key: 'SF45',
                              value: 'SF45'
                            },
                            {
                              key: 'SF46',
                              value: 'SF46'
                            },
                            {
                              key: 'SF47',
                              value: 'SF47'
                            },
                            {
                              key: 'SF48',
                              value: 'SF48'
                            },
                            {
                              key: 'SF49',
                              value: 'SF49'
                            },
                            {
                              key: 'SF50',
                              value: 'SF50'
                            },
                            {
                              key: 'S',
                              value: 'S'
                            },
                            {
                              key: 'M',
                              value: 'M'
                            }
                          ]
                        }
                      ]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {},
                    type: 'separator'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_PROD_additional_coverages_label'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'car_assistance_yes',
                      label: '_ST_MOTOR_vehicle_liability_deductible'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'discount_protection',
                      label: '_NT_MOTOR_discount_protection'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'gap_coverage',
                      label: '_NT_MOTOR_gap_coverage'
                    },
                    type: 'checkbox'
                  }
                ]
              }
            ]
          },
          section_id: 'existing_insurance_contract',
          section_title: '_ST_MOTOR_coverage_selector_section_title'
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          section_id: 'upload_title'
        },
        type: 'documentsSection'
      },
      {
        content_data: {
          config: {
            description_title: '_NT_MOTOR_tariff_selection_benefits',
            descriptions: ['_ST_MOTOR_benefits_text_1_NEW', '_ST_MOTOR_benefits_text_2', '_ST_MOTOR_benefits_text_3'],
            descriptions_row_modifiers: ['64px', '120px', '64px'],
            green_packages: {},
            has_go_green_descriptions: true,
            packages: [
              {
                coverages: {
                  motor: [
                    {
                      available: true
                    },
                    {
                      available: true
                    },
                    {
                      available: true
                    }
                  ]
                },
                default: true,
                name: 'basic',
                value: 'car-switch'
              },
              {
                coverages: {
                  motor: [{ available: false }, { available: true }, { available: true }]
                },
                default: false,
                name: 'green',
                value: 'car-switch-green'
              }
            ]
          },
          package_definition: {
            basic: {
              default: true,
              name: 'basic',
              value: 'car-switch'
            },
            green: {
              name: 'green',
              value: 'car-switch-green'
            }
          },
          package_parameter_name: 'product.motor.package',
          prefix: '_ST_MOTOR_title',
          product_type_default: 'motor',
          section_id: 'tariff_title',
          section_margin_bottom: 'mb-huge2x',
          section_title: '_ST_MOTOR_tariff_title'
        },
        type: 'tariffSection'
      },
      {
        content_data: {
          config: {},
          section_id: 'conclude_section',
          section_title: ''
        },
        type: 'concludeSection'
      }
    ],
    header: {
      subtitle: '_ST_MOTOR_main_description',
      title: '_ST_MOTOR_main_title'
    },
    model: [
      ...COMMON_IN_ALL_FORM_MODEL,
      ...COMMON_SWITCH_MOTOR_COVERAGES_MODEL_DE,
      ...CUSTOMER_MODEL,
      ...DIRECT_DEBIT_MODEL,
      ...[{ ...FILE_UPLOAD_MODEL[0], validators: [{ type: 'required' }] }],
      ...LEGAL_ENTITY_MODEL,
      ...VEHICLE_ID_MODEL_DE,
      { attribute: 'policy_holder', defaults: {}, validators: [] },
      { attribute: 'current_contract_confirmation', defaults: {}, validators: [{ type: 'required' }] }
    ],
    offer_config: {
      additional_inputs: [
        {
          form_control_name: 'license_plate',
          transform_type: 'uppercase',
          wc_parameter: 'object.vehicle.license-plate'
        },
        {
          form_control_name: 'vehicle_id',
          transform_type: 'uppercase',
          wc_parameter: 'object.vehicle.id'
        },
        { target_data: 'currentPackage', wc_parameter: 'product.motor.package' }
      ],
      excluded_sections: ['bank_info_title', 'upload_title'],
      fields_to_exclude: [
        'email',
        'phone_number',
        'mobile_phone',
        'street',
        'house_number',
        'file',
        'direct_debit_iban_name_holder',
        'direct_debit_iban',
        'previous_insurer',
        'policy_number_previous_insurance'
      ],
      modified_sections: {
        current_policy_info_title: {
          content_data: {
            config: {
              controls: [
                {
                  columns: [
                    {
                      options: {
                        control_name: 'current_contract_confirmation',
                        error_key: '_ST_MOTOR_current_contract_checkbox_error_message',
                        label: '_ST_MOTOR_current_contract_confirmation_checkbox',
                        required: true
                      },
                      type: 'checkbox'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      options: {
                        control_name: 'previous_insurance_premium_with_tax',
                        i18n_prefix_key: '_ST_MOTOR_',
                        label: '_ST_MOTOR_previous_insurance_premium_with_tax',
                        required: true
                      },
                      type: 'amount'
                    },
                    {
                      options: {
                        control_name: 'payment_frequency',
                        label: '_PROD_payment_frequency_label',
                        required: true,
                        source_name: 'payment_frequency',
                        sources: [
                          {
                            name: 'payment_frequency',
                            values: [
                              {
                                key: 'yearly',
                                value: '_PROD_payment_frequency_yearly'
                              },
                              {
                                key: 'half-yearly',
                                value: '_PROD_payment_frequency_half_yearly'
                              },
                              {
                                key: 'quarterly',
                                value: '_PROD_payment_frequency_quarterly'
                              },
                              {
                                key: 'monthly',
                                value: '_PROD_payment_frequency_monthly'
                              }
                            ]
                          }
                        ],
                        translation_options: null
                      },
                      type: 'dropdown'
                    }
                  ],
                  columns_modifier: 'cols-tablet-2'
                },
                {
                  columns: [
                    {
                      options: {
                        content_title: '_ST_MOTOR_start_date_title'
                      },
                      type: 'texts'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      options: {
                        greater_than: {
                          by_years: 25,
                          input_name: 'birthday'
                        },
                        less_than: {
                          by_years: 70,
                          input_name: 'birthday'
                        },
                        required: true
                      },
                      type: 'start-date'
                    }
                  ]
                }
              ]
            },
            section_id: 'current_policy_info_title',
            section_title: '_ST_MOTOR_current_policy_info_title'
          },
          type: 'dynamicSection'
        }
      }
    },
    product_prefix: '_ST_MOTOR_',
    variables: {
      coverage_type: 'vehicle_liability_deductible'
    },
    variables_dependencies: [
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['partial_comprehensive_deductible', 'fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'partial_comprehensive_deductible',
        validators: [{ type: 'required' }]
      },
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'fully_comprehensive_deductible',
        validators: [{ type: 'required' }]
      },
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'sf_class_full',
        validators: [{ type: 'required' }]
      }
    ]
  }
};

export const SWITCH_MOTOR_MODEL_GERMAN_BRANCH = {
  product_form_config: {
    calculation_config: {
      conclude_package: [{ target_data: 'currentPackage', wc_parameter: 'product.motor.package' }],
      inputs: [
        {
          form_control_name: 'coverage_type',
          transform_type: 'product_type',
          wc_parameter: 'product.motor.product-type'
        },
        {
          form_control_name: 'previous_insurance_premium_with_tax',
          transform_type: 'currency',
          wc_parameter: 'product.motor.previous-premium-amount'
        },
        {
          form_control_name: 'birthday',
          transform_type: 'date',
          wc_parameter: 'product.motor.policyholder.birthdate'
        },
        {
          form_control_name: 'postal_code',
          wc_parameter: 'product.motor.policyholder.address.postcode'
        },
        {
          form_control_name: 'discount_protection',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.discount-protection.is-included'
        },
        {
          form_control_name: 'gap_coverage',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.comprehensive.fully.gap-coverage.is-included'
        },
        {
          form_control_name: 'car_assistance_yes',
          transform_type: 'checkbox',
          wc_parameter: 'product.motor.coverage.vehicle-assistance.is-included'
        },
        {
          form_control_name: 'payment_frequency',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.previous-premium-payment-interval'
        },
        {
          target_data: 'brokerDiscount',
          transform_type: 'percentage',
          wc_parameter: 'product.motor.switch-discount'
        },
        {
          form_control_name: 'partial_comprehensive_deductible',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'partial_comprehensive_deductible'
              },
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.coverage.comprehensive.partial.deductible'
        },
        {
          form_control_name: 'fully_comprehensive_deductible',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.coverage.comprehensive.fully.deductible'
        },
        {
          form_control_name: 'sf_class_full',
          required_if: {
            controls: [
              {
                target: 'coverage_type',
                value: 'fully_comprehensive_deductible'
              }
            ]
          },
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.no-claims-discount-comprehensive.level'
        },
        {
          form_control_name: 'sf_class_liability',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.no-claims-discount-liability.level'
        }
      ],
      quote_packages: [
        {
          immediate_return: true,
          value: 'car-switch',
          wc_parameter: 'product.motor.package'
        }
      ],
      required_calculate_fields: [
        'current_contract_confirmation',
        'payment_frequency',
        'previous_insurance_premium_with_tax',
        'postal_code',
        'sf_class_liability'
      ],
      required_to_calculate_if: [
        {
          depends_on: {
            control_input: 'coverage_type',
            value: 'fully_comprehensive_deductible'
          },
          control_name: 'sf_class_full'
        },
        {
          depends_on: {
            control_input: 'policy_holder',
            value: 'privatePerson'
          },
          control_name: 'birthday'
        }
      ],
      trigger_calculate_fields: [
        'current_contract_confirmation',
        'fully_comprehensive_deductible',
        'partial_comprehensive_deductible',
        'previous_insurance_premium_with_tax',
        'birthday',
        'postal_code',
        'payment_frequency',
        'sf_class_full',
        'sf_class_liability'
      ]
    },
    conclude_config: {
      insured_object_inputs: [
        {
          form_control_name: 'license_plate',
          wc_parameter: 'object.vehicle.license-plate'
        },
        {
          form_control_name: 'vehicle_id',
          transform_type: 'uppercase',

          wc_parameter: 'object.vehicle.id'
        },
        {
          form_control_name: 'previous_insurer',
          transform_type: 'dropdown',
          wc_parameter: 'product.motor.previous-insurer-id'
        },
        {
          form_control_name: 'policy_number_previous_insurance',
          wc_parameter: 'product.motor.previous-policy-number'
        }
      ],
      product_type: 'car'
    },
    form_sections: [
      {
        content_data: {
          config: {
            age: { greater_than: 25, less_than: 70 },
            age_margin: { max_age: 70, min_age: 25 },
            can_be_legal_entity: true,
            can_prefill_customer: true,
            dialog_options: {
              description: '_PROD_prefilling_dialog_not_valid_description',
              i18n_key: '_ST_MOTOR',
              title: '_PROD_prefilling_dialog_not_valid_title'
            },
            disabled_fields: [],
            has_disabled_customer_fields: false,
            i18n_age_range_key: '_ST_MOTOR',
            i18n_prefix_key: '_ST_MOTOR',
            is_prefilled: false,
            preferred_language: false
          },
          section_title: '_ST_MOTOR_customer_info_title'
        },
        type: 'customerSection'
      },
      {
        content_data: {
          config: {
            content_description: '_PROD_payment_description',
            content_title: '_PROD_payment_card_subtitle',
            i18n_prefix_key: '_ST_MOTOR',
            show_description: true,
            show_title: true
          },
          section_id: 'bank_info_title',
          section_title: '_ST_MOTOR_bank_info_title'
        },
        type: 'paymentSection'
      },
      {
        content_data: {
          config: {},
          section_id: 'car_info_title',
          section_title: '_ST_MOTOR_car_info_title'
        },
        type: 'vehicleIdSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      control_name: 'current_contract_confirmation',
                      error_key: '_ST_MOTOR_current_contract_checkbox_error_message',
                      label: '_ST_MOTOR_current_contract_confirmation_checkbox',
                      required: true
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'previous_insurer',
                      label: '_ST_MOTOR_previous_insurer_id',
                      required: true
                    },
                    type: 'typeahead'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'policy_number_previous_insurance',
                      error_keys: { max_length: '_ST_MOTOR_policy_number_previous_insurance_maxlength_error' },
                      label: '_ST_MOTOR_policy_number_previous_insurance',
                      placeholder: '',
                      required: true,
                      type: 'text'
                    },
                    type: 'input'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'previous_insurance_premium_with_tax',
                      i18n_prefix_key: '_ST_MOTOR_',
                      label: '_ST_MOTOR_previous_insurance_premium_with_tax',
                      required: true
                    },
                    type: 'amount'
                  },
                  {
                    options: {
                      control_name: 'payment_frequency',
                      label: '_PROD_payment_frequency_label',
                      required: true,
                      source_name: 'payment_frequency',
                      sources: [
                        {
                          name: 'payment_frequency',
                          values: [
                            {
                              key: 'yearly',
                              value: '_PROD_payment_frequency_yearly'
                            },
                            {
                              key: 'half-yearly',
                              value: '_PROD_payment_frequency_half_yearly'
                            },
                            {
                              key: 'quarterly',
                              value: '_PROD_payment_frequency_quarterly'
                            },
                            {
                              key: 'monthly',
                              value: '_PROD_payment_frequency_monthly'
                            }
                          ]
                        }
                      ],
                      translation_options: null
                    },
                    type: 'dropdown'
                  }
                ],
                columns_modifier: 'cols-tablet-2'
              },
              {
                columns: [
                  {
                    options: {
                      content_title: '_ST_MOTOR_start_date_title'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      greater_than: {
                        by_years: 25,
                        input_name: 'birthday'
                      },
                      less_than: {
                        by_years: 70,
                        input_name: 'birthday'
                      }
                    },
                    type: 'start-date'
                  }
                ]
              }
            ]
          },
          section_id: 'current_policy_info_title',
          section_title: '_ST_MOTOR_current_policy_info_title'
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      content_title: '_ST_MOTOR_existing_vehicle_coverage_title'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'coverage_type',
                      radio_options: [
                        {
                          checked: true,
                          label: '_NT_MOTOR_liability',
                          value: 'vehicle_liability_deductible'
                        },
                        {
                          label: '_NT_MOTOR_partial_comprehensive',
                          value: 'partial_comprehensive_deductible'
                        },
                        {
                          label: '_NT_MOTOR_fully_comprehensive',
                          value: 'fully_comprehensive_deductible'
                        }
                      ]
                    },
                    type: 'radio'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'partial_comprehensive_deductible',
                      default_value: '150',
                      label: '_NT_MOTOR_partial_comprehensive_deductible_label',
                      required: true,
                      source_name: 'partial_comprehensive_deductible',
                      sources: [
                        {
                          name: 'partial_comprehensive_deductible',
                          values: [
                            {
                              key: '0',
                              value: '0'
                            },
                            {
                              key: '150',
                              value: '150'
                            },
                            {
                              key: '300',
                              value: '300'
                            },
                            {
                              key: '500',
                              value: '500'
                            },
                            {
                              key: '1000',
                              value: '1000'
                            },
                            {
                              key: '2500',
                              value: '2500'
                            },
                            {
                              key: '5000',
                              value: '5000'
                            }
                          ]
                        }
                      ],
                      translation_options: {
                        additionalKey: '_NT_MOTOR_partial_comprehensive',
                        hasColon: true,
                        hasCurrency: true,
                        position: 'prefix'
                      }
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'fully_comprehensive_deductible',
                      default_value: '300',
                      label: '_NT_MOTOR_fully_comprehensive_deductible_label',
                      required: true,
                      source_name: 'fully_comprehensive_deductible',
                      sources: [
                        {
                          name: 'fully_comprehensive_deductible',
                          values: [
                            {
                              key: '0',
                              value: '0'
                            },
                            {
                              key: '150',
                              value: '150'
                            },
                            {
                              key: '300',
                              value: '300'
                            },
                            {
                              key: '500',
                              value: '500'
                            },
                            {
                              key: '1000',
                              value: '1000'
                            },
                            {
                              key: '2500',
                              value: '2500'
                            },
                            {
                              key: '5000',
                              value: '5000'
                            }
                          ]
                        }
                      ],
                      translation_options: {
                        additionalKey: '_NT_MOTOR_fully_comprehensive',
                        hasColon: true,
                        hasCurrency: true,
                        position: 'prefix'
                      }
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: { content: '_NT_MOTOR_SF_class_dropdowns', control_name: 'sf_class_banner', type: 'info' },
                    type: 'info-banner'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_NT_MOTOR_SF_class_dropdowns_label'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'sf_class_liability',
                      label: '_NT_MOTOR_sf_class_liability_label',
                      required: true,
                      source_name: 'sf_class_liability',
                      sources: [
                        {
                          name: 'sf_class_liability',
                          values: [
                            {
                              key: '0',
                              value: 'SF0'
                            },
                            {
                              key: 'SF1/2',
                              value: 'SF1/2'
                            },
                            {
                              key: 'SF1',
                              value: 'SF1'
                            },
                            {
                              key: 'SF2',
                              value: 'SF2'
                            },
                            {
                              key: 'SF3',
                              value: 'SF3'
                            },
                            {
                              key: 'SF4',
                              value: 'SF4'
                            },
                            {
                              key: 'SF5',
                              value: 'SF5'
                            },
                            {
                              key: 'SF6',
                              value: 'SF6'
                            },
                            {
                              key: 'SF7',
                              value: 'SF7'
                            },
                            {
                              key: 'SF8',
                              value: 'SF8'
                            },
                            {
                              key: 'SF9',
                              value: 'SF9'
                            },
                            {
                              key: 'SF10',
                              value: 'SF10'
                            },
                            {
                              key: 'SF11',
                              value: 'SF11'
                            },
                            {
                              key: 'SF12',
                              value: 'SF12'
                            },
                            {
                              key: 'SF13',
                              value: 'SF13'
                            },
                            {
                              key: 'SF14',
                              value: 'SF14'
                            },
                            {
                              key: 'SF15',
                              value: 'SF15'
                            },
                            {
                              key: 'SF16',
                              value: 'SF16'
                            },
                            {
                              key: 'SF17',
                              value: 'SF17'
                            },
                            {
                              key: 'SF18',
                              value: 'SF18'
                            },
                            {
                              key: 'SF19',
                              value: 'SF19'
                            },
                            {
                              key: 'SF20',
                              value: 'SF20'
                            },
                            {
                              key: 'SF21',
                              value: 'SF21'
                            },
                            {
                              key: 'SF22',
                              value: 'SF22'
                            },
                            {
                              key: 'SF23',
                              value: 'SF23'
                            },
                            {
                              key: 'SF24',
                              value: 'SF24'
                            },
                            {
                              key: 'SF25',
                              value: 'SF25'
                            },
                            {
                              key: 'SF26',
                              value: 'SF26'
                            },
                            {
                              key: 'SF27',
                              value: 'SF27'
                            },
                            {
                              key: 'SF28',
                              value: 'SF28'
                            },
                            {
                              key: 'SF29',
                              value: 'SF29'
                            },
                            {
                              key: 'SF30',
                              value: 'SF30'
                            },
                            {
                              key: 'SF31',
                              value: 'SF31'
                            },
                            {
                              key: 'SF32',
                              value: 'SF32'
                            },
                            {
                              key: 'SF33',
                              value: 'SF33'
                            },
                            {
                              key: 'SF34',
                              value: 'SF34'
                            },
                            {
                              key: 'SF35',
                              value: 'SF35'
                            },
                            {
                              key: 'SF36',
                              value: 'SF36'
                            },
                            {
                              key: 'SF37',
                              value: 'SF37'
                            },
                            {
                              key: 'SF38',
                              value: 'SF38'
                            },
                            {
                              key: 'SF39',
                              value: 'SF39'
                            },
                            {
                              key: 'SF40',
                              value: 'SF40'
                            },
                            {
                              key: 'SF41',
                              value: 'SF41'
                            },
                            {
                              key: 'SF42',
                              value: 'SF42'
                            },
                            {
                              key: 'SF43',
                              value: 'SF43'
                            },
                            {
                              key: 'SF44',
                              value: 'SF44'
                            },
                            {
                              key: 'SF45',
                              value: 'SF45'
                            },
                            {
                              key: 'SF46',
                              value: 'SF46'
                            },
                            {
                              key: 'SF47',
                              value: 'SF47'
                            },
                            {
                              key: 'SF48',
                              value: 'SF48'
                            },
                            {
                              key: 'SF49',
                              value: 'SF49'
                            },
                            {
                              key: 'SF50',
                              value: 'SF50'
                            },
                            {
                              key: 'S',
                              value: 'S'
                            },
                            {
                              key: 'M',
                              value: 'M'
                            }
                          ]
                        }
                      ]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'sf_class_full',
                      label: '_NT_MOTOR_sf_class_full_label',
                      required: true,
                      source_name: 'sf_class_full',
                      sources: [
                        {
                          name: 'sf_class_full',
                          values: [
                            {
                              key: '0',
                              value: 'SF0'
                            },
                            {
                              key: 'SF1/2',
                              value: 'SF1/2'
                            },
                            {
                              key: 'SF1',
                              value: 'SF1'
                            },
                            {
                              key: 'SF2',
                              value: 'SF2'
                            },
                            {
                              key: 'SF3',
                              value: 'SF3'
                            },
                            {
                              key: 'SF4',
                              value: 'SF4'
                            },
                            {
                              key: 'SF5',
                              value: 'SF5'
                            },
                            {
                              key: 'SF6',
                              value: 'SF6'
                            },
                            {
                              key: 'SF7',
                              value: 'SF7'
                            },
                            {
                              key: 'SF8',
                              value: 'SF8'
                            },
                            {
                              key: 'SF9',
                              value: 'SF9'
                            },
                            {
                              key: 'SF10',
                              value: 'SF10'
                            },
                            {
                              key: 'SF11',
                              value: 'SF11'
                            },
                            {
                              key: 'SF12',
                              value: 'SF12'
                            },
                            {
                              key: 'SF13',
                              value: 'SF13'
                            },
                            {
                              key: 'SF14',
                              value: 'SF14'
                            },
                            {
                              key: 'SF15',
                              value: 'SF15'
                            },
                            {
                              key: 'SF16',
                              value: 'SF16'
                            },
                            {
                              key: 'SF17',
                              value: 'SF17'
                            },
                            {
                              key: 'SF18',
                              value: 'SF18'
                            },
                            {
                              key: 'SF19',
                              value: 'SF19'
                            },
                            {
                              key: 'SF20',
                              value: 'SF20'
                            },
                            {
                              key: 'SF21',
                              value: 'SF21'
                            },
                            {
                              key: 'SF22',
                              value: 'SF22'
                            },
                            {
                              key: 'SF23',
                              value: 'SF23'
                            },
                            {
                              key: 'SF24',
                              value: 'SF24'
                            },
                            {
                              key: 'SF25',
                              value: 'SF25'
                            },
                            {
                              key: 'SF26',
                              value: 'SF26'
                            },
                            {
                              key: 'SF27',
                              value: 'SF27'
                            },
                            {
                              key: 'SF28',
                              value: 'SF28'
                            },
                            {
                              key: 'SF29',
                              value: 'SF29'
                            },
                            {
                              key: 'SF30',
                              value: 'SF30'
                            },
                            {
                              key: 'SF31',
                              value: 'SF31'
                            },
                            {
                              key: 'SF32',
                              value: 'SF32'
                            },
                            {
                              key: 'SF33',
                              value: 'SF33'
                            },
                            {
                              key: 'SF34',
                              value: 'SF34'
                            },
                            {
                              key: 'SF35',
                              value: 'SF35'
                            },
                            {
                              key: 'SF36',
                              value: 'SF36'
                            },
                            {
                              key: 'SF37',
                              value: 'SF37'
                            },
                            {
                              key: 'SF38',
                              value: 'SF38'
                            },
                            {
                              key: 'SF39',
                              value: 'SF39'
                            },
                            {
                              key: 'SF40',
                              value: 'SF40'
                            },
                            {
                              key: 'SF41',
                              value: 'SF41'
                            },
                            {
                              key: 'SF42',
                              value: 'SF42'
                            },
                            {
                              key: 'SF43',
                              value: 'SF43'
                            },
                            {
                              key: 'SF44',
                              value: 'SF44'
                            },
                            {
                              key: 'SF45',
                              value: 'SF45'
                            },
                            {
                              key: 'SF46',
                              value: 'SF46'
                            },
                            {
                              key: 'SF47',
                              value: 'SF47'
                            },
                            {
                              key: 'SF48',
                              value: 'SF48'
                            },
                            {
                              key: 'SF49',
                              value: 'SF49'
                            },
                            {
                              key: 'SF50',
                              value: 'SF50'
                            },
                            {
                              key: 'S',
                              value: 'S'
                            },
                            {
                              key: 'M',
                              value: 'M'
                            }
                          ]
                        }
                      ]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {},
                    type: 'separator'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_PROD_additional_coverages_label'
                    },
                    type: 'texts'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'car_assistance_yes',
                      label: '_ST_MOTOR_vehicle_liability_deductible'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'discount_protection',
                      label: '_NT_MOTOR_discount_protection'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                column_hide: ['coverage_type'],
                columns: [
                  {
                    options: {
                      control_name: 'gap_coverage',
                      label: '_NT_MOTOR_gap_coverage'
                    },
                    type: 'checkbox'
                  }
                ]
              }
            ]
          },
          section_id: 'existing_insurance_contract',
          section_title: '_ST_MOTOR_coverage_selector_section_title'
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          section_id: 'upload_title'
        },
        type: 'documentsSection'
      },
      {
        content_data: {
          config: {
            description_title: '_NT_MOTOR_tariff_selection_benefits',
            descriptions: ['_ST_MOTOR_benefits_text_1_NEW', '_ST_MOTOR_benefits_text_2', '_ST_MOTOR_benefits_text_3'],
            descriptions_row_modifiers: ['64px', '100px', '64px'],
            green_packages: {},
            has_go_green_descriptions: false,
            packages: [
              {
                coverages: {
                  motor: [
                    {
                      available: true
                    },
                    {
                      available: true
                    },
                    {
                      available: true
                    }
                  ]
                },
                default: true,
                name: 'basic',
                value: 'car-switch'
              }
            ]
          },
          package_definition: {
            basic: {
              default: true,
              name: 'basic',
              value: 'car-switch'
            }
          },
          package_parameter_name: 'product.motor.package',
          prefix: '_ST_MOTOR_title',
          product_type_default: 'motor',
          section_id: 'tariff_title',
          section_margin_bottom: 'mb-huge2x',
          section_title: '_ST_MOTOR_tariff_title'
        },
        type: 'tariffSection'
      },
      {
        content_data: {
          config: {},
          section_id: 'conclude_section',
          section_title: ''
        },
        type: 'concludeSection'
      }
    ],
    header: {
      subtitle: '_ST_MOTOR_main_description',
      title: '_ST_MOTOR_main_title'
    },
    model: [
      ...COMMON_IN_ALL_FORM_MODEL,
      ...COMMON_SWITCH_MOTOR_COVERAGES_MODEL_DE,
      ...CUSTOMER_MODEL,
      ...DIRECT_DEBIT_MODEL,
      ...[{ ...FILE_UPLOAD_MODEL[0], validators: [{ type: 'required' }] }],
      ...LEGAL_ENTITY_MODEL,
      ...VEHICLE_ID_MODEL_DE,
      { attribute: 'policy_holder', defaults: {}, validators: [] },
      { attribute: 'current_contract_confirmation', defaults: {}, validators: [{ type: 'required' }] }
    ],
    offer_config: {
      additional_inputs: [
        {
          form_control_name: 'license_plate',
          transform_type: 'uppercase',
          wc_parameter: 'object.vehicle.license-plate'
        },
        {
          form_control_name: 'vehicle_id',
          transform_type: 'uppercase',
          wc_parameter: 'object.vehicle.id'
        },
        { target_data: 'currentPackage', wc_parameter: 'product.motor.package' }
      ],
      excluded_sections: ['bank_info_title', 'upload_title'],
      fields_to_exclude: [
        'email',
        'phone_number',
        'mobile_phone',
        'street',
        'house_number',
        'file',
        'direct_debit_iban_name_holder',
        'direct_debit_iban',
        'previous_insurer',
        'policy_number_previous_insurance'
      ],
      modified_sections: {
        current_policy_info_title: {
          content_data: {
            config: {
              controls: [
                {
                  columns: [
                    {
                      options: {
                        control_name: 'current_contract_confirmation',
                        error_key: '_ST_MOTOR_current_contract_checkbox_error_message',
                        label: '_ST_MOTOR_current_contract_confirmation_checkbox',
                        required: true
                      },
                      type: 'checkbox'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      options: {
                        control_name: 'previous_insurance_premium_with_tax',
                        i18n_prefix_key: '_ST_MOTOR_',
                        label: '_ST_MOTOR_previous_insurance_premium_with_tax',
                        required: true
                      },
                      type: 'amount'
                    },
                    {
                      options: {
                        control_name: 'payment_frequency',
                        label: '_PROD_payment_frequency_label',
                        required: true,
                        source_name: 'payment_frequency',
                        sources: [
                          {
                            name: 'payment_frequency',
                            values: [
                              {
                                key: 'yearly',
                                value: '_PROD_payment_frequency_yearly'
                              },
                              {
                                key: 'half-yearly',
                                value: '_PROD_payment_frequency_half_yearly'
                              },
                              {
                                key: 'quarterly',
                                value: '_PROD_payment_frequency_quarterly'
                              },
                              {
                                key: 'monthly',
                                value: '_PROD_payment_frequency_monthly'
                              }
                            ]
                          }
                        ],
                        translation_options: null
                      },
                      type: 'dropdown'
                    }
                  ],
                  columns_modifier: 'cols-tablet-2'
                },
                {
                  columns: [
                    {
                      options: {
                        content_title: '_ST_MOTOR_start_date_title'
                      },
                      type: 'texts'
                    }
                  ]
                },
                {
                  columns: [
                    {
                      options: {
                        greater_than: {
                          by_years: 25,
                          input_name: 'birthday'
                        },
                        less_than: {
                          by_years: 70,
                          input_name: 'birthday'
                        },
                        required: true
                      },
                      type: 'start-date'
                    }
                  ]
                }
              ]
            },
            section_id: 'current_policy_info_title',
            section_title: '_ST_MOTOR_current_policy_info_title'
          },
          type: 'dynamicSection'
        }
      }
    },
    product_prefix: '_ST_MOTOR_',
    variables: {
      coverage_type: 'vehicle_liability_deductible'
    },
    variables_dependencies: [
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['partial_comprehensive_deductible', 'fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'partial_comprehensive_deductible',
        validators: [{ type: 'required' }]
      },
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'fully_comprehensive_deductible',
        validators: [{ type: 'required' }]
      },
      {
        bound_to: [
          {
            variable: 'coverage_type',
            visible_if: {
              values: ['fully_comprehensive_deductible']
            }
          }
        ],
        control_name: 'sf_class_full',
        validators: [{ type: 'required' }]
      }
    ]
  }
};
