import { Injectable } from '@angular/core';
import { VariablesStore } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/stores/variables.store';

@Injectable()
export class DynamicFormModelService {
  constructor(private _varStore: VariablesStore) {}
  // eslint-disable-next-line
  public initDataModel(model) {
    if(model.variables2) {
      this._varStore.setInitialVariables(model.variables2);
    }
    return JSON.parse(JSON.stringify(model));
  }

  public setVariable2(key: string, value: boolean | string | number): void { // eslint-disable-line
    this._varStore.setVariable(key, value);
  }

  public setVariable(model: any, variableName: string, value: any): void { // eslint-disable-line
    const newVars = model.variables;
    newVars[variableName] = value;
    model.variables = {...newVars};
  }
 }