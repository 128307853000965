export const UiButtonSizes = {
  large: '-large' as const,
  medium: '-medium' as const,
  small: '-small' as const
};

export type UiButtonSizes = typeof UiButtonSizes[keyof typeof UiButtonSizes];

export const UiButtonTheme = {
  compressed: '-compressed' as 'compressed',
  danger: '-danger' as const,
  primary: '-primary' as const,
  rounded: '-rounded' as const,
  secondary: '-secondary' as const,
  secondaryFilled: '-secondaryFilled' as const
};

export type UiButtonTheme = typeof UiButtonTheme[keyof typeof UiButtonTheme];

export const UiButtonAction = {
  cancel: 'cancel' as const,
  close: 'close' as const,
  discard: 'discard' as const,
  edit: 'edit' as const,
  save: 'save' as const
};

export type UiButtonAction = typeof UiButtonAction[keyof typeof UiButtonAction];
