import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from "@angular/core";
import { ZipDocument } from "@wefoxGroupOneBP/app/private/product/interfaces";
import { GAEventActions, GAEventCategories } from "@wefoxGroupOneBPCore/analytics/constants";
import { COUNTRIES } from "@wefoxGroupOneBPCore/constants";
import { ToasterType } from "@wefoxGroupOneBPCore/interfaces/wg-toaster.interface";
import { ProductService } from "@wefoxGroupOneBPCore/services/product.service";
import { StringUtil } from "@wefoxGroupOneBPCore/utils/string-util";
import { ErrorKeys, SuccessKeys } from "@wefoxGroupOneBPShared/constants";
import { DetailCardService } from "@wefoxGroupOneBPShared/services/detail-card.service";
import { Observable, throwError } from "rxjs";
import { CustomersService } from "../../../customers/services/customers.service";
import { ContractsService } from "../../services/contracts.service";
import { TranslocoModule } from '@ngneat/transloco';
import { translate } from "@ngneat/transloco";


@Component({
  selector: 'wg-download-documents',
  templateUrl: 'download-documents.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  providers: [ProductService, DetailCardService, ContractsService, CustomersService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DownloadDocumentsComponent implements OnInit {

  @Input() country: string;
  @Input() contract: any; // eslint-disable-line
  @Input() isThirdParty: boolean;

  public hasDocuments = false;
  public loadingDownload = false;

  constructor(
    private _productService: ProductService,
    private _detailCardService: DetailCardService,
    private _contractsService: ContractsService,
    private _customersService: CustomersService
  ) { }

  public ngOnInit(): void {
    this.hasDocuments = this.country === COUNTRIES.it ? this.contract.documents?.length > 0 : this.contract.documents_available;
  }

  public onDownload(): void {
    const contract = this.contract.contract || this.contract;

    this._detailCardService.setTrackingEvent(GAEventActions.Download, contract.id, GAEventCategories.CustomerDetail);
    this.loadingDownload = true;

    if (this.country === COUNTRIES.it) {
      this._contractsService.downloadApplicationZipFiles$(contract.external_id).subscribe({
        next: res => {
          if (res) {
            this._productService.downloadZipFile(res, this._formatFileName(contract));
          }
        },
        // eslint-disable-next-line
        error: (err: any) => this._handleDownloadError(err),
        complete: () => (this.loadingDownload = false)
      });
    } else {
      if (this.isThirdParty) {
        if (contract.id) {
          this._customersService.downloadThirdPartyContracts$(contract.id).subscribe({
            next: (file: ZipDocument) => {
              const customerName = `${contract.customer.firstname}_${contract.customer.lastname}`;
              const fileName = `${translate(SuccessKeys.download.policyDocuments)}_${customerName}`;
              this._productService.downloadZipFile(file, fileName);
            },
            // eslint-disable-next-line
            error: (err: any) => this._handleDownloadError(err),
            complete: () => (this.loadingDownload = false)
          });
        } else {
          this._detailCardService.setToasterMessage(ErrorKeys.download.offers, ToasterType.error);
          this.loadingDownload = false;
        }
      } else {
        this._customersService.downloadAllContracts$(contract.id).subscribe({
          next: (file: ZipDocument) => {
            const customerName = contract.account.legal_name
              ? StringUtil.replaceAll(contract.account.legal_name, [' '], '_')
              : `${contract.account.firstname}_${contract.account.lastname}`;
            const fileName = `${translate(SuccessKeys.download.policyDocuments)}_${customerName}_${file.name
              .split('_')
              .pop()}`;
            this._productService.downloadFile(file, fileName);
          },
          // eslint-disable-next-line
          error: (err: any) => this._handleDownloadError(err),
          complete: () => (this.loadingDownload = false)
        });
      }
    }
  }

  // eslint-disable-next-line
  private _formatFileName(element: any): string {
    return `${translate('_PROD_application_documents')}-${element.legal_name ? element.legal_name : element.customer_name
      }-${element.policy_number}.zip`;
  }

  // eslint-disable-next-line
  private _handleDownloadError(err): Observable<any> {
    this._detailCardService.setToasterMessage(ErrorKeys.download.offers, ToasterType.error);
    return throwError(err);
  }

}