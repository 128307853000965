import { Component, OnDestroy, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { PerformanceService } from '@wefoxGroupOneBPShared/services/performance.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'one-performance-section',
  templateUrl: './performance-section.component.html',
  styleUrls: ['./performance-section.component.scss']
})
export class PerformanceSectionComponent implements OnDestroy, OnInit {
  public commissionCardsData = [];
  public currentMonthAndYear: string;
  public loading = false;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _performanceService: PerformanceService) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit() {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();
    this.currentMonthAndYear = `${translate(`_GEN_calendar_month_${currentMonth + 1}`)}  ${currentYear}`;

    this.loading = true;

    this._performanceService
      .getPoliciesSold$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((policiesSold: number) => {
        this.commissionCardsData.push({
          title: '_DASH_commision_card_title_policies_sold',
          value: policiesSold,
          date: this.currentMonthAndYear
        });
        this.loading = false;
      });
    this._performanceService
      .getActiveCustomers$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((activeCustomers: number) => {
        this.commissionCardsData.push({
          title: '_DASH_commision_card_title_active_customers',
          value: activeCustomers,
          date: this.currentMonthAndYear
        });
        this.loading = false;
      });
    this._performanceService
      .getCommissionsAmountCurrentMonth$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((commissionsCurrentMonth: number) => {
        this.commissionCardsData.push({
          title: '_DASH_commision_card_title_amount_current_month',
          value: `${commissionsCurrentMonth} €`,
          date: this.currentMonthAndYear
        });
        this.loading = false;
      });
    this._performanceService
      .getCommissionsAmountLastWeek$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((commissionsLastWeek: number) => {
        this.commissionCardsData.push({
          title: '_DASH_commision_card_title_amount_last_week',
          value: `${commissionsLastWeek} €`,
          date: '_DASH_commision_card_date_amount_last_week'
        });
        this.loading = false;
      });
  }
}
