import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DetailsEditComponent } from '@wefoxGroupOneBPPrivate/components/details-edit/details-edit.component';
import { ClaimsDetailsPageComponent } from '@wefoxGroupOneBPPrivate/modules/claims/pages/claims-details/claims-details-page.component';
import { ClaimsDetailsDataService } from '@wefoxGroupOneBPPrivate/modules/claims/services/claims-details-data.service';
import { ClaimsDetailsService } from '@wefoxGroupOneBPPrivate/modules/claims/services/claims-details.service';
import { ContractsDataService } from '@wefoxGroupOneBPPrivate/modules/contracts/services/contracts-data.service';
import { ContractsService } from '@wefoxGroupOneBPPrivate/modules/contracts/services/contracts.service';
import { ContractDetailDynamicPageComponent } from '@wefoxGroupOneBPPrivate/modules/customers/pages/contract-details/contract-detail-dynamic-page.component';
import { CustomersDataService } from '@wefoxGroupOneBPPrivate/modules/customers/services/customers-data.service';
import { CustomersService } from '@wefoxGroupOneBPPrivate/modules/customers/services/customers.service';
import { PageDetailsDataService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details-data.service';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { CustomSectionBuildModule } from '@wefoxGroupOneBPShared/components/custom-section-build/custom-section-build.module';
import { WgDynamicComponentsModule } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/wg-dynamic-components.module';
import { CurrencyTransform } from '@wefoxGroupOneBPShared/pipes';
import { DetailCardService } from '@wefoxGroupOneBPShared/services/detail-card.service';
import { SharedModule } from '@wefoxGroupOneBPShared/shared.module';
import { DetailsEditModalComponent } from '../../components/details-edit-modal/details-edit-modal.component';
import { CommissionsDataService } from '../account/services/commissions-data.service';
import { CommissionsService } from '../account/services/commissions.service';
import { ContactCarrierAdvisorComponent } from '../contacts/components/contact-carrier-advisor/contact-carrier-advisor.component';
import { CancelPolicyComponent } from '../contracts/components/cancel-policy/cancel-policy.component';
import { DownloadDocumentsComponent } from '../contracts/components/download-documents/download-documents.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    ClaimsDetailsPageComponent,
    ContractDetailDynamicPageComponent,
    DetailsEditComponent,
    DetailsEditModalComponent
  ],
  exports: [
    ClaimsDetailsPageComponent,
    ContractDetailDynamicPageComponent,
    DetailsEditComponent,
    DetailsEditModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CustomSectionBuildModule,
    WgDynamicComponentsModule,
    SharedModule,
    RouterModule,
    TranslocoModule,
    ContactCarrierAdvisorComponent,
    DownloadDocumentsComponent,
    CancelPolicyComponent
  ],
  providers: [
    CurrencyTransform,
    DetailCardService,
    DetailCardService,
    PageDetailsService,
    PageDetailsDataService,
    ClaimsDetailsService,
    ClaimsDetailsDataService,
    ContractsDataService,
    ContractsService,
    CommissionsDataService,
    CommissionsService,
    CustomersService,
    CustomersDataService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedPrivateModule {}
