import { Component, Input } from '@angular/core';

@Component({
  selector: 'wg-risk-area-card',
  templateUrl: './risk-area-card.component.html',
  styleUrls: ['./risk-area-card.component.scss']
})
export class RiskAreaCardComponent {

  @Input() public riskCategory;
  @Input() public isExpandable: boolean;

  public isExpanded = false;
  
  public iconCategory = {
    HEALTH: 'health',
    PROPERTY: 'product-homeowners',
    DEMISE: 'product-life',
    LIVING: 'person'
  };

  public iconPrimaryColor = {
    low: 'success-80',
    medium: 'warning-80',
    high: 'danger-80'
  };

  public iconSecondaryColor = {
    low: 'success-30',
    medium: 'warning-30',
    high: 'danger-30'
  };

  public riskParameters = {
    HEALTH: ['_RA_gender_parameter', '_RA_housing_parameter', '_RA_age_parameter', '_RA_income_parameter', '_RA_postal_code_parameter', '_RA_profession_parameter',],
    PROPERTY: ['_RA_postal_code_parameter', '_RA_income_parameter', '_RA_dependents_parameter'],
    DEMISE: ['_RA_gender_parameter', '_RA_housing_parameter', '_RA_age_parameter', '_RA_income_parameter', '_RA_postal_code_parameter', '_RA_dependents_parameter'],
    LIVING: ['_RA_income_parameter', '_RA_postal_code_parameter', '_RA_dependents_parameter'],
  };

  public toggleCard() {
    if (this.isExpandable) {
      this.isExpanded = !this.isExpanded;
    }
  }
}
