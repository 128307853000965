import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ToasterNotification } from '../interfaces/wg-toaster.interface';

@Injectable({ providedIn: 'root' })
export class WgToasterService {
  public notifications: ToasterNotification[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public notificationsObservable: Observable<any>;
  public notificationSource = new Subject<ToasterNotification[]>();

  constructor() {
    this.notificationsObservable = this.notificationSource.asObservable();
  }
  /**
   * Hide single message
   * @param notification Message displayed
   */
  public dismiss(notification: ToasterNotification): void {
    this.notifications = this.notifications.filter(
      (message: ToasterNotification) => notification.timestamp !== message.timestamp
    );

    this.notificationSource.next(this.notifications);
  }
  /**
   * Display notifications
   * @param notification Messages to show and setup the default values
   */
  public show(notification: ToasterNotification): void {
    const currentTime = new Date();
    notification.timestamp = currentTime.getTime();

    if (!Object(notification).hasOwnProperty('selfDestroy')) {
      notification.selfDestroy = true;
    }
    if (!Object(notification).hasOwnProperty('lifeTime')) {
      notification.lifeTime = 3000;
    }

    this.notifications = [...this.notifications, notification];
    this.notificationSource.next(this.notifications);
  }
}
