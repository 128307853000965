import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

// FIXME this component is a copy of info-banner-adapter, we should fix the dependency cycle between shared module, custom-section-build module and table module in order to remove this duplicate component

export interface TableBannerOptions {
  content: string;
  linkAction: Function; // eslint-disable-line
  linkId: string;
  type: string;
}

@Component({
  selector: 'one-info-banner-table',
  templateUrl: './info-banner-table.component.html',
  styleUrls: ['./info-banner-table.component.scss']
})
export class InfoBannerTableComponent implements AfterViewInit {
  @ViewChild('bannerLink') public bannerLink: ElementRef;
  @Input() public content_data: Partial<TableBannerOptions>;
  @Input() public parentGroup: UntypedFormGroup;

  public getTheme(type: string): string {
    switch (type) {
      case 'danger':
        return 'alert-bg-danger alert-color-danger';
      case 'info':
        return 'alert-bg-info alert-color-info';
      case 'success':
        return 'alert-bg-success alert-color-success';
      case 'warning':
        return 'alert-bg-warning alert-color-warning';
    }
  }

  public ngAfterViewInit(): void {
    const linkId = this.content_data?.linkId;

    if (this.bannerLink?.nativeElement?.querySelector(`#${linkId}`)) {
      this.bannerLink.nativeElement.addEventListener('click', event => {
        if (event.target.id === linkId) {
          this.content_data.linkAction();
        }
      });
    }
  }
}
