import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConsentThirdParty } from '@wefoxGroupOneBPCore/interfaces/customer-detail.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { InfoToasterService } from '@wefoxGroupOneBPShared/services/info-toaster.service';
import { Observer, Subject, switchMap, takeUntil } from 'rxjs';
import { CustomersService } from '../../customers/services/customers.service';
import { CredentialField, ExternalSite, FieldData, ModalOptions } from '../interfaces';
import { AccountService } from '../services/account.service';

@Component({
  selector: 'wg-login-to-external-site-modal',
  templateUrl: './login-to-external-site-modal.component.html',
  styleUrls: ['./login-to-external-site-modal.component.scss']
})
export class LoginToExternalSiteModalComponent implements OnInit, OnDestroy {
  public consentRead = false;
  public isTokenBased: boolean;
  public formFieldsData: FieldData[];
  public isModify = false;
  public isForceCreate = false;
  public externalToolForm: UntypedFormGroup = this._fb.group({
    externalTool: this._fb.control('', [Validators.required])
  });
  public loginForm: UntypedFormGroup;
  public loading: boolean;
  public options: ModalOptions;
  public showCheckbox;
  public contentViewOptions: { options: ModalOptions };
  private _unsubscribe$: Subject<void> = new Subject();

  public externalToolToEdit = {
    control_name: 'externalTool',
    type: 'text',
    logo: '',
    label: '_ACC_external_tool_name',
    required: true
  };

  public externalToolOptions: WgDropdownOption[] = [];

  constructor(
    private _customersService: CustomersService,
    private _router: Router,
    private _fb: UntypedFormBuilder,
    protected _sessionQuery: SessionQuery,
    private _modal: WgModalService,
    private _accountService: AccountService,
    private _toaster: InfoToasterService
  ) {}

  public getButtonDisabledState(): boolean {
    if (this.loading) {
      return true;
    }

    const consentRequiredControl = this.loginForm?.get('consent');

    if (!this.loginForm || !consentRequiredControl) {
      return;
    }

    if (consentRequiredControl.disabled) {
      return true;
    }

    if (!this.loginForm.valid) {
      return true;
    }

    return false;
  }

  ngOnInit(): void {
    this.options = this.contentViewOptions.options;
    this.isModify = this.options.action === 'MODIFY';
    this.isForceCreate = this.options.action === 'FORCE_CREATE';
    if (this.isModify || this.isForceCreate) {
      const { external_tool } = this.options?.external_tool;
      this.externalToolToEdit.logo = external_tool.logo_icon;
      this.externalToolForm.controls['externalTool'].setValue(external_tool.display_name);
      this.externalToolForm.controls['externalTool'].disable();
      const { credential_fields } = external_tool;
      this.showCheckbox = external_tool.id === 'fks';
      this.setUpCredentialsForm(credential_fields, this.options?.external_tool);
    } else {
      this.options.external_tools.forEach(externalTool => {
        this.externalToolOptions.push({
          key: externalTool.external_tool.id,
          value: externalTool.external_tool.display_name,
          icon: externalTool.external_tool.logo_icon,
          additionalData: externalTool
        });
        this.externalToolForm.controls['externalTool']?.valueChanges
          ?.pipe(takeUntil(this._unsubscribe$))
          .subscribe(externalToolInfo => {
            const { credential_fields } = externalToolInfo?.additionalData?.external_tool;
            this.showCheckbox = externalToolInfo?.key === 'fks';
            this.setUpCredentialsForm(credential_fields);
          });
      });
    }
  }

  public onReadMoreClick(): void {
    this.consentRead = true;
    this.loginForm.get('consent').enable();
  }

  public setUpCredentialsForm(fields: CredentialField[], fieldValues?: unknown): void {
    let controls = fields.reduce((acc, field) => {
      const defaultValue = fieldValues ? fieldValues[field.name] : null;
      acc = { ...acc, [field.name]: this._fb.control(defaultValue, [Validators.required]) };
      return acc;
    }, {});

    if (this.showCheckbox) {
      controls = { ...controls, consent: this._fb.control(false, [Validators.required]) };
    }

    this.loginForm = this._fb.group(controls);
    this.formFieldsData = fields.map(field => ({
      control_name: field.name,
      type: field.masked ? 'password' : 'text',
      label: field.display_name,
      required: true,
      icon: field.masked
        ? {
            key: 'view-show',
            size: 'icon-md',
            class: 'onebp-u-pointer fw-bold'
          }
        : undefined
    }));
  }

  public closeModal(): void {
    this._modal.hide();
  }

  public toggleMaskedFieldType(fieldData): void {
    fieldData.type = fieldData.type === 'text' ? 'password' : 'text';
    fieldData.icon.key = fieldData.icon.key === 'view-show' ? 'view-hide' : 'view-show';
  }

  public submitForm(): void {
    if (this.loginForm.valid) {
      this.loading = true;
      let dataToSend;
      const { ...loginData } = this.loginForm.value;
      if (this.isModify) {
        const exToolOpt = this.options?.external_tool;
        dataToSend = {
          ...loginData,
          id: exToolOpt.id,
          external_tool: exToolOpt.external_tool
        };
        this._accountService
          .modifyExternalSite$(dataToSend)
          .pipe(takeUntil(this._unsubscribe$))
          .subscribe(this._manageSubmitResponse(this.isModify));
      } else {
        dataToSend = {
          ...loginData,
          external_tool: {
            id: this.isForceCreate
              ? this.options.external_tool.external_tool.id
              : this.externalToolForm.value.externalTool.key
          }
        };
        if (this.showCheckbox) {
          const consent: ConsentThirdParty = {
            type: 'PRIVACY',
            consent_approval: true
          };
          this._accountService
            .loginDeExternalSites$(dataToSend)
            .pipe(
              switchMap(() => this._customersService.sendConsentApprovalThirdParty$(consent)),
              takeUntil(this._unsubscribe$)
            )
            .subscribe(this._manageSubmitResponse(this.isModify));
        } else {
          this._accountService
            .loginDeExternalSites$(dataToSend)
            .pipe(takeUntil(this._unsubscribe$))
            .subscribe(this._manageSubmitResponse(this.isModify));
        }
      }
    }
  }

  private _manageSubmitResponse(isModify: boolean): Partial<Observer<ExternalSite[]>> {
    const messageType = isModify ? 'modify' : 'add';
    return {
      next: () => {
        this.options.buttonAction();
        this._toaster.successMessage(`_ACC_${messageType}_site_success`);
        this.closeModal();
      },
      error: () => {
        this.loading = false;
        this._toaster.errorMessage(`_ACC_${messageType}_site_error`);
      }
    };
  }

  navigateToContactUs(): void {
    this.closeModal();
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/support`);
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }
}
