import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountStore, BrokerDTOState } from '@wefoxGroupOneBPCore/interfaces/account.store';

@Injectable({ providedIn: 'root' })
export class AccountQuery extends Query<BrokerDTOState> {
  public account$ = this.select();
  public isAccountReady$ = this.select(state => !!state.loading);

  constructor(protected store: AccountStore) {
    super(store);
  }

  public getBrokerLastName(): string {
    return this.getValue().lastname;
  }
}
