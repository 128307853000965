import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicInputControls, VariableDependent } from '@wefoxGroupOneBPCore/interfaces/dynamic-forms.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Variable } from '../interfaces/variable.interface';
import { DynamicOperationService } from '../services/dynamic-operation.service';
import { VariablesStore } from '../stores/variables.store';
import { CommonModule } from '@angular/common';
import { AdaptersModule } from '../inputs/wg-adapters.module';

@Component({
  selector: 'one-dynamic-row-component',
  standalone: true,
  templateUrl: './dynamic-row.component.html',
  imports: [CommonModule, AdaptersModule],
})
export class DynamicRowComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() public content_data: DynamicInputControls;
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public variables: { [key: string]: any }; // eslint-disable-line
  @Input() public variablesDependencies: VariableDependent[];
  @Output() public emitFilePath = new EventEmitter();

  public visible = true;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _cdr: ChangeDetectorRef,
    private _operation: DynamicOperationService,
    private _varStore: VariablesStore
  ) {}

  public selectedFiles(event) {
    this.emitFilePath.emit(event);
  }

  /**
   * Registers the form control name of the input
   * Then checks the visibility state of the control.
   */
  public ngAfterViewInit(): void {
    this.content_data.columns.forEach(control => {
      const controlName = control?.options?.control_name;
      const dependentVariables = this._varStore.getDependentVariables(controlName);
      if (dependentVariables?.length > 0) {
        this._updateVariablesOnComponentChange(controlName, dependentVariables);
      }
      this._zLegacyVariableManagement();
      this._cdr.detectChanges();
    });

    this._checkInitialVisibilityOfTheRow();
    this._varStore.lastUpdate$.pipe(takeUntil(this._unsubscribe$)).subscribe(variable => {
      this._checkVisibilityOfTheRow(variable);
    });
  }

  /**
   * Checks the visibility state when the visible property changes.
   */
  public ngOnChanges(): void {
    this._zLegacyVariableManagement();
    this._cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _checkInitialVisibilityOfTheRow(): void {
    if (this.content_data.row_hide) {
      this.visible = this._varStore.getBoolVariableValue(this.content_data.row_hide);
    }
  }

  private _checkVisibilityOfTheRow(variable: Variable): void {
    if (this.content_data.row_hide === variable.key) {
      this.visible = variable.value as boolean;
    }
  }

  // eslint-disable-next-line
  private _generatesDependencyDictionary(variable): { [key: string]: any } {
    return variable.depends_on.reduce((acc, curr) => ((acc[curr] = this.parentGroup.get(curr).value), acc), {});
  }

  private _updateVariablesOnComponentChange(controlName: string, dependentVariables: Variable[]): void {
    const inputControl = this.parentGroup.get(controlName);
    if (inputControl.value !== '') {
      // draft case
      this._updateDependentVariables(dependentVariables, inputControl.value);
    }
    inputControl.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe(value => {
      this._updateDependentVariables(dependentVariables, value);
    });
  }

  private _zLegacyVariableManagement() {
    if (this.content_data.column_hide) {
      const controlName = this.content_data.columns[0]?.options?.control_name;
      const dependentVariables = this.variablesDependencies?.find(d => d.control_name === controlName);
      if (dependentVariables) {
        const visible = [];
        dependentVariables?.bound_to?.forEach(element => {
          const parentControlName = element.variable;
          const visibleIf = element.visible_if;
          const variableValue = this.variables[parentControlName].key || this.variables[parentControlName];

          visible.push(
            visibleIf.operator === 'and'
              ? visibleIf.values.every(val => val === variableValue)
              : visibleIf.values.some(val => val === variableValue)
          );
        });

        if (dependentVariables.multiple_binding_operator) {
          this.visible =
            dependentVariables.multiple_binding_operator === 'and'
              ? visible.every(ele => ele === true)
              : visible.some(ele => ele === true);

          return;
        }
        this.visible = visible.every(ele => ele === true);
      }
      this._cdr.detectChanges();
    }
  }

  private _updateDependentVariables(dependentVariables: Variable[], value: boolean) {
    dependentVariables.forEach(variable => {
      if (!variable.operation) {
        this._varStore.setVariable(variable.key, value);
      } else {
        const valuesDictionary = this._generatesDependencyDictionary(variable);
        const operationResult = this._operation.solve(variable.operation, valuesDictionary);
        this._varStore.setVariable(variable.key, operationResult);
      }
    });
  }
}
