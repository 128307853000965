import { EnvDefinition } from './interfaces/environment.interface';

export const environment: EnvDefinition = {
  apiUrl: 'https://sys-gateway.1cdfd4245.eu-central-1.wipo.wefox.cloud/api',
  termLifeApiUrl: 'https://distribution-api.wefox.com',
  apiUrlIT: 'https://server-ms-gateway.1cdfd4245.eu-central-1.wipo.wefox.cloud/api',
  assetsUrl: '//s3.eu-central-1.amazonaws.com/wipo-broker-portal-web-pro/app/assets/',
  authUrl: 'https://sys-gateway.1cdfd4245.eu-central-1.wipo.wefox.cloud',
  availableCountries: ['CH', 'DE', 'IT', 'INTL'],
  keycloakMsUrl: 'https://server-ms-gateway.1cdfd4245.eu-central-1.wipo.wefox.cloud/api',
  keycloakUrl: 'https://id.wefox.com/auth',
  basicAuthToken: 'dXNlcjpNQmIzb1hpQXZRQXk0R0Y=',
  currentVersion: '6.26.0',
  domains: {
    cancel: 'https://cdn-ma.policy.wefox.cloud/cancellation/'
  },
  envName: 'PROD',
  firebase: {
    apiKey: 'AIzaSyCXHDUKrztCj-3waGxH016Hn9WV-syUYAc',
    authDomain: 'wipo-notification-7fae1.firebaseapp.com',
    projectId: 'wipo-notification-7fae1',
    storageBucket: 'wipo-notification-7fae1.appspot.com',
    messagingSenderId: '935938735094',
    appId: '1:935938735094:web:1266f251b29fcfc1a5a5d7',
    measurementId: 'G-3G84FDWDQG',
    vapidKey: 'BJzMhcI9LUzXEBUfVICZpsfGX_dhl8EGkkN2wfp3rTkq6lLDjRZRDRu09FzUTnJX_ypTUb0U1hGcYJBXxbs_yXY'
  },
  launchdarkly: {
    clientSideId: '60fe91add192010bbaf4ef7c',
    userKey: 'WIPO-FE'
  },
  local: false,
  oneSites: {
    customers: 'https://one-insurance.com/de/de/',
    landing: 'https://one-insurance.com/de/de/makler/',
    register: 'https://makler.one-versicherung.de/s/makler-registrieren'
  },
  production: true,
  commsUrl: 'https://server-ms-gateway.1cdfd4245.eu-central-1.wipo.wefox.cloud',
  riskAnalysisUrl: 'https://risk-analysis.wefox.com/survey?type=RISK-ANALYSIS&locale=en-de',
  wefoxIconCDNUrl: 'https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.4.3/',
  dxpCDNUrl: 'https://cdn-assets.wefox.com/distribution-product-portal/',
  thirdParty: {
    awsCognitoIdentityPoolId: 'b1d6aaa1-bbb9-4f10-ad21-aea9e62885aa',
    awsRegion: 'eu-central-1',
    awsS3Bucket: 'wipo-web-broker-portal-tmp-bucket',
    gmapsUrl:
      'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBbn7Df7r3WRC4wo-D4a3iChQ3q4UDVTng&callback=Function.prototype',
    recaptchaKey: '6Lfo1bYZAAAAAJy576Z2zHpe1GqzD3C69FrOw398'
  },
  translations: { url: 'https://s3.eu-central-1.amazonaws.com/wipo-broker-portal-web-pro/locales/release/' },
  url: {
    'broker-ch.one-insurance.com': 'CH',
    'makler-pro.one-versicherung.de': 'DE'
  }
};
