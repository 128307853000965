import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WgTooltipModule } from '@wefoxGroupOneBPShared/modules/wg-tooltip/wg-tooltip.module';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon.module';
import { wgInputComponents } from './components';
import { WgFileDragDropDirective } from './components/wg-file-upload/wg-file-drag-drop.directive';
import { InputRefDirective } from './directives/input-ref.directive';

@NgModule({
  declarations: [...wgInputComponents, InputRefDirective, WgFileDragDropDirective],
  exports: [...wgInputComponents, InputRefDirective, FormGroupDirective],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, WgTooltipModule, WgUiIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WgInputModule { }
