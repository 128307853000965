import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';

@Component({
  selector: 'wg-share-questions-modal-confirmation',
  standalone: true,
  templateUrl: './share-questions-modal-confirmation.component.html',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [WgUiIconModule, TranslocoModule]
})
export class ShareQuestionsModalConfirmationComponent {

  constructor(private _modal: WgModalService) { }

  public closeModal(): void {
    this._modal.hide();
  }

}
