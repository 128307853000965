export const RISK_ANALYSIS_MODEL = {
  form_config: {
    form_sections: [
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      control_name: 'marital_status',
                      label: '_RA_marital_status',
                      required: true,
                      source_name: 'marital_status',
                      sources: [
                        {
                          name: 'marital_status',
                          values: [
                            {
                              key: 1,
                              value: '_RA_marital_status_1'
                            },
                            {
                              key: 2,
                              value: '_RA_marital_status_2'
                            },
                            {
                              key: 2,
                              value: '_RA_marital_status_2_1'
                            },
                            {
                              key: 3,
                              value: '_RA_marital_status_3'
                            },
                            {
                              key: 4,
                              value: '_RA_marital_status_4'
                            },
                            {
                              key: 5,
                              value: '_RA_marital_status_5'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  },
                  {
                    options: {
                      control_name: 'profession_type',
                      label: '_RA_profession_type',
                      required: true,
                      source_name: 'profession_type',
                      sources: [
                        {
                          name: 'profession_type',
                          values: [
                            {
                              key: 1,
                              value: '_RA_profession_type_labourer'
                            },
                            {
                              key: 2,
                              value: '_RA_profession_type_self_employed'
                            },
                            {
                              key: 5,
                              value: '_RA_profession_type_public_sector'
                            },
                            {
                              key: 7,
                              value: '_RA_profession_type_student'
                            },
                            {
                              key: 8,
                              value: '_RA_profession_type_homemaker'
                            },
                            {
                              key: 9,
                              value: '_RA_profession_type_unemployed'
                            },
                            {
                              key: 10,
                              value: '_RA_profession_type_retired'
                            },
                            {
                              key: 12,
                              value: '_RA_profession_type_apprentice'
                            },
                            {
                              key: 14,
                              value: '_RA_profession_type_civil_servant'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'home_type',
                      label: '_RA_home_type',
                      required: true,
                      source_name: 'home_type',
                      sources: [
                        {
                          name: 'home_type',
                          values: [
                            {
                              key: 1,
                              value: '_RA_home_type_rented_flat'
                            },
                            {
                              key: 2,
                              value: '_RA_home_type_rented_house'
                            },
                            {
                              key: 3,
                              value: '_RA_home_type_own_flat'
                            },
                            {
                              key: 4,
                              value: '_RA_home_type_own_house'
                            },
                            {
                              key: 5,
                              value: '_RA_home_type_parents_partner'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  },
                  {
                    options: {},
                    type: 'separator'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'vehicle_ownership_check',
                      default_value: false,
                      label: '_RA_vehicle_ownership_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_is_vehicle_owner',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_vehicle_type'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_PKW',
                      label: '_RA_passenger_car'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_KRA',
                      label: '_RA_motorbike'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_LKW',
                      label: '_RA_truck'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_kein',
                      label: '_RA_none'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                row_hide: 'v_is_car_selected',
                columns: [
                  {
                    options: {
                      control_name: 'vehicle_value',
                      label: '_RA_vehicle_value',
                      source_name: 'vehicle_value',
                      sources: [
                        {
                          name: 'vehicle_value',
                          values: [
                            {
                              key: 5000,
                              value: '_RA_vehicle_value_less_than_5'
                            },
                            {
                              key: 10000,
                              value: '_RA_vehicle_value_6_to_10'
                            },
                            {
                              key: 20000,
                              value: '_RA_vehicle_value_11_to_20'
                            },
                            {
                              key: 60000,
                              value: '_RA_vehicle_value_21_to_60'
                            },
                            {
                              key: 150000,
                              value: '_RA_vehicle_value_more_than_60'
                            }
                          ]
                        }
                      ],
                      translation_options: null
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {},
                    type: 'separator'
                  },
                  {
                    options: {
                      control_name: 'pet_ownership_check',
                      default_value: false,
                      label: '_RA_pet_ownership_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_has_pets',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_pets_type'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'dog',
                      label: '_RA_pets_type_dog'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'cat',
                      label: '_RA_pets_type_cat'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'other',
                      label: '_RA_pets_type_other'
                    },
                    type: 'checkbox'
                  }
                ]
              }
            ]
          },
          section_id: 'risk_analysis_first_section',
          section_title: '_RA_info_section_title',
          step_count: 1
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_healthcare_preferences'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'short_waitingtimes',
                      label: '_RA_healthcare_preferences_short_waitingtimes'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'dental_healthcare',
                      label: '_RA_healthcare_preferences_dental_healthcare'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'choice_of_doctor',
                      label: '_RA_healthcare_preferences_choice_of_doctor'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'modern_equipment',
                      label: '_RA_healthcare_preferences_modern_equipment'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'second_opinion',
                      label: '_RA_healthcare_preferences_second_opinion'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'income_gross_yearly',
                      label: '_RA_income_gross_yearly',
                      required: true,
                      source_name: 'income_gross_yearly',
                      sources: [
                        {
                          name: 'income_gross_yearly',
                          values: [
                            {
                              key: 10000,
                              value: '_RA_income_gross_yearly_less_than_10'
                            },
                            {
                              key: 20000,
                              value: '_RA_income_gross_yearly_10_to_25'
                            },
                            {
                              key: 32500,
                              value: '_RA_income_gross_yearly_25_to_40'
                            },
                            {
                              key: 52500,
                              value: '_RA_income_gross_yearly_40_to_65'
                            },
                            {
                              key: 65000,
                              value: '_RA_income_gross_yearly_more_than_65'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'income_depending_check',
                      default_value: false,
                      label: '_RA_income_depending_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_has_dependent',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_family_income_depending'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'income_depending_partner',
                      label: '_RA_income_depending_partner_spouse'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'income_depending_parents',
                      label: '_RA_income_depending_parents'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'income_depending_children',
                      label: '_RA_income_depending_children'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_financial_security_preferences'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'low_incomegaps',
                      label: '_RA_financial_security_preferences_low_incomegaps'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'independence_family',
                      label: '_RA_financial_security_preferences_independence_family'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'independence_state',
                      label: '_RA_financial_security_preferences_independence_state'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'saving_for_goals',
                      label: '_RA_financial_security_preferences_saving_for_goals'
                    },
                    type: 'checkbox'
                  }
                ]
              },
            ]
          },
          section_id: 'risk_analysis_second_section',
          section_title: '_RA_info_section_title',
          step_count: 2
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      control_name: 'legal_check_1',
                      label: '_RA_data_consent'
                    },
                    type: 'checkbox'
                  }
                ],
                columns_modifier: 'cols-tablet-1'
              },
              {
                columns: [
                  {
                    options: {
                      button_loading: false,
                      control_name: 'confirm_button',
                      label: '_RA_button_finish',
                      options: [{ checked: true, icon: '', value: 'confirm' }],
                      theme: 'primary',
                      type: 'submit'
                    },
                    type: 'button'
                  }
                ],
                columns_modifier: 'cols-tablet-2'
              }
            ]
          },
          section_id: '',
          section_title: '',
          step_count: 2
        },
        type: 'dynamicSection'
      }
    ],
    header: {
      subtitle: '_RA_main_description',
      title: '_RA_main_title'
    },
    model: [],
    product_prefix: '_RA_',
    variables2: [
      {
        depends_on: ['vehicle_ownership_check'],
        key: 'v_is_vehicle_owner',
        operation: '$vehicle_ownership_check === true',
        value: false
      },
      {
        depends_on: ['vehicle_type_PKW', 'vehicle_ownership_check'],
        key: 'v_is_car_selected',
        operation: '$vehicle_type_PKW  === true && $vehicle_ownership_check === true',
        value: false
      },
      {
        depends_on: ['pet_ownership_check'],
        key: 'v_has_pets',
        operation: '$pet_ownership_check === true ',
        value: false
      },
      {
        depends_on: ['income_depending_check'],
        key: 'v_has_dependent',
        operation: '$income_depending_check === true',
        value: false
      }
    ]
  }
};

export const RISK_ANALYSIS_CUSTOMER_FLOW_MODEL = {
  form_config: {
    form_sections: [
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      control_name: 'marital_status',
                      label: '_RA_marital_status',
                      required: true,
                      source_name: 'marital_status',
                      sources: [
                        {
                          name: 'marital_status',
                          values: [
                            {
                              key: 1,
                              value: '_RA_marital_status_1'
                            },
                            {
                              key: 2,
                              value: '_RA_marital_status_2'
                            },
                            {
                              key: 2,
                              value: '_RA_marital_status_2_1'
                            },
                            {
                              key: 3,
                              value: '_RA_marital_status_3'
                            },
                            {
                              key: 4,
                              value: '_RA_marital_status_4'
                            },
                            {
                              key: 5,
                              value: '_RA_marital_status_5'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  },
                  {
                    options: {
                      control_name: 'profession_type',
                      label: '_RA_profession_type',
                      required: true,
                      source_name: 'profession_type',
                      sources: [
                        {
                          name: 'profession_type',
                          values: [
                            {
                              key: 1,
                              value: '_RA_profession_type_labourer'
                            },
                            {
                              key: 2,
                              value: '_RA_profession_type_self_employed'
                            },
                            {
                              key: 5,
                              value: '_RA_profession_type_public_sector'
                            },
                            {
                              key: 7,
                              value: '_RA_profession_type_student'
                            },
                            {
                              key: 8,
                              value: '_RA_profession_type_homemaker'
                            },
                            {
                              key: 9,
                              value: '_RA_profession_type_unemployed'
                            },
                            {
                              key: 10,
                              value: '_RA_profession_type_retired'
                            },
                            {
                              key: 12,
                              value: '_RA_profession_type_apprentice'
                            },
                            {
                              key: 14,
                              value: '_RA_profession_type_civil_servant'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'home_type',
                      label: '_RA_home_type',
                      required: true,
                      source_name: 'home_type',
                      sources: [
                        {
                          name: 'home_type',
                          values: [
                            {
                              key: 1,
                              value: '_RA_home_type_rented_flat'
                            },
                            {
                              key: 2,
                              value: '_RA_home_type_rented_house'
                            },
                            {
                              key: 3,
                              value: '_RA_home_type_own_flat'
                            },
                            {
                              key: 4,
                              value: '_RA_home_type_own_house'
                            },
                            {
                              key: 5,
                              value: '_RA_home_type_parents_partner'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  },
                  {
                    options: {},
                    type: 'separator'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'vehicle_ownership_check',
                      default_value: false,
                      label: '_RA_vehicle_ownership_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_is_vehicle_owner',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_vehicle_type'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_PKW',
                      label: '_RA_passenger_car'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_KRA',
                      label: '_RA_motorbike'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_LKW',
                      label: '_RA_truck'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'vehicle_type_kein',
                      label: '_RA_none'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                row_hide: 'v_is_car_selected',
                columns: [
                  {
                    options: {
                      control_name: 'vehicle_value',
                      label: '_RA_vehicle_value',
                      source_name: 'vehicle_value',
                      sources: [
                        {
                          name: 'vehicle_value',
                          values: [
                            {
                              key: 5000,
                              value: '_RA_vehicle_value_less_than_5'
                            },
                            {
                              key: 10000,
                              value: '_RA_vehicle_value_6_to_10'
                            },
                            {
                              key: 20000,
                              value: '_RA_vehicle_value_11_to_20'
                            },
                            {
                              key: 60000,
                              value: '_RA_vehicle_value_21_to_60'
                            },
                            {
                              key: 150000,
                              value: '_RA_vehicle_value_more_than_60'
                            }
                          ]
                        }
                      ],
                      translation_options: null
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {},
                    type: 'separator'
                  },
                  {
                    options: {
                      control_name: 'pet_ownership_check',
                      default_value: false,
                      label: '_RA_pet_ownership_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_has_pets',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_pets_type'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'dog',
                      label: '_RA_pets_type_dog'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'cat',
                      label: '_RA_pets_type_cat'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'other',
                      label: '_RA_pets_type_other'
                    },
                    type: 'checkbox'
                  }
                ]
              }
            ]
          },
          section_id: 'risk_analysis_first_section',
          section_title: '_RA_info_section_title',
          step_count: 1
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_healthcare_preferences'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'short_waitingtimes',
                      label: '_RA_healthcare_preferences_short_waitingtimes'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'dental_healthcare',
                      label: '_RA_healthcare_preferences_dental_healthcare'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'choice_of_doctor',
                      label: '_RA_healthcare_preferences_choice_of_doctor'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'modern_equipment',
                      label: '_RA_healthcare_preferences_modern_equipment'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'second_opinion',
                      label: '_RA_healthcare_preferences_second_opinion'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'income_gross_yearly',
                      label: '_RA_income_gross_yearly',
                      required: true,
                      source_name: 'income_gross_yearly',
                      sources: [
                        {
                          name: 'income_gross_yearly',
                          values: [
                            {
                              key: 10000,
                              value: '_RA_income_gross_yearly_less_than_10'
                            },
                            {
                              key: 20000,
                              value: '_RA_income_gross_yearly_10_to_25'
                            },
                            {
                              key: 32500,
                              value: '_RA_income_gross_yearly_25_to_40'
                            },
                            {
                              key: 52500,
                              value: '_RA_income_gross_yearly_40_to_65'
                            },
                            {
                              key: 65000,
                              value: '_RA_income_gross_yearly_more_than_65'
                            }
                          ]
                        }
                      ],
                      translation_options: null,
                      validators: [{ type: 'required' }]
                    },
                    type: 'dropdown'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      control_name: 'income_depending_check',
                      default_value: false,
                      label: '_RA_income_depending_check',
                      required: true
                    },
                    type: 'question'
                  }
                ]
              },
              {
                row_hide: 'v_has_dependent',
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_family_income_depending'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'income_depending_partner',
                      label: '_RA_income_depending_partner_spouse'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'income_depending_parents',
                      label: '_RA_income_depending_parents'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'income_depending_children',
                      label: '_RA_income_depending_children'
                    },
                    type: 'checkbox'
                  }
                ]
              },
              {
                columns: [
                  {
                    options: {
                      content_subtitle: '_RA_financial_security_preferences'
                    },
                    type: 'texts'
                  },
                  {
                    options: {
                      control_name: 'low_incomegaps',
                      label: '_RA_financial_security_preferences_low_incomegaps'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'independence_family',
                      label: '_RA_financial_security_preferences_independence_family'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'independence_state',
                      label: '_RA_financial_security_preferences_independence_state'
                    },
                    type: 'checkbox'
                  },
                  {
                    options: {
                      control_name: 'saving_for_goals',
                      label: '_RA_financial_security_preferences_saving_for_goals'
                    },
                    type: 'checkbox'
                  }
                ]
              },
            ]
          },
          section_id: 'risk_analysis_second_section',
          section_title: '_RA_info_section_title',
          step_count: 2
        },
        type: 'dynamicSection'
      },
      {
        content_data: {
          config: {
            controls: [
              {
                columns: [
                  {
                    options: {
                      button_loading: false,
                      control_name: 'confirm_button',
                      label: '_RA_button_finish',
                      options: [{ checked: true, icon: '', value: 'confirm' }],
                      theme: 'primary',
                      type: 'submit'
                    },
                    type: 'button'
                  }
                ],
                columns_modifier: 'cols-tablet-2'
              }
            ]
          },
          section_id: '',
          section_title: '',
          step_count: 2
        },
        type: 'dynamicSection'
      }
    ],
    header: {
      subtitle: '_RA_main_customer_flow_description',
      title: '_RA_main_title'
    },
    model: [],
    product_prefix: '_RA_',
    variables2: [
      {
        depends_on: ['vehicle_ownership_check'],
        key: 'v_is_vehicle_owner',
        operation: '$vehicle_ownership_check === true',
        value: false
      },
      {
        depends_on: ['vehicle_type_PKW', 'vehicle_ownership_check'],
        key: 'v_is_car_selected',
        operation: '$vehicle_type_PKW  === true && $vehicle_ownership_check === true',
        value: false
      },
      {
        depends_on: ['pet_ownership_check'],
        key: 'v_has_pets',
        operation: '$pet_ownership_check === true ',
        value: false
      },
      {
        depends_on: ['income_depending_check'],
        key: 'v_has_dependent',
        operation: '$income_depending_check === true',
        value: false
      }
    ]
  }
};
