export const FinMask = '999.999.999';
export const SerialNumberMask = 'AAAAAAAAAAAAA0000';
export const IbanMask = 'SS00 AAAA 0000 0000 0000 9999 9999 9999 99';
export const PostalCodeDEMask = '00000';
export const PostalCodeCHMask = '0000';
export const DateMask = 'd0.M0.0000';
export const MonthYearMask = 'M0.0000';
export const CantonMask = 'SS';
export const HsnMask = '0000';
export const TsnMask = 'AAA';
export const TypeNumberMask = '000000';

// A -> Any letter or number
// S -> Only letters
// 0 -> Only numbers