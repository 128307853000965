import { Component, EventEmitter, Input, Output } from '@angular/core';

const limitPageable = 5;
const actualPage = 1;
const totalPages = 0;
const listItemsPerPage = [20, 40, 80];

@Component({
  selector: 'wg-paginator',
  styleUrls: ['./wg-paginator.component.scss'],
  templateUrl: './wg-paginator.component.html'
})
export class WgPaginatorComponent {
  public actualItemsPerPage: number = listItemsPerPage[0];
  @Input() public actualPage = actualPage;
  @Input() public interiorPagination = false;
  @Input() public itemsPerPage = true;
  @Output() public itemsPerPageBy: EventEmitter<number> = new EventEmitter<number>();
  @Input() public limitPageable = limitPageable;
  public listItemsPerPage = listItemsPerPage;
  @Output() public paginateBy: EventEmitter<number> = new EventEmitter<number>();
  @Input() public totalPages = totalPages;

  /**
   * Moves the page position one step forward
   * @param event Event generated by click
   */
  public goForwardPage(event: KeyboardEvent): void {
    event.stopPropagation();

    if (this.actualPage !== 1) {
      this.setPage(this.actualPage - 1);
    }
  }

  /**
   * Moves the page position one step
   * @param event Event generated by click
   */
  public goNextPage(event: KeyboardEvent): void {
    event.stopPropagation();

    if (this.actualPage !== this.totalPages) {
      this.setPage(this.actualPage + 1);
    }
  }

  /**
   * Returns if the three dots view on the left should appear
   */
  public isPageCloseLeft(): boolean {
    return 2 !== this.actualPage;
  }

  /**
   * Returns if the three dots view on the right should appear
   */
  public isPageCloseRight(): boolean {
    return this.totalPages - 2 !== this.actualPage;
  }

  /**
   * Returns if the page is on the array of visible pages
   * @param page Number of the page
   */
  public isPageOnRange(page: number): boolean {
    return this.pagesToArray().some(rangePage => rangePage === page);
  }

  /**
   * Return an array of pages due a range
   */
  public pagesToArray(): Array<number> {
    let arrayOfPages: number[];

    if (this.totalPages <= this.limitPageable) {
      arrayOfPages = [...Array(this.totalPages).keys()].map(i => i + 1);
    } else {
      const end = this.actualPage < this.limitPageable ? this.limitPageable : this.actualPage;
      const start = end + 1 - this.limitPageable;

      arrayOfPages = [...Array(end - start + 1).keys()].map(i => start + i);

      // If we're on the last position on the array and it's not the last page
      // Show one more page
      if (this.actualPage === arrayOfPages[arrayOfPages.length - 1] && this.actualPage !== this.totalPages) {
        arrayOfPages = [...Array(end - start + 2).keys()].map(i => start + i);
        arrayOfPages.splice(0, 2);
      }
    }

    return arrayOfPages;
  }

  public setItemsPerPage(items: number): void {
    if (items !== this.actualItemsPerPage) {
      this.actualPage = actualPage;
      this.actualItemsPerPage = items;
      this.itemsPerPageBy.emit(items);
    }
  }

  /**
   * Sets the actual page number and emit it by an output
   * @param page Number of the page
   */
  public setPage(page: number): void {
    if (page !== this.actualPage) {
      this.actualPage = page;
      this.paginateBy.emit(page);
    }
  }
}
