export const FormControlItem = {
  already_registered_check: {
    label: 'already_registered_check',
    name: 'already_registered_check'
  },
  birthdate: {
    label: 'birthday',
    name: 'birthdate',
    validationKey: 'birthdate'
  },
  birthday: {
    label: 'birthday',
    name: 'birthday'
  },
  city: {
    label: 'place',
    name: 'place',
    validationKey: 'place'
  },
  country: {
    label: 'country',
    name: 'country',
    validationKey: 'country'
  },
  coverage_type: {
    label: 'coverage_type',
    name: 'coverage_type'
  },
  email: {
    label: 'email',
    name: 'email',
    validationKey: 'email'
  },
  file: {
    label: 'file',
    name: 'file',
    validationKey: 'file'
  },
  firmename: {
    label: 'legal_name',
    name: 'legal_name',
    validationKey: 'legalName'
  },
  first_insured_date: {
    label: 'first_insured_date',
    name: 'first_insured_date'
  },
  first_registered_date: {
    label: 'first_registered_date',
    name: 'first_registered_date'
  },
  firstname: {
    label: 'firstname',
    name: 'firstname',
    validationKey: 'firstname'
  },
  first_name: {
    label: 'first_name',
    name: 'first_name',
    validationKey: 'first_name'
  },
  gap_coverage: {
    label: 'gap_coverage',
    name: 'gap_coverage'
  },
  gender: {
    label: 'gender',
    name: 'gender',
    validationKey: 'gender'
  },
  house_number: {
    label: 'house_number',
    name: 'house_number',
    validationKey: 'houseNumber'
  },
  householdAdults: {
    label: 'household_adults',
    name: 'household_adults'
  },
  householdChildren: {
    label: 'household_children',
    name: 'household_children'
  },
  hsn: {
    label: 'hsn',
    name: 'hsn'
  },
  iban: {
    label: 'direct_debit_iban',
    name: 'direct_debit_iban',
    validationKey: 'iban'
  },
  ibanHolder: {
    label: 'direct_debit_iban_name_holder',
    name: 'direct_debit_iban_name_holder'
  },
  ibanHolderFirstname: {
    label: 'direct_debit_iban_holder_firstname',
    name: 'direct_debit_iban_holder_firstname'
  },
  ibanHolderLastname: {
    label: 'direct_debit_iban_holder_lastname',
    name: 'direct_debit_iban_holder_lastname'
  },
  insured_before_check: {
    label: 'insured_before_check',
    name: 'insured_before_check'
  },
  lastname: {
    label: 'lastname',
    name: 'lastname',
    validationKey: 'lastname'
  },
  last_name: {
    label: 'last_name',
    name: 'last_name',
    validationKey: 'last_name'
  },
  legalName: {
    label: 'legal_name',
    name: 'legal_name',
    validationKey: 'legalName'
  },
  mak_id: {
    label: 'mak_id',
    name: 'mak_id'
  },
  marketingConsent: {
    label: 'marketing_consent',
    name: 'marketing_consent'
  },
  marketingConsentString: {
    label: 'personal_data_marketing_consent',
    name: 'marketing_consent_string',
    validationKey: 'marketingConsentString'
  },
  mobile_phone: {
    label: 'mobile_phone',
    name: 'mobile_phone',
    validationKey: 'mobilePhone'
  },
  other_drivers_check: {
    label: 'other_drivers_check',
    name: 'other_drivers_check'
  },
  other_drivers_youngest_age: {
    label: 'other_drivers_youngest_age',
    name: 'other_drivers_youngest_age'
  },
  payment_frequency: {
    label: 'payment_frequency',
    name: 'payment_frequency'
  },
  payment_type: {
    label: 'payment_type',
    name: 'payment_type'
  },
  phone_number: {
    label: 'phone_number',
    name: 'phone_number',
    validationKey: 'phoneNumber'
  },
  policyHolder: {
    label: 'policy_holder',
    name: 'policy_holder'
  },
  place: {
    label: 'place',
    name: 'place'
  },
  policy_number_previous_insurance: {
    label: 'policy_number_previous_insurance',
    name: 'policy_number_previous_insurance'
  },
  postal_code: {
    label: 'postal_code',
    name: 'postal_code',
    validationKey: 'postalCode'
  },
  preferred_language: {
    label: 'preferred_language',
    name: 'preferred_language'
  },
  previous_insurance_premium_with_tax: {
    label: 'previous_insurance_premium_with_tax',
    name: 'previous_insurance_premium_with_tax'
  },
  previous_insurer: {
    label: 'previous_insurer',
    name: 'previous_insurer'
  },
  quote: {
    label: 'quote',
    name: 'quote'
  },
  sf_class_check: {
    name: 'sf_class_check'
  },
  sf_has_existing_insurance: {
    label: 'sf_has_existing_insurance',
    name: 'sf_has_existing_insurance'
  },

  sf_class_full: {
    name: 'sf_class_full'
  },
  sf_class_partial: {
    name: 'sf_class_partial'
  },
  sf_no_claims_discount_comprehensive: {
    label: 'sf_no_claims_discount_comprehensive',
    name: 'sf_no_claims_discount_comprehensive'
  },
  sf_no_claims_discount_liability: {
    label: 'sf_no_claims_discount_liability',
    name: 'sf_no_claims_discount_liability'
  },
  sf_second_car_no_claims_discount_check: {
    label: 'sf_second_car_no_claims_discount_check',
    name: 'sf_second_car_no_claims_discount_check'
  },
  sf_second_car_no_claims_discount_liability: {
    label: 'sf_second_car_no_claims_discount_liability',
    name: 'sf_second_car_no_claims_discount_liability'
  },
  sf_second_car_no_claims_discount_comprehensive: {
    label: 'sf_second_car_no_claims_discount_comprehensive',
    name: 'sf_second_car_no_claims_discount_comprehensive'
  },
  sf_second_car_is_policyholder: {
    label: 'sf_second_car_is_policyholder',
    name: 'sf_second_car_is_policyholder'
  },
  sf_second_car_is_vehicleholder: {
    label: 'sf_second_car_is_vehicleholder',
    name: 'sf_second_car_is_vehicleholder'
  },
  start_date: {
    label: 'start_date',
    name: 'start_date',
    validationKey: 'start_date'
  },
  street: {
    label: 'street',
    name: 'street',
    validationKey: 'street'
  },
  tsn: {
    label: 'tsn',
    name: 'tsn'
  }
};

export const FormInsuredObjects = {
  carholder: {
    label: 'carholder',
    name: 'carholder'
  },
  class_full_label: {
    label: 'class_full_label',
    name: 'class_full_label'
  },
  class_partial_label: {
    label: 'class_partial_label',
    name: 'class_partial_label'
  },
  current_mileage: {
    label: 'current_mileage',
    name: 'current_mileage'
  },
  hsn_tsn_combined_label: {
    label: 'hsn_tsn_combined_label',
    name: 'hsn_tsn_combined_label'
  },
  insured_date: {
    label: 'first_insured_date',
    name: 'first_insured_date'
  },
  intended_usage: {
    label: 'intended_usage',
    name: 'intended_usage'
  },
  label_brand_and_model: {
    label: 'label_brand_and_model',
    name: 'label_brand_and_model'
  },
  license_plate: {
    label: 'license_plate',
    name: 'license_plate',
    validationKey: 'license_plate'
  },
  new_registration: {
    label: 'new_registration',
    name: 'new_registration'
  },
  registered_date: {
    label: 'first_registered_date',
    name: 'first_registered_date'
  },
  vehicleId: {
    label: '',
    name: 'vehicle_id',
    validationKey: 'vehicle_id'
  },
  yearly_mileage: {
    label: 'yearly_mileage',
    name: 'yearly_mileage'
  }
};

export const ErrorKeys = {
  b2cLegal: '_ST_MOTOR_B2C_legal_error',
  download: {
    offers: '_OFL_retrieving_offers_error'
  },
  iban: 'direct_debit_iban_format_error',
  licensePlatePrefix: '_PROD_license_plate_region_error',
  maxLength: {
    email: 'email_maxlength_error',
    firstname: 'firstname_maxlength_error',
    lastname: 'lastname_maxlength_error',
    legalName: 'legal_name_maxlength_error',
    street: 'street_maxlength_error'
  },
  pattern: {
    email: 'email_email_error',
    firstname: 'firstname_pattern_error',
    houseNumber: 'house_number_pattern_error',
    ibanHolder: 'direct_debit_iban_name_holder_pattern_error',
    lastname: 'lastname_pattern_error',
    legalName: 'legal_name_pattern_error',
    license_plate: 'license_plate_pattern_error',
    place: 'place_pattern_error',
    postalCode: 'postal_code_pattern_error',
    street: 'street_pattern_error',
    vehicle_id: '_pattern_error'
  },
  required: '_PROD_required_error',
  save: '_CUD_customer_profile_edit_error',
  scan: '_SUP_scan_document_error'
};

export const SuccessKeys = {
  download: {
    policyDocuments: '_PROD_success_screen_policy_documents'
  },
  save: '_CUD_customer_profile_edit_success'
};

export const ErrorType = {
  iban: 'iban',
  license_plate: 'licensePlatePrefix',
  maxlength: 'maxlength',
  pattern: 'pattern',
  required: 'required'
};

export const FormInputType = {
  number: 'number',
  tel: 'tel',
  text: 'text'
};

export const SectionTitleKey = {
  claimsOverview: '_PROD_claims_overview_title',
  contractOverview: '_ST_MOTOR_contract_overview_title',
  coverages: '_FAQ_category_coverages',
  damage: '_PROD_damage_title',
  insuredObject: '_PROD_insured_object',
  payment: '_CUD_payment_data',
  personalData: '_ST_MOTOR_customer_info_title'
};

export const UXKeys = {
  loading: {
    insuredObject: '_CUD_retrieving_insured_data',
    paymentData: '_CUD_retrieving_payment_data',
    personalData: '_CUD_retrieving_personal_data'
  }
};

export const DateFormat = {
  concludeRequest: 'YYYY-MM-DD',
  default: 'DD.MM.YYYY',
  monthYear: 'MM.YYYY'
};

export const DateInterval = {
  day: 'day',
  days: 'days',
  month: 'month',
  months: 'months',
  year: 'year',
  years: 'years'
};

export const SourceNameCommon = {
  carPackage: 'car.package',
  fullyDeductible: 'fully_amount',
  gender: 'gender',
  liabilityDeductible: 'liability_amount',
  partialDeductible: 'partial_amount',
  paymentFrequency: 'payment_frequency',
  preferredLanguage: 'customer.preferred-language',
  productType: 'car.product-type'
};

export const SourceNameProductSpecific = {
  householdSwitchDE: {
    deductible: 'product.household.deductible',
    frequency: 'product.household.previous-premium-payment-interval',
    package: 'product.household.package'
  },
  liabilityDE: {
    cSuite: {
      adultsInsured: 'product.private-liability.number-insured-adults',
      childrenInsured: 'product.private-liability.number-insured-children',
      frequency: SourceNameCommon.paymentFrequency
    },
    deductible: 'product.private-liability.deductible',
    package: 'product.private-liability.package',
    switch: {
      frequency: 'product.private-liability.previous-premium-payment-interval'
    }
  },
  motorSwitchDE: {
    fullyDeductible: 'fully_amount',
    partialDeductible: 'partial_amount'
  }
};

export const DynamicInputType = {
  checkbox: 'checkbox',
  date: 'date',
  dropdown: 'dropdown',
  file: 'file',
  input: 'input',
  percentage: 'percentage',
  startDate: 'start-date',
  textArea: 'textArea',
  texts: 'texts',
  typeahead: 'typeahead'
};

export const DynamicSectionName = {
  conclude: 'concludeSection',
  customer: 'customerSection',
  dynamic: 'dynamicSection',
  features: 'featuresBoxSection',
  tariff: 'tariffSection'
};
