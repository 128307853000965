// Each of this custom events must have a related interface
export enum AnalyticsCustomEvents {
  singleEvent = 'GASingleEvent',
  pageView = 'GAPageEvent',
  setBrokerId = 'SetBrokerId',
  ctaEvent = 'cta_event',
  interactionEvent = 'interaction_event',
  pageViewCustom = 'page_view_custom'
}

export enum GAEventPages {
  CustomerDetailPage = 'Customer Detail Page',
  LeadDetailPage = 'Lead Detail Page',
  Dashboard = 'Dashboard',
  ThirdParty = 'Create Third Party Application',
  BrokerCalendarPage = 'Broker Calendar Page',
  PortfolioTransfer = 'Create Portfolio Transfer'
}

export enum GAEventCategories {
  AragForm = 'AragForm',
  B2C = 'B2C',
  Bridge = 'Bridge',
  BrokerFlowRiskAnalysis = 'Broker Flow Risk Analysis',
  BrokerRoles = 'Roles',
  Users = 'Users',
  Calendar = 'Calendar',
  CancelContract = 'CancelContract',
  CarModelSelector = 'CarModelSelector',
  CancellationLetter = 'CancellationLetter',
  CommonSwitchConfirmation = 'CommonSwitchConfirmation',
  Contracts = 'Contracts',
  Create = 'Create',
  CrossSell = 'CrossSell',
  CustomerDetail = 'CustomerDetail',
  CustomerFlowRiskAnalysis = 'Customer Flow Risk Analysis',
  Customers = 'Customers',
  CustomersList = 'CustomersList',
  DifferentLanguage = 'DifferentLanguage',
  Dashboard = 'Dashboard',
  Documents = 'Documents',
  Draft = 'Draft',
  EditCustomerInfo = 'EditCustomerInfo',
  EditPaymentMethod = 'EditPaymentMethod',
  EditInsuredObject = 'EditInsuredObject',
  ExternalProducts = 'ExternalProducts',
  ExternalProductCard = 'ExternalProductCard',
  Filter = 'filtering',
  LeadsList = 'LeadsList',
  LeadsCalculatePremium = 'calculate premium',
  LeadsSell3rdPartyPolicy = 'sell 3rd party policy',
  LeadsPriceComparison = 'price comparison',
  LinkSharing = 'LinkSharing',
  Login = 'Login',
  Offer = 'Offer',
  Products = 'Products',
  Prefilling = 'Prefilling',
  Registration = 'Registration',
  RegistrationLinks = 'RegistrationLink',
  RiskAnalysis = 'RiskAnalysis',
  Santander = 'Santander',
  SameLanguage = 'SameLanguage',
  SupportSelfService = 'SupportSelfService',
  SwitchFormOCR = 'SwitchFormOCR',
  ThirdParty = 'ThirdParty',
  ClassicMotorIT = 'ClassicMotorIT',
  CustomerCare = 'CustomerCare',
  Copilot = 'Copilot',
  CopilotInCustomerCare = 'CopilotInCustomerCare',
  Navigation = 'Navigation',
  PortfolioTransfer = 'PortfolioTransfer'
}

export enum GAEventActions {
  Activity = 'Activity',
  AddContract = 'AddContract',
  BrandAndModel = 'ByBrandModelDropdown',
  Calculate = 'Calculate',
  Cancel = 'Cancel',
  ClickOn = 'ClickOn',
  Close = 'Close',
  Conclude = 'Conclude',
  ConcludeFromSuggestedProduct = 'ConcludeFromSuggestedProduct',
  ConcludeFromOtherProduct = 'ConcludeFromOtherProduct',
  ConcludeFromDraftContract = 'ConcludeFromDraftContract',
  ConcludeFromDraftOffer = 'ConcludeFromDraftOffer',
  ContractCreation = 'contract creation',
  CreateFKSApplication = 'CreateFKSApplication',
  CreatePortfolioTransfer = 'CreatePortfolioTransfer',
  DirectingTo = 'Directing To',
  Download = 'Download',
  DownloadPolicyZip = 'DownloadPolicyZip',
  Duration = 'Duration',
  Error = 'Error',
  FeatureNotUsed = 'FeatureNotUsed',
  Finish = 'Finish',
  Generate = 'Generate',
  LeadsCalculatePremium = 'calculate premium',
  LeadsSell3rdPartyPolicy = 'sell 3rd party policy',
  LeadsPriceComparison = 'price comparison',
  LinkSharingDisable = 'DisableCampaign',
  LinkSharingEnable = 'EnableCampaign',
  Open = 'Open',
  OpenedInDifferentForm = 'OpenedInDifferentForm',
  OpendedInSameForm = 'OpendedInSameForm',
  OpenOcrForm = 'Ocr filled form opened',
  OpenModal = 'OpenModal',
  OtherProductSelected = 'OtherProductSelected',
  PrefillingReset = 'Reset',
  PrefillingSelectCustomer = 'SelectCustomer',
  Review = 'Review',
  Save = 'Save',
  ScrolledIntoView = 'ScrolledIntoView',
  SelectInsuranceCarrier = 'select insurance carrier',
  SelectNotCeoBrokerRegistration = 'SelectNotCeoBrokerRegistration',
  SelectProduct = 'select product',
  SelectProductGroup = 'select product group',
  SelectTypeABrokerRegistration = 'SelectTypeABrokerRegistration',
  SetBrokerId = 'SetBrokerId',
  Share = 'Share',
  ShareQuestions = 'Share questions',
  StartCreateFKSCustomer = 'StartCreateFKSCustomer',
  Success = 'Success',
  SuggestedProductSelected = 'SuggestedProductSelected',
  TimeUploadPolicies = 'TimeUploadPolicies',
  TypeNumber = 'ByTypenumber',
  SelectedProduct = 'SelectProduct',
  SelectOffer = 'SelectOffer',
  SaveAsDraftStep = 'SaveAsDraftStep',
  BackPrevious = 'BackPrevious',
  CompleteRiskAnalysis = 'Complete Risk Analysis',
  XProductAvailable = 'X product available',
  Continue = 'Continue',
  WefoxPolicies = 'Wefox Policies',
  ThirdPartyPolicies = '3rd Party Policies',
  Search = 'Search',
  NextContactDetails = 'Next: Contact Details',
  OpenCalendar = 'Open Calendar Drawer From Navbar',
  SendRequest = 'Send Request for an Appointment'
}

export const PaymentAnalyticsType = {
  contract: 'Contract',
  customer: 'Customer'
};

export const InsuredObject = 'InsuredObject';

export const SupportButtonType = {
  cancel: 'Cancel/Close',
  main: 'Main Button'
};

export const RolesButtonType = {
  confirm: 'ConfirmRoleDelete',
  create: 'RoleCreate',
  delete: 'RoleDelete',
  edit: 'RoleEdit',
  save: 'RoleSave'
};

export const UsersButtonType = {
  confirm: 'ConfirmUserDelete',
  create: 'UserCreate',
  deactivate: 'UserDeactivate',
  edit: 'UserEdit',
  reactivate: 'UserReactivate',
  resendPassword: 'ResendPassword',
  save: 'UserSave'
};

export const ProfileAnalyticsType = {
  edit: 'EditButton',
  save: 'SaveButton',
  navigate: 'NavigateButton'
};

export const GARegistrationAction = {
  headerLink: 'Click on Header Link to Registration',
  loginLink: 'Click on Login Link to Registration'
};

export const LeadsBtnEvents: { [key: string]: string } = {
  OpenIn: 'OPEN',
  CompareIn: 'COMPARE',
  SellIn: 'SELL'
};

export const GALeadsEventsMapping = {
  [LeadsBtnEvents.OpenIn]: GAEventActions.LeadsCalculatePremium,
  [LeadsBtnEvents.CompareIn]: GAEventActions.LeadsPriceComparison,
  [LeadsBtnEvents.SellIn]: GAEventActions.LeadsSell3rdPartyPolicy
};

export const GALeadsCategoriesMapping = {
  [LeadsBtnEvents.OpenIn]: GAEventCategories.LeadsCalculatePremium,
  [LeadsBtnEvents.CompareIn]: GAEventCategories.LeadsPriceComparison,
  [LeadsBtnEvents.SellIn]: GAEventCategories.LeadsSell3rdPartyPolicy
};
