import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ThirdPartyService } from '@wefoxGroupOneBP/app/private/modules/carriers/services/third-party.service';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { BaseRouteName, RedirectionService } from '@wefoxGroupOneBPShared/services/redirection.service';
import { Subject, takeUntil } from 'rxjs';
import { CarrierExcerptData, InsuranceCardData } from './interfaces/carrier-excerpt.interface';
@Component({
  selector: 'one-carrier-excerpt',
  templateUrl: './carrier-excerpt.component.html',
  styleUrls: ['./carrier-excerpt.component.scss']
})
export class CarrierExcerptComponent implements OnInit, OnDestroy {
  @Input() config: CarrierExcerptData;

  public currentCountry: string;
  public carriers: InsuranceCardData[];

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _sessionQuery: SessionQuery,
    private _router: Router,
    private _thirdPartyService: ThirdPartyService,
    private _redirectionService: RedirectionService
  ) {
    this.currentCountry = this._sessionQuery.getCountry();
  }
  ngOnInit(): void {
    this._thirdPartyService
      .getCarriers$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((data: InsuranceCardData[]) => {
        this.carriers = data;
      });
  }

  public navigateToCarriers(): void {
    this._redirectionService.navigate(BaseRouteName.carriers);
  }

  public navigateToSell3rdParty(carrier: InsuranceCardData): void {
    this._router.navigateByUrl(this.getCountryPrefix() + '/carriers/third-party/' + carrier.id, {
      state: { carrier }
    });
  }

  public getCountryPrefix(): string {
    return this._sessionQuery.getCountryPrefix();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
