// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comet-card {
  min-width: 505px;
  width: 505px;
}
.comet-card span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::part(comet-small-card-title) {
  font-weight: 600;
}

wg-ui-icon[key=copy] {
  cursor: pointer;
}

.comet-card::part(comet-card-container) {
  border: 1px solid var(--neutral-20);
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/available-tool-card/available-tool-card.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,YAAA;AACF;AACE;EACE,gBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,mCAAA;AAAF","sourcesContent":[".comet-card {\n  min-width: 505px;\n  width: 505px;\n\n  span {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n}\n\n::part(comet-small-card-title) {\n  font-weight: 600;\n}\n\nwg-ui-icon[key='copy'] {\n  cursor: pointer;\n}\n\n.comet-card::part(comet-card-container) {\n  border: 1px solid var(--neutral-20);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
