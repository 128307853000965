import { Injectable } from '@angular/core';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {
  private _country = this._sessionQuery.getCountry();

  constructor(private _sessionQuery: SessionQuery) {}

  // eslint-disable-next-line
  public getCityByPostcode$(postalCode: any): Observable<{ [key: string]: string }> {
    postalCode = postalCode === '' ? '0' : postalCode; // Postcode cannot be empty space

    if (!this._isPostalCodeValid(postalCode?.length)) {
      return of({});
    }

    return new Observable(observer => {
      new google.maps.Geocoder().geocode(
        {
          componentRestrictions: {
            country: this._country,
            postalCode: postalCode
          }
        },
        (results, status) => {
          let kanton;
          if (results) {
            kanton = results[0].address_components[2].short_name;
            if (kanton.length !== 2) {
              kanton = results[0].address_components[3].short_name;
            }
          }
          if (status === 'OK') {
            // Tested many zipcodes and this seems to have the most consistently accurate result.
            observer.next({
              kanton,
              name: results[0]?.formatted_address?.replace(`${postalCode} `, '')?.split(',')[0]
            });
          } else {
            observer.next(null);
          }

          observer.complete();
        }
      );
    });
  }

  public getPlacePredictions$(
    inputId: string,
    fields: string[] = ['address_components', 'geometry'],
    types: string[] = ['address']
  // eslint-disable-next-line
  ): Observable<any> {
    const country = this._sessionQuery.getCountry();
    const input = document.getElementById(inputId) as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(input, {
      componentRestrictions: { country },
      fields,
      types
    });

    return new Observable(observer => {
      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        const predictions = autocomplete.getPlace();

        if (predictions !== null) {
          observer.next(predictions);
        } else {
          observer.next(null);
        }
        observer.complete();
      });
    });
  }

  private _isPostalCodeValid(length): boolean {
    switch (this._country) {
      case COUNTRIES.ch:
        return length === 4;
      default:
        return length === 5;
    }
  }
}
