// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onebp-loading-message {
  margin: 0 auto;
  margin-top: calc(50vh + 40px);
  max-width: 800px;
  width: auto;
}

.onebp-loading {
  background-color: rgba(255, 255, 255, 0.85);
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;
}

.onebp-loading .onebp-loading--one {
  left: calc(50% + 40px);
  margin-left: -72px;
  margin-top: -48px;
  position: absolute;
  top: 50%;
  transition: left 0.5s ease-out;
}

.onebp-o-spinnerLayout .onebp-loader__spinner {
  animation: wipoSpinner 0.5s linear infinite;
  border: 1em solid rgba(0, 0, 0, 0.2);
  border-left-color: var(--primary-base);
  border-radius: 50%;
  font-size: 6px;
  height: 72px;
  position: relative;
  width: 72px;
}

@keyframes wipoSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes wipoSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/loading/loading.component.scss"],"names":[],"mappings":"AACA;EACE,cAAA;EACA,6BAAA;EACA,gBAAA;EACA,WAAA;AAAF;;AAGA;EACE,2CAAA;EACA,cAAA;EACA,YAAA;EACA,OAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,sBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,QAAA;EACA,8BAAA;AAAF;;AAIE;EACE,2CAAA;EACA,oCAAA;EACA,sCAAA;EACA,kBAAA;EACA,cAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;AADJ;;AAKA;EACE;IACE,uBAAA;EAFF;EAKA;IACE,yBAAA;EAHF;AACF;AAMA;EACE;IACE,uBAAA;EAJF;EAOA;IACE,yBAAA;EALF;AACF","sourcesContent":["\n.onebp-loading-message {\n  margin: 0 auto;\n  margin-top: calc(50vh + 40px);\n  max-width: 800px;\n  width: auto;\n}\n\n.onebp-loading {\n  background-color: rgb(255 255 255 / 85%);\n  display: block;\n  height: 100%;\n  left: 0;\n  overflow: hidden;\n  position: fixed;\n  text-align: center;\n  top: 0;\n  width: 100%;\n  z-index: 100;\n}\n\n.onebp-loading .onebp-loading--one {\n  left: calc(50% + 40px);\n  margin-left: -72px;\n  margin-top: -48px;\n  position: absolute;\n  top: 50%;\n  transition: left 0.5s ease-out;\n}\n\n.onebp-o-spinnerLayout {\n  .onebp-loader__spinner {\n    animation: wipoSpinner 0.5s linear infinite;\n    border: 1em solid rgb(0 0 0 / 20%);\n    border-left-color: var(--primary-base);\n    border-radius: 50%;\n    font-size: 6px;\n    height: 72px;\n    position: relative;\n    width: 72px;\n  }\n}\n\n@keyframes wipoSpinner {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@keyframes wipoSpinner {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
