import { PaymentDetail } from "@wefoxGroupOneBPCore/interfaces/customers.interface";

export interface AllCustomerInfo {
  content: Partial<Customer>[];
  empty: boolean;
  total_elements: number;
  total_pages: number;
}

export interface PolicyHolderLegalEntity {
  legal_name: string;
  type: string;
}

export interface PolicyHolderPrivatePerson {
  birthdate: string;
  first_name: string;
  gender: string;
  last_name: string;
  type: string;
}

export interface Customer extends PolicyHolderLegalEntity, PolicyHolderPrivatePerson {
  city: string;
  email: string;
  house_number: string;
  id: string;
  mobile_phone?: string;
  payment_details?: PaymentDetail;
  phone_number: string;
  postal_code: string;
  street: string;
}
