import { Injectable } from '@angular/core';
import { AnyTypeAnnotation } from '@babel/types';
import { translate } from '@ngneat/transloco';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { BannerType } from '@wefoxGroupOneBPCore/constants/banner.constant';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { CustomerDynamicDetailMapped } from '@wefoxGroupOneBPCore/interfaces/customers.interface';
import {
  AllListInfo,
  DynamicTableConfigParsed,
  DynamicViewConfigParsed,
  PageDataPagination
} from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import {
  DetailCardDetailItemsConfig,
  DetailCardsConfig,
  DetailItemsConfig
} from '@wefoxGroupOneBPPrivate/modules/claims/interfaces/claims-details.interface';
import { PAYMENT_FREQUENCY_SHORT_KEY } from '@wefoxGroupOneBPPrivate/modules/contracts/constants/contracts.constants';
import { LEADS_PRODUCT_TYPE } from '@wefoxGroupOneBPPrivate/modules/leads/constants/leads-list-config.constants';
import { PAGE_DATA_DEFAULTS } from '@wefoxGroupOneBPPrivate/product/constants/list.constant';
import {
  DATA_TYPE,
  DETAILS_CARD_TYPE,
  DYNAMIC_TABLE_PROPERTY_NAME,
  FEEDBACK_STATUS_BADGE_COLOR,
  STATUS_BADGE_COLOR
} from '@wefoxGroupOneBPPrivate/product/constants/page-details.constant';
import { ListSortDefaults } from '@wefoxGroupOneBPPrivate/product/interfaces/page-details.interface';
import { DateFormat } from '@wefoxGroupOneBPShared/constants';
import {
  DynamicDetailCardConfig,
  DynamicDetailColumnConfig,
  TableRowItemConfig
} from '@wefoxGroupOneBPShared/interfaces/dynamic-details-card.interface';
import { FilterType } from '@wefoxGroupOneBPShared/modules/wg-table2/constants/table2.constants';
import { CurrencyTransform } from '@wefoxGroupOneBPShared/pipes';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { Observable } from 'rxjs';
import { PageDetailsDataService } from './page-details-data.service';

@Injectable({
  providedIn: 'root'
})
export class PageDetailsService {
  constructor(
    private _currency: CurrencyTransform,
    private _pageDetailsDataService: PageDetailsDataService,
    private _sessionQuery: SessionQuery
  ) { }

  public buildDetailCards(
    details: Partial<DynamicDetailCardConfig>[] | Partial<DynamicTableConfigParsed>[],
    data: Partial<CustomerDynamicDetailMapped>,
    prefix: string
  ): Partial<DetailCardOptions>[] {
    return details.map(detail => {
      if (
        detail.type === DETAILS_CARD_TYPE.table ||
        detail.type === DETAILS_CARD_TYPE.note ||
        detail.type === DETAILS_CARD_TYPE.stages
      ) {
        return detail;
      }
      const detailItems = this._buildDetailItems(detail.detail_items, data, prefix);
      const infoBanner = detail.info_banner_text
        ? {
          infoBanner: {
            textKey: detail.info_banner_text,
            type: BannerType.info
          }
        }
        : {};
      return {
        ...detail,
        columnModifier: detail.column_modifier,
        customerId: data.original_data.id,
        detailItems,
        descriptionText: detail.descriptionText,
        dynamicFormConfig: detail.edit_config,
        ...infoBanner,
        isEditable: detail.is_editable,
        isBeta: detail.is_beta ?? false,
        isSmallCard: detail.is_small_card ?? false,
        isHidden: detail.is_hidden ?? false,
        footer: detail.footer,
        loaderHeightModifier: detail.loader_height,
        loaderText: detail.loader_text,
        title: `_PROD_${detail.name}_title`
      };
    });
  }

  // eslint-disable-next-line
  public findItemValue(data: any, displayItems: any): string {
    if (displayItems.default_value) {
      return displayItems.default_value;
    }

    let value;
    let tempData;
    displayItems?.target_data?.forEach(param => {
      const string = typeof param === DATA_TYPE.string;
      if (string) {
        value = tempData ? tempData[param] : data[param];
        tempData = value;
      }

      if (!string) {
        param.forEach(p => {
          const stringSub = typeof p === DATA_TYPE.string;
          if (!stringSub) {
            p.forEach(pam => {
              value = tempData ? tempData[pam] : data[pam];
              tempData = value ? value : tempData;
              value = tempData;
            });
          } else {
            value = tempData ? tempData[p] : data[p];
            tempData = value ? value : tempData;
            value = tempData;
          }
        });
      }
    });
    return value;
  }

  public getCardDetailsConfig$(type?: string, subtype?: string, subSubtype?: string, target?: string): Observable<DetailCardsConfig> {
    return this._pageDetailsDataService.getCardDetailsConfig$(
      this._sessionQuery.getCountry(),
      type,
      subtype,
      subSubtype,
      target
    );
  }

  public getSortDefault(listOfColumns): ListSortDefaults {
    const sort = listOfColumns?.find(c => c.sortDefault);
    if (sort) {
      return {
        sort_key: sort.sortKey,
        sort_order: sort.sortDefault
      };
    }
    return;
  }

  public getStatusBadgeColor(status): string {
    return STATUS_BADGE_COLOR[status?.toLowerCase()];
  }

  public getFeedbackStatusBadgeColor(status): string {
    return FEEDBACK_STATUS_BADGE_COLOR[status?.toLowerCase()];
  }

  // eslint-disable-next-line
  public getViewConfig$(type: string, target: string): Observable<any> {
    return this._pageDetailsDataService.getViewConfig$(this._sessionQuery.getCountry(), type, target);
  }

  public getTableConfig$(type: string, subtype: string): Observable<DetailCardsConfig> {
    return this._pageDetailsDataService.getTableConfig$(this._sessionQuery.getCountry(), type, subtype);
  }

  public getTableRowItems(dynamicConfig: Partial<DynamicTableConfigParsed>[], listType: string): TableRowItemConfig[] {
    const listConfig = dynamicConfig.find(c => c.list_type === listType);

    return listConfig.column_headers.map(head => {
      return {
        auto_transform: head.auto_transform,
        name: head.cell.displayKey,
        clickable: head.clickable,
        custom_prefix: head.custom_prefix,
        replace_empty_value: head.replace_empty_value,
        transform_type: head.transform_type
      };
    });
  }

  // eslint-disable-next-line
  public mapDetailsCards(detailsData: any, dynamicConfig: any): any {
    const detailsCards = dynamicConfig.filter(card => card.type === DETAILS_CARD_TYPE.details);
    const detailsConfig = detailsCards.reduce((acc, det) => {
      const all = [];
      det.detail_items.forEach(i => all.push(i));
      return [...acc, ...all];
    }, []);
    const additionalDetails = [];
    detailsCards.forEach(c => c.additional_needed_data?.forEach(d => additionalDetails.push(d)));

    return [...detailsConfig, ...additionalDetails].reduce((acc, item) => {
      return { ...acc, [item.name]: this.findItemValue(detailsData, item) };
    }, {});
  }

  // eslint-disable-next-line
  public mapExpandableConfig(dynamicConfig: any, data: any): any {
    if (!dynamicConfig) {
      return;
    }

    return dynamicConfig.map(e => {
      return {
        header: e.header,
        t_selector: `expanded_data_${e.name}_`,
        value: this.transformValue(e, data)
      };
    });
  }

  // eslint-disable-next-line
  public mapListToHeader(dynamicConfig: Partial<DynamicTableConfigParsed>[], data: any, listType: string): any {
    const listConfig = dynamicConfig.find(i => i.list_type === listType);
    const displayItems = listConfig.column_headers.map(h => {
      const { custom_prefix, replace_empty_value, target_data, transform_type } = h;
      return {
        name: h.cell.displayKey,
        custom_prefix,
        replace_empty_value,
        target_data,
        transform_type
      };
    });

    return [...displayItems, ...listConfig.additional_needed_data].reduce((acc, config) => {
      acc = {
        ...acc,
        [config?.name]: this.findItemValue(data, config)
      };

      return acc;
    }, {});
  }

  // eslint-disable-next-line
  public mapSelectedFilters(tableConfig, filters): any {
    const tableFilters = tableConfig.column_headers.filter(h => h[DYNAMIC_TABLE_PROPERTY_NAME.filterConfig]);
    return tableFilters?.map(f => {
      const selectedFilter = filters.find(s => s.key === f.filterConfig.key);
      const selectedFilterType = f.filterConfig.type;

      return {
        id: f.filterConfig.key,
        category_label: f.headerLabel,
        filter_values_labels:
          selectedFilter?.value &&
            (selectedFilterType === FilterType.FILTER_SINGLE_OPTION ||
              selectedFilterType === FilterType.FILTER_MULTIPLE_OPTION)
            ? selectedFilter.value.map(v => f.filterConfig.options.find(option => option.value === v).label)
            : selectedFilter?.value && selectedFilterType === FilterType.FILTER_STRING
              ? selectedFilter.value
              : ['_GEN_all_types'],
        selected: selectedFilter?.value?.length > 0
      };
    });
  }

  public parseConfig(
    data: DetailCardsConfig
  ): DynamicDetailCardConfig[] | DynamicTableConfigParsed[] | DynamicViewConfigParsed[] {
    return data.config.map(c => JSON.parse(c));
  }

  public parseConfigListOfColumns(data: DetailCardsConfig): DynamicDetailColumnConfig[] {
    return data.config.map(c => JSON.parse(c));
  }

  public replaceSpecialCharacters(str: string): string {
    if (!str) {
      return;
    }
    return str.split(' ').join('_').split('-').join('_').split('.').join('_').toLowerCase();
  }

  // eslint-disable-next-line
  public transformValue(item: any, data: any, prefix?: string): string {
    const country = this._sessionQuery.getCountry();
    const value = item.default_value ? item.default_value : data[item.name];
    const PREFIX = prefix ? prefix : '_PROD_';
    switch (item.transform_type) {
      case 'add_prefix':
        return `${translate(item.value_prefix)} ${value ? value : '-'}`;
      case 'add_suffix':
        return `${value ? value : '-'} ${translate(item?.value_suffix)}`;
      case 'address': {
        if (value) {
          const { street, place, house_number, postal_code, city } = value;
          return `${street || '-'} ${house_number || '-'}, ${postal_code || '-'} ${city || place || '-'}`;
        }
        return `${data.street || '-'} ${data.house_number || '-'}, ${data.postal_code || '-'} ${data.city || data.place || '-'
          }`;
      }
      case 'address-third-party': {
        return `${data.original_data.address || data.address}, ${data.postal_code} ${data.city}`;
      }
      case 'addresses-array': {
        const addressArray = data.addresses || data.address;
        if (addressArray?.length) {
          const { street, place, house_number, postal_code, city } = addressArray[0];
          return `${street} ${house_number}, ${postal_code} ${city || place}`;
        } else {
          return `${data.street} ${data.house_number}, ${data.postal_code} ${data.city || data.place}`;
        }
      }
      case 'array': {
        return value[item.transform_array.position][item.transform_array.property];
      }
      case 'brand_and_model':
        return `${data.brand} - ${data.model}`;
      case 'boolean': {
        if (value === undefined || typeof value === 'object') {
          return 'null';
        }

        if (typeof value !== DATA_TYPE.string && typeof value !== 'boolean') {
          return '-';
        }
        return `_PROD_${value === 'true' || value === 'yes' ? 'yes_value' : 'no_value'}`;
      }
      case 'boolean_status':
        const val = data.status === true ? 'true' : 'false';
        const statusPrefix = item.custom_prefix ? item.custom_prefix : '_PROD_status';
        return `${statusPrefix}_${val}`;
      case 'capitalize-first-letter':
        return value?.charAt(0)?.toUpperCase() + value?.slice(1);
      case 'currency':
        return `${this._currency.transform(value)}`;
      case 'currency_no_decimal':
        return `${this._currency.transform(Math.floor(value), { digitsInfo: '1.0-0' })}`;
      case 'date':
        return value ? dayjs(value, DateFormat.concludeRequest)?.format(DateFormat.default) : '-';
      case 'date-epoch':
        return dayjs(new Date(Number(value))).format(DateFormat.default);
      case 'date-month-year':
        return dayjs(value, DateFormat.concludeRequest).format('MMMM YYYY');
      case 'date-utc':
        return value ? dayjs(value)?.format(DateFormat.default) : '-';
      case 'hsn_and_tsn':
        return `${data.hsn} / ${data.tsn}`;
      case 'license_plate': {
        const plateValue = value?.toUpperCase();
        switch (country) {
          case COUNTRIES.ch:
            return plateValue.replace(/(\d)/, '-$1');
          case COUNTRIES.it: {
            switch (data['type_of_vehicle']) {
              case 'car': {
                const valArr = Array.from(plateValue);
                [2, 6].forEach(idx => valArr.splice(idx, 0, ' '));
                return valArr.join('');
              }
              case 'light_truck': {
                const valArr = Array.from(plateValue);
                [2, 6].forEach(idx => valArr.splice(idx, 0, ' '));
                return valArr.join('');
              }
              case 'motorcycle': {
                return plateValue.replace(/(\D)(\d)/, '$1 $2');
              }
              case 'moped': {
                return plateValue.replace(/(\D)(\d)/, '$1 $2');
              }
              default:
                return plateValue;
            }
          }
          default:
            return plateValue;
        }
      }
      case 'lokalise':
        return item.auto_transform
          ? item.custom_prefix
          : `${item.custom_prefix ? item.custom_prefix : PREFIX}${item.custom_prefix ? '' : item.name}${value ? '_' : ''
          }${value ? this.replaceSpecialCharacters(value) : ''}`;
      case 'translate':
        return translate(`${item.custom_prefix}${value}`);
      case 'lokalise-multi-strings':
        const items = value.split(',');

        return items
          .map(i => {
            const itemName = item.custom_prefix ? '' : item.name;
            const prefixed = item.custom_prefix || PREFIX;
            const replacedString = i ? this.replaceSpecialCharacters(i) : '';
            const space = i ? '_' : '';

            return item.auto_transform
              ? item.custom_prefix
              : translate(`${prefixed}${itemName}${space}${replacedString}`);
          })
          .join(', ');
      case 'leads-product-type':
        let productType = '';
        Object.keys(LEADS_PRODUCT_TYPE).forEach(key => {
          const item = LEADS_PRODUCT_TYPE[key];
          if (value?.toUpperCase() === item.value.toUpperCase()) {
            productType = translate(`_LL${item.label}`);
          }
        });
        return !!productType ? productType : '-';
      case 'mileage':
        return value ? `${value} km` : '-';
      case 'lowercase':
        return value.toLowerCase();
      case 'name': {
        let naturalPerson;
        if (country === COUNTRIES.it) {
          naturalPerson = !data.legal_name;
        } else {
          naturalPerson =
            data.is_natural_person === 'PersonAccount' ||
            data.record_type === 'PersonAccount' ||
            data.person_type === 'natural_person' ||
            data.is_natural_person === 'natural_person' ||
            data.is_natural_person === true;
        }
        return naturalPerson
          ? `${data.gender ? `${translate(`_PROD_gender_title_${data.gender}`)} ` : ''}${data.first_name} ${data.last_name
          }`
          : data.legal_name;
      }
      case 'name-no-legal-entity':
        return `${data.gender ? `${translate(`_PROD_gender_title_${data.gender}`)} ` : ''}${data.firstname
          } ${data.lastname}`;
      case 'name-third-party':
        const company = !data.lastname;
        return company
          ? `${data.firstname}`
          : `${data.gender ? `${translate(`_PROD_gender_title_${data.gender}`)} ` : ''}${data.firstname} ${data.lastname
          }`;
      case 'phones-array': {
        if (value) {
          const { prefix, number } = value[0];
          return `${prefix ? prefix : ''}${number ? number : '-'}`;
        } else {
          return '-';
        }
      }
      case 'premium':
        return data.premium ? this._currency.transform(data.premium) : '-';
      case 'premium_and_frequency':
        return data.premium ? `${data.premium} ( ${translate(data.payment_frequency)} )` : '-';
      case 'previous_premium_and_frequency':
        return data.previous_premium && typeof data.previous_premium !== 'object'
          ? `${data.previous_premium} ${data.previous_payment_frequency
            ? `( ${translate(
              PAYMENT_FREQUENCY_SHORT_KEY[data.previous_payment_frequency.toLowerCase()]
            )} )`
            : ''
          }`
          : '-';
      case 'remove_type_prefix':
        return value.split('-')[1];
      case 'serial_number': {
        const valArr = Array.from(value);
        [3, 7].forEach(idx => valArr.splice(idx, 0, '.'));
        return valArr.join('');
      }
      case 'square_meters':
        return `${value} m²`;
      case 'today':
        return dayjs().format(DateFormat.default);
      case 'quotation_status':
        return `_QL_status_${data.status_name ? data.status.name : ''}${data.status_reason ? '_' : ''
          }${data.status?.reason?.toLowerCase()?.split(' ')?.join('_')}`;
      case 'uppercase':
        return value?.toUpperCase();
      default: {
        if (value === 0 || value === '0') {
          return '0';
        }
        return value !== (undefined || null) ? value : item.replace_empty_value ? item.replace_empty_value : '-';
      }
    }
  }

  // eslint-disable-next-line
  public transformTableData(tableItems: any, data: AnyTypeAnnotation) {
    return tableItems.reduce((acc, i) => {
      acc = { ...acc, [i.name]: this.transformValue(i, data) };
      if (i.clickable) {
        acc = { ...acc, [`${i.name}Enabled`]: true };
      }

      return acc;
    }, {});
  }

  public updatePageData(response: Partial<AllListInfo>): PageDataPagination {
    if (!response) {
      return;
    }
    const pageNumber = response.number || response.page_number || 0;
    const pageSize = response.number_of_elements || response.page_size || 0;
    const totalElements = response.total_elements || 0;
    return response.content?.length
      ? {
        from: pageNumber * pageSize + 1,
        to: totalElements <= (pageNumber + 1) * pageSize ? totalElements : pageNumber * pageSize + pageSize,
        total: totalElements
      }
      : PAGE_DATA_DEFAULTS;
  }

  // eslint-disable-next-line
  private _buildDetailItems(items: DetailCardDetailItemsConfig[], data: any, prefix: string): DetailItemsConfig[] {
    if (!items) {
      return;
    }
    return items
      .map(item => {
        return {
          control_name: item.name,
          label: item.custom_label ? item.custom_label : `_PROD_${item.name}`,
          remove_null_value: item.remove_null_value,
          value: this.transformValue(item, data, prefix),
          values: [this.transformValue(item, data, prefix)]
        };
      })
      .filter(i => {
        if (i.value === 'null' && !i.remove_null_value) {
          i.value = '-';
          i.values = ['-'];
        }
        return i.value !== 'null';
      });
  }
}
