import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';
import { Variable } from '../interfaces/variable.interface';

export interface VariablesState {
  variables: Variable[];
  lastUpdate: Variable;
}

const initialState: VariablesState = {
  variables: [],
  lastUpdate: { key: 'INITIAL', value: false }
}

@Injectable( { providedIn: 'root' })
export class VariablesStore {
  private _state: BehaviorSubject<VariablesState>;

  constructor() {
    this._state = new BehaviorSubject<VariablesState>(initialState);
  }

  get state$(): Observable<VariablesState> {
    return this._state.asObservable();
  }

  get state(): VariablesState {
    return this._state.getValue();
  }

  get variables(): Variable[] {
    return this._state.getValue().variables;
  }

  get lastUpdate$(): Observable<Variable> {
    return this._state.pipe(map(s => s.lastUpdate));
  }

  public getBoolVariableValue(key: string):boolean {
    return this.variables.find(v => v.key === key)?.value as boolean;
  }

  public getStrVariableValue(key: string): string {
    return this.variables.find(v => v.key === key)?.value as string;
  }

  public getDependentVariables(controlName: string): Variable[] {
    return this.variables.filter(d => d.depends_on?.includes(controlName));
  }

  public setInitialVariables(variables: Variable[]): void {
    this.setState((state) => { 
      return ({
        variables: [...variables],
        lastUpdate: state.lastUpdate
      });
    });
  }

  public setVariable(key: string, value: boolean | string | number): void {
    this.setState((state) => { 
      const variables = state.variables;
      const actualVar = variables.find(v => v.key === key);
      if (actualVar) {
        actualVar.value = value;
      }
      return ({
        variables: [...variables],
        lastUpdate: actualVar
      });
    });
  }

  setState<K extends keyof VariablesState, E extends Partial<Pick<VariablesState, K>>>(
    fn: (state: VariablesState) => E
  ): void {
    const state = fn(this.state);
    this._state.next({ ...this.state, ...state });
  }

  select<K>(selector: (state: VariablesState) => K): Observable<K> {
    return this.state$.pipe(map(selector), distinctUntilChanged());
  }
}