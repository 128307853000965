import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WgUiIconComponent } from './wg-ui-icon.component';

@NgModule({
  declarations: [WgUiIconComponent],
  exports: [WgUiIconComponent],
  imports: [CommonModule]
})
export class WgUiIconModule {}
