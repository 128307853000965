import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@wefoxGroupOneBPCore/services/base-data.service';
import { Observable } from 'rxjs';
import { IpLocation } from '../interfaces/ip-location.interface';
import { SessionQuery } from '../queries';

@Injectable({
  providedIn: 'root'
})
export class IpLocationService extends BaseDataService {
  private _route = 'http://ip-api.com/json';

  constructor(
    protected httpClient: HttpClient,
    protected sessionQuery: SessionQuery
  ) {
    super(httpClient, sessionQuery);
  }

  public getGeolocationData(): Observable<IpLocation> {
      return this.get$(this._route) as Observable<IpLocation>;
  }
}
