export class StringUtil {
  public static camelToSnake(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  public static capitalizeFirstLetter(str: string): string {
    if (typeof str !== 'string') {
      return;
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  public static cleanSpecialCharacters(str: string): string {
    return str.replace(/[^a-zA-Z0-9 ]/g, '');
  }

  public static replaceAll(str: string, charsToReplace: string[], replaceWith: string): string {
    if (!str) {
      return;
    }

    let string = str;

    charsToReplace.forEach(char => {
      string = string.split(char).join(replaceWith);

      return string;
    });

    return string;
  }

  public static snakeToCamel(str: string): string {
    return str.replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace('-', '')
        .replace('_', '')
    );
  }

  public static toLokaliseKey(str: string): string {
    if (!str) {
      return;
    }

    return str.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ' ').replace(new RegExp(' ', 'g'), '_');
  }
}
