import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
interface DialogErrorOptions {
  buttonAction: Function; // eslint-disable-line
  buttonActionSecondary: Function; // eslint-disable-line
  buttonText: string;
  buttonTextSecondary: string;
  checkboxLabel?: string;
  closeAction: Function; // eslint-disable-line
  description: string;
  descriptionWithoutSpacing?: boolean;
  errorKeys: any[]; // eslint-disable-line
  icon?: { color?: string; id: string };
  iconId: string;
  isDanger: boolean;
  keepModalOpen: boolean;
  list: { label: string; value: string }[];
  noBorder?: boolean;
  placeholderText: string;
  showCloseButton: boolean;
  subtitle: string;
  title: string;
  tSelector: string;
}

@Component({
  selector: 'one-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent implements OnInit {
  public contentViewOptions: { options: Partial<DialogErrorOptions> };
  public errorKeys: any[]; // eslint-disable-line
  public form: UntypedFormGroup;
  public options: Partial<DialogErrorOptions>;

  constructor(private _modal: WgModalService, private _fb: UntypedFormBuilder) {}

  public buttonAction(): void {
    if (this.options.buttonAction) {
      const checkboxValue = this.options.checkboxLabel ? this.form.get('dontShow').value : null;
      this.options.buttonAction(checkboxValue);
    }
    if (this.options.keepModalOpen) {
      return;
    }
    this._modal.hide();
  }

  public buttonActionSecondary(): void {
    if (this.options.buttonActionSecondary) {
      this.options.buttonActionSecondary();
    } else {
      this._modal.hide();
    }
  }

  public closeAction(): void {
    if (this.options.closeAction) {
      this.options.closeAction();
    } else {
      this._modal.hide();
    }
  }

  public getHeaderModifier(): string {
    const iconId = this.options.icon?.id;
    return iconId ? `-${iconId.split('__').pop()}` : '';
  }

  public ngOnInit(): void {
    this.options = { ...this.contentViewOptions?.options };
    this.errorKeys = this.options?.errorKeys || [];

    if (this.options.checkboxLabel) {
      this._generateForm();
    }
  }

  private _generateForm() {
    this.form = this._fb.group({
      dontShow: this._fb.control('')
    });
  }
}
