import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProductQuery } from '@wefoxGroupOneBPCore/queries/product.query';

export interface MileageAdapterComponentOptions {
  control_name: string;
  label?: string;
  required: boolean,
  validators: [{ type: string }];
}
@Component({
  selector: 'one-mileage-adapter',
  templateUrl: './mileage-adapter.component.html'
})
export class MileageAdapterComponent {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public translationPrefix: string;

  constructor(private _productQuery: ProductQuery) {
    this.translationPrefix = this._productQuery.getTranslationPrefix(this._productQuery.getActive().id);
  }
}
