// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.remove-field-button {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 44px;
}

.fields-container {
  width: 94%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-dynamic-components/inputs/duplicate-field/duplicate-field-adapter.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,QAAA;EACA,SAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".remove-field-button {\n  cursor: pointer;\n  position: absolute;\n  right: 0;\n  top: 44px;\n}\n\n.fields-container {\n  width: 94%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
