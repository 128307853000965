import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  QueryList,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { TopActionRow } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { WgErrorComponent } from '../wg-error/wg-error.component';

const KeyCodes = {
  enter: 'Enter',
  keyDown: 'ArrowDown',
  keyUp: 'ArrowUp',
  tabulator: 'Tab'
};

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WgDropdownComponent)
    }
  ],
  selector: 'wg-dropdown',
  styleUrls: ['./wg-dropdown.component.scss'],
  templateUrl: './wg-dropdown.component.html'
})
export class WgDropdownComponent implements ControlValueAccessor, AfterViewInit, AfterContentInit {
  // eslint-disable-next-line
  @Input() public set errors(err) {
    this._errorMap = err;
  }
  public chosenOption: WgDropdownOption;

  @ViewChild('container') public container: ElementRef;
  public currentIndex = 0;
  public disabled = false;
  @Input() public expandWidth = false;
  public hasFocus: boolean;
  @Input() public helperText: string;
  @Input() public label: string;
  @Input() public name: string;
  @Input() public options: Array<WgDropdownOption>;
  @Input() public optionsPanelModifiers: string;
  @Input() public required = true;
  @Input() public rounded = false;
  @Input() public selectByValue = false;
  @Input() public selectorLabel = '';
  public showOptions = false;
  @Input() public theme = '';
  @Input() public tooltipText: string;
  @Input() public topActionRow: TopActionRow;

  @ContentChildren(WgErrorComponent) protected _contentErrors: QueryList<WgErrorComponent>;
  protected _errorMap: { [key: string]: string };
  protected _inputControl: AbstractControl;

  public constructor(protected _controlContainer: FormGroupDirective) {}

  public focusLostOptionPanel(): void {
    this.showOptions = false; // configurable?
    this.setFocus(false);
  }

  public getCurrentError(): { className: string; errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this._inputControl.errors || (this._inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: '-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this._inputControl.errors)[0]]) {
      return {
        className: 'one-field-invalid -invalid',
        errorMessage: this._errorMap[Object.keys(this._inputControl.errors)[0]]
      };
    }
    return { className: 'one-field-valid -valid', errorMessage: '' };
  }

  public getCurrentValue(): string {
    const currentValue = this._inputControl.value;
    if (currentValue) {
      this.chosenOption.value = translate(currentValue.value);
      return this.chosenOption.value;
    } else {
      return this.selectorLabel;
    }
  }

  public getFocus(): boolean {
    this.container.nativeElement.focus();
    return true;
  }

  public highlightOption(index: number): void {
    this.currentIndex = index;
  }

  public isDisabled(): boolean {
    return this._inputControl ? this._inputControl.disabled : this.disabled;
  }

  @HostListener('keydown', ['$event'])
  public keyDownEvent(event: KeyboardEvent): void {
    if (this.showOptions) {
      if (event.code === KeyCodes.tabulator) {
        this.selectOption(this.options[this.currentIndex]);
      }
    }
  }

  @HostListener('keyup', ['$event'])
  public keyUpEvent(event: KeyboardEvent): void {
    if (this.showOptions) {
      if (event.code === KeyCodes.keyDown) {
        if (this.currentIndex < this.options.length - 1) {
          this.currentIndex++;
        }
      }
      if (event.code === KeyCodes.keyUp) {
        if (this.currentIndex > 0) {
          this.currentIndex--;
        }
      }
      if (event.code === KeyCodes.enter) {
        this.selectOption(this.options[this.currentIndex]);
      }
    }
  }

  public ngAfterContentInit(): void {
    this._inputControl = this._controlContainer.control.get(this.name);
    this.disabled = this._inputControl.disabled;
    this.topActionRow?.refreshOptions?.subscribe(data => {
      this.options = data.list;
      this.selectOption(data.selected);
    });
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });
  }

  // eslint-disable-next-line
  public onClickTopActionRow(action): void {
    action();
    this.showOptions = false;
  }

  // eslint-disable-next-line
  public propagateChange = (_: any) => {
    this.isDisabled();
  };

  // eslint-disable-next-line
  public registerOnChange(fn: any): void {
    // ControlValueAccessor method

    this.propagateChange = fn;
  }

  public registerOnTouched(): void {
    // ControlValueAccessor method. Not used now
    return;
  }

  public selectOption(option: WgDropdownOption): void {
    this._inputControl.setValue(option);
    this.chosenOption = option;
    this.showOptions = false;
    this.propagateChange(this.chosenOption);
  }

  public setFocus(value: boolean): void {
    this.hasFocus = value;
  }

  public toggleOptions(): void {
    this.showOptions = !this.showOptions;
    if (this.showOptions) {
      setTimeout(() => this.container.nativeElement.focus());
    }
  }

  public writeValue(value: WgDropdownOption): void {
    this.chosenOption = value;
  }
}
