import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  Renderer2
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';
import { InputRefDirective } from '@wefoxGroupOneBPShared/modules/wg-input/directives/input-ref.directive';
import { WgTooltip } from '@wefoxGroupOneBPShared/modules/wg-tooltip/interfaces';
import { WgIconSize, WgIconTypes } from '../../../wg-ui-icon/wg-ui-icon-constants';

interface WgUiIconInterface {
  key: string;
  size: WgIconSize;
  type: WgIconTypes;
}

@Component({
  selector: 'wg-input',
  styleUrls: ['./wg-field.component.scss'],
  templateUrl: './wg-field.component.html'
})
export class WgFieldComponent implements AfterViewInit, AfterContentInit {
  @Input() public controlName: string;
  public disabled: boolean;
  @Input() public disableErrorMessage: boolean;
  // eslint-disable-next-line
  @Input() public set errors(err) {
    this._errorMap = err;
  }

  @Input() public inputInformation: WgTooltip = null;
  @Input() public iconInformation: Partial<WgUiIconInterface>;

  @Output() public iconInsideFieldClicked = new EventEmitter();

  protected _inputControl: AbstractControl;

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _errorMap: { [key: string]: string };
  private _formControlName: string;
  @ContentChild(InputRefDirective) private _inputChild: InputRefDirective;

  constructor(private _controlContainer: FormGroupDirective, private _renderer: Renderer2) {}

  public getCurrentError(): { className: string; errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this._inputControl.errors || (this._inputControl.untouched && !this._controlContainer.submitted)) {
      this._renderer.removeClass(this._inputChild.el.nativeElement, 'is-invalid');
      return { className: 'one-field-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this._inputControl.errors)[0]]) {
      this._renderer.addClass(this._inputChild.el.nativeElement, 'is-invalid');
      return {
        className: 'one-field-invalid',
        errorMessage: this._errorMap[Object.keys(this._inputControl.errors)[0]]
      };
    }
    this._renderer.removeClass(this._inputChild.el.nativeElement, 'is-invalid');
    return { className: 'one-field-valid', errorMessage: '' };
  }

  public isDisabled(): boolean {
    return this._inputControl ? this._inputControl.disabled : this.disabled;
  }

  public ngAfterContentInit(): void {
    this._formControlName = this._inputChild.el.nativeElement.getAttribute('formControlName') || this.controlName;
    this._inputControl = this._controlContainer.control.get(this._formControlName);
    this.disabled = this._inputControl.disabled;
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          let translatedErrorValues = {};
          if (error.placeholders?.length === 1) {
            translatedErrorValues = { value: error.placeholders[0] };
          } else if (error.placeholders?.length === 2) {
            translatedErrorValues = { start: error.placeholders[0], end: error.placeholders[1] };
          }
          return {
            ...acc,
            ...{ [error.key]: translate(error.value, translatedErrorValues) }
          };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });
  }

  public toggleIcon(): void {
    this.iconInsideFieldClicked.emit();
  }
}
