import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadsService } from '@wefoxGroupOneBP/app/private/modules/leads/services/leads.service';
import { DETAILS_CARD_FOOTER_ITEM_TYPE } from '@wefoxGroupOneBP/app/private/product/constants/page-details.constant';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { RouteParams } from '@wefoxGroupOneBPCore/constants/route.constant';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { RiskAnalysisService } from '@wefoxGroupOneBPRiskAnalysis/services/risk-analysis.service';
import { DialogComponent } from '@wefoxGroupOneBPShared/components';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { finalize } from 'rxjs';
import { ShareQuestionsModalComponent } from '../share-questions-modal/share-questions-modal.component';

const BTN_EVENTS: { [key: string]: string } = {
  recalculate: 'RECALCULATE_PROFILE',
  riskProfile: 'COMPLETE_RISK_PROFILE',
  shareQuestions: 'SHARE_QUESTIONS_CLICK',
  expandFooter: 'EXPAND_FOOTER'
};

@Component({
  selector: 'wg-risk-profile-customer-card',
  templateUrl: './risk-profile-customer-card.component.html',
  styleUrls: ['./risk-profile-customer-card.component.scss']
})
export class RiskProfileCustomerCardComponent implements OnInit {
  @Input() public options: DetailCardOptions;
  @Input() public customerId: string;
  @Input() public leadId: string;
  @Input() public isThirdParty: boolean;
  public footerItemTypes = DETAILS_CARD_FOOTER_ITEM_TYPE;

  public profile;
  public products: string[];
  public footerConfig;
  public expandableFooterIsVisible = false;
  public hasRiskProfile;
  public isHidden: boolean;
  public drawerIsVisible = false;

  public loading: boolean;

  constructor(
    private _riskAnalysisService: RiskAnalysisService,
    private _analytics: AnalyticsService,
    private _leadsService: LeadsService,
    private _modal: WgModalService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sessionQuery: SessionQuery
  ) { }

  public ngOnInit(): void {
    this.loading = true;
    this.footerConfig = this.options.footer;
    this.isHidden = false;
    this.leadId = this._route.snapshot.params[RouteParams.leadId];
    //this.leadId = "44444"; // this is the only leadId that returns has_risk_profile: true

    const profileObservable = this.customerId
      ? this._riskAnalysisService.getRiskAnalysisProfileByCustomerId$(this.customerId, this.isThirdParty)
      : this._leadsService.getRiskAnalysisProfileByleadId$(this.leadId);

    profileObservable
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe({
        next: profile => {
          this.profile = profile;
          this.products = profile?.risk_analysis_extra_dto?.recommendations.map(productItem => productItem.product);
          this.hasRiskProfile = profile.has_risk_profile;

          if (this.customerId && !this.hasRiskProfile) {
            this.isHidden = false;
          } else if (this.leadId && !this.hasRiskProfile) {
            this.isHidden = true;
          }

          if (this.customerId && this.products) {
            this.footerConfig = {
              alignSetting: 'justify-content-between',
              elements: [
                {
                  type: this.footerItemTypes.textWithIcon,
                  iconType: 'wecons-circle',
                  iconKey: 'safety',
                  iconPrimaryColor: 'success-dark',
                  iconSecondaryColor: 'success-30',
                  title: '_CUD_risk_profile_footer_title_complete',
                  subtitle: '_CUD_risk_profile_footer_subtitle_complete'
                },
                {
                  type: this.footerItemTypes.callToAction,
                  buttons: [
                    {
                      eventName: 'RECALCULATE_PROFILE',
                      class: 'btn-b2c',
                      text: '_CUD_risk_profile_recalculate_button',
                      tSelector: 'recalculate-profile-button',
                      isHidden: false
                    },
                    {
                      eventName: 'EXPAND_FOOTER',
                      class: 'btn-primary ml-huge',
                      text: '_CUD_risk_profile_footer_see_products',
                      tSelector: 'expand-footer-button',
                      isHidden: false,
                      icon: {
                        key: 'arrow-drop-down',
                        primaryColor: 'neutral-start',
                        type: 'ui',
                        size: 'icon-md'
                      }
                    }
                  ]
                }
              ]
            };
          }
        },
        complete: () => (this.loading = false)
      });
  }

  public handleClickOnElement(eventName: string, button): void {
    switch (eventName) {
      case BTN_EVENTS.riskProfile:
        this._analytics.event({
          GAEventCategory: GAEventCategories.BrokerFlowRiskAnalysis,
          GAEventAction: GAEventActions.CompleteRiskAnalysis,
          GAEventType: AnalyticsCustomEvents.ctaEvent
        });
        this.navigateToRiskAnalysis();
        break;
      case BTN_EVENTS.shareQuestions:
        this._analytics.event({
          GAEventCategory: GAEventCategories.CustomerFlowRiskAnalysis,
          GAEventAction: GAEventActions.ShareQuestions,
          GAEventType: AnalyticsCustomEvents.ctaEvent
        });
        this.openShareQuestionsModal(this.customerId);
        break;
      case BTN_EVENTS.expandFooter:
        button.icon.key = button.icon.key === 'arrow-drop-up' ? 'arrow-drop-down' : 'arrow-drop-up';
        this.expandableFooterIsVisible = !this.expandableFooterIsVisible;
        break;
      case BTN_EVENTS.recalculate:
        this.openRecalculationModal();
        break;
    }
  }

  public navigateToRiskAnalysis(): void {
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/risk-analysis/${this.isThirdParty ? 'third-party' : 'wefox-customers'}/${this.customerId}`);
  }

  public openRecalculationModal(): void {
    this._modal.setAndShow({
      contentView: DialogComponent,
      contentViewOptions: {
        options: {
          buttonAction: () => this.navigateToRiskAnalysis(),
          buttonActionSecondary: () => {
            // this._modal.hide();
            this.openShareQuestionsModal(this.customerId);
          },
          buttonText: '_CUD_risk_profile_complete_cta',
          buttonTextSecondary: '_RA_modal_title',
          description: '_RA_recalculate_profile_modal_description',
          showCloseButton: true,
          title: '_RA_recalculate_profile_modal_title',
          tSelector: 'recalculate-profile-modal'
        }
      }
    });
  }

  public openShareQuestionsModal(customerId: string): void {
    this._modal.setAndShow({
      contentView: ShareQuestionsModalComponent,
      contentViewOptions: {
        options: {
          buttonActionSecondary: () => this._modal.hide(),
          buttonText: '_RA_share_button',
          buttonTextSecondary: '_RA_cancel_button',
          description: '_RA_modal_description',
          title: '_RA_modal_title',
          customerId,
          isThirdParty: this.isThirdParty
        }
      }
    });
  }

  public openDrawer() {
    this.drawerIsVisible = true;
  }

  public closeDrawer() {
    this.drawerIsVisible = false;
  }
}
