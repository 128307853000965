// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 16px;
  max-width: 600px;
  min-width: 300px;
}

.bg-dark-purple {
  background-color: var(--primary-90);
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/commission-card/commission-card.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mCAAA;AACF","sourcesContent":[".card {\n  border-radius: 16px;\n  max-width: 600px;\n  min-width: 300px;\n}\n\n.bg-dark-purple {\n  background-color: var(--primary-90);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
