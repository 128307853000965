export class BaseModelUtils {
  // eslint-disable-next-line
  public static modifyFormSections(baseSections, newSections) {
    return baseSections.map(section => {
      newSections.forEach(newSection => {
        if (newSection.type === section.type) {
          section = newSection;
        }
      });
      return section;
    });
  }
}

export const B2BFormSections = [
  {
    content_data: {
      config: {
        age: { greater_than: 18, less_than: 70 },
        age_margin: { max_age: 70, min_age: 18 },
        can_be_legal_entity: true,
        can_prefill_customer: false,
        dialog_options: {
          description: '_PROD_prefilling_dialog_not_valid_description',
          i18n_key: '_ST_MOTOR',
          title: '_PROD_prefilling_dialog_not_valid_title'
        },
        disabled_fields: [],
        has_disabled_customer_fields: false,
        i18n_age_range_key: '_ST_MOTOR',
        i18n_prefix_key: '_ST_MOTOR',
        is_prefilled: false,
        preferred_language: false
      },
      section_title: '_ST_MOTOR_customer_info_title'
    },
    type: 'customerSection'
  },
  {
    content_data: {
      config: {
        description_title: '_NT_MOTOR_tariff_selection_benefits',
        descriptions: [
          '_ST_MOTOR_B2B_benefits_text_1',
          '_ST_MOTOR_B2B_benefits_text_2',
          '_ST_MOTOR_B2B_benefits_text_3'
        ],
        green_packages: {},
        has_go_green_descriptions: true,
        is_required: false,
        packages: [
          {
            coverages: {
              motor: [
                {
                  available: true
                },
                {
                  available: true,
                  value: '_ST_MOTOR_B2B_benefits_value_basic_2'
                },
                {
                  available: true,
                  value: '_ST_MOTOR_B2B_benefits_value_basic_3'
                }
              ]
            },
            default: true,
            name: 'basic',
            value: 'car-switch'
          },
          {
            coverages: {
              motor: [
                { available: true },
                { available: true, value: '_ST_MOTOR_B2B_benefits_value_green_2' },
                { available: true, value: '_ST_MOTOR_B2B_benefits_value_green_3' }
              ]
            },
            default: false,
            name: 'green',
            value: 'car-switch-green'
          }
        ]
      },
      package_definition: {
        basic: {
          name: 'basic',
          value: 'car-switch'
        },
        green: {
          name: 'green',
          value: 'car-switch-green'
        }
      },
      package_parameter_name: 'product.motor.package',
      prefix: '_ST_MOTOR_title',
      product_type_default: 'motor',
      section_id: 'tariff_title',
      section_margin_bottom: 'mb-huge2x',
      section_title: '_ST_MOTOR_tariff_title'
    },
    type: 'tariffSection'
  }
];

export const ApellaFormSections = [
  ...B2BFormSections,
  {
    content_data: {
      config: {
        age: { greater_than: 18, less_than: 99 },
        age_margin: { max_age: 99, min_age: 18 },
        can_be_legal_entity: true,
        can_prefill_customer: false,
        dialog_options: {
          description: '_PROD_prefilling_dialog_not_valid_description',
          i18n_key: '_ST_MOTOR',
          title: '_PROD_prefilling_dialog_not_valid_title'
        },
        disabled_fields: [],
        has_disabled_customer_fields: false,
        i18n_age_range_key: '_ST_MOTOR',
        i18n_prefix_key: '_ST_MOTOR',
        is_prefilled: false,
        preferred_language: false
      },
      section_title: '_ST_MOTOR_customer_info_title'
    },
    type: 'customerSection'
  },
  {
    content_data: {
      config: {
        custom_underwriting_rules: 'apella'
      },
      section_title: ''
    },
    type: 'concludeSection'
  }
];

export const CUSTOMER_MODEL = [
  {
    attribute: 'birthday',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'email',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 80,
        type: 'maxLength'
      },
      {
        // eslint-disable-next-line max-len
        param:
          /^[a-z0-9A-Z!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?$/
            .source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'firstname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'gender',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'house_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^([0-9a-zA-Zöäüß\/-/(/)/(/\-)]{1,4})$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'lastname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'mobile_phone',
    defaults: {},
    validators: [
      {
        param: /^([+]?[0-9() ]+)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'street',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 27,
        type: 'maxLength'
      },
      {
        // eslint-disable-next-line max-len
        param: /(?!^ +$)^.+$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'phone_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^([+]?[0-9() ]+)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'place',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param:
          // eslint-disable-next-line max-len
          /^([a-zA-ZôöâäûüßàáêëèéîìïíòóùúçñÂÀÁÄÊÈÉËÎÌÍÏÔÒÓÖÛÙÚÜÇÑß():;/.,´'-]{2,}[a-zA-ZôöâäûüßàáêèëéîìïíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑß():;/.,´' -]*)$/
            .source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'postal_code',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/.source,
        type: 'pattern'
      }
    ]
  }
];

export const CUSTOMER_MODEL_CH = [
  {
    attribute: 'birthday',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'email',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 80,
        type: 'maxLength'
      },
      {
        // eslint-disable-next-line max-len
        param:
          /^[a-z0-9A-Z!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9A-Z!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?\.)+[a-z0-9A-Z](?:[a-z0-9A-Z-]*[a-z0-9A-Z])?$/
            .source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'firstname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'gender',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'house_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^([0-9a-zA-Zöäüß\/-/(/)/(/\-)]{1,4})$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'lastname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'mobile_phone',
    defaults: {},
    validators: [
      {
        param: /^([+]?[0-9() ]+)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'street',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 27,
        type: 'maxLength'
      },
      {
        // eslint-disable-next-line max-len
        param: /(?!^ +$)^.+$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'phone_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^([+]?[0-9() ]+)$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'place',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param:
          // eslint-disable-next-line max-len
          /^([a-zA-ZôöâäûüßàáêëèéîìïíòóùúçñÂÀÁÄÊÈÉËÎÌÍÏÔÒÓÖÛÙÚÜÇÑß():;/.,´'-]{2,}[a-zA-ZôöâäûüßàáêèëéîìïíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑß():;/.,´' -]*)$/
            .source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'postal_code',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^[0-9]{4}$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'preferred_language',
    default: {},
    validators: [{ type: 'required' }]
  }
];

export const DEDUCTIBLE_MODEL = [
  {
    attribute: 'deductible',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const DIRECT_DEBIT_MODEL = [
  {
    attribute: 'direct_debit_iban',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        is_custom: true,
        type: 'validateIban'
      }
    ]
  },
  {
    attribute: 'direct_debit_iban_name_holder',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      }
    ]
  }
];

export const VEHICLE_ID_MODEL_DE = [
  {
    attribute: 'vehicle_id',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(?!.*[ioqIOQ])[1234567890a-zA-Z_.-]{17}$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'license_plate',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(([A-ZÖÄÜ]{1,3})-([A-Z]{1,2}) ([0-9]{1,4})([H]{0,1}))$/.source,
        type: 'pattern'
      },
      {
        is_custom: true,
        type: 'licensePlatePrefix'
      }
    ]
  }
];

export const VEHICLE_ID_MODEL_CH = [
  {
    attribute: 'vehicle_id',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^((([0-9]{3}).){2}([0-9]{3}))$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'license_plate',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param:
          /^((\b(?:AG|AI|AR|BE|BL|BS|FR|GE|GL|GR|JU|LU|NE|NW|OW|SG|SH|SO|SZ|TG|TI|U|UR|VD|VS|ZG|ZH)\b)-(([0-9]{1,6})|\s*))$/
            .source,
        type: 'pattern'
      }
    ]
  }
];

export const FILE_UPLOAD_MODEL = [
  {
    attribute: 'file',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const CHILDREN_ADULTS_MODEL = [
  {
    attribute: 'household_adults',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'household_children',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const LEGAL_ENTITY_MODEL = [
  {
    attribute: 'legal_name',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['0-9a-zA-ZôöäûüßâàáêëèéîïìíòóùúçœñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ&.+ -]*)$/.source,
        type: 'pattern'
      }
    ]
  }
];

export const COMMON_IN_ALL_FORM_MODEL = [
  {
    attribute: 'quote',
    defaults: {},
    validators: []
  },

  {
    attribute: 'start_date',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const COMMON_COVERAGE_TYPE_SELECTOR_MODEL = [
  {
    attribute: 'coverage_type',
    defaults: {},
    validators: []
  },
  {
    attribute: 'fully_comprehensive_deductible',
    defaults: {},
    validators: []
  },
  {
    attribute: 'fully_comprehensive_deductible_check',
    defaults: {},
    validators: []
  },
  {
    attribute: 'partial_comprehensive_deductible',
    defaults: {},
    validators: []
  },
  {
    attribute: 'partial_comprehensive_deductible_check',
    defaults: {},
    validators: []
  }
];

export const COMMON_SWITCH_MOTOR_COVERAGES_MODEL_DE = [
  {
    attribute: 'coverage_type',
    defaults: {},
    validators: []
  },
  {
    attribute: 'fully_comprehensive_deductible',
    defaults: {},
    validators: []
  },
  {
    attribute: 'fully_comprehensive_deductible_check',
    defaults: {},
    validators: []
  },
  {
    attribute: 'partial_comprehensive_deductible',
    defaults: {},
    validators: []
  },
  {
    attribute: 'partial_comprehensive_deductible_check',
    defaults: {},
    validators: []
  },
  {
    attribute: 'car_assistance_yes',
    defaults: {},
    validators: []
  },
  {
    attribute: 'discount_protection',
    defaults: {},
    validators: []
  },
  {
    attribute: 'gap_coverage',
    defaults: {},
    validators: []
  },
  {
    attribute: 'payment_frequency',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'policy_number_previous_insurance',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 20,
        type: 'maxLength'
      },
      {
        param: /^(?!kein)([\w][^§$%&=]{1,20})$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'previous_insurance_premium_with_tax',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        is_custom: true,
        param: 1,
        type: 'currencyInputMin'
      }
    ]
  },
  {
    attribute: 'previous_insurer',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'sf_class_liability',
    default: {},
    validators: [{ type: 'required' }]
  },
  {
    attribute: 'sf_class_full',
    default: {},
    validators: [{ type: 'required' }]
  },
  {
    attribute: 'sf_class_banner',
    default: {},
    validators: []
  }
];

export const INSURED_HOME_MODEL = [
  {
    attribute: 'object_street',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 27,
        type: 'maxLength'
      },
      {
        param: /^(?!^ +$)^.+$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'object_street_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(([0-9]){1,4})[ -)(/]?([0-9a-zA-Zöäüß (/-]*[)]?)?$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'object_postal_code',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 6,
        type: 'maxLength'
      },
      {
        param: /^[0-9]{5}$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'object_city',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 25,
        type: 'maxLength'
      },
      {
        param:
          // eslint-disable-next-line max-len
          /^([a-zA-ZôöâäûüßàáêëèéîìïíòóùúçñÂÀÁÄÊÈÉËÎÌÍÏÔÒÓÖÛÙÚÜÇÑß():;/.,´'-]{2,}[a-zA-ZôöâäûüßàáêèëéîìïíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑß():;/.,´' -]*)$/
            .source,
        type: 'pattern'
      }
    ]
  }
];

export const MAK_ID_MODEL = [
  {
    attribute: 'mak_id',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const PREMIUM_AND_PAYMENT_FREQUENCY_MODEL = [
  {
    attribute: 'previous_insurance_premium_with_tax',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        is_custom: true,
        param: 1,
        type: 'currencyInputMin'
      }
    ]
  },
  {
    attribute: 'payment_frequency',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const PAYMENT_FREQUENCY_MODEL = [
  {
    attribute: 'payment_frequency',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const PAYMENT_TYPE_MODEL = [
  {
    attribute: 'payment_type',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  }
];

export const HSN_TSN_MODEL = [
  {
    attribute: 'hsn',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^[0-9]{4}$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'tsn',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^[0-9a-zA-Z]{3}$/.source,
        type: 'pattern'
      }
    ]
  }
];

export const CAR_HOLDER_MODEL = [
  {
    attribute: 'holder_same_as_policy_owner_check',
    defaults: {},
    validators: []
  },
  {
    attribute: 'car_holder_birthday',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'car_holder_firstname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      },
      {
        param: 20,
        type: 'maxLength'
      }
    ]
  },
  {
    attribute: 'car_holder_gender',
    defaults: {},
    validators: [
      {
        type: 'required'
      }
    ]
  },
  {
    attribute: 'car_holder_house_number',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^([0-9a-zA-Zöäüß\/-/(/)/(/\-)]{1,4})$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'car_holder_lastname',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(['a-zA-ZôöäûüßâàáêëèéîïìíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑ -]*)$/.source,
        type: 'pattern'
      },
      {
        param: 50,
        type: 'maxLength'
      }
    ]
  },
  {
    attribute: 'car_holder_street',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: 27,
        type: 'maxLength'
      },
      {
        // eslint-disable-next-line max-len
        param: /(?!^ +$)^.+$/.source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'car_holder_place',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param:
          // eslint-disable-next-line max-len
          /^([a-zA-ZôöâäûüßàáêëèéîìïíòóùúçñÂÀÁÄÊÈÉËÎÌÍÏÔÒÓÖÛÙÚÜÇÑß():;/.,´'-]{2,}[a-zA-ZôöâäûüßàáêèëéîìïíòóùúçñÂÀÄÁÊÈËÉÎÌÏÍÔÖÒÓÛÜÙÚÇÑß():;/.,´' -]*)$/
            .source,
        type: 'pattern'
      }
    ]
  },
  {
    attribute: 'car_holder_postal_code',
    defaults: {},
    validators: [
      {
        type: 'required'
      },
      {
        param: /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/.source,
        type: 'pattern'
      }
    ]
  }
];
