import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AddressControlNamesDefault } from '@wefoxGroupOneBPShared/components';

@Component({
  selector: 'one-address-section-adapter-component',
  templateUrl: './address-section-adapter.component.html'
})
export class AddressSectionAdapterComponent implements AfterViewInit, OnInit {
  @Input() public content_data: any; // eslint-disable-line
  public invalidAddress: boolean;
  @Input() public parentGroup: UntypedFormGroup;

  constructor(private _cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this._monitorCopyAddress();
    this._disableAddressFields();
  }

  public ngOnInit(): void {
    if (this.content_data.config.options.can_copy_address) {
      this.parentGroup.addControl('address_same_as_policy_holder', new UntypedFormControl(false));
    }
  }

  private _disableAddressFields(): void {
    const { config } = this.content_data;

    if (config.fields_to_lock) {
      config.fields_to_lock.forEach(field => {
        const lockedInput = this.parentGroup.get(field.field_to_lock);
        const fieldValue = this.parentGroup.get(field.origin).value;

        if (config.toggle_disable) {
          lockedInput.disable();
        }

        if (fieldValue && lockedInput) {
          this._setValue(lockedInput, field, fieldValue);
        }

        this.parentGroup.get(field.origin).valueChanges.subscribe(value => {
          setTimeout(() => {
            this._setValue(lockedInput, field, value);
          }, 0);
        });
      });
    }
    this._cdr.detectChanges();
  }

  private _monitorCopyAddress(): void {
    const controlNames = this.content_data.config.options.controlNames;
    const customerAddress = AddressControlNamesDefault;
    const switchControl = this.parentGroup.get('address_same_as_policy_holder');

    switchControl?.valueChanges.subscribe(checked => {
      if (checked) {
        const validAddressControls = Object.values(customerAddress).filter(field => this.parentGroup.get(field));

        const validControls = validAddressControls.every(
          control => this.parentGroup.get(control).valid || this.parentGroup.get(control).disabled
        );

        if (validControls) {
          Object.keys(controlNames).forEach(field => {
            const fieldComponent = this.parentGroup.get(controlNames[field]);
            fieldComponent?.setValue(this.parentGroup.get(customerAddress[field]).value);
            fieldComponent?.disable();
          });
          this.invalidAddress = false;
        } else {
          switchControl.setValue(false);
          this.invalidAddress = true;
        }
      } else {
        if (controlNames !== customerAddress) {
          Object.values(controlNames).forEach(field => {
            const fieldComponent = this.parentGroup.get(field as any); // eslint-disable-line
            fieldComponent?.setValue(null);
            fieldComponent?.enable();
          });
        }
        this.invalidAddress = false;
      }
    });
  }

  private _setValue(lockedInput, field, value): void {
    lockedInput.setValue(value);
    if (field.toggle_disable) {
      if (value === '') {
        lockedInput.enable();
      } else {
        lockedInput.disable();
      }
    } else {
      lockedInput.disable();
    }
  }
}
