// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-carrier-excerpt {
  min-width: auto;
  overflow-x: scroll;
}
.container-carrier-excerpt .carrier-card {
  min-width: 250px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/carrier-excerpt/carrier-excerpt.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;AACJ","sourcesContent":[".container-carrier-excerpt {\n  min-width: auto;\n  overflow-x: scroll;\n\n  & .carrier-card {\n    min-width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
