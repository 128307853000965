import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { SourceValue } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-yes-no-dropdown',
  templateUrl: './yes-no-dropdown.component.html'
})
export class YesNoDropdownComponent implements OnDestroy, OnInit {
  @Input() public controlName: string;
  public dropdownOptions: WgDropdownOption[];
  @Input() public hasBooleanValue = true;
  @Input() public label = '_PROD_prefilling_select';
  public optionsValues: { key: string | boolean; value: string }[];
  @Input() public parentGroup: UntypedFormGroup;

  private _control: AbstractControl;
  private _currentLocale = this._sessionQuery.getCurrentLocale();
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _sessionQuery: SessionQuery) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.optionsValues = this._getOptionsValues();
    this.dropdownOptions = this._translateValues(this.optionsValues);
    this._control = this.parentGroup.get(this.controlName);
    this._monitorLocaleChanges();
  }

  private _getOptionsValues(): { key: string | boolean; value: string }[] {
    return [
      { key: this.hasBooleanValue ? true : 'yes', value: '_PROD_yes_value' },
      { key: this.hasBooleanValue ? false : 'no', value: '_PROD_no_value' }
    ];
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(locale => {
      if (this._currentLocale !== locale) {
        this.dropdownOptions = this._translateValues(this.optionsValues);
        this._updateTranslation();
      }
    });
  }

  // eslint-disable-next-line
  private _translateValues(values: SourceValue[]): any {
    return values.map((data: SourceValue) => {
      return { key: data.key, value: translate(data.value) };
    });
  }

  private _updateTranslation(): void {
    const options = this.optionsValues.find(item => item.key === this._control.value?.key);

    if (options) {
      this._control.patchValue({
        key: options.key,
        value: translate(options.value)
      });
    }
  }
}
