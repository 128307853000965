// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  border: solid 1px var(--neutral-base) !important;
  border-radius: 4px;
  height: 50px;
  max-height: 50px;
  padding-left: 16px;
  padding-top: 16px;
}
:host:focus-within {
  border-color: var(--primary-base);
  border-width: 2px;
  box-shadow: 0 0 4px 10px var(--neutral-start);
  outline: 1px solid transparent;
}

.wg-m-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative;
}
.wg-m-input:focus {
  outline: none;
}

.wg-a-fieldInvalid {
  color: var(--danger-base);
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-checkbox/wg-checkbox.component.scss"],"names":[],"mappings":"AACA;EACE,gDAAA;EACA,kBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;AAAF;AAEE;EACE,iCAAA;EACA,iBAAA;EACA,6CAAA;EACA,8BAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AADF;AAGE;EACE,aAAA;AADJ;;AAKA;EACE,yBAAA;EACA,eAAA;AAFF","sourcesContent":["\n:host {\n  border: solid 1px var(--neutral-base) !important;\n  border-radius: 4px;\n  height: 50px;\n  max-height: 50px;\n  padding-left: 16px;\n  padding-top: 16px;\n\n  &:focus-within {\n    border-color: var(--primary-base);\n    border-width: 2px;\n    box-shadow: 0 0 4px 10px var(--neutral-start);\n    outline: 1px solid transparent;\n  }\n}\n\n.wg-m-input {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n  position: relative;\n\n  &:focus {\n    outline: none;\n  }\n}\n\n.wg-a-fieldInvalid {\n  color: var(--danger-base);\n  margin-top: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
