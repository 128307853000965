import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { COUNTRIES, COUNTRY_CH } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';

export interface AmountAdapterComponentOptions {
  currency_input?: { min: number, max: number }
  mask?: string;
  mask_options?: {
    remove_decimal?: boolean;
  };
  control_name: string;
  i18n_prefix_key: string;
  input_modifier?: string;
  label?: string;
  placeholder?: string;
  required: boolean;
}

@Component({
  selector: 'one-amount-adapter',
  templateUrl: './amount-adapter.component.html'
})
export class AmountAdapterComponent implements OnInit {
  @Input() public content_data: AmountAdapterComponentOptions;
  public country: COUNTRIES;
  public maskPrefix: string;
  public maskSuffix: string;
  public thousandSeparator: string;
  @Input() public parentGroup: UntypedFormGroup;
  protected renderer?: Renderer2;

  constructor(protected _sessionQuery: SessionQuery) { }

  /**
   * Creates the currency mask based on the country.  DE will add € and CH will add CHF to the value amount.
   */
  public ngOnInit(): void {
    this.maskPrefix = this.country === COUNTRY_CH.countryCode ? 'CHF ' : '';
    this.maskSuffix = this.country === COUNTRY_CH.countryCode ? '' : ' €'
    this.thousandSeparator = this.country === COUNTRY_CH.countryCode ? "'" : '.'
  }

}
