// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-available-tools {
  min-width: auto;
  overflow-x: scroll;
  padding-bottom: 24px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/available-tools/available-tools.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,oBAAA;AACF","sourcesContent":[".container-available-tools {\n  min-width: auto;\n  overflow-x: scroll;\n  padding-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
