import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import {
  ClaimsDetailsResponse,
  ClaimsDetailsResponseIT
} from '@wefoxGroupOneBPPrivate/modules/claims/interfaces/claims-details.interface';
import { Observable } from 'rxjs';

@Injectable()
export class ClaimsDetailsDataService extends BaseDataService {
  private _getClaimsDetailsEndpointIT = 'claim';
  private _getClaimsDetailsEndpoint = 'claims';
  private _createClaimEndpoint = 'claim-management';
  private _uploadFileEndpoint = 'file/upload';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  public getClaimsDetails$(id: string): Observable<ClaimsDetailsResponse | ClaimsDetailsResponseIT> {
    const url = this.getUrl({
      path: `${
        this.sessionQuery.getCountry() === COUNTRIES.it
          ? this._getClaimsDetailsEndpointIT
          : this._getClaimsDetailsEndpoint
      }/${id}`
    });

    return this.get$(url) as Observable<ClaimsDetailsResponse | ClaimsDetailsResponseIT>;
  }

  public createClaim(body) {
    const url = this.getUrl({ path: this._createClaimEndpoint });
    return this.post$(url, body, {});
  }

  // eslint-disable-next-line
  public uploadClaimFile$(body: FormData, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: this._uploadFileEndpoint
    });
    return this.post$(url, body, options);
  }
}
