import { UiButtonSizes, UiButtonTheme } from '@wefoxGroupOneBPShared/constants/ui-types.constants';

export type HTMLButtonElementType = 'submit' | 'reset' | 'button';

export interface ButtonComponent {
  content: any; // eslint-disable-line
  disabled: boolean;
  size: UiButtonSizes;
  theme: UiButtonTheme;
  type: HTMLButtonElementType;
}

export interface ButtonConfig {
  action?();
  text: string;
}
