import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';

@Component({
  selector: 'one-review-cards-adapter',
  templateUrl: './review-cards-adapter.component.html'
})
export class ReviewCardsAdapterComponent implements OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  public reviewDetails;

  constructor(private _sessionQuery: SessionQuery) {}

  public ngOnInit(): void {
    this._sessionQuery.reviewCardsDetails$.subscribe(details => (this.reviewDetails = details));
  }
}
