import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInitializerService } from './services/app-initializer.service';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@wefoxGroupOneBPShared/shared.module';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SuccessComponent } from './components/success/success.component';
import { LanguageSelectorComponent } from '@wefoxGroupOneBPShared/components/language-selector/language-selector.component';
import { RiskAnalysisModule } from '@wefoxGroupOneBPRiskAnalysis/risk-analysis.module';
import { TranslocoRootModule } from './transloco-root.module';

export function appInitializerFactory(appInitializerService: AppInitializerService) {
  return (): Promise<unknown> => {
    return new Promise(resolve => {
      appInitializerService.init().then(() => {
        appInitializerService.setInitialMasterData$().subscribe(() => resolve(true))
      });
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    SuccessComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    TranslocoRootModule,
    RiskAnalysisModule,
    HttpClientModule,
    SharedModule,
    LanguageSelectorComponent
  ],
  providers: [
    AppInitializerService,
    {
      deps: [AppInitializerService],
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
