import { Injectable } from '@angular/core';
import { DetailCardsConfig } from '@wefoxGroupOneBP/app/private/modules/claims/interfaces/claims-details.interface';
import { AllListInfo, DynamicTableConfigParsed, DynamicViewConfigParsed } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { DynamicDetailCardConfig } from '@wefoxGroupOneBPShared/interfaces/dynamic-details-card.interface';
import { Observable } from 'rxjs';
import { PortfolioTransferDataService } from './portfolio-transfer-data.service';

@Injectable({ providedIn: 'root' })
export class PortfolioTransferService {

    constructor(private _portfolioTransferData: PortfolioTransferDataService) { }

    public getPortfolioTransfer$(
        page = 1,
        sort?: string,
        sortOrder?: string,
        size?: number,
        id?: string,
        customerName?: string,
        insurer?: string,
        status?: string
        // eslint-disable-next-line
    ): Observable<AllListInfo> {
        return this._portfolioTransferData.getPortfolioTransfer$(
            page,
            sort,
            sortOrder,
            size,
            id,
            customerName,
            insurer,
            status
        );
    }

    public parseConfig(
        data: DetailCardsConfig
    ): DynamicDetailCardConfig[] | DynamicTableConfigParsed[] | DynamicViewConfigParsed[] {
        return data.config.map(c => JSON.parse(c));
    }

    // eslint-disable-next-line    
    public postPortfolioTransfer$(customerId: number, body: any, options?: RequestInit): Observable<any> {
        return this._portfolioTransferData.postPortfolioTransfer$(customerId, body, options);
    }

    // eslint-disable-next-line
    public createCustomerPortfolioTransfer$(body: any, options?: RequestInit): Observable<any> {
        return this._portfolioTransferData.createCustomerPortfolioTransfer(body, options);
    }
}