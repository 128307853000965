import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WgToasterComponent } from './components/wg-toaster/wg-toaster.component';
import { WgToasterDirective } from './directives/wg-toaster.directive';

@NgModule({
  declarations: [WgToasterComponent, WgToasterDirective],
  exports: [WgToasterComponent, WgToasterDirective],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WgToasterModule {}
