import { WgTooltip } from './interfaces';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WgTooltipPosition } from './constants';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'one-wg-tooltip',
  styleUrls: ['./wg-tooltip.component.scss'],
  templateUrl: './wg-tooltip.component.html'
})
export class WgTooltipComponent {
  @Input() public oneWgTooltipClass = '';
  @Input() public oneWgTooltipPosition: WgTooltipPosition;
  @Input() public oneWgTooltipText: WgTooltip = null;
  @Input() public oneWgTooltipWidth: number = undefined;
}
