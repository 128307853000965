// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .icon-wrapper {
        display: flex;
        gap: var(--small);
        align-items: center;
    }
  `, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-dynamic-components/inputs/tooltip/tooltip-adapter.component.ts"],"names":[],"mappings":";IACI;QACI,aAAa;QACb,iBAAiB;QACjB,mBAAmB;IACvB","sourcesContent":["\n    .icon-wrapper {\n        display: flex;\n        gap: var(--small);\n        align-items: center;\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
