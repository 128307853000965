import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@wefoxGroupOneBP/app/private/modules/auth/services/authentication.service';
import {
  AnalyticsCustomEvents,
  GAEventActions,
  GAEventCategories,
  GARegistrationAction
} from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { PERMISSIONS } from '@wefoxGroupOneBPCore/constants/auth.constants';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants/countries.constants';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { NavigationLink } from '@wefoxGroupOneBPCore/interfaces';
import { CentralizedQuery, SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { Observable, Subject } from 'rxjs';
import { HeaderComponentOptions } from '../../interfaces';
import { DropdownNotificationsResponse } from './dropdown-notifications/constants/dropdown-notifications.constants';

@Component({
  selector: 'one-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnChanges {
  public closeDropdown: Subject<void> = new Subject<void>();
  public currentState: string;
  public hasLanguageSelector: boolean;
  public headerLinks: Partial<NavigationLink>[];
  public locale$: Observable<string>;
  public dropdownNotifications: DropdownNotificationsResponse[] = [];
  public LOGIN_ROUTE = 'login';
  @Input() public navigation = true;
  @Input() public options: Partial<HeaderComponentOptions>;
  public REGISTRATION_ROUTE = 'registration';
  public showMobileMenu = false;
  public hasCopilotPermission = false;

  public isLoggedIn: boolean;
  private _waitBeforeReloading = UxIntervalTimes.medium;

  constructor(
    private _analytics: AnalyticsService,
    private _auth: AuthenticationService,
    private _centralizedQuery: CentralizedQuery,
    private _router: Router,
    private _sessionQuery: SessionQuery
  ) {}

  public isNotificationsDropdownAvailable(): boolean {
    const country = this._sessionQuery?.getCountry() || this._centralizedQuery?.getCountry();
    return country === 'DE' || country === 'CH';
  }

  public getCountryPrefix(): string {
    return this._sessionQuery?.getCountryPrefix() || this._centralizedQuery?.getCountryPrefix();
  }

  public getLogo(): string {
    return 'assets/images/brand/logo-wefox.svg?v=#wefox__logo';
  }

  public getMenuIcon(): string {
    return this.showMobileMenu ? 'x' : 'menu-hamburger';
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.options = changes?.options.currentValue;
    this._mapHeaderLinks();
  }

  public ngOnInit(): void {
    this.locale$ = this._sessionQuery.locale$;
    this.isLoggedIn = this._sessionQuery.isLoggedIn();
    this._mapHeaderLinks();
    this.hasLanguageSelector = this._sessionQuery.hasLanguageOptions();
    this.currentState = this._router.url;
    this.hasCopilotPermission =
      this._auth.hasPermissions([PERMISSIONS.TOOL_COPILOT]) && this._sessionQuery.getCountry() !== COUNTRIES.it;
  }

  // eslint-disable-next-line
  public onClickLabelLink(link: any): void {
    const routeLink = this.getCountryPrefix() + '/' + link.router_link;
    this._router.navigate([routeLink]);

    if (link.label === '_NAV_register') {
      this._analytics.event({
        GAEventAction: GARegistrationAction.headerLink as GAEventActions,
        GAEventCategory: GAEventCategories.RegistrationLinks
      });
    }
  }

  public onHamburgerMenuClick(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  public onLogoClick(canNavigate: boolean): void {
    if (!canNavigate) {
      return;
    }
    if (this.isLoggedIn) {
      this._router.navigateByUrl(`${this.getCountryPrefix()}/dashboard`);
    } else {
      this._router.navigateByUrl(`${this.getCountryPrefix()}/login`);
    }
  }

  public onNavItemClick(): void {
    this.showMobileMenu = false;
  }

  // TODO: check this method
  private _filterByPermissions() {
    this.headerLinks = this.headerLinks?.filter(l => this._auth.hasPermissions(l.permissions, l.router_link));
    this.headerLinks = this.headerLinks?.map(hl => {
      return { ...hl, options: hl.options.filter(o => this._auth.hasPermissions(o.permissions)) };
    });
  }

  private _mapHeaderLinks(): void {
    this.headerLinks = this.options?.config?.header_links;
    if (this.isLoggedIn) {
      setTimeout(() => {
        this._filterByPermissions();
      }, this._waitBeforeReloading);
    }
    this.headerLinks?.map(link => {
      const brokerPath = this._sessionQuery.getBrokerPath();
      const brokerPathArr = brokerPath.split('/');
      link.router_link = link.router_link?.includes(brokerPathArr[0])
        ? link.router_link.split('/')[0]
        : link.router_link;
      if (link.requires_broker_id) {
        link.router_link = `${link.router_link}/${brokerPath}`;
        return link;
      }
    });
  }

  public trackClickOnCalendar(): void {
    this._analytics.event({
      GAEventType: AnalyticsCustomEvents.interactionEvent,
      GAEventAction: GAEventActions.OpenCalendar,
      GAEventCategory: GAEventCategories.Calendar
    });
  }
}
