import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { MasterDataDefinition } from '@wefoxGroupOneBPCore/interfaces';

const createInitialState: Partial<MasterDataDefinition> = {
  brokerState: null,
  context: null,
  countries: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'common' })
export class CommonStore extends Store<Partial<MasterDataDefinition>> {
  constructor() {
    super(createInitialState);
  }
}
