// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wipo-a-list {
  display: flex;
  flex-direction: column;
  left: -72px;
  position: absolute;
  top: 50px;
}
.wipo-a-list.-hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/language-selector/language-selector.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;AAAF;AAEE;EACE,aAAA;AAAJ","sourcesContent":["\n.wipo-a-list {\n  display: flex;\n  flex-direction: column;\n  left: -72px;\n  position: absolute;\n  top: 50px;\n\n  &.-hidden {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
