export const DetailItemLabel = {
  address: '_COL_column_address',
  birthdate: '_ST_MOTOR_birthday',
  email: '_ACC_profile_email',
  iban: '_ST_MOTOR_direct_debit_iban',
  ibanHolder: '_PROD_personal_data_account_holder',
  marketingConsent: '_PROD_personal_data_marketing_consent',
  mobilePhone: '_ST_MOTOR_mobile_phone',
  name: '_ACC_profile_name',
  phoneNumber: '_ST_MOTOR_phone_number',
  preferredLanguage: '_COL_column_preferred_language'
};

export const PaymentMethodFormData = {
  mode: {
    payout: 'PAYOUT',
    premia: 'PREMIA'
  },
  type: {
    credit: 'CREDITCARD',
    iban: 'DIRECTDEBIT',
    invoice: 'INVOICE'
  }
};

export const PaymentMethodRequestBodyReplacement = {
  legalEntity: 'LE'
};

export const CardContentSectionHeight = {
  insuredObject: '341px',
  payment: '115px',
  personalData: '250px'
};
