import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[wgPreventDoubleClick]'
})
export class WgPreventDoubleClickDirective implements OnInit, OnDestroy {
  @Output()
  public throttledClick = new EventEmitter();
  @Input()
  public throttleTime = 500;

  private _clicks = new Subject();
  private _subscription: Subscription;

  @HostListener('click', ['$event'])
  // eslint-disable-next-line
  public clickEvent(event): void {
    event.preventDefault();
    event.stopPropagation();
    this._clicks.next(event);
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this._subscription = this._clicks.pipe(throttleTime(this.throttleTime)).subscribe(e => this._emitThrottledClick(e));
  }

  private _emitThrottledClick(e): void {
    this.throttledClick.emit(e);
  }
}
