import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components';

export interface RadioAdapterComponentOptions {
  control_name: string;
  group_label: {
    text: string
  };
  has_border: boolean;
  modifiers: {
    grid: string,
    label: string,
    padding: string
  };
  radio_options: [
    {
      disabled: boolean,
      not_visible: boolean
    },
    {
      checked: boolean,
      label: string,
      value: string
    },
    {
      label: string,
      value: string
    }
  ]
}
@Component({
  selector: 'one-radio-adapter',
  templateUrl: './radio-adapter.component.html'
})
export class RadioAdapterComponent implements AfterContentInit, AfterViewInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public disabled: boolean;
  public inputControl: AbstractControl;
  @Input() public parentGroup: UntypedFormGroup;

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _errorMap: { [key: string]: string };

  constructor(
    private _controlContainer: FormGroupDirective
  ) { }

  public getCurrentError(): { className: string, errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this.inputControl.errors || (this.inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: '-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this.inputControl.errors)[0]]) {
      return {
        className: '-invalid',
        errorMessage: this._errorMap[Object.keys(this.inputControl.errors)[0]]
      };
    }
    return { className: '-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this.inputControl = this._controlContainer.form.get(this.content_data.control_name);
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});

        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });
  }
}
