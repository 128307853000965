import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Filter } from '@wefoxGroupOneBPCore/interfaces/filter.interface';
import { StatsStore } from '../stores/stats.store';

@Injectable({ providedIn: 'root' })
// eslint-disable-next-line
export class StatsQuery extends Query<any> {
  constructor(protected store: StatsStore) {
    super(store);
  }

  public getAllFiltersForCurrentPage(): Filter[] {
    return this.getValue().pageStates[this.getValue().currentPage]?.filters;
  }

  public getCurrentPage(): string {
    return this.getValue().currentPage;
  }

  public getFilterForCurrentPage(id: string): Filter {
    if (this.getValue().pageStates[this.getValue().currentPage]?.filters) {
      return this.getValue().pageStates[this.getValue().currentPage]?.filters[id];
    }
    return null;
  }

  // eslint-disable-next-line
  public getSortingForCurrentPage(): any {
    return {
      sortField: this.getValue().pageStates[this.getValue().currentPage]?.sortField,
      sortOrder: this.getValue().pageStates[this.getValue().currentPage]?.sortOrder
    };
  }
}
