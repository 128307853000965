import { Injectable } from '@angular/core';
import { AccessData } from '@wefoxGroupOneBPCore/interfaces/authentication.interface';
import { CookieService } from '@wefoxGroupOneBPCore/services/cookie.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class TokenStorageService {
  /**
   * Get access token
   * @returns {Observable<string>}
   */
  constructor(
    private _cookieService: CookieService,
  ) {}

  /**
   * Remove tokens
   */
  public clear(): void {
    this._cookieService.delete('accessToken');
    this._cookieService.delete('refreshToken');
    localStorage.removeItem('__broker_auth_token');
  }

  public getAccessToken(): Observable<string> {
    const token: string = <string>this._cookieService.get('accessToken');
    return of(token);
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): Observable<string> {
    const token: string = <string>this._cookieService.get('refreshToken');
    return of(token);
  }

  /**
   * Set access token
   * @returns {TokenStorageService}
   */
  public setAccessToken(token: string): void {
    this._cookieService.set('accessToken', token);
    localStorage.setItem('__broker_auth_token', token);
  }

  /**
   * Set refresh token
   * @returns {TokenStorageService}
   */
  public setRefreshToken(token: string): void {
    this._cookieService.set('refreshToken', token);
  }

  public setTokens({ accessToken, refreshToken }: AccessData): void {
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  }
}
