import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { COUNTRIES, LOCALES } from '@wefoxGroupOneBPCore/constants';
import {
  ConfigOptions,
  FooterConfigOptions,
  HeaderConfigOptions,
  MasterDataDefinition
} from '@wefoxGroupOneBPCore/interfaces';
import { CommonStore } from '@wefoxGroupOneBPCore/stores/common.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonQuery extends Query<Partial<MasterDataDefinition>> {
  constructor(protected store: CommonStore) {
    super(store);
  }

  public getAllowedRoutes(isLoggedIn: boolean): string[] {
    const routes = this.getValue().routes;
    return isLoggedIn ? [...routes.priv] : [...routes.pub];
  }

  public getCountry(): COUNTRIES {
    return this.getValue().country;
  }

  public getCountryCode(): string {
    return this.getValue().country_code;
  }

  public getCountryPrefix(): string {
    return `/${this.getCountry().toLowerCase()}`;
  }

  public getHeaderOptions(): Partial<HeaderConfigOptions> {
    return this.getPublicConfig().header_options;
  }

  public getFooterOptions(): FooterConfigOptions {
    return this.getPublicConfig().footer_options;
  }

  public getLocales(): Observable<LOCALES[]> {
    return this.select(state => state.languages);
  }

  public getLanguages(): LOCALES[] {
    return this.getValue().languages;
  }

  public getRegistrationLink(): string | null {
    return this.getPublicConfig()?.header_options?.header_links?.find(link => link?.label === '_NAV_register')
      ? `${this.getCountryPrefix()}/registration`
      : null;
  }

  private getPublicConfig(): ConfigOptions | undefined {
    return this.getValue().config?.pub;
  }
}
