import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Validators } from '@angular/forms';
import { WgFieldComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-field/wg-field.component';
import { DateValidators } from '@wefoxGroupOneBPShared/modules/wg-input/validators/date.validator';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

@Component({
  selector: 'wg-date',
  styleUrls: ['./wg-date.component.scss'],
  templateUrl: './wg-date.component.html'
})
export class WgDateComponent extends WgFieldComponent implements AfterViewInit, OnChanges {
  @Input() public dateFormat: string;
  @Input() public dateGreaterThan: string;
  @Input() public dateInvalid: string;
  @Input() public dateLessThan: string;
  @Input() public required = true;

  public ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this._setDateValidations();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.dateGreaterThan?.firstChange && changes.dateGreaterThan?.currentValue) {
      this.dateGreaterThan = changes.dateGreaterThan?.currentValue;
      this._setDateValidations();
    }
    if (!changes?.dateInvalid?.firstChange && changes.dateInvalid?.currentValue) {
      this.dateInvalid = changes.dateInvalid?.currentValue;
      this._setDateValidations();
    }
    if (!changes?.dateLessThan?.firstChange && changes.dateLessThan?.currentValue) {
      this.dateLessThan = changes.dateLessThan?.currentValue;
      this._setDateValidations();
    }
  }

  private _setDateValidations(): void {
    const validators = [];
    if (this.required) {
      validators.push(Validators.required);
    }
    if (this.dateFormat) {
      validators.push(DateValidators.format(this.dateFormat));
    }
    if (this.dateFormat && this.dateGreaterThan) {
      validators.push(DateValidators.greaterThan(this.dateFormat, dayjs(this.dateGreaterThan, this.dateFormat)));
    }
    if (this.dateFormat && this.dateInvalid) {
      validators.push(DateValidators.invalid(this.dateFormat, dayjs(this.dateInvalid, this.dateFormat)));
    }
    if (this.dateFormat && this.dateLessThan) {
      validators.push(DateValidators.lessThan(this.dateFormat, dayjs(this.dateLessThan, this.dateFormat)));
    }
    this._inputControl.setValidators(validators);
    setTimeout(() => this._inputControl.updateValueAndValidity()); // we need an extra tick of th jsvm
  }
}
