import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface BrokerDTOState {
  account: BrokerDTOAccount;
  brokerNumber: number;
  email: string;
  firstname: string;
  lastname: string;
  loading: boolean;
  id?: string;
  sfid: string;
}

export interface BrokerDTOAccount {
  country: string;
  external_source: BrokerDTOExternalSource;
  id: string;
}

export interface BrokerDTOExternalSource {
  broker_discounts: BrokerDTODiscount[];
  id: string;
  name: string;
}

export interface BrokerDTODiscount {
  discount: string;
  id: string;
  product_group: string;
}

const createInitialState: BrokerDTOState = {
  account: undefined,
  brokerNumber: undefined,
  email: undefined,
  firstname: undefined,
  lastname: undefined,
  loading: false,
  sfid: undefined
};

export interface BrokerDTOIt {
  id: string;
  first_name: string;
  last_name: string;
  nationality: string;
  locale: string;
  gender: string;
  birthdate: string;
  emails: BrokerItEmail[];
  phones: BrokerItPhone[];
  addresses: BrokerItAddress[];
}

export interface BrokerItEmail {
  email: string;
  type: string;
  is_default: boolean;
  version: string;
}

export interface BrokerItPhone {
  id: string;
  prefix: string;
  number: string;
  type: string;
  is_default: boolean;
  version: string;
}

export interface BrokerItAddress {
  id: string;
  country: string;
  city: string;
  postal_code: string;
  street: string;
  house_number: string;
  type: string;
  version: string;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'account', resettable: true })
export class AccountStore extends Store<BrokerDTOState> {
  constructor() {
    super(createInitialState);
  }
}
