import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalAction } from '@wefoxGroupOneBPShared/modules/wg-modal/interfaces/wg-modal.interface';

interface DialogErrorOptions {
  description: string;
  errorKeys: any[]; // eslint-disable-line
  title: string;
}

@Component({
  selector: 'one-dialog-session-expire',
  templateUrl: './dialog-session-expire.component.html'
})
export class DialogSessionExpireComponent implements OnInit {
  @Output() public actionEvent: EventEmitter<ModalAction> = new EventEmitter(null);
  public contentViewOptions: { options: Partial<DialogErrorOptions> };
  public description: string;
  public errorKeys = [];
  public title: string;

  public buttonAction(): void {
    this.actionEvent.emit({ renewSession: { clicked: true } });
  }

  public ngOnInit(): void {
    if (this.contentViewOptions && this.contentViewOptions.options) {
      this.title = this.contentViewOptions.options.title;
      this.description = this.contentViewOptions.options.description;
      this.errorKeys = this.contentViewOptions.options.errorKeys;
    }
  }
}
