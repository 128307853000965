export type WgIconSize = 'icon-sm' | '' | 'icon-md' | 'icon-lg';
export type WgIconTypes = 'flags' | 'wecons-circle' | 'ui' | 'wecons-helix' | 'three-dimensional';

export const WgIconType = {
    flags: 'flags',
    'wecons-circle': 'wecons-circle',
    ui: 'ui',
    'wecons-helix': 'wecons-helix',
    'three-dimensional': 'three-dimensional'
};
