import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon.module';
import { AdaptersModule } from '../inputs/wg-adapters.module';
import { TranslocoModule } from '@ngneat/transloco';

export interface DynamicModalFormOptions {
  button_action(values): void;
  button_action_secondary(): void;
  button_text: string;
  button_text_secondary: string;
  close_action(): void;
  controls: any[]; // eslint-disable-line
  footer_text: string;
  keep_modal_open: boolean;
  model: any[]; // eslint-disable-line
  show_close_button: boolean;
  subtitle: string;
  t_selector: string;
  title: string;
}

@Component({
  selector: 'one-dynamic-modal',
  standalone: true,
  templateUrl: './dynamic-modal.component.html',
  imports: [CommonModule, AdaptersModule, ReactiveFormsModule, WgUiIconModule, TranslocoModule],
})
export class DynamicModalComponent implements OnInit {
  public contentViewOptions: { options: Partial<DynamicModalFormOptions> };
  public dynamicModalForm: UntypedFormGroup;
  public options: Partial<DynamicModalFormOptions>;

  constructor(private _formService: FormService, private _modal: WgModalService) { }

  public buttonActionSecondary(): void {
    if (this.options.button_action_secondary) {
      this.options.button_action_secondary();
    } else {
      this._modal.hide();
    }
  }

  public closeAction(): void {
    if (this.options.close_action) {
      this.options.close_action();
    } else {
      this._modal.hide();
    }
  }

  public ngOnInit(): void {
    this.options = { ...this.contentViewOptions?.options };
    this.dynamicModalForm = this._formService.generateFormGroup(this.options.model);
  }

  public submit(): void {
    if (this.dynamicModalForm.valid) {
      if (this.options.button_action) {
        this.options.button_action(this.dynamicModalForm.getRawValue());
      }
      if (this.options.keep_modal_open) {
        return;
      }
      this._modal.hide();
    }
  }
}
