import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { GoogleMapsService } from '@wefoxGroupOneBPCore/services';
import { AddressControlName } from '@wefoxGroupOneBPShared/components';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { IbanTransform } from '@wefoxGroupOneBPShared/pipes';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { FormService } from './form.service';

class PlacesResponse {
  types: string[];
  long_name: string;
  short_name: string;
}
@Injectable({
  providedIn: 'root'
})
export class AddressService implements OnDestroy {
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    public _iban: IbanTransform,
    private _formService: FormService,
    private _gmaps: GoogleMapsService,
    private _sessionQuery: SessionQuery,
    private _zone: NgZone
  ) {}

  public fillCityFromPostalCode(
    formGroup: UntypedFormGroup,
    controlNames: { postal_code; city: string; street: string }
  ): void {
    formGroup
      .get(controlNames.postal_code)
      .valueChanges.pipe(
        debounceTime(UxIntervalTimes.fast),
        takeUntil(this._unsubscribe$),
        switchMap(value => this._gmaps.getCityByPostcode$(value))
      )
      .subscribe(city => {
        this._zone.run(() => {
          const cityInput = formGroup.get(controlNames.city);

          if (
            !formGroup.get(controlNames.street)?.value ||
            formGroup.get(controlNames.postal_code.disabled) ||
            (formGroup.get(controlNames.street)?.value && !formGroup.get(controlNames.city)?.value)
          ) {
            this._formService.updateInput(cityInput, city?.name ? city.name : null);
          }

          const plateInput = formGroup.get('license_plate');

          if (this._sessionQuery.getCountry() === COUNTRIES.ch) {
            if (city) {
              this._formService.updateInput(plateInput, city?.kanton || null);
            } else {
              this._formService.updateInput(plateInput);
            }
          }
        });
      });
  }

  public monitorAddressChanges(formGroup: UntypedFormGroup, controlNames: AddressControlName): void {
    if (formGroup.get(controlNames.street) && formGroup.get(controlNames.house_number)) {
      this._formService.monitorAddressChanges(formGroup, controlNames.street, controlNames.house_number);
    }
  }

  public monitorStreetInputChanges(formGroup: UntypedFormGroup, controlNames: AddressControlName): void {
    formGroup.get(controlNames.street)?.valueChanges.subscribe(value => {
      if (value?.length < 2) {
        [controlNames.house_number, controlNames.city, controlNames.postal_code].forEach(input => {
          const control = formGroup.get(input);
          if (!control.disabled) {
            control.patchValue(null);
            control.markAsUntouched();
            control.markAsPristine();
          }
        });
      }
      this._predictAddress(formGroup, controlNames);
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public selectInputText(target: { value; select }): void {
    if (target.value) {
      target.select();
    }
  }

  private _predictAddress(formGroup: UntypedFormGroup, controlNames: AddressControlName): void {
    const isIT = this._sessionQuery.getCountry() === COUNTRIES.it
    const cityType = isIT ? 'administrative_area_level_3' : 'locality';
    this._gmaps.getPlacePredictions$(controlNames.street).subscribe(prediction => {
      formGroup.get(controlNames.house_number).setValue(null);
      this._zone.run(() => {
        if (prediction) {
          const addresses = prediction.address_components;
          const predictedPlaceToFormControl = {
            [controlNames.house_number]: { type: 'street_number', isShort: false },
            [controlNames.city]: { type: cityType, isShort: false },
            [controlNames.postal_code]: { type: 'postal_code', isShort: false },
            [controlNames.street]: { type: 'route', isShort: false },
            [controlNames.province]: { type: 'administrative_area_level_2', isShort: false },
            [controlNames.short_province]: { type: 'administrative_area_level_2', isShort: true, updateDisabled: true }
          };
          [...Object.values(controlNames)].forEach(field => {
            const fieldOptions = predictedPlaceToFormControl[field];
            const result = this._extractAddressData(addresses, fieldOptions);

            if ((result && !formGroup.get(field)?.disabled) || fieldOptions.updateDisabled) {
              this._formService.updateInput(formGroup.get(field), result);
            }
          });

          if (!formGroup.get(controlNames.house_number)?.value) {
            document.getElementById(controlNames.house_number)?.focus();
          }
          document.querySelectorAll('.pac-container').forEach(pac => pac.remove());
        }
      });
    });
  }

  private _extractAddressData(placesResponse: PlacesResponse[], options: { type: string; isShort: boolean }): string {
    if (options.isShort) {
      return placesResponse.find(place => place.types.indexOf(options.type) !== -1)?.short_name;
    }
    return placesResponse.find(place => place.types.indexOf(options.type) !== -1)?.long_name;
  }
}
