import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ContractData, PolicyHolderData, VehicleType } from '../interfaces';

export interface CustomerState {
  customerContract: ContractData;
  policyHolder: PolicyHolderData;
  vehicleType: VehicleType;
}

const createInitialState = {
  customerContract: null,
  policyHolder: null,
  vehicleType: null
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customer' })
export class CustomerStore extends Store<CustomerState> {
  constructor() {
    super(createInitialState);
  }
}
