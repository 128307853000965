// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-expand {
  min-width: 630px;
  width: 100%;
}

.-rounded {
  border-radius: 40px;
}

input {
  overflow: hidden;
  padding-right: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
input .-compressed {
  cursor: pointer !important;
  height: 40px !important;
}

:host ::ng-deep one-loader .onebp-o-spinnerLayout {
  background: var(--neutral-start);
  height: 80px;
  justify-content: center;
}
:host ::ng-deep one-loader .onebp-loader__spinner::after {
  background: var(--neutral-start) !important;
}
:host ::ng-deep one-loader .onebp-loader__text {
  color: var(--primary-base);
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-dropdown/wg-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,0BAAA;EACA,uBAAA;AACJ;;AAIE;EACE,gCAAA;EACA,YAAA;EACA,uBAAA;AADJ;AAMM;EACE,2CAAA;AAJR;AAQI;EACE,0BAAA;EACA,eAAA;AANN","sourcesContent":[".-expand {\n  min-width: 630px;\n  width: 100%;\n}\n\n.-rounded {\n  border-radius: 40px;\n}\n\ninput {\n  overflow: hidden;\n  padding-right: 40px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n\n  .-compressed {\n    cursor: pointer !important;\n    height: 40px !important;\n  }\n}\n\n:host ::ng-deep one-loader {\n  .onebp-o-spinnerLayout {\n    background: var(--neutral-start);\n    height: 80px;\n    justify-content: center;\n  }\n\n  .onebp-loader {\n    &__spinner {\n      &::after {\n        background: var(--neutral-start) !important;\n      }\n    }\n\n    &__text {\n      color: var(--primary-base);\n      font-size: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
