import { ID } from '@datorama/akita';
import { Attachment } from './product.interface';
import { Customer } from '@wefoxGroupOneBPShared/interfaces/customer.interface';

export interface ConcludeResponse {
  concludeResponse: SwitchTariffResponse;
  preConcludeSuccessfully: boolean;
  uploadingProgress: {
    loaded: number;
    total: number;
  };
}
export interface TariffConcludeResponse extends TariffConclude {
  coverages: any[]; // eslint-disable-line
  created_date: string;
  currency: string;
  external_id: string;
  last_modified_date: string;
  payments: any[]; // eslint-disable-line
  portfolio_owners: any[]; // eslint-disable-line
  premium_with_tax: number;
  premium_without_tax: number;
  tax: number;
  users: any[]; // eslint-disable-line
}

export interface TariffConclude extends TariffBase {
  calculation: Partial<TariffCalculation>;
  cancellation_letter: Partial<CancellationLetter>;
  documents: Partial<Document>[];
  extended_inputs: Partial<CustomInput>[];
  external_application_id: string;
  insured_object: Partial<InsuredObject>;
}

export interface TariffCalculation {
  inputs: Partial<CustomInput>[];
  payment_frequency: string;
  product_id: ID;
  product_version: string;
}

export interface CancellationLetter {
  cancellation_reason: string;
  customer: Partial<TariffCustomer>;
  customer_previous_insurance_number: string;
  preferred_language: string;
  previous_insurance_id: string;
}
export interface TariffBase {
  customer: Partial<TariffCustomer>;
  mak_id?: string;
  payment_details: Partial<PaymentDetails>;
  payment_frequency: string;
  portfolio_owners?: PortfolioOwner[];
  product_id: string | number;
  product_version: string;
  cross_sell_product_id: string | number;
  cross_sell_product_version: string;
  start_date: string;
}

export interface InsuredObject {
  inputs: Partial<CustomInput>[];
  type: string;
}

export interface TariffCustomer {
  address: {
    country: string; // TODO not present
    house_number: string;
    house_number_suffix?: string;
    place: string;
    postal_code: string;
    street: string;
  };
  birthdate: string;
  email: string;
  first_name: string;
  gender: string;
  last_name: string;
  legal_name: string;
  mobile_number: string;
  phone_number: string;
  type: string;
}

export interface PortfolioOwner {
  code?: string;
  legal_id: string;
  type: string;
}

export interface CustomInput {
  label: any; // eslint-disable-line
  name: string;
  type: string;
  validation: any; // eslint-disable-line
  value: any; // eslint-disable-line
}

export interface PaymentDetails {
  direct_debit_iban: string;
  direct_debit_iban_name_holder: string;
  type: PaymentDetailsType;
}

export type PaymentDetailsType = typeof PaymentDetailsType[keyof typeof PaymentDetailsType];

export const PaymentDetailsType = {
  creditCard: 'CreditCard' as const,
  directDebit: 'DirectDebit' as const,
  invoice: 'invoice' as const
};

export interface SwitchTariffResponse {
  attachments: Attachment[];
  contract_id: string;
  customer: TariffCustomer;
  external_id: string;
  inputs: Partial<CustomInput>[];
  insured_object?: Partial<InsuredObject>;
  payment_details: PaymentDetails;
  payment_frequency: string;
  premium_with_tax: number;
  product_id: string;
  product_version: string;
  start_date: string;
}

export interface CurrentPackage {
  default?: boolean;
  name: string;
  value: string;
}

export interface Document {
  label: string;
  name: string;
  size: number;
  type: string;
  url: string;
}

export interface SwitchTariff extends TariffBase {
  extended_inputs?: Partial<CustomInput>[];
  external_application_id?: string;
  inputs: Partial<CustomInput>[];
}

export interface SwitchTariffCalculation {
  inputs: Partial<CustomInput>[];
  payment_frequency: string;
  product_id?: string | number;
  product_version: string;
}

export interface MultiPackageCalculation {
  inputs: Partial<CustomInput>[] | Array<Array<Partial<CustomInput>>>;
  payment_frequency: string;
  product_id?: string | number;
  product_version: string;
}

export interface TariffOfferConclude {
  calculation: Partial<SwitchTariffCalculation>;
  customer: Partial<Customer>;
  start_date: string;
}

export interface ProductDescription {
  descriptionList: string[];
  labelPrice: string;
  mainTitle: string;
  price: number;
  selected: boolean;
}

export const MotorPackageDefinition = {
  switch: 'car-switch' as const,
  switchGreen: 'car-switch-green' as const
};

export type MotorPackageDefinition = typeof MotorPackageDefinition[keyof typeof MotorPackageDefinition];

export type MotorProductPackage = { [key in MotorPackageDefinition]: ProductDescription };

export const LiabilityPackageDefinition = {
  switch: 'PRIVATE_LIABILITY_SWITCH' as const,
  switchGreen: 'PRIVATE_LIABILITY_SWITCH_GREEN' as const
};

export const MotorDeductibleAmount = {
  full: 'fully_amount' as const,
  liability: 'liability_amount' as const,
  partial: 'partial_amount' as const
};

export type MotorDeductibleAmount = typeof MotorDeductibleAmount[keyof typeof MotorDeductibleAmount];

export const MotorDeductibleType = {
  fullyComprehensive: 'fully_comprehensive_deductible' as const,
  fullyComprehensiveChecked: 'fully_comprehensive_deductible_check' as const,
  partialComprehensive: 'partial_comprehensive_deductible' as const,
  partialComprehensiveChecked: 'partial_comprehensive_deductible_check' as const,
  vehicleLiability: 'vehicle_liability_deductible' as const
};

export type MotorDeductibleType = typeof MotorDeductibleType[keyof typeof MotorDeductibleType];

export const SwitchMotorProductType = {
  fullyComprehensive: 'fully-comprehensive' as const,
  partialComprehensive: 'partial-comprehensive' as const,
  vehicleLiability: 'vehicle-liability' as const
};

export type SwitchMotorProductType = typeof SwitchMotorProductType[keyof typeof SwitchMotorProductType];

export type LiabilityPackageDefinition = typeof LiabilityPackageDefinition[keyof typeof LiabilityPackageDefinition];

export type LiabilityProductPackage = { [key in LiabilityPackageDefinition]: ProductDescription };

export const HouseholdPackageDefinition = {
  switch: 'SWITCH_HH' as const,
  switchGreen: 'SWITCH_GREEN_HH' as const
};

export type HouseholdPackageDefinition = typeof HouseholdPackageDefinition[keyof typeof HouseholdPackageDefinition];

export type HouseholdProductPackage = { [key in HouseholdPackageDefinition]: ProductDescription };

// CH products

export const MotorCHPackageDefinition = {
  switch: 'Car-Switch' as const
};

export type MotorCHPackageDefinition = typeof MotorCHPackageDefinition[keyof typeof MotorCHPackageDefinition];

export type MotorCHProductPackage = { [key in MotorCHPackageDefinition]: ProductDescription };

export type ProductPackage =
  | MotorProductPackage
  | LiabilityProductPackage
  | HouseholdProductPackage
  | MotorCHProductPackage;
