import { Injectable } from '@angular/core';
import { KeycloakToken } from '@wefoxGroupOneBPCore/interceptors/keycloak-token.interface';
import { COUNTRIES, LOCALES } from '@wefoxGroupOneBPCore/constants';
import { SessionState, SessionStore } from '@wefoxGroupOneBPCore/stores';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { DraftData } from '@wefoxGroupOneBPCore/interfaces/draft.interface';
import { HeaderBackOptions } from '@wefoxGroupOneBPCore/interfaces/header-back-options.interface';
import { SwitchTariffResponse, TariffConcludeResponse } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor(private _sessionStore: SessionStore) {}

  public setBannerDraftOptions(options: BannerOptions): void {
    this._sessionStore.update({ bannerDraftOptions: options });
  }

  public setBrokerDiscount(discount: string): void {
    this._sessionStore.update({ brokerDiscount: discount });
  }
  public setBrokerId(brokerId: string): void {
    this._sessionStore.update({ brokerId });
  }

  public setBrokerPermissions(options: boolean): void {
    this._sessionStore.update({ itBrokerPermissions: options });
  }

  public setConcludeResponse(concludeResponse: Partial<SwitchTariffResponse | TariffConcludeResponse>): void {
    this._sessionStore.update({ concludeResponse });
  }
  // eslint-disable-next-line
  public setConcludeSectionActions(actions: { [key: string]: any }): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      concludeSectionActions: { ...state.concludeSectionActions, ...actions }
    }));
  }

  public setCountriesList(countriesList: WgDropdownOption[]): void {
    this._sessionStore.update({ countriesList });
  }

  public setCountry(country: COUNTRIES): void {
    this._sessionStore.update({ country });
  }

  public setCrossSellData(crossSellData: Partial<DraftData>): void {
    this._sessionStore.update({ crossSellData });
  }

  // eslint-disable-next-line
  public setDropdownOptionsState(options: any): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      dropdownOptionsState: { ...state.dropdownOptionsState, ...options }
    }));
  }

  public setExternalLinkParams(errorKey: string, type: string): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      externalLink: {
        errorKey,
        type
      }
    }));
  }

  public setHeaderBackOptions(options: Partial<HeaderBackOptions>): void {
    this._sessionStore.update({ headerBackOptions: options });
  }

  // eslint-disable-next-line
  public setInteractiveTariffState(interactiveTariffState: any): void {
    this._sessionStore.update({ interactiveTariffState });
  }

  public setIsLegalEntity(isLegalEntity: boolean): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      formCurrentState: { ...state.formCurrentState, isLegalEntity }
    }));
  }

  public setIsOffer(isOffer: boolean): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      formCurrentState: { ...state.formCurrentState, isOffer }
    }));
  }

  public setIsPrefilled(isPrefilled: boolean): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      formCurrentState: { ...state.formCurrentState, isPrefilled }
    }));
  }

  public setKeycloakToken(keycloakToken: KeycloakToken): void {
    this._sessionStore.update({ keycloakToken });
  }

  public setLoading(loading: boolean, loadingMessage?: string): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      ui: { ...state.ui, loading }
    }));
    if (loadingMessage) {
      this.setLoadingMessage(loadingMessage);
    }
  }

  public setLoadingMessage(loadingMessage: string): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      ui: { ...state.ui, loadingMessage }
    }));
  }

  public setLocale(locale: LOCALES): void {
    this._sessionStore.update({ locale });
  }

  public setReviewCardsDetails(reviewCardsDetails: Partial<DetailCardOptions>[]): void {
    this._sessionStore.update({ reviewCardsDetails });
  }

  public setRolePermissions(rolePermissions: string[]): void {
    this._sessionStore.update({ rolePermissions });
  }

  public setScrollEventTracking(externalProductSeen: boolean): void {
    this._sessionStore.update({ externalProductSeen });
  }

  // eslint-disable-next-line
  public setSupportCategories(options: any): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      dropdownOptionsState: { ...state.dropdownOptionsState, category: options }
    }));
  }

  // eslint-disable-next-line
  public setSupportSubCategories(options: any): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      dropdownOptionsState: { ...state.dropdownOptionsState, sub_category: options }
    }));
  }

  public setTariffCalculationState(status: string): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      tariffState: { ...state.tariffState, calculationState: status }
    }));
  }

  // eslint-disable-next-line
  public setTariffState(tariffState: any): void {
    this._sessionStore.update({ tariffState });
  }

  // eslint-disable-next-line
  public setTariffValues(tariffValues: any): void {
    this._sessionStore.update({ tariffValues });
  }

  public setToasterRoleOptions(options: BannerOptions): void {
    this._sessionStore.update({ toasterRoleOptions: options });
  }

  public setTokenExpiration(expires_in: number): void {
    this._sessionStore.update({ token: { expires_in } });
  }

  // eslint-disable-next-line
  public setTypeaheadState(options: any): void {
    this._sessionStore.update((state: SessionState) => ({
      ...state,
      typeaheadState: { ...state.typeaheadState, ...options }
    }));
  }

  // eslint-disable-next-line
  public setUploadedFiles(uploadedFiles: any): void {
    this._sessionStore.update({ uploadedFiles });
  }
}
