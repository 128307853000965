import { AbstractControl, ValidatorFn } from '@angular/forms';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';


export interface CurrencyRangeParams { 
  min: number,
  max: number,
  allowCero: boolean 
}

export class CurrencyInputValidator {
  public static max(amount: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (parseFloat(this.getCurrencyValueFromMask(control.value, this.getCountry(control.value))) > amount) {
        return {
          max: true
        };
      }
      return null;
    };
  }

  public static min(amount: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (parseFloat(this.getCurrencyValueFromMask(control.value, this.getCountry(control.value))) < amount) {
        return {
          min: true
        };
      }
      return null;
    };
  }

  public static currencyRange(params: CurrencyRangeParams): ValidatorFn {
    return (control: AbstractControl) => {
      const value = parseFloat(this.getCurrencyValueFromMask(control.value, this.getCountry(control.value)));
      if(params.allowCero && value === 0) {
        return null;
      }
      if (value > params.min && value < params.max) {
        return null;
      }
      return {
        range: true
      };
    };
  }

  private static getCountry(amount: string): COUNTRIES {
    if (!amount) {
      return;
    }

    if (amount?.toString().split(' ')[0] === 'CHF') {
      return COUNTRIES.ch;
    }
  }

  private static getCurrencyValueFromMask(value: string, country: COUNTRIES) {
    if (value !== null && value !== undefined) {
      if (country) {
        return value.toString().split('’').join('').split('CHF ').join('');
      }

      return value.toString().split('.').join('').replace(',', '.').split(' €').join('');
    }
  }
}
