import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { AddressService } from '@wefoxGroupOneBPShared/services/address.service';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';

export interface AddressOptions {
  postal_code_mask?: string;
  postalCodeTooltip?: string;
  can_copy_address?: boolean;
  control_names?: AddressControlName;
  controlNames?: AddressControlName;
  custom_labels?: AddressControlName;
}

export interface AddressControlName {
  city: string;
  house_number: string;
  postal_code: string;
  province?: string;
  short_province?: string;
  street: string;
}

export const AddressControlNamesDefault = {
  city: 'place',
  house_number: 'house_number',
  postal_code: 'postal_code',
  province: 'province',
  short_province: 'short_province',
  street: 'street'
};

@Component({
  selector: 'one-address',
  templateUrl: './address.component.html'
})
export class AddressComponent implements OnInit {
  @Input() public i18nPrefixKey = '_ST_MOTOR_';
  @Input() public invalidAddress: boolean;
  public maxAddressLength: number;
  @Input() public options: AddressOptions;
  @Input() public parentGroup: UntypedFormGroup;

  private _controlNames;
  private _defaultOptions: AddressOptions;

  constructor(
    private _addressService: AddressService,
    private _formService: FormService,
    protected _sessionQuery: SessionQuery,
  ) {
    this._defaultOptions = {
      controlNames: AddressControlNamesDefault
    };
  }

  public ngOnInit(): void {
    this.options = { ...this._defaultOptions, ...this.options };
    this._controlNames = this.options.controlNames;
    this.maxAddressLength = this._formService.getCombinedAddressLength();

    this._addressService.monitorStreetInputChanges(this.parentGroup, this._controlNames);
    this._addressService.monitorAddressChanges(this.parentGroup, this._controlNames);
    this._addressService.fillCityFromPostalCode(this.parentGroup, this._controlNames);
  }

  // eslint-disable-next-line
  public onStreetInputFocus(event): void {
    this._addressService.selectInputText(event.target);
  }
}
