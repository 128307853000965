import { Component, Input } from '@angular/core';

class FileDownload {
  // eslint-disable-next-line @typescript-eslint/ban-types
  download: Function;
  id: string;
  label: string;
  name: string;
  content_type: string;
  url: string;
  created_date: string;
  last_modified_date: string;
  downloaded: boolean;
}

@Component({
  selector: 'wg-file-download',
  templateUrl: './file-download.component.html'
})
export class FileDownloadComponent {
  @Input() public files: FileDownload[];

  public getDownloadIcon(index: number): string {
    return this.files[index]?.downloaded ? 'step-done' : 'file-download';
  }

  public getIconColor(index: number): string {
    return this.files[index]?.downloaded ? 'success-dark' : 'neutral-end';
  }

  public downloadFile(index: number): void {
    const file = this.files[index];
    if (file.download) {
      file.download();
      file.downloaded = true;
    } else {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = file.url;
      link.download = file.name;
      link.click();
      file.downloaded = true;
    }
  }
}
