import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DetailCardsConfig } from '@wefoxGroupOneBP/app/private/modules/claims/interfaces/claims-details.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { Observable } from 'rxjs';

@Injectable()
export class PageDetailsDataService extends BaseDataService {
  private _cardConfigEndpoint = 'details-card-config';
  private _tableConfigEndpoint = 'table-config';
  private _viewConfigEndpoint = 'view-config';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  public getCardDetailsConfig$(
    country: string,
    type: string,
    subtype: string,
    subSubtype?: string,
    target?: string,
  ): Observable<DetailCardsConfig> {
    const sst = subSubtype ? { subSubtype } : {};
    const tgt = target ? { target } : {};
    const url = this.getUrlWithParams({
      options: {
        country,
        type,
        subtype,
        ...tgt,
        ...sst
      },
      path: this._cardConfigEndpoint
    });

    return this.get$(url) as Observable<DetailCardsConfig>;
  }

  public getTableConfig$(country: string, type: string, subtype: string): Observable<DetailCardsConfig> {
    const url = this.getUrlWithParams({
      options: {
        country,
        type,
        subtype
      },
      path: this._tableConfigEndpoint
    });
    return this.get$(url) as Observable<DetailCardsConfig>;
  }

  // eslint-disable-next-line
  public getViewConfig$(country: string, type: string, target: string): Observable<any> {
    const url = this.getUrlWithParams({
      options: {
        country,
        type,
        target
      },
      path: this._viewConfigEndpoint
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }
}
