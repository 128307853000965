import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PrivateConfig } from '@wefoxGroupOneBPCore/interfaces';
import { DetailsEditConfig } from '@wefoxGroupOneBPCore/interfaces/customers.interface';

export interface ConfigState {
  config: PrivateConfig;
  customer_details: DetailsEditConfig;
  privateFooters: any; // eslint-disable-line
  privateHeaders: any; // eslint-disable-line
  privateSidebar: any; // eslint-disable-line
  support: any; // eslint-disable-line
}

const createInitialState: ConfigState = {
  config: null,
  customer_details: null,
  privateFooters: null,
  privateHeaders: null,
  privateSidebar: null,
  support: null
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'config' })
export class ConfigStore extends Store<ConfigState> {
  constructor() {
    super(createInitialState);
  }
}
