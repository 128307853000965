export interface ToasterNotification {
  lifeTime?: number;
  selfDestroy?: boolean;
  timestamp?: number;
  title?: string;
  type?: ToasterType;
}

export const ToasterType = {
  error: '-error' as const,
  info: '-info' as const,
  success: '-success' as const,
  warning: '-warning' as const
};

export type ToasterType = typeof ToasterType[keyof typeof ToasterType];
