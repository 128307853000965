import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { COUNTRIES } from "@wefoxGroupOneBPCore/constants/countries.constants";
import { MasterDataService } from "@wefoxGroupOneBPCore/services/master-data.service";
import { Observable } from "rxjs";
import { LOCALES } from '@wefoxGroupOneBPCore/constants/languages.constants';

@Injectable()
export class AppInitializerService {
  public initConfig$: Observable<any> = new Observable; // eslint-disable-line
  private _country: COUNTRIES = COUNTRIES.de;

  constructor(
    private translocoService: TranslocoService,
    private _masterDataService: MasterDataService
  ) {
  }

  public init(): Promise<any> { //eslint-disable-line
    const locale = new URL(location.href).searchParams.get('locale') as LOCALES
    this.translocoService.setActiveLang(locale);
    return this.translocoService.load(locale).toPromise();
  }

  public setInitialMasterData$(): Observable<unknown> {
    return this._masterDataService.getData(this._country);
  }

  private _getCountryUrl(): COUNTRIES {
    return new URL(location.href).pathname.split('/')[1].toUpperCase() as COUNTRIES;
  }
}