import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants/countries.constants';
import { HeaderBackOptions } from '@wefoxGroupOneBPCore/interfaces/header-back-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-page-header',
  styleUrls: ['./page-header.component.scss'],
  templateUrl: './page-header.component.html'
})
export class PageHeaderComponent implements OnDestroy, OnInit {
  @Input() public defaultBackDestination = null;
  @Input() public fullWidth = false;
  public headerBackOptions: Partial<HeaderBackOptions>;
  @Input() public isItaly = this._sessionQuery.getCountry() === COUNTRIES.it;
  public showBackButton = this._sessionQuery.isLoggedIn();
  @Input() public subtitle: string;
  @Input() public subtitleLink: string;
  @Input() public title: string;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _sessionQuery: SessionQuery, private _sessionService: SessionService) {}

  public getCountryPrefix(): string {
    return this._sessionQuery.getCountryPrefix();
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this.headerBackOptions = null;
    this._sessionService.setHeaderBackOptions(null);
  }

  public ngOnInit(): void {
    this._sessionQuery.headerBackOptions$.pipe(takeUntil(this._unsubscribe$)).subscribe(options => {
      this.headerBackOptions = options || { destination: this.defaultBackDestination };
    });
  }

  public onBackClick(): void {
    this.headerBackOptions?.customBackAction();
  }
}
