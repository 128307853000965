// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert-bg-danger {
  background-color: #fff0ee;
}

.alert-color-danger {
  color: #621613;
}

.alert-bg-info {
  background-color: #e9f4fd;
}

.alert-color-info {
  color: #023067;
}

.alert-bg-success {
  background-color: #def7e8;
}

.alert-color-success {
  color: #0b4a24;
}

.alert-bg-warning {
  background-color: #fef6e4;
}

.alert-color-warning {
  color: #4c3211;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-table2/components/info-banner-table/info-banner-table.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,cAAA;AACF","sourcesContent":[".alert-bg-danger {\n  background-color: #fff0ee;\n}\n\n.alert-color-danger {\n  color: #621613;\n}\n\n.alert-bg-info {\n  background-color: #e9f4fd;\n}\n\n.alert-color-info {\n  color: #023067;\n}\n\n.alert-bg-success {\n  background-color: #def7e8;\n}\n\n.alert-color-success {\n  color: #0b4a24;\n}\n\n.alert-bg-warning {\n  background-color: #fef6e4;\n}\n\n.alert-color-warning {\n  color: #4c3211;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
