import { Component, Input } from '@angular/core';
import { RedirectionService } from '@wefoxGroupOneBPShared/services/redirection.service';

export interface DynamicBannerOptions {
  class: string;
  max_width: string;
  theme: string;
  title: string;
  description: string;
  buttonLabel: string;
  icon: string;
  route: string;
  routeParams: string;
  tSelector: string;
}
@Component({
  selector: 'one-dynamic-banner',
  templateUrl: './dynamic-banner.component.html'
})
export class DynamicBannerComponent {
  @Input() config: DynamicBannerOptions;

  constructor(private _redirectionService: RedirectionService) {}

  public navigateToRoute(route: string, params: string): void {
    this._redirectionService.navigate(route, params);
  }
}
