import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { OfferCreationForm } from '../interfaces/offer-creation.interface';
import { OfferCreation } from '../models/offer-creation';
import { OfferCreationDataService } from './offer-creation-data.service';

@Injectable({ providedIn: 'root' })
export class OfferCreationService {
  private selectedOffers$: Subject<Array<OfferCreationForm>> = new Subject();

  constructor(private _offerCreationData: OfferCreationDataService) {}

  // eslint-disable-next-line
  public uploadSignedDocument$(file: File): Observable<any> {
    return this._offerCreationData.uploadSignedDocument$(file);
  }

  public submitOfferCreation$(body: OfferCreation, options?: RequestInit): Observable<OfferCreation> {
    return this._offerCreationData.submitOfferCreation$(body, options);
  }

  public updateOfferCreation$(body: OfferCreation, options?: RequestInit): Observable<OfferCreation> {
    return this._offerCreationData.updateOfferCreation$(body, options);
  }

  public getSelectedOffers$(): Observable<Array<OfferCreationForm>> {
    return this.selectedOffers$.asObservable();
  }

  public setSelectedOffers$(selectedOffers: Array<OfferCreationForm>): void {
    this.selectedOffers$.next(selectedOffers);
  }

  // eslint-disable-next-line
  public getStaticDocuments$(insuranceCompanyData): Observable<any> {
    return this._offerCreationData.getStaticDocuments$(insuranceCompanyData);
  }
}
