// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onebp-o-card-rectangle {
  padding: 8px;
}
.onebp-o-card-rectangle .--title {
  color: var(--primary-dark);
  display: block;
  font-size: 20px;
  font-weight: 500;
  left: 28px;
  margin-bottom: 8px;
  position: relative;
}
.onebp-o-card-rectangle .--title::before {
  background-color: var(--primary-dark);
  border-radius: 60px;
  content: "";
  height: 16px;
  left: -28px;
  position: absolute;
  top: 4px;
  width: 16px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/card-rectangle/card-rectangle.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;AAAF;AAEE;EACE,0BAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,qCAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,QAAA;EACA,WAAA;AAAN","sourcesContent":["\n.onebp-o-card-rectangle {\n  padding: 8px;\n\n  .--title {\n    color: var(--primary-dark);\n    display: block;\n    font-size: 20px;\n    font-weight: 500;\n    left: 28px;\n    margin-bottom: 8px;\n    position: relative;\n\n    &::before {\n      background-color: var(--primary-dark);\n      border-radius: 60px;\n      content: '';\n      height: 16px;\n      left: -28px;\n      position: absolute;\n      top: 4px;\n      width: 16px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
