// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wg-c-tooltip {
  background-color: var(--secondary-lightest);
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.12);
  color: var(--secondary-dark);
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1.38;
  max-width: 290px;
  padding: 16px;
  text-align: left;
}
.wg-c-tooltip.-mobile {
  margin-top: 10px;
  max-width: 100%;
}

.wg-c-tooltip__body {
  font-size: 14px;
  letter-spacing: -0.3px;
  line-height: 1.43;
}

.wg-c-tooltip__title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.18px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-tooltip/wg-tooltip.component.scss"],"names":[],"mappings":"AACA;EACE,2CAAA;EACA,kBAAA;EACA,0CAAA;EACA,4BAAA;EACA,oBAAA;EACA,kBAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;EACA,gBAAA;EACA,aAAA;EACA,gBAAA;AAAF;AAEE;EACE,gBAAA;EACA,eAAA;AAAJ;;AAIA;EACE,eAAA;EACA,sBAAA;EACA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,iBAAA;EACA,uBAAA;AADF","sourcesContent":["\n.wg-c-tooltip {\n  background-color: var(--secondary-lightest);\n  border-radius: 8px;\n  box-shadow: 0 4px 15px rgb(0 0 0 / 12%);\n  color: var(--secondary-dark);\n  font-stretch: normal;\n  font-style: normal;\n  font-weight: normal;\n  letter-spacing: normal;\n  line-height: 1.38;\n  max-width: 290px;\n  padding: 16px;\n  text-align: left;\n\n  &.-mobile {\n    margin-top: 10px;\n    max-width: 100%;\n  }\n}\n\n.wg-c-tooltip__body {\n  font-size: 14px;\n  letter-spacing: -0.3px;\n  line-height: 1.43;\n}\n\n.wg-c-tooltip__title {\n  font-size: 14px;\n  font-weight: bold;\n  letter-spacing: -0.18px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
