// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-performance-section {
  overflow-x: scroll;
  padding-bottom: 24px;
}

.skeleton-container {
  border-radius: 16px;
  height: 140px;
  min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/performance-section/performance-section.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,oBAAA;AACF;;AAEA;EACE,mBAAA;EACA,aAAA;EACA,gBAAA;AACF","sourcesContent":[".container-performance-section {\n  overflow-x: scroll;\n  padding-bottom: 24px;\n}\n\n.skeleton-container {\n  border-radius: 16px;\n  height: 140px;\n  min-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
