import { NgModule } from '@angular/core';
import { DynamicModalComponent } from './dynamic-modal/dynamic-modal.component';
import { DynamicRowComponent } from './row/dynamic-row.component';
import { DynamicSectionComponent } from './section/dynamic-section.component';
import { AdaptersModule } from './inputs/wg-adapters.module';
import { DynamicOperationService } from './services/dynamic-operation.service';
import { VariablesStore } from './stores/variables.store';


@NgModule({
  exports: [
    DynamicRowComponent,
    DynamicSectionComponent,
    DynamicModalComponent,
    AdaptersModule
  ],
  imports: [
    DynamicRowComponent,
    DynamicSectionComponent,
    DynamicModalComponent,
    AdaptersModule
  ],
  providers: [DynamicOperationService, VariablesStore],
})
export class WgDynamicComponentsModule { }
