import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  forwardRef,
  Input,
  OnInit,
  QueryList
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components';

const plateInputPatterns = {
  CH: ['XX', '123456'],
  DE: ['XXX', 'XX', '1234H'],
  IT: ['XX', '123', 'XX']
};

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicensePlateFieldComponent)
    }
  ],
  selector: 'one-license-plate-field',
  templateUrl: './license-plate-field.component.html'
})
export class LicensePlateFieldComponent implements ControlValueAccessor, AfterViewInit, AfterContentInit, OnInit {
  public licensePlateForm: UntypedFormGroup;
  public licensePlatePattern: string[];
  @Input() public name: string;
  protected _inputControl: AbstractControl;

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _country: COUNTRIES;
  private _errorMap: { [key: string]: string };

  constructor(
    private _controlContainer: FormGroupDirective,
    private _fb: UntypedFormBuilder,
    private _sessionQuery: SessionQuery
  ) {
    this._country = this._sessionQuery.getCountry();
  }

  public getCurrentError(): { className: string, errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this._inputControl.errors || (this._inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: '', errorMessage: '' };
    }
    return {
      className: 'is-invalid',
      errorMessage: this._errorMap[Object.keys(this._inputControl.errors)[0]]
    };
  }

  public ngAfterContentInit(): void {
    this._inputControl = this._controlContainer.control.get(this.name);
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });
  }

  public ngOnInit(): void {
    const control = {};
    this._errorMap = { licensePlatePrefix: translate('_PROD_license_plate_region_error') };
    this.licensePlatePattern = plateInputPatterns[this._country];
    this.licensePlatePattern.forEach((pattern, i) => (control[`position${i}`] = ['', []]));

    this.licensePlateForm = this._fb.group(control);
    this.licensePlateForm.valueChanges.subscribe((data: { [key: string]: string }) =>
      this.propagateChange(this._formatPlateValue(data))
    );
  }

  public propagateChange = (_: any) => { }; // eslint-disable-line
  public propagateTouched = (_: any) => { }; // eslint-disable-line

  // eslint-disable-next-line
  public registerOnChange(fn: any) {
    // ControlValueAccessor method
    this.propagateChange = fn;
  }
  // eslint-disable-next-line
  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  } // ControlValueAccessor method

  // eslint-disable-next-line
  public writeValue(value: any) {
    // ControlValueAccessor method
    if (value) {
      const result = this._getValidatedResult(value.toUpperCase());
      if (result) {
        this.licensePlatePattern.forEach((pattern, i) => {
          this.licensePlateForm.get(`position${i}`).patchValue(result[i + 2]);
        });
      } else {
        this.licensePlateForm.get('position0').patchValue(value.split('-')[0]);
      }
    } else {
      this.licensePlatePattern.forEach((pattern, i) => {
        this.licensePlateForm.get(`position${i}`).patchValue(null);
      });
    }
  }

  private _formatPlateValue(data: { [key: string]: string }): string {
    if (!data.position0) {
      return;
    }
    let value;
    switch (this._country) {
      case COUNTRIES.ch:
        value = `${data.position0?.toUpperCase()}-${data.position1?.toUpperCase() || ''}`;
        break;
      default:
        value = `${data.position0?.toUpperCase()}-${data.position1?.toUpperCase()} ${data.position2?.toUpperCase()}`;
    }
    return value;
  }

  private _getValidatedResult(value: string): string {
    let result;
    switch (this._country) {
      case COUNTRIES.ch:
        result = value.match(ProductValidations.licensePlatePatternCH);
        break;
      default:
        result = value.match(ProductValidations.licensePlatePatternDE);
    }
    return result;
  }
}
