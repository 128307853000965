import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Header, SortOrder } from '@wefoxGroupOneBPCore/interfaces/wg-table.interface';
import { Customer } from '@wefoxGroupOneBPShared/interfaces/customer.interface';
import { Observable } from 'rxjs';

const minListLength = 5;

@Component({
  selector: 'wg-table',
  styleUrls: ['./wg-table.component.scss'],
  templateUrl: './wg-table.component.html'
})
export class WgTableComponent implements OnInit {
  @Output() public clickedRow: EventEmitter<any> = new EventEmitter(); // eslint-disable-line
  public currentSelectedId: string;
  @Input() public data: Observable<Customer[]>;
  public dataLength: number;
  @Input() public headers: Header[];
  @Input() public isEditable = false;
  @Output() public linkBy: EventEmitter<string> = new EventEmitter();
  @Input() public listLength = minListLength;
  public openEditOptions = false;

  @Input() public selectable = false;
  public selected: string;
  @Output() public selectedBy: EventEmitter<string> = new EventEmitter();
  @Output() public sortBy: EventEmitter<SortOrder> = new EventEmitter();

  @Input() public sorting = false;
  public sortOrder: SortOrder[];
  public startFrom = 0;
  /**
   * Updates the sortOrder array with the new order types
   * Also calls to emit the new type of order
   * @param sortField Receive the type of head will update the order
   */
  public changeSortOrder(header: Header): void {
    this._highlightSortedColumn(header.name);
    this.sortOrder.map((sort: SortOrder, i) => {
      if (header.sort === sort.type) {
        const newOrder: SortOrder = { ...sort, order: sort.order === 'asc' ? 'desc' : 'asc' };
        this.sortOrder[i] = newOrder;
        this._emitSortType(newOrder);
      }
    });
  }

  /**
   * By passing an ID by row and the checked value sets the item selected from data
   * @param id Main ID to identify
   */
  // eslint-disable-next-line
  public clickedRowData(data: any) {
    this.clickedRow.emit(data);
  }

  public emitLink(event: Event, link: string): void {
    event.stopPropagation();
    this.linkBy.emit(link);
  }

  /**
   * Get the type of order by passing a header as a parameter, could be ASC or DESC
   * @param head
   */
  public getSortOrder(head: string): string {
    return this.sortOrder.find((sort: SortOrder) => sort.type === head).order;
  }

  // eslint-disable-next-line
  public isFieldObject(field: any): boolean {
    return field === Object(field);
  }

  public ngOnInit(): void {
    this._initSortOrder();
  }

  // eslint-disable-next-line
  public onButtonClick(action: string, client): void {
    if (action === 'delete') {
      client.deleteAction();
    }

    if (action === 'edit') {
      if (client.editOptions) {
        this.currentSelectedId = client.id;
        this.openEditOptions = !this.openEditOptions;

        return;
      }
      client.editAction(client.product_group);
    }
  }

  // eslint-disable-next-line
  public onOptionSelect(client, type): void {
    this.openEditOptions = false;
    client.editAction(type);
  }

  /**
   * By passing an ID by row and the checked value sets the item selected from data
   * @param id Main ID to identify
   */
  public selectRow = (id: string): void => {
    if (id === this.selected) {
      this.selected = null;
    } else {
      this.selected = id;
    }
    this._emitSelectedID(this.selected);
  };

  /**
   * Emits by the Output how to order the table
   * @param newOrder Recieve an object with the type of order and how
   */
  private _emitSelectedID(ID: string): void {
    this.selectedBy.emit(ID);
  }

  /**
   * Emits by the Output how to order the table
   * @param newOrder Recieve an object with the type of order and how
   */
  private _emitSortType(newOrder: SortOrder): void {
    this.sortBy.emit(newOrder);
  }

  private _highlightSortedColumn(id: string): void {
    this.headers.map((head: Header) => (head.selected = head.name === id ? true : false));
  }

  /**
   * Initialize an array of SortOrder objects to save the sort type for each header
   */
  private _initSortOrder(): void {
    if (this.sorting) {
      this.sortOrder = this.headers?.map((head: Header) => {
        if (head.order_default) {
          this._highlightSortedColumn(head.name);
          return head.order_default;
        } else {
          return { type: head.sort, order: 'desc' };
        }
      });
    }
  }
}
