import { Injectable } from '@angular/core';
import { Filter } from '@wefoxGroupOneBPCore/interfaces/filter.interface';
import { StatsQuery } from '../queries';
import { StatsState, StatsStore } from '../stores/stats.store';

export const StatePages = {
  CommissionsPage: 'commissions-page' as const,
  ContractsPage: 'contracts-page' as const,
  ThirdPartyContractsPage: 'third-party-contracts-page' as const,
  ContractsDetailPage: 'contracts-detail-page' as const,
  CustomersPage: 'customers-page' as const,
  ThirdPartyCustomersPage: 'third-party-customers-page' as const,
  DraftsPage: 'drafts-page' as const,
  UsersPage: 'users-page' as const,
  LeadsPage: 'leads-page' as const,
  QuotesPage: 'quotes-page' as const,
  DigitalSignaturePage: 'digital-signature-page' as const
};

@Injectable({
  providedIn: 'root'
})
export class StateService {
  constructor(private _statsStore: StatsStore, private _statsQuery: StatsQuery) {}

  public clearFiltersInPageState(currentPage: string): void {
    this._statsStore.update((state: StatsState) => ({
      ...state,
      pageStates: {
        ...state.pageStates,
        [currentPage]: {
          ...state.pageStates[currentPage],
          filters: {}
        }
      }
    }));
  }

  public clearSingleFilterInCurrentPageState(filterName: string): void {
    const currentPage = this.getCurrentPage();
    this._statsStore.update((state: StatsState) => ({
      ...state,
      pageStates: {
        ...state.pageStates,
        [currentPage]: {
          ...state.pageStates[currentPage],
          filters: {
            ...state.pageStates[currentPage]?.filters,
            [filterName]: null
          }
        }
      }
    }));
  }

  public getCurrentPage(): string {
    return this._statsQuery.getCurrentPage();
  }

  public setCurrentPage(page: string): void {
    this._statsStore.update({ currentPage: page });
  }

  public setFiltersInCurrentPageState(config: Partial<Filter>): void {
    const currentPage = this.getCurrentPage();
    this._statsStore.update((state: StatsState) => ({
      ...state,
      pageStates: {
        ...state.pageStates,
        [currentPage]: {
          ...state.pageStates[currentPage],
          filters: {
            ...state.pageStates[currentPage]?.filters,
            [config.name]: config
          }
        }
      }
    }));
  }

  public setSortingInCurrentPageState(sortField: string, sortOrder: string): void {
    const currentPage = this.getCurrentPage();
    this._statsStore.update((state: StatsState) => ({
      ...state,
      pageStates: {
        ...state.pageStates,
        [currentPage]: {
          ...state.pageStates[currentPage],
          sortField,
          sortOrder
        }
      }
    }));
  }
}
