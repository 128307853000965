// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onebp-c-radio {
  position: relative;
}

.onebp-a-fieldInvalid {
  bottom: 24px;
  color: var(--danger-base);
  margin-top: 4px;
  position: relative;
}

:host.onebp-m-radio__row {
  display: flex !important;
  flex-flow: row wrap !important;
  justify-content: flex-start !important;
}
:host.onebp-m-radio__row .onebp-a-fieldInvalid {
  bottom: 0;
}

:host.onebp-m-radio__column {
  display: flex;
  flex-direction: column;
}
:host.onebp-m-radio__column .c-inputRadio {
  margin-bottom: 8px;
  overflow: inherit;
  text-overflow: inherit;
  white-space: inherit;
}
:host.onebp-m-radio__column .onebp-c-radio {
  border-top: 1px solid var(--neutral-base) !important;
}
:host.onebp-m-radio__column .onebp-a-fieldInvalid {
  bottom: 0;
}

.onebp-c-radio__break {
  flex-basis: 100%;
  height: 0;
  overflow: hidden;
  width: 0;
}

.-hasTooltip {
  width: calc(100% - 40px);
}

.onebp-c-radio__icon {
  position: absolute;
  right: 24px;
  top: 16px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-radio-button/wg-radio-button.component.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;AAAF;;AAGA;EACE,YAAA;EACA,yBAAA;EACA,eAAA;EACA,kBAAA;AAAF;;AAGA;EACE,wBAAA;EACA,8BAAA;EACA,sCAAA;AAAF;AAEE;EACE,SAAA;AAAJ;;AAIA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,oBAAA;AADJ;AAIE;EACE,oDAAA;AAFJ;AAKE;EACE,SAAA;AAHJ;;AAOA;EACE,gBAAA;EACA,SAAA;EACA,gBAAA;EACA,QAAA;AAJF;;AAOA;EACE,wBAAA;AAJF;;AAOA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAJF","sourcesContent":["\n.onebp-c-radio {\n  position: relative;\n}\n\n.onebp-a-fieldInvalid {\n  bottom: 24px;\n  color: var(--danger-base);\n  margin-top: 4px;\n  position: relative;\n}\n\n:host.onebp-m-radio__row {\n  display: flex !important;\n  flex-flow: row wrap !important;\n  justify-content: flex-start !important;\n\n  .onebp-a-fieldInvalid {\n    bottom: 0;\n  }\n}\n\n:host.onebp-m-radio__column {\n  display: flex;\n  flex-direction: column;\n\n  .c-inputRadio {\n    margin-bottom: 8px;\n    overflow: inherit;\n    text-overflow: inherit;\n    white-space: inherit;\n  }\n\n  .onebp-c-radio {\n    border-top: 1px solid var(--neutral-base) !important;\n  }\n\n  .onebp-a-fieldInvalid {\n    bottom: 0;\n  }\n}\n\n.onebp-c-radio__break {\n  flex-basis: 100%;\n  height: 0;\n  overflow: hidden;\n  width: 0;\n}\n\n.-hasTooltip {\n  width: calc(100% - 40px);\n}\n\n.onebp-c-radio__icon {\n  position: absolute;\n  right: 24px;\n  top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
