import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Product, ProductCustom, ProductGroup, Source } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { ProductState, ProductStore } from '@wefoxGroupOneBPCore/stores/product.store';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';

@Injectable({ providedIn: 'root' })
export class ProductQuery extends QueryEntity<ProductState, Product> {
  constructor(protected store: ProductStore) {
    super(store);
  }

  public getActiveAllSources(): Source[] {
    return this.getActive().sources;
  }

  public getActiveSources(name: string): Source {
    return this.getActiveAllSources().find(source => source.name === name);
  }

  public getCancelableProduct(productId: string): boolean {
    const validProductIds = ['p_YsMwxuaCF9RAcnDXTvkGwSWT', 'p_CarSwitchSwitzerland']; // FIXME: this is a temporary fix, until we don't have any motor contract active
    const isCancelable = this.getValue().entities[productId]?.cancel_policy_available;
    return validProductIds.includes(productId) || isCancelable;
  }

  public getProductGroups(): ProductGroup[] {
    const productG = this.getAll()
      .map(p => p.product_group)
      .sort((p1, p2) => p1.display_order - p2.display_order);
    const uniqueProG = [];
    productG.forEach(pg => {
      if (!uniqueProG.some(p => p.id === pg.id)) {
        uniqueProG.push(pg);
      }
    });
    return uniqueProG;
  }

  public getProductByGroup(id: string): Partial<ProductCustom>[] {
    return this.getAll()
      .filter(product => product.product_group.id === id)
      .sort((p1, p2) => p1.display_order - p2.display_order);
  }

  public getProductGroup(): string {
    return this.getActive().product_group.id;
  }

  public getSourceValues(name: string): WgDropdownOption[] {
    return this.getActiveSources(name).values as WgDropdownOption[];
  }

  public getTranslationPrefix(productId: string): string {
    return this.getValue().entities[productId]?.translation_prefix;
  }
}
