import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup
} from '@angular/forms';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';

const CONTENT_DATA = {
  required: true,
  feedback: null,
  files: [],
  allowed_file_types: [],
  disabled: false,
  max_upload_size: 20,
  max_uploadd_limit: 5,
  multiple_files: true,
  label: {
    input_button: '',
    input_text: '',
    initial_message: ''
  }
};

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: WgDocumentUploadComponent
    }
  ],
  selector: 'wg-document-upload',
  styleUrls: ['./wg-document-upload.component.scss'],
  templateUrl: './wg-document-upload.component.html'
})
export class WgDocumentUploadComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit {
  // eslint-disable-next-line
  @Input() set fileUploadOptions(fileOptions: any) {
    this.options = { ...this._defaultOptions, ...fileOptions };
  }
  // eslint-disable-next-line
  public get getUploadOptions(): any {
    return this.options;
  }
  @Input() public controlName: string;
  @Input() public currentFiles: File[];
  public feedback = null;
  public inputControl: AbstractControl;
  @Input() public isRequired = false;
  public isSelectBtnClick = false;
  public options: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  public selectedFiles = [];

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _defaultOptions = CONTENT_DATA;
  private _errorMap: { [key: string]: string };

  constructor(private _host: ElementRef<HTMLInputElement>, private _controlContainer: FormGroupDirective) {}

  public addFiles(event: File[]): void {
    if (event?.length === 0) {
      return;
    }

    if (this.selectedFiles.length + event?.length > this.getUploadOptions.max_uploadd_limit) {
      this._setLimitError();
      return;
    }

    if (event && event.length <= this.getUploadOptions.max_uploadd_limit) {
      if (this.selectedFiles) {
        if (this.selectedFiles.length + event.length <= this.getUploadOptions.max_uploadd_limit) {
          for (const file of event) {
            const findExistFile = this.selectedFiles.find((item: File) => item.name === file.name);
            if (findExistFile) {
              this.feedback = {
                background: 'bg-warning-light',
                icon: 'feedback-warning',
                message: this.getUploadOptions.message.file_already_selected_error
              };
              return;
            }
            if (!findExistFile && !!this.checkAllowedFiles([file], 'type')) {
              if (!!this.checkAllowedFiles(event, 'size')) {
                this.selectedFiles.push(file);
              } else {
                this.inputControl.setErrors({
                  errorMessage: this.getUploadOptions.message.file_max_size_error
                });
                return;
              }
            }
          }
        } else {
          this._setLimitError();
        }
      } else {
        if (!!this.checkAllowedFiles(event, 'type') && !!this.checkAllowedFiles(event, 'size')) {
          this.selectedFiles = Array.from(event);
        }
      }
      this._checkUploadSize();
    } else {
      this._setLimitError();
    }
  }

  public checkAllowedFiles(event: File[], checkAs: 'type' | 'size'): boolean {
    if (typeof event === 'object') {
      for (const file of event) {
        switch (checkAs) {
          case 'type':
            return this.getUploadOptions.allowed_file_types.includes(file.type);
          case 'size':
            return !!(file.size <= this.getUploadOptions.max_upload_size * 1024 * 1024);
        }
      }
    }
    return false;
  }

  @HostListener('change', ['$event.target.files'])
  public emitFiles(event: File[]): void {
    this.addFiles(event);
    this.isSelectBtnClick = false;
  }

  public getButtonDisabledState(): string | null {
    const a =
      this.inputControl?.disabled ||
      this.options.disabled ||
      this.selectedFiles.length === this.getUploadOptions.max_uploadd_limit;
    return a ? 'true' : null;
  }

  public getFilesDisabledState(): string | null {
    const a = this.inputControl?.disabled || this.options.disabled;
    return a ? 'true' : null;
  }

  public getCurrentError(): { className: string; errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (this._errorMap) {
      if (!this.inputControl.errors || (this.inputControl.untouched && !this._controlContainer.submitted)) {
        return { className: '-valid', errorMessage: '' };
      }
      return {
        className: '-invalid',
        errorMessage: this._errorMap[Object.keys(this.inputControl.errors)[0]]
      };
    }

    return { className: '-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this.parentGroup.controls[this.controlName]?.statusChanges.subscribe(() => {
      if (
        !this.parentGroup.controls[this.controlName].valid &&
        this.parentGroup.controls[this.controlName].hasError('virus')
      ) {
        this.onRemoveFile(this.parentGroup.controls[this.controlName].value);
      }
    });
    this.inputControl = this._controlContainer.control.get(this.controlName);

    setTimeout(() => {
      if (this.currentFiles?.length) {
        this.addFiles(this.currentFiles);
      }
    }, 0);
  }

  public ngAfterViewInit(): void {
    this.feedback = this.getUploadOptions.feedback;
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: error.value } };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });

    if (this.currentFiles) {
      this.addFiles(this.currentFiles);
    }
  }

  // eslint-disable-next-line
  public onAddFileClick(event): void {
    this.setFeedback(null);
    this.isSelectBtnClick = true;
    event.target.value = null;
  }

  // eslint-disable-next-line
  public onChange = () => {
    return {};
  };
  public onRemoveFile(item: File): void {
    if (this.options.disabled) {
      return;
    }
    this.selectedFiles.splice(this.selectedFiles.indexOf(!!item['value'] ? item['value'] : item), 1);
    this._checkUploadSize();

    if (this.selectedFiles?.length === 0 && this.isRequired) {
      this.inputControl.setErrors({ required: true });
    }
  }

  // eslint-disable-next-line
  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    return;
  }

  public writeValue(): void {
    this._host.nativeElement.value = '';
  }

  private _checkUploadSize(): void {
    let fileMaxSize = false;
    let MaxUploadSize = false;
    const totalFileSize = this.selectedFiles.reduce((size, file) => {
      if (file.size >= this.getUploadOptions.max_upload_size * 1024 * 1024) {
        fileMaxSize = true;
      }

      return size + file.size;
    }, 0);

    MaxUploadSize =
      this.getUploadOptions.max_upload_size && totalFileSize >= this.getUploadOptions.max_upload_size * 1024 * 1024;

    if (fileMaxSize || MaxUploadSize) {
      this.inputControl.setErrors({
        errorMessage: fileMaxSize
          ? this.getUploadOptions.message.file_over_limit_error
          : this.getUploadOptions.message.files_combined_over_limit_error
      });
      return;
    } else {
      if (this.selectedFiles.length === 0 && this.isRequired) {
        this.inputControl.setErrors({
          errorMessage: this.getUploadOptions.message.file_required_one_error
        });
      } else {
        this.inputControl.setErrors(null);
      }
    }
  }

  private setFeedback(info): void {
    this.feedback = info;
  }

  private _setLimitError(): void {
    this.setFeedback({
      background: 'bg-warning-light',
      icon: 'feedback-warning',
      message: this.getUploadOptions.message.files_max_amount_error
    });
    if (this.selectedFiles.length === 0 && this.isRequired) {
      this.inputControl.setErrors({
        errorMessage: this.getUploadOptions.message.file_required_one_error
      });
      return;
    }
  }

  public getStatusIcon(file): string {
    switch (file.status) {
      case 'loading':
        return 'spinner';
      case 'success':
        return 'trash';
      case 'error':
        return 'feedback-warning';
      default:
        return 'trash';
    }
  }
}
