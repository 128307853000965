// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wg-a-fieldInvalid {
  align-self: flex-start;
  color: var(--danger-base);
}
.wg-a-fieldInvalid:not(:empty) {
  margin-top: 4px;
}

.wg-a-icon {
  height: 20px;
  position: absolute;
  right: 16px;
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-date/wg-date.component.scss"],"names":[],"mappings":"AACA;EACE,sBAAA;EACA,yBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AADF","sourcesContent":["\n.wg-a-fieldInvalid {\n  align-self: flex-start;\n  color: var(--danger-base);\n\n  &:not(:empty) {\n    margin-top: 4px;\n  }\n}\n\n.wg-a-icon {\n  height: 20px;\n  position: absolute;\n  right: 16px;\n  width: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
