import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  Signal,
  WritableSignal,
  computed,
  forwardRef,
  signal
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectComponent)
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MultiselectComponent),
      multi: true
    }
  ],
  selector: 'one-multi-select',
  templateUrl: './multi-select.component.html',
  styles: `
  :host {
    &::ng-deep {
      --comet-color-disabled-label: #301254;
      --comet-color-disabled-bg: #f9f8fa;
      --comet-color-invalid: #ea3e35;
    }
  }
  .invalid-feedback {
    display: block;
    margin-top: 0px;
  }

  .comet-disabled::part(comet-multi-select-container) {
    border: none !important;
  }
  `
})
export class MultiselectComponent implements ControlValueAccessor, Validator, AfterViewInit, OnDestroy {
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public name: string;
  @Input() options: WritableSignal<Array<{ label: string; value: string; selected: boolean }>> = signal([]);
  @Input() placeholder: string;
  @Input() label: string;
  @Input() required: boolean = false;

  items: Signal<Array<{ label: string; value: string; selected: boolean }>> = computed(() => {
    return this.options().map(_ => ({ ..._, label: translate(_.label) }));
  });
  value: Array<string>;
  disabled = false;
  // eslint-disable-next-line
  onChange: any = (value: any) => {};
  // eslint-disable-next-line
  onTouched: any = () => {};

  private _control: AbstractControl;
  private _controlChangesSubscription: Subscription;

  ngAfterViewInit(): void {
    this._control = this.parentGroup.get(this.name);
    this._control.setValue(
      this.options()
        .filter(_ => _.selected)
        .map(({ value }) => value)
    );
    this._controlChangesSubscription = this._control.valueChanges.subscribe((values: Array<string>) => {
      this.options.update(options => {
        return options.map(opt => {
          opt.selected = values?.includes(opt.value);
          return opt;
        });
      });
    });
  }

  ngOnDestroy(): void {
    this._controlChangesSubscription?.unsubscribe();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || !control.value.length || control.value.length === 0) {
      return { required: true };
    }
    return null;
  }

  writeValue(value: Array<string>): void {
    this.value = value;
  }

  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // eslint-disable-next-line
  onSelectChange({ detail }: any): void {
    const value = (detail as Array<{ value: string; label: string }>)?.map(({ value }) => value);
    this._control.setValue(value || []);
    this._control.markAsTouched();
  }
}
