import { Component } from '@angular/core';
import { LOCALES } from '@wefoxGroupOneBPCore/constants/languages.constants';
import { SessionService } from '@wefoxGroupOneBPCore/services/session.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'one-customer-risk-analysis',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html'
})
export class AppComponent {

  public closeDropdown: Subject<void> = new Subject<void>();

  constructor(
    private _sessionService: SessionService
  ) {
    const locale = new URL(location.href).searchParams.get('locale') as LOCALES
    this._sessionService.setLocale(locale);
  }

  getLogo(): string {
    return 'assets/images/brand/logo-wefox.svg?v=#wefox__logo';

  }
}
