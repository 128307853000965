import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRY_CH, COUNTRY_DEFAULT } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { Currency } from '@wefoxGroupOneBPPrivate/product/interfaces';

@Pipe({
  name: 'CurrencyTransform'
})
export class CurrencyTransform extends CurrencyPipe implements PipeTransform {
  constructor(private _sessionQuery: SessionQuery) {
    super(COUNTRY_DEFAULT.countryCode);
  }

  // eslint-disable-next-line
  public transform(value: any, args?: any): any {
    args = { ...this._getCurrencyInfo(), ...args };

    return super.transform(parseFloat(value), args.currencyCode, args.display, args.digitsInfo, args.locale);
  }

  private _getCurrencyInfo(): Currency {
    // TODO: Move currency data to Masterdata
    const defaultOptions = {
      currencyCode: COUNTRY_DEFAULT.currencySymbol,
      digitsInfo: '1.2-2',
      display: 'symbol',
      locale: COUNTRY_DEFAULT.countryCode
    };

    switch (this._sessionQuery.getCountry()) {
      case COUNTRY_CH.countryCode:
        return {
          ...defaultOptions,
          currencyCode: COUNTRY_CH.currencySymbol,
          locale: 'de-CH'
        };

      default:
        return defaultOptions;
    }
  }
}
