import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private _defaultErrorKey = '_GEN_default_backend_error';

  // eslint-disable-next-line
  public getMessage(error: any): string {
    let translation = translate(this._defaultErrorKey);
    if (error.key) {
      const attemptedTranslation = translate(error.key);
      if (attemptedTranslation !== error.key) {
        translation = attemptedTranslation;
      }
    }
    return translation;
  }
}
