import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Filter } from '@wefoxGroupOneBPCore/interfaces/filter.interface';

export interface StatsState {
  currentPage: string;
  pageStates: { [key: string]: { filters: { [key: string]: Partial<Filter> } } };
}

const createInitialState: StatsState = {
  currentPage: null,
  pageStates: {}
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'stats', resettable: true })
export class StatsStore extends Store<StatsState> {
  constructor() {
    super(createInitialState);
  }
}
