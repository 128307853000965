import { Injectable } from '@angular/core';
import { ModalAction, ModalOptions } from '@wefoxGroupOneBPShared/modules/wg-modal/interfaces/wg-modal.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WgModalService {
  public actionListener$: BehaviorSubject<ModalAction> = new BehaviorSubject<ModalAction>(null);
  public isShown$: BehaviorSubject<boolean>;
  public modalOptions$: BehaviorSubject<ModalOptions>;

  private _defaultOptions: ModalOptions;

  constructor() {
    this.isShown$ = new BehaviorSubject<boolean>(false);
    this.modalOptions$ = new BehaviorSubject(<ModalOptions>{});

    this._defaultOptions = {
      closeConfirmText: undefined,
      closeText: '_GEN_close',
      containerModifier: undefined,
      content: undefined,
      contentModifier: undefined,
      contentView: undefined,
      contentViewOptions: undefined,
      isFullScreen: true,
      isIframe: false,
      overlayModifier: undefined,
      showCloseButton: false,
      showCloseConfirm: false,
      showFooter: false,
      showHeader: false
    };
  }

  public actionListener(): Observable<ModalAction> {
    return this.actionListener$;
  }

  public hide(): void {
    this.isShown$.next(false);
  }

  public setActionListener(action: ModalAction): void {
    return this.actionListener$.next(action);
  }

  public setAndShow(options: Partial<ModalOptions>): void {
    this.setModalOptions(options);
    this.show();
  }

  public setModalOptions(options: Partial<ModalOptions>): void {
    if (!options) {
      return;
    }

    const completeOptions = { ...this._defaultOptions, ...options };

    completeOptions.containerModifier = completeOptions.containerModifier || '';

    if (completeOptions.containerModifier === '-full') {
      completeOptions.isFullScreen = true;
    } else if (completeOptions.isFullScreen === true) {
      completeOptions.containerModifier += ' -full';
    }

    if (completeOptions.isIframe) {
      completeOptions.contentModifier += ' -iframe';
    }

    this.modalOptions$.next(completeOptions);
  }

  public show(): void {
    this.isShown$.next(true);
  }
}
