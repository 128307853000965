// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.align-items-flex-start {
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/contacts/components/contact-card/contact-card.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ","sourcesContent":[".align-items-flex-start {\n    align-items: flex-start;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
