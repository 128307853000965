import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormValidatorService } from '@wefoxGroupOneBPShared/services/validators.service';
import { Subject, takeUntil } from 'rxjs';
import { Variable } from '../../interfaces/variable.interface';
import { VariablesStore } from '../../stores/variables.store';

@Component({
  selector: 'one-input-build-component',
  templateUrl: './input-build.component.html',
})
export class InputBuildComponent implements AfterViewInit, OnDestroy {
  @Input() public content_data: any; // eslint-disable-line
  @Output() public emitFilePath = new EventEmitter();
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public type: string;

  public visible = true;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _formValidatorService: FormValidatorService, private _varStore: VariablesStore) {}

  /**
   * If the initial configuration has the element disabled, OnInit adds the disabled state to the element/control.
   */
  public ngAfterViewInit(): void {
    if (this.content_data.options?.disabled) {
      this.parentGroup.get(this.content_data.options?.control_name)?.disable();
    }

    this._checkInitialDisabledStatus();
    this._checkInitialVisibility();

    this._varStore.lastUpdate$.pipe(takeUntil(this._unsubscribe$)).subscribe(variable => {
      this._listenVariableChangesAndDisable(variable);
      this._listenVariableChangesAndHide(variable);    
    });
  }

  private _checkInitialDisabledStatus(): void {
    if (this.content_data.column_disabled) {
      const variableValue = this._varStore.getBoolVariableValue(this.content_data.column_disabled);
      this._setStatus(variableValue);
    }
  }

  private _checkInitialVisibility(): void {
    if (this.content_data.column_hide) {
      const variableValue = this._varStore.getBoolVariableValue(this.content_data.column_hide);
      this._setVisibility(variableValue);
    }
  }

  /**
   * Manages the disabled state of the element/control based on the variables2 configuration.
   */
  private _listenVariableChangesAndDisable(variable: Variable) {
    if (this.content_data.column_disabled === variable.key) {
      this._setStatus(variable.value as boolean)
    }
  }

  /**
   * Manages the visibility state of the element/control based on the variables2 configuration.
   */
  private _listenVariableChangesAndHide(variable: Variable) {
    if (this.content_data.column_hide  === variable.key) {
      this._setVisibility(variable.value as boolean);
    }
  }

  private _setVisibility(variableValue: boolean) {
    const inputs = this.content_data.options.control_name
      ? this.content_data.options.control_name
      : this.content_data.options.config.control_names;
    this.visible = variableValue;

    if (this.visible) {
      this._addValidators(inputs);
    } else {
      this._cleanValidators(inputs);
    }
  }

  private _setStatus(variableValue: boolean) {
    const input = this.parentGroup.get(this.content_data.options.control_name);
    if (!variableValue) {
      input.enable();
      if (this.content_data.options.validators) {
        input.addValidators(this._formValidatorService.getValidators(this.content_data.options.validators));
        input.updateValueAndValidity();
      }
    } else {
      input.disable();
      input.clearValidators();
    }
  }

  private _addValidators(inputsName: string | { [key: string]: string }) {
    if (typeof inputsName === 'object') {
      Object.keys(inputsName).forEach((input) => {
        const inputToValidate = this.parentGroup.get(inputsName[input]);
        if (inputToValidate) {
          inputToValidate.addValidators(this._formValidatorService.getValidators(this.content_data.validators));
          inputToValidate.updateValueAndValidity();
        }
      });
    } else {
      const inputToValidate = this.parentGroup.get(inputsName);
      inputToValidate.addValidators(this._formValidatorService.getValidators(this.content_data.validators));
      inputToValidate.updateValueAndValidity();
    }
  }

  private _cleanValidators(inputs: string | { [key: string]: string }) {
    if (typeof inputs === 'object') {
      Object.keys(inputs).forEach((input) => {
        const inputToValidate = this.parentGroup.get(inputs[input]);
        if (inputToValidate) {
          inputToValidate.removeValidators(this.content_data.validators);
          inputToValidate.clearValidators();
          inputToValidate.updateValueAndValidity();
        }
      });
    } else {
      const inputToValidate = this.parentGroup.get(inputs);
      inputToValidate.removeValidators(this.content_data.validators);
      inputToValidate.clearValidators();
      inputToValidate.updateValueAndValidity();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
