import { Component, OnDestroy, OnInit, Renderer2, RendererFactory2, RendererStyleFlags2 } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-loading',
  styleUrls: ['./loading.component.scss'],
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit, OnDestroy {
  public loading$ = this._sessionQuery.loading$;
  public loadingMessage$ = this._sessionQuery.loadingMessage$;
  private _renderer: Renderer2;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _sessionQuery: SessionQuery, private _rendererFactory: RendererFactory2) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this._renderer = this._rendererFactory.createRenderer(null, null);
    this.loading$.pipe(takeUntil(this._unsubscribe$)).subscribe(loading => {
      if (loading) {
        this._renderer.setStyle(document.body, 'overflow', 'hidden', RendererStyleFlags2.Important);
      } else {
        this._renderer.removeStyle(document.body, 'overflow');
      }
    });
  }
}
