// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  .multi-select-wrapper {
    position: relative;
    z-index: 3;
    one-tooltip-adapter {
      position: absolute;
      top: var(--small);
      right: 0px;
    }
  }
  `, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-dynamic-components/inputs/multi-select/multi-select-adapter.component.ts"],"names":[],"mappings":";EACE;IACE,kBAAkB;IAClB,UAAU;IACV;MACE,kBAAkB;MAClB,iBAAiB;MACjB,UAAU;IACZ;EACF","sourcesContent":["\n  .multi-select-wrapper {\n    position: relative;\n    z-index: 3;\n    one-tooltip-adapter {\n      position: absolute;\n      top: var(--small);\n      right: 0px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
