// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-subtle {
  background: linear-gradient(to right, var(--secondary-base), var(--secondary-base)), linear-gradient(to right, var(--color-transparent), var(--color-transparent));
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px, 0 2px;
  transition: background-size 0.3s ease;
}

.btn-subtle:hover {
  background-size: 0 2px, 100% 2px;
}

.sidebar-container {
  align-self: flex-start;
  top: 80px;
}`, "",{"version":3,"sources":["webpack://./libs/feature/risk-analysis/src/lib/components/product-recommendations/product-recommendations.component.scss"],"names":[],"mappings":"AAAA;EAEI,kKAAA;EACA,sCAAA;EACA,4BAAA;EACA,gCAAA;EACA,qCAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AAEA;EACI,sBAAA;EACA,SAAA;AACJ","sourcesContent":[".btn-subtle {\n    background: -webkit-gradient(linear,left top, right top,from(var(--secondary-base)),to(var(--secondary-base))),-webkit-gradient(linear,left top, right top,from(var(--color-transparent)),to(var(--color-transparent)));\n    background: linear-gradient(to right,var(--secondary-base),var(--secondary-base)),linear-gradient(to right,var(--color-transparent),var(--color-transparent));\n    background-position: 100% 100%,0 100%;\n    background-repeat: no-repeat;\n    background-size: 100% 2px,0 2px;\n    transition: background-size .3s ease;\n}\n\n.btn-subtle:hover {\n    background-size: 0 2px,100% 2px;\n}\n\n.sidebar-container {\n    align-self: flex-start;\n    top: 80px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
