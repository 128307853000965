// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wf-c-toaster {
  z-index: 999999;
}

.wf-c-toaster__body {
  background-color: var(--neutral-start);
  width: 707px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-toaster/components/wg-toaster/wg-toaster.component.scss"],"names":[],"mappings":"AACA;EACE,eAAA;AAAF;;AAGA;EACE,sCAAA;EACA,YAAA;AAAF","sourcesContent":["\n.wf-c-toaster {\n  z-index: 999999;\n}\n\n.wf-c-toaster__body {\n  background-color: var(--neutral-start);\n  width: 707px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
