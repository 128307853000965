import { Injectable } from '@angular/core';
import { DynamicFormSection } from '@wefoxGroupOneBPCore/interfaces/dynamic-forms.interface';
import { StepState } from '@wefoxGroupOneBPShared/components';
import { StepStatus } from 'aws-sdk/clients/sagemaker';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface StepModel {
  currentStep: number;
  isLastStep: boolean;
  label?: string;
  navigationDisabled?: boolean;
  sectionName: string;
  status: string;
  visible: boolean;
  stepIndex: number;
  hideFinalStepBtn: boolean;
}

export interface SectionModel {
  name: string;
}

@Injectable()
export class StepService {
  public currentStep$ = new BehaviorSubject<StepModel>(null);
  public steps$ = new BehaviorSubject<StepModel[]>([]);

  public getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  public getSteps(): Observable<StepModel[]> {
    return this.steps$.pipe(map(steps => steps.filter(s => s.visible)));
  }

  public initStepsFromSections(
    formSections: DynamicFormSection[],
    labelPrefix: string,
    disableIndex?: number,
    disableClicks?: boolean,
    hideFinalStepBtn?: boolean
  ): void {
    const steps = [];
    const uniqueSections = [...new Set(formSections?.map(item => item.content_data.step_count))];

    uniqueSections.forEach((section, index) => {
      const disableNaviation = disableClicks ? true : disableIndex === undefined ? false : index > disableIndex;
      steps.push({
        currentStep: index + 1,
        isLastStep: uniqueSections.length === index + 1,
        label: `${labelPrefix}${section}`,
        navigationDisabled: disableNaviation,
        sectionName: formSections[index].type,
        status: index === 0 ? StepState.active : StepState.pending,
        visible: true,
        stepIndex: section,
        hideFinalStepBtn: hideFinalStepBtn ?? false
      });
    });

    this.steps$.next(steps);
    this.currentStep$.next(steps[0]);
  }

  public initSteps(sections: SectionModel[], labelPrefix: string, disableIndex?: number, hideFinalStepBtn?: boolean): void {
    const steps = [];
    sections.forEach((section, index) => {
      steps.push({
        currentStep: index + 1,
        isLastStep: sections.length === index + 1,
        label: `${labelPrefix}${section.name}`,
        navigationDisabled: disableIndex === undefined ? false : index > disableIndex,
        sectionName: sections[index].name,
        status: index === 0 ? StepState.active : StepState.pending,
        visible: true,
        stepIndex: index + 1,
        hideFinalStepBtn: hideFinalStepBtn && (sections.length - 1 === index + 1) ? hideFinalStepBtn : false
      });
    });

    this.steps$.next(steps);
    this.currentStep$.next(steps[0]);
  }

  public changeStepVisibility(index: number, value: boolean): void {
    const newSteps = this.steps$.value;
    const step = newSteps.find(s => s.stepIndex === index);
    step.visible = value;
    newSteps.forEach(s => (s.isLastStep = this._isLastStep(s.stepIndex)));
    this.steps$.next(newSteps);
  }

  /**
   *
   * @param  { DynamicFormSection[] } formSections An array of DynamicFormSection fronm the model
   * @param  { string }  sectionId ID of the section we want to retrieve 'section_id' in the content_data
   * @returns { number } return the step_count (step index) of the section
   */
  public getSectionStepCount(formSections: DynamicFormSection[], sectionId: string): number {
    return formSections.find(sect => sect.content_data.section_id === sectionId).content_data.step_count;
  }

  private _isLastStep(index): boolean {
    return index === this.steps$.value.filter(s => s.visible).length;
  }

  public moveToNextStep(status?: StepStatus): void {
    const index = this.currentStep$.value.currentStep;
    const validStep = this.steps$.value.find(s => s.currentStep === index + 1);
    if (status !== undefined) {
      this.currentStep$.value.status = status;
    }
    if (validStep) {
      this.currentStep$.next(validStep);
    }
  }

  public moveToPreviousStep(): void {
    if (this.currentStep$.value.currentStep > 1) {
      const index = this.currentStep$.value.currentStep - 1;
      const previousStep = this.steps$.value.find(s => s.currentStep === index);
      this.currentStep$.next(previousStep);
    }
  }

  public setStepStatus(status: StepStatus): void {
    this.currentStep$.value.status = status;
  }

  public setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }
}
