import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SourceValue } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-gender-selector',
  templateUrl: './gender-selector.component.html'
})
export class GenderSelectorComponent implements OnDestroy, OnInit {
  @Input() public controlName: string;
  public genderOptions: WgDropdownOption[];
  public genderValues: SourceValue[];
  @Input() public hasAdditionalGender = false;
  @Input() public parentGroup: UntypedFormGroup;

  private _currentLocale = this._sessionQuery.getCurrentLocale();
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _formService: FormService,
    private _sessionQuery: SessionQuery
  ) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.genderValues = this.hasAdditionalGender ? this._getWithAdditionalGender() : this._getGenderValues();
    this.genderOptions = this._formService.translateSourceValues(this.genderValues);
    this._monitorLocaleChanges();
  }

  private _getGenderValues(): SourceValue[] {
    // For use in public pages
    return [
      { key: '0', value: '_PROD_gender_values_female' },
      { key: '1', value: '_PROD_gender_values_male' }
    ];
  }

  private _getWithAdditionalGender(): SourceValue[] {
    return [
      { key: 'w', value: '_PROD_gender_values_female' },
      { key: 'm', value: '_PROD_gender_values_male' },
      { key: 'd', value: '_PROD_gender_values_diverse' }
    ];
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(locale => {
      if (this._currentLocale !== locale) {
        this.genderOptions = this._formService.translateSourceValues(this.genderValues);
        this._updateTranslation();
        this._currentLocale = locale;
      }
    });
  }

  private _updateTranslation(): void {
    this._formService.updateTranslations(this.parentGroup, this.controlName, this.genderValues);
  }
}
