import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[oneNumericTransform]'
})
export class NumericTransformDirective implements OnInit {
  @Input() public transformTo: string;
  private _transformValue: number;

  constructor(private elementRef: ElementRef, private ngControl: NgControl) {}

  public ngOnInit(): void {
    // FIXME refactor pattern from (private module) ProductValidations.integerPattern and make it shared
    // This directive has to be move to private/product/components/km-mileage
    const regex = RegExp('^([0-9]*)$');
    this._transformValue = this.transformTo === 'thousand' ? 1000 : 1;

    this.ngControl.control.valueChanges.subscribe(() => {
      if (regex.test(this.elementRef.nativeElement.value)) {
        const rawValue = this.elementRef.nativeElement.value;
        const thousandTransform = rawValue * this._transformValue;
        this.ngControl.control.setValue(thousandTransform, {
          emitEvent: false,
          emitModelToViewChange: false,
          emitViewToModelChange: false
        });
      }
    });
  }
}
