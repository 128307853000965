import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Pipe({ name: 'getValue' })
export class GetValuePipe implements PipeTransform {
  public transform(controlName: string, parentGroup: UntypedFormControl): string {
    const control = parentGroup.get(controlName);
    if (control?.value?.hasOwnProperty('value')) {
      // dropdown case
      return control.value.value;
    }
    return control?.value ? control.value : '-';
  }
}

@Component({
  selector: 'one-summary-section-adapter',
  templateUrl: './summary-section-adapter.component.html'
})
export class SummarySectionAdapterComponent {
  @Input() public content_data: any; // eslint-disable-line
  public modifier_class: string;
  @Input() public parentGroup: UntypedFormGroup;
}
