import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { COUNTRIES, LOCALES } from '@wefoxGroupOneBPCore/constants';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { MasterDataDefinition } from '@wefoxGroupOneBPCore/interfaces';
import { AccountStore } from '@wefoxGroupOneBPCore/interfaces/account.store';
import { CMSConfig } from '@wefoxGroupOneBPCore/interfaces/cms-config.interface';
import { BaseDataService } from '@wefoxGroupOneBPCore/services/base-data.service';
import { SessionService } from '@wefoxGroupOneBPCore/services/session.service';
import { CMSStore } from '@wefoxGroupOneBPCore/stores/cms.store';
import { CommonStore } from '@wefoxGroupOneBPCore/stores/common.store';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CentralizedQuery, SessionQuery } from '../queries';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService extends BaseDataService {
  private _basicAuthToken = environment.basicAuthToken;
  private _resource = 'main-config/public/country';
  private _cmsURL = 'cms/home';

  constructor(
    protected httpClient: HttpClient,
    protected sessionQuery: SessionQuery,
    private _commonDataStore: CommonStore,
    private _cmsDataStore: CMSStore,
    private _accountStore: AccountStore,
    private _session: SessionService,
    private _centralizedQuery: CentralizedQuery
  ) {
    super(httpClient, sessionQuery);
  }

  public getData(countryCode: COUNTRIES): Observable<MasterDataDefinition> {
    this._centralizedQuery.setActiveQuery(this._centralizedQuery.LEGACY_QUERY);
    const url = this.getUrlByCountry(
      {
        path: `${this._resource}/${countryCode}`
      },
      countryCode
    );

    const options: RequestInit = {
      headers: {
        Authorization: `Basic ${this._basicAuthToken}`,
        'Content-Type': 'application/json'
      }
    };

    return this.get$(url, options).pipe(
      tap((masterData: MasterDataDefinition) => {
        this._commonDataStore.update({ ...masterData });
        this._session.setLocale(masterData.default_language);
      })
    ) as Observable<MasterDataDefinition>;
  }

  public getCMSConfig(accessToken?: string): Observable<CMSConfig> {
    this._centralizedQuery.setActiveQuery(this._centralizedQuery.CMS_QUERY);

    const options: RequestInit = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    if (accessToken) {
      options['Authorization'] = accessToken;
    }
    const configType = !!accessToken ? 'private' : 'public';
    const url = `${environment.termLifeApiUrl}/api/cms/${configType}/intl/home`;
    return this.get$(url, options).pipe(
      map((cmsConfig: CMSConfig) => {
        const sidebarLinks = this.adaptSidebarLinks(cmsConfig.sidebarLinks);
        const routes = (cmsConfig.routes = [...cmsConfig.routes, { value: 'appointment-scheduler', status: 'Active' }]);
        return { ...cmsConfig, country_code: 'INTL', default_language: LOCALES.en, sidebarLinks, routes };
      }),
      tap((cmsConfig: CMSConfig) => {
        this._cmsDataStore.update({ ...cmsConfig });
        const defaultLanguage = cmsConfig.default_language;
        this._session.setLocale(defaultLanguage);
        if (cmsConfig.broker) {
          this._accountStore.update({
            email: cmsConfig.broker.emails[0].email,
            firstname: cmsConfig.broker.first_name,
            lastname: cmsConfig.broker.last_name,
            id: cmsConfig.broker.id
          });
        }
      })
    );
  }

  public adaptSidebarLinks(sidebarLinks = []) {
    return sidebarLinks.map(link => this.adaptLink(link));
  }

  public adaptLink(link) {
    if (link.options?.length > 0) {
      const optionsMapped = link.options.map(option => this.adaptLink(option));
      return { ...this.mapOption(link), options: optionsMapped };
    } else {
      return this.mapOption(link);
    }
  }

  public mapOption(option) {
    return {
      name: option.name,
      icon: option.icon,
      label: option.label,
      requires_broker_id: option.requiresBrokerId,
      router_link: option.routerLink || 'dynamic-journey',
      bottom_divider: option.bottomDivider,
      disabled: option.disabled,
      test_id: option.testId,
      action_type: option.actionType,
      journey_type: option.journeyType,
      base_url: option.baseUrl
    };
  }
}
