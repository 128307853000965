// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .item-list {
        width: 100%;
        max-width: 30rem;
        list-style-type: none;
      }

      .bullet {
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 100%;
        margin-right: 0.375rem;
      }
    `, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/dashboard/lead-prices/lead-prices.components.ts"],"names":[],"mappings":";MACM;QACE,WAAW;QACX,gBAAgB;QAChB,qBAAqB;MACvB;;MAEA;QACE,eAAe;QACf,gBAAgB;QAChB,mBAAmB;QACnB,sBAAsB;MACxB","sourcesContent":["\n      .item-list {\n        width: 100%;\n        max-width: 30rem;\n        list-style-type: none;\n      }\n\n      .bullet {\n        width: 0.375rem;\n        height: 0.375rem;\n        border-radius: 100%;\n        margin-right: 0.375rem;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
