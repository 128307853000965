export const VEHICLE_CHECKBOX_GROUP_VALUE = {
    vehicle_type_PKW: 'PKW', //Car
    vehicle_type_KRA: 'KRA', //Motorcicle
    vehicle_type_LKW: 'LKW', //truck
    vehicle_type_kein: '999' //other
}

export const VEHICLE_CHECKBOX_GROUP_KEY = {
    vehicle_type_PKW: 'car',
    vehicle_type_KRA: 'motorcycle',
    vehicle_type_LKW: 'truck',
    vehicle_type_kein: 'other'
}

export const FINANCIAL_SECURITY_PREF_CHECKBOX_GROUP = {
    low_incomegaps: 'low-incomegaps',
    independence_family: 'independence-family',
    independence_state: 'independence-state',
    saving_for_goals: 'saving-for-goals'
}

export const HEALTHCARE_PREF_CHECKBOX_GROUP = {
    short_waitingtimes: 'short-waitingtimes',
    dental_healthcare: 'dental-healthcare',
    choice_of_doctor: 'choice-of-doctor',
    modern_equipment: 'modern-equipment',
    second_opinion: 'second-opinion',
}

export const INCOME_DEPENDING_CHECKBOX_GROUP = {
    income_depending_partner: 'partner',
    income_depending_parents: 'parent',
    income_depending_children: 'child'
}

export const PET_CHECKBOX_GROUP = {
    dog: "dog",
    cat: "cat",
    other: "other"
}
