import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { PERMISSIONS } from '@wefoxGroupOneBPCore/constants/auth.constants';
import {
  AllListInfo,
  DynamicTableColumnHeader,
  DynamicTableConfigParsed
} from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import {
  Contract,
  ContractIT,
  ContractMapped,
  ContractMappedIT
} from '@wefoxGroupOneBPPrivate/modules/contracts/interfaces/contracts.interface';
import { ContractsService } from '@wefoxGroupOneBPPrivate/modules/contracts/services/contracts.service';
import { ListCategory, PAGE_DATA_DEFAULTS } from '@wefoxGroupOneBPPrivate/product/constants/list.constant';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { Observable } from 'rxjs/internal/Observable';
import { map, tap } from 'rxjs/operators';
import { FksBackgroundProcessModalComponent } from '../../../account/components/fks-background-process-modal/fks-background-process-modal.component';
import { ExternalSite } from '../../../account/interfaces/external-site.interface';
import { LoginToExternalSiteModalComponent } from '../../../account/login-to-external-site-modal/login-to-external-site-modal.component';
import { AccountService } from '../../../account/services/account.service';
import { AuthenticationService } from '../../../auth/services/authentication.service';
import { ThirdPartyService } from '../../../carriers/services/third-party.service';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'one-contract-dashboard',
  templateUrl: 'contract-dashboard.component.html',
  styleUrls: []
})
export class ContractDashboardComponent implements OnInit {
  @Input() config: DynamicTableConfigParsed;

  public hasCustomerPermission: boolean;
  public isExclusiveAdvisor: boolean;
  public currentCountry: string;
  public COUNTRY_IT: string = COUNTRIES.it;
  public listOfColumns: DynamicTableColumnHeader[];
  public loading: boolean;
  public pageData = PAGE_DATA_DEFAULTS;
  public defaultPageSize = 6;
  public feedbackConfig = null;
  public fksConfig: ExternalSite;
  public locale = 'de';

  constructor(
    private _auth: AuthenticationService,
    private _contractsService: ContractsService,
    private _pageDetailsService: PageDetailsService,
    private _thirdPartyService: ThirdPartyService,
    private _accountService: AccountService,
    private _sessionQuery: SessionQuery,
    private _router: Router,
    private _modal: WgModalService
  ) {
    this.currentCountry = this._sessionQuery.getCountry();
    this.locale = this._sessionQuery.getCurrentLocale();
  }

  public navigateToContracts(): void {
    this._router.navigate([
      `${this._sessionQuery.getCountryPrefix()}/contracts/${this._sessionQuery.getBrokerPath()}${
        this.isExclusiveAdvisor ? '/third-party' : ''
      }`
    ]);
  }

  public loadData = (
    currentPage: number,
    pageSize: number,
    sortOrder: string | null,
    searchBarValue: string | null,
    sort: Array<string> | null
  ): Observable<AllListInfo> => {
    const sortField = sort.join(',');
    const pageIndex = currentPage + 1;

    const request$ =
      this.currentCountry === this.COUNTRY_IT
        ? this._getContractsIT(pageIndex, pageSize, sortField, sortOrder, [])
        : this.isExclusiveAdvisor
        ? this._getThirdPartyContracts(pageIndex)
        : this._getContractsDefault(pageIndex, pageSize, sortField, sortOrder, []);

    return request$.pipe(tap((response: AllListInfo) => this._updatePageData(response)));
  };

  public ngOnInit(): void {
    this.getTableData();
  }

  public getTableData(): void {
    this.hasCustomerPermission = this._auth.hasPermissions([PERMISSIONS.CUSTOMER]);
    this.isExclusiveAdvisor =
      this._sessionQuery.getCountry() === COUNTRIES.de && this._auth.hasPermissions([PERMISSIONS.EXCLUSIVE_ADVISOR]);

    if (this.isExclusiveAdvisor) {
      this._accountService.getBrokerDeExternalSites$().subscribe((externalSites: ExternalSite[]) => {
        this.fksConfig = externalSites.find((site: ExternalSite) => site.external_tool.id === 'fks');
      });
      const hasConsent = this._thirdPartyService
        .getFksConsent$(this._sessionQuery.getBrokerId())
        .pipe(map(consentArray => consentArray.length > 0 && consentArray[0].consent_approval));

      hasConsent.subscribe((consent: boolean) => {
        if (consent) {
          this.listOfColumns = this._translateData(this.config.column_headers);
          this.feedbackConfig = null;
        } else {
          this.feedbackConfig = { type: 'consent', icon: 'info', callback: true };
        }
      });
    } else {
      this.listOfColumns = this._translateData(this._checkPermissions());
    }
  }

  private _translateData(data: DynamicTableColumnHeader[]): DynamicTableColumnHeader[] {
    data = data.map(col => {
      col.headerLabel = translate(col.headerLabel);
      if (col.filterConfig) {
        col.filterConfig.options = col.filterConfig.options.map(option => ({
          ...option,
          label: translate(option.label)
        }));
      }
      return col;
    });

    return data;
  }

  public transformData = (data: Contract[] | ContractIT[]): Partial<ContractMapped>[] | ContractMappedIT[] => {
    return data.map((element: Contract | ContractIT) => {
      const item = {
        ...this._pageDetailsService.transformTableData(
          this._pageDetailsService.getTableRowItems([this.config], ListCategory.contracts),
          this._pageDetailsService.mapListToHeader([this.config], element, ListCategory.contracts)
        ),
        original_data: element
      };
      Array.of('product_type', 'origin', 'tariff')
        .filter(col => item[col])
        .forEach(col => (item[col] = translate(item[col])));

      return item;
    });
  };

  public onFeedbackClick(type: string): void {
    switch (type) {
      case 'consent':
        this.openFksModal();
        break;
      case 'processing':
        this.getTableData();
        break;
    }
  }

  public openFksModal() {
    this._modal.setAndShow({
      contentView: LoginToExternalSiteModalComponent,
      contentViewOptions: {
        options: {
          action: 'FORCE_CREATE',
          external_tool: this.fksConfig,
          buttonAction: () => {
            setTimeout(() => this.openFksBackgroundProcessModal());
          }
        }
      }
    });
  }

  public openFksBackgroundProcessModal() {
    this._modal.setAndShow({
      contentView: FksBackgroundProcessModalComponent,
      contentViewOptions: {
        options: {
          buttonAction: () => this.getTableData()
        }
      }
    });
  }

  private _getContractsDefault(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
  ) {
    const onlyOwnCustomers = true;
    return this._contractsService.getFilteredContracts$(
      pageIndex,
      this.defaultPageSize,
      sortField,
      sortOrder,
      filters,
      onlyOwnCustomers,
      this.hasCustomerPermission
    );
  }

  private _getThirdPartyContracts(pageIndex: number) {
    return this._contractsService.getThirdPartyContracts$(pageIndex, this.defaultPageSize).pipe(
      tap((response: AllListInfo) => {
        this._updatePageData(response);
        if (response?.content?.length === 0) {
          this.feedbackConfig = { type: 'processing', icon: 'stopwatch', callback: true };
        } else {
          this.feedbackConfig = null;
        }
      })
    );
  }

  private _getContractsIT(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
  ) {
    return this._contractsService.getPoliciesIT$(pageIndex, this.defaultPageSize, sortField, sortOrder, filters);
  }

  private _checkPermissions() {
    if (this.currentCountry !== this.COUNTRY_IT && !this.isExclusiveAdvisor) {
      return this.config.column_headers.filter(column => {
        return this.hasCustomerPermission && this.currentCountry !== this.COUNTRY_IT
          ? column.cell.displayKey !== 'creation_date'
          : column.cell.displayKey !== 'broker_name';
      });
    }
    return this.config.column_headers;
  }

  private _updatePageData(response: AllListInfo): void {
    this.pageData = response?.content?.length ? this._pageDetailsService.updatePageData(response) : PAGE_DATA_DEFAULTS;
  }
}
