import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WgTooltipDirective } from './directives/wg-tooltip.directive';
import { WgTooltipComponent } from './wg-tooltip.component';

@NgModule({
  declarations: [WgTooltipDirective, WgTooltipComponent],
  exports: [WgTooltipDirective, WgTooltipComponent],
  imports: [CommonModule, OverlayModule, PortalModule, PlatformModule]
})
export class WgTooltipModule {}
