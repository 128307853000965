import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { AuthenticationService } from '@wefoxGroupOneBP/app/private/modules/auth/services/authentication.service';
import { PERMISSIONS } from '@wefoxGroupOneBPCore/constants/auth.constants';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import {
  OriginTranslationsMapping,
  ProductType,
  ProductTypeTranslations,
  StatusContractsKey,
  TariffTypeTranslations
} from '@wefoxGroupOneBPPrivate/modules/contracts/constants/contracts.constants';
import { Contract, ContractMapped } from '@wefoxGroupOneBPPrivate/modules/contracts/interfaces/contracts.interface';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { DateFormat } from '@wefoxGroupOneBPShared/constants';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable } from 'rxjs';
import { ContractsDataService } from './contracts-data.service';

@Injectable({ providedIn: 'root' })
export class ContractsService {
  private viewHasTabsSubject = new BehaviorSubject(false);
  public readonly viewHasTabs: Observable<boolean> = this.viewHasTabsSubject.asObservable();

  constructor(
    private _auth: AuthenticationService,
    private _contractsData: ContractsDataService,
    private _pageDetailsService: PageDetailsService
  ) { }

  public setViewHasTabs(hasTabs: boolean): void {
    this.viewHasTabsSubject.next(hasTabs);
  }

  // eslint-disable-next-line
  public downloadApplicationZipFiles$(applicationId: string): Observable<any> {
    return this._contractsData.downloadApplicationZipFiles$(applicationId);
  }

  public getAllContracts$(
    search?: string,
    page = 1,
    size?: number,
    sort?: string,
    dateType?: string,
    tariffType?: string,
    ownType?: string
  ): Observable<AllListInfo> {
    return this._contractsData.getAllContracts$(search, page, size, sort, dateType, tariffType, ownType);
  }

  public getThirdPartyContracts$(
    page = 1,
    size?: number,
    sort?: string,
    customerName?: string,
    insurer?: string
  ): Observable<AllListInfo> {
    return this._contractsData.getThirdPartyContracts$(page, size, sort, customerName, insurer);
  }

  public getTermLifePolicies$(brokerId, size): Observable<AllListInfo> {
    return this._contractsData.getTermLifePolicies$(brokerId, size);
  }

  public getFilteredContracts$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    onlyOwnCustomers: boolean,
    hasCustomerPermission: boolean
    // eslint-disable-next-line
  ): Observable<any> {
    return this._contractsData.getFilteredContracts$(
      pageIndex,
      pageSize,
      sortField,
      sortOrder,
      filters,
      onlyOwnCustomers,
      hasCustomerPermission
    );
  }

  public getFilteredPolicies$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
    // eslint-disable-next-line
  ): Observable<any> {
    return this._contractsData.getFilteredPolicies$(pageIndex, pageSize, sortField, sortOrder, filters);
  }

  public getPoliciesIT$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
    // eslint-disable-next-line
  ): Observable<any> {
    return this._contractsData.getPoliciesIT$(pageIndex, pageSize, sortField, sortOrder, filters);
  }

  public mapContractsListToTable(contracts: Contract[]): Partial<ContractMapped>[] {
    return contracts.map((contract: Contract) => {
      const statusBadge = this._pageDetailsService.getStatusBadgeColor(contract.status);
      const contentContract: Partial<ContractMapped> = {
        claims_number: contract.claims_number,
        contract_number: contract.contract_number,
        contract_numberEnabled: true,
        creation_date: `${dayjs(contract.start_date).format(DateFormat.default)}`,
        id: contract.id,
        isSelectable: true,
        legal_name: contract.customer_name,
        origin: OriginTranslationsMapping[contract.origin] ?? '-',
        product: ProductTypeTranslations[contract.product],
        product_type:
          contract.sub_product && contract.product !== ProductType.motor
            ? translate(ProductTypeTranslations[contract.sub_product])
            : translate(ProductTypeTranslations[contract.product]),
        status: translate(StatusContractsKey[contract.status]),
        tariff: translate(TariffTypeTranslations[contract.tariff]),
        statusBadge
      };

      if (this._auth.hasPermissions([PERMISSIONS.CUSTOMER])) {
        contentContract.broker_name = contract.sold_by;
      }
      return contentContract;
    });
  }
}
