import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';

// Supported locales, applicable to Angular and Moment.js localization
export enum LOCALES {
  de = 'de',
  en = 'en',
  es = 'es',
  fr = 'fr',
  it = 'it',
  ja = 'ja'
}

// eslint-disable-next-line
export const ANGULAR_LOCALE_DATA: { [id in LOCALES]: any } = {
  [LOCALES.de]: localeDe,
  [LOCALES.en]: localeEn,
  [LOCALES.es]: localeEs,
  [LOCALES.fr]: localeFr,
  [LOCALES.it]: localeIt,
  [LOCALES.ja]: localeJa
};
