import { Component, Input } from '@angular/core';
import { CardListType } from '../../constants/card.constants';

@Component({
  selector: 'wg-card-body-list',
  styleUrls: ['./card-body-list.component.scss'],
  templateUrl: './card-body-list.component.html'
})
export class CardBodyListComponent {
  public cardListType = CardListType;
  @Input() public list = [];
  @Input() public listType = CardListType.row;
}
