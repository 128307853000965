import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TariffCustomer } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import { NoteCategoriesTranslations } from '@wefoxGroupOneBPShared/constants/customer.constant';
import { Customer } from '@wefoxGroupOneBPShared/interfaces/index';
import * as dayjs from 'dayjs';

@Component({
  selector: 'one-customer-info-preview',
  templateUrl: './customer-info-preview.component.html'
})
export class CustomerInfoPreviewComponent implements OnInit {
  @Output() public backToEditEmitter: EventEmitter<string> = new EventEmitter<string>();
  public customer: Partial<Customer>;
  @Input() public customerData: TariffCustomer;
  @Input() public isEditable = true;
  @Input() public isLegalEntity = false;
  @Input() public title: string;

  public backToEdit(tag: string): void {
    this.backToEditEmitter.emit(tag);
  }

  public ngOnInit(): void {
    this.customer = {
      birthdate: dayjs(this.customerData.birthdate).format('DD.MM.YYYY'),
      city: this.customerData.address.place,
      email: this.customerData.email,
      first_name: this.customerData.first_name,
      gender: NoteCategoriesTranslations[this.customerData.gender],
      house_number: this.customerData.address.house_number,
      last_name: this.customerData.last_name,
      legal_name: this.customerData.legal_name,
      phone_number: this.customerData.phone_number,
      postal_code: this.customerData.address.postal_code,
      street: this.customerData.address.street
    };
  }
}
