import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';
import { InputRefDirective } from '@wefoxGroupOneBPShared/modules/wg-input/directives/input-ref.directive';

@Component({
  selector: 'wg-km-mileage',
  styleUrls: ['./wg-km-mileage.component.scss'],
  templateUrl: './wg-km-mileage.component.html'
})
export class WgKmMileageComponent implements AfterViewInit, AfterContentInit {
  @Input() public controlName: string;
  public disabled: boolean;
  // eslint-disable-next-line
  @Input() public set errors(err) {
    this._errorMap = err;
  }
  @Input() public maskLabel: string;
  @Input() public maskNumberLabel = '.000';
  protected _inputControl: AbstractControl;
  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _errorMap: { [key: string]: string };
  private _formControlName: string;
  @ContentChild(InputRefDirective) private _inputChild: InputRefDirective;

  constructor(private _controlContainer: FormGroupDirective) {}

  public focusOnInput(): void {
    if (this._inputChild) {
      setTimeout(() => {
        this._inputChild.el.nativeElement.parentElement.classList.add('-focus');
        this._inputChild.el.nativeElement.focus();
      }, 0);
    }
  }

  public focusOutInput(): void {
    if (this._inputChild) {
      this._inputChild.el.nativeElement.parentElement.classList.remove('-focus');
    }
  }

  public getCurrentError(): { className: string, errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this._inputControl.errors || (this._inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: 'one-field-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this._inputControl.errors)[0]]) {
      return {
        className: 'one-field-invalid',
        errorMessage: this._errorMap[Object.keys(this._inputControl.errors)[0]]
      };
    }
    return { className: 'one-field-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this._formControlName = this._inputChild.el.nativeElement.getAttribute('formControlName');
    const control = this._formControlName ? this._formControlName : this.controlName;
    this._inputControl = this._controlContainer.control.get(control);
    this._inputControl.valueChanges.subscribe((value: string) => {
      if (+value <= 0) {
        this.maskNumberLabel = '';
      }

      if (+value > 0 && this.maskNumberLabel === '') {
        this.maskNumberLabel = '.000';
      }
    });
    this.disabled = this._inputControl.disabled;
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });
  }
}
