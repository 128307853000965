import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon.module';
import { WgTableComponent } from './wg-table.component';

@NgModule({
  declarations: [WgTableComponent],
  exports: [WgTableComponent],
  imports: [CommonModule, WgUiIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WgTableModule {}
