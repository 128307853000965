export const IbanLengthByCountry = {
  AD: 24,
  AE: 23,
  AL: 28,
  AO: 25,
  AT: 20,
  AX: 18,
  AZ: 28,
  BA: 20,
  BE: 16,
  BF: 28,
  BG: 22,
  BH: 22,
  BI: 16,
  BJ: 28,
  BL: 27,
  BR: 29,
  BY: 28,
  CF: 27,
  CG: 27,
  CH: 21,
  CI: 28,
  CM: 27,
  CR: 22,
  CV: 25,
  CY: 28,
  CZ: 24,
  DE: 22,
  DJ: 27,
  DK: 18,
  DO: 28,
  DZ: 26,
  EA: 24,
  EE: 20,
  EG: 29,
  ES: 24,
  FI: 18,
  FO: 18,
  FR: 27,
  GA: 27,
  GB: 22,
  GE: 22,
  GF: 27,
  GG: 22,
  GI: 23,
  GL: 18,
  GP: 27,
  GQ: 27,
  GR: 27,
  GT: 28,
  GW: 25,
  HN: 28,
  HR: 21,
  HU: 28,
  IC: 24,
  IE: 22,
  IL: 23,
  IM: 22,
  IQ: 23,
  IR: 26,
  IS: 26,
  IT: 27,
  JE: 22,
  JO: 30,
  KM: 27,
  KW: 30,
  KZ: 20,
  LB: 28,
  LC: 32,
  LI: 21,
  LT: 20,
  LU: 20,
  LV: 21,
  MA: 28,
  MC: 27,
  MD: 24,
  ME: 22,
  MF: 27,
  MG: 27,
  MK: 19,
  ML: 28,
  MQ: 27,
  MR: 27,
  MT: 31,
  MU: 30,
  MZ: 25,
  NC: 27,
  NE: 28,
  NI: 32,
  NL: 18,
  NO: 15,
  PF: 27,
  PK: 24,
  PL: 28,
  PM: 27,
  PS: 29,
  PT: 25,
  QA: 29,
  RE: 27,
  RO: 24,
  RS: 22,
  SA: 24,
  SC: 31,
  SE: 24,
  SI: 19,
  SK: 24,
  SM: 27,
  SN: 28,
  ST: 25,
  SV: 28,
  TD: 27,
  TF: 27,
  TG: 28,
  TL: 23,
  TN: 24,
  TR: 26,
  UA: 29,
  VA: 22,
  VG: 24,
  WF: 27,
  XK: 20,
  YT: 27
};

export const IbanLongestLength = Math.max(...(Object.values(IbanLengthByCountry) as any)); // eslint-disable-line

export const IbanPrefixLength = 2;
