import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateFormat, FormControlItem } from '@wefoxGroupOneBPShared/constants';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export interface StartDateInputAdapterComponentOptions {
  has_date_range_comparison: any; // eslint-disable-line
  control_name: string;
  format: string;
  mask?: string;
  greater_than?: StartDateAgeComparison;
  i18n_prefix_key: string;
  label: string;
  less_than?: StartDateAgeComparison;
  maximum_date?: {
    duration: number;
    type: any; // eslint-disable-line
  };
  placeholder?: string;
  required: boolean;
  same_day_policy?: boolean;
}

export interface StartDateAgeComparison {
  by_years: number;
  input_name: string;
}

@Component({
  selector: 'one-start-date-input-adapter',
  templateUrl: './start-date-input-adapter.component.html'
})
export class StartDateInputAdapterComponent implements OnInit {
  @Input() public content_data: StartDateInputAdapterComponentOptions;
  /**
   * By default, the start date must be at least tomorrow.
   */
  @Input() public dateGreaterThan = {
    default: dayjs().add(1, 'day'),
    error: '_PROD_start_date_greaterthan',
    value: dayjs().add(1, 'day').format(DateFormat.default)
  };

  /**
   * By default, the start date cannot be more than three years in the future.
   */
  @Input() public dateLessThan = {
    default: dayjs().add(3, 'year'),
    error: '_PROD_start_date_lessthan',
    value: dayjs().add(3, 'year').format(DateFormat.default)
  };

  @Input() public parentGroup: FormGroup;
  public productPrefix: string;
  private _controlName = FormControlItem.start_date.name;

  private _compareStartDateToAnother(): void {
    this._monitorStartDateChanges();
    this._monitorComparedToDateChanges();
  }

  /**
   * Get the date mask for the format DD.MM.YYYY
   * If the maximum date is anything other than the default of 3 years, then set the new max date
   * Check if start date needs to be compared to a birthdate
   */
  public ngOnInit(): void {
    this._checkForSameDayPolicy();
    this._checkForCustomMaxDate();

    if (this.content_data.has_date_range_comparison) {
      this._compareStartDateToAnother();
    } else {
      this._setDateLimits();
    }
  }

  private _checkForSameDayPolicy(): void {
    if (this.content_data.same_day_policy) {
      this.dateGreaterThan = {
        ...this.dateGreaterThan,
        default: dayjs(),
        value: dayjs().format(DateFormat.default)
      };
    }
  }

  private _checkForCustomMaxDate(): void {
    if (this.content_data.maximum_date) {
      this._setMaxDate(this.content_data.maximum_date);
    }
  }

  private _setDateLimits(): void {
    if (this.content_data.greater_than) {
      this._dateGreaterThanMonitor(this.content_data.greater_than);
    }

    if (this.content_data.less_than) {
      this._dateLessThanMonitor(this.content_data.less_than);
    }
  }

  private _getDateDiff(): void {
    const compareControl = this.content_data.has_date_range_comparison;
    const range = compareControl.range;
    const startDate = dayjs(this.parentGroup.get(this._controlName).value, DateFormat.default);
    const compareDate = dayjs(this.parentGroup.get(compareControl.control_name).value, DateFormat.default);
    const diff = startDate.diff(compareDate, range.interval, true);

    if (this.parentGroup.get(compareControl.control_name).value) {
      if (diff && diff <= range.max && diff >= range.min) {
        this.parentGroup.get(compareControl.control_name).setErrors(null);
        this.parentGroup.get(this._controlName).setErrors(null);
      } else {
        this.parentGroup.get(compareControl.control_name).setErrors({ dateGreaterThan: true });
        this.parentGroup.get(this._controlName).setErrors({ dateOutOfRange: true });
      }
    }
  }

  private _monitorStartDateChanges(): void {
    this.parentGroup.get(this._controlName).valueChanges.subscribe(val => {
      if (val) {
        this._getDateDiff();
        this._checkMaxDate();
        this._checkMinDate();
      }
    });
  }

  private _monitorComparedToDateChanges(): void {
    const compareControl = this.content_data.has_date_range_comparison;
    this.parentGroup.get(compareControl.control_name).valueChanges.subscribe(val => {
      if (val && this.parentGroup.get(this._controlName).value) {
        this._getDateDiff();
      }
    });
  }

  private _checkMaxDate(): void {
    const startDate = dayjs(this.parentGroup.get(this._controlName).value, DateFormat.default);
    const maxdateConfig = this.content_data.maximum_date;
    const maxdateDiff = startDate.diff(dayjs().startOf('day'), maxdateConfig?.type, true);

    if (maxdateDiff > maxdateConfig.duration) {
      this.parentGroup.get(this._controlName).setErrors({ dateLessThan: true });
    }
  }

  private _checkMinDate(): void {
    const startDate = dayjs(this.parentGroup.get(this._controlName).value, DateFormat.default);
    const minDateConfig = this.content_data.same_day_policy ? dayjs() : dayjs().add(1, 'day');
    const dateDiff = startDate.diff(minDateConfig.startOf('day'), 'day', true);

    if (dateDiff < 0) {
      this.parentGroup.get(this._controlName).setErrors({ pastDate: true });
    }
  }

  /**
   * Compares the start date to the birthdate of customer or policy holder
   * and sets a new minimum date based on the age.
   * Also sets the correct error key.
   *
   * @param {StartDateAgeComparison}
   */
  private _dateGreaterThanMonitor(greaterThan): void {
    const control = this.parentGroup.controls[greaterThan.input_name];

    if (control) {
      control?.statusChanges.subscribe(event => {
        if (event === 'VALID') {
          if (greaterThan.by_years) {
            const date = dayjs(control.value, DateFormat.default).add(greaterThan.by_years, 'year');
            if (date > this.dateGreaterThan.default) {
              this.dateGreaterThan.value = date.format(DateFormat.default);
              this.dateGreaterThan.error = '_PROD_start_date_out_of_range_error';
            } else {
              this.dateGreaterThan.value = this.dateGreaterThan.default.format(DateFormat.default);
              this.dateGreaterThan.error = '_PROD_start_date_greaterthan';
            }
          }
          if (greaterThan.by_months) {
            const date = dayjs(control.value, DateFormat.default).add(greaterThan.by_months, 'months');
            if (date > this.dateGreaterThan.default) {
              this.dateGreaterThan.value = date.format(DateFormat.default);
              this.dateGreaterThan.error = '_PROD_start_date_out_of_range_error';
            } else {
              this.dateGreaterThan.value = this.dateGreaterThan.default.format(DateFormat.default);
              this.dateGreaterThan.error = '_PROD_start_date_greaterthan';
            }
          }
        }
      });
    }
  }

  /**
   * Compares the start date to the birthdate of customer or policy holder
   * and sets a new maximum date based on the age.
   * Also sets the correct error key.
   *
   * @param {StartDateAgeComparison}
   */
  private _dateLessThanMonitor(lessThan): void {
    const control = this.parentGroup.controls[lessThan.input_name];

    if (control) {
      control?.statusChanges.subscribe(event => {
        if (event === 'VALID') {
          const date = dayjs(control.value, DateFormat.default).add(lessThan.by_years, 'year');
          if (date < this.dateLessThan.default) {
            this.dateLessThan.value = date.format(DateFormat.default);
            this.dateLessThan.error = '_PROD_start_date_out_of_range_error';
          } else {
            this.dateLessThan.value = this.dateLessThan.default.format(DateFormat.default);
            this.dateLessThan.error = '_PROD_start_date_lessthan';
          }
        }
      });
    }
  }

  /**
   * Sets a new maximum start date if it is not the default of 3 years. Also sets the correct error key.
   *
   * @param { duration: number; type: string; }
   *
   * duration is the length of time
   * type is the type of time (day, month, year etc.)
   */
  private _setMaxDate(maximumDate): void {
    this.dateLessThan = {
      default: dayjs().add(maximumDate.duration, maximumDate.type),
      error: `_PROD_start_date_lessthan_${maximumDate.duration}_${maximumDate.type}`,
      value: dayjs().add(maximumDate.duration, maximumDate.type).format(DateFormat.default)
    };
  }
}
