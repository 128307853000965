import { Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wgToasterMessage]'
})
export class WgToasterDirective implements OnInit {
  /**
   * Event emitted to advice when the notification is removed
   */
  @Output() public closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  /**
   * Determines how long an auto-dismissed toast will be shown.
   */
  @Input() public lifeTime: number;
  /**
   * Destroy toast element.
   */
  @Input() public selfDestroy: boolean;

  constructor(private _renderer: Renderer2, private _element: ElementRef) {}

  public ngOnInit(): void {
    if (this.selfDestroy) {
      setTimeout(() => {
        this._renderer.removeChild(this._element.nativeElement.parentNode, this._element.nativeElement);
        this.closed.emit(true);
      }, this.lifeTime);
    }
  }
}
