import { Component, Input } from '@angular/core';

@Component({
  selector: 'one-card-rectangle',
  styleUrls: ['./card-rectangle.component.scss'],
  templateUrl: './card-rectangle.component.html'
})
export class CardRectangleComponent {
  @Input() public body: any; // eslint-disable-line
  @Input() public clickable = true;
  public content: string;
  @Input() public footer: any; // eslint-disable-line
  @Input() public subtitle: any; // eslint-disable-line
  @Input() public title: string;
}
