import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_METHODS, COUNTRY_DATA } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { AccountQuery } from '@wefoxGroupOneBPPrivate/modules/account/queries/account.query';
import { AuthenticationService } from '@wefoxGroupOneBP/app/private/modules/auth/services/authentication.service';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-dropdown-nav',
  styleUrls: ['./dropdown-nav.component.scss'],
  templateUrl: './dropdown-nav.component.html',
})
export class DropdownNavComponent implements OnInit, OnDestroy {
  public accountName: string;
  public accountReady$: Observable<boolean>;
  @Input() public closeDropdown: Subject<void>;
  public expanded = false;
  @ViewChild('menuButton') public menuButton: ElementRef;
  @ViewChild('menuList') public menuList: ElementRef;

  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _accountQuery: AccountQuery,
    private _auth: AuthenticationService,
    private _router: Router,
    private _sessionQuery: SessionQuery
  ) {}

  public doLogout(event: Event): void {
    event.preventDefault();
    this._auth.logout();
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/login`);
  }

  public getAccountEmail(): string {
    return this._accountQuery.getValue().email;
  }

  /**
   * Expose account full name to the view
   */
  public getAccountName(): string {
    return `${this._accountQuery.getValue().firstname} ${this._accountQuery.getValue().lastname}`;
  }

  public getCountryPrefix(): string {
    return this._sessionQuery.getCountryPrefix();
  }

  public menuClicked(): void {
    this.expanded = false;
    this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
    this.menuList.nativeElement.classList.add('-hidden');
    this.menuButton.nativeElement.classList.remove('-active');
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    const country = this._sessionQuery.getCountry();
    const countryData = COUNTRY_DATA[country];
    const authMethod = countryData.authMethod;
    if (authMethod === AUTH_METHODS.oauth) {
      this.accountReady$ = this._accountQuery.isAccountReady$;
    } else if (authMethod === AUTH_METHODS.keycloak) {
      // FIXME when we have the broker endpoint
      this.accountReady$ = of(true);
    }
    this.closeDropdown.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      this.expanded = false;
      this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
      this.menuList.nativeElement.classList.add('-hidden');
      this.menuButton.nativeElement.classList.remove('-active');
    });
  }

  /**
   * Listener for be aware if the user clicks outside the menu
   * https://stackoverflow.com/questions/51616574/angular-6-click-outside-menu#answer-51639799
   * @param targetElement Current click from user
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: EventTarget): void {
    const clickedInside =
      this.menuButton.nativeElement.contains(targetElement) || this.menuList.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.expanded = false;
      this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
      this.menuList.nativeElement.classList.add('-hidden');
      this.menuButton.nativeElement.classList.remove('-active');
    }
  }

  public toggleMenu(event: Event): void {
    this.closeDropdown.next();
    event.stopPropagation();
    this.expanded = this.menuButton.nativeElement.getAttribute('aria-expanded') === 'true';
    this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
    this.menuButton.nativeElement.classList.toggle('-active');
    this.menuList.nativeElement.classList.toggle('-hidden');
  }
}
