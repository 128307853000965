import { Component, Input } from '@angular/core';

@Component({
  selector: 'one-selected-filters',
  templateUrl: './selected-filters.component.html'
})
export class SelectedFiltersComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public selectedFilters: any;
}
