import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BannerType } from '@wefoxGroupOneBPCore/constants/banner.constant';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { RedirectionService } from '@wefoxGroupOneBPShared/services/redirection.service';

@Component({
  selector: 'one-info-banner',
  templateUrl: './banner.component.html'
})
export class BannerComponent implements AfterViewInit, OnInit {
  @Input() public addIcon = true;
  @ViewChild('bannerText') public bannerText: ElementRef;
  @Input() public options: Partial<BannerOptions>;

  private _bannerStyle = {
    [BannerType.error]: 'bg-danger-light',
    [BannerType.info]: 'bg-info-light',
    [BannerType.success]: 'bg-success-light',
    [BannerType.warning]: 'bg-warning-light'
  };

  private _defaultOptions: BannerOptions;

  private _toasterIconColor = {
    [BannerType.error]: 'danger-dark',
    [BannerType.info]: 'info-dark',
    [BannerType.success]: 'success-dark',
    [BannerType.warning]: 'warning-dark'
  };

  private _toasterIconKey = {
    [BannerType.error]: 'feedback-danger',
    [BannerType.info]: 'feedback-info',
    [BannerType.success]: 'feedback-success',
    [BannerType.warning]: 'feedback-warning'
  };

  constructor(private _redirectionService: RedirectionService) {
    this._defaultOptions = {
      closeAction: null,
      iconMarginModifier: null,
      linkClickAction: null,
      linkId: null,
      showCloseButton: false,
      textKey: '',
      type: null
    };
  }

  public getIconState(): { color: string; key: string } {
    return !!this._toasterIconKey[this.options.type]
      ? { color: this._toasterIconColor[this.options.type], key: this._toasterIconKey[this.options.type] }
      : null;
  }

  public getStyle(): string {
    return this._bannerStyle[this.options.type];
  }

  public ngAfterViewInit(): void {
    this._manageBannerLink();
  }

  public ngOnInit(): void {
    this.options = { ...this._defaultOptions, ...this.options };
  }

  private _manageBannerLink(): void {
    const linkId = this.options.linkId;

    if (this.bannerText?.nativeElement?.querySelector(`#${linkId}`)) {
      this.bannerText.nativeElement.addEventListener('click', event => {
        if (event.target.id === linkId) {
          this.options.linkClickAction();
        }
      });
    }
  }
}
