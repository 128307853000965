import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WgSwitchComponent)
    }
  ],
  selector: 'wg-switch',
  templateUrl: './wg-switch.component.html'
})
export class WgSwitchComponent implements ControlValueAccessor {
  @Input() public modifier: string;
  @Input() public offIcon: string;
  @Input() public offLabel: string;
  @Input() public onIcon: string;
  @Input() public tooltipText: string;
  @Input() public turnedOn = false;
  @Output() public turnedOnChange: EventEmitter<boolean> = new EventEmitter();
  @Input() public yesLabel: string;

  // eslint-disable-next-line
  public propagateChange = (_: any) => {
    return {};
  };

  // eslint-disable-next-line
  public propagateTouched = (_: any) => {
    return {};
  };

  // eslint-disable-next-line
  public registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line
  public registerOnTouched(fn: any) {
    this.propagateTouched = fn;
  }

  public toggle(): void {
    this.turnedOn = !this.turnedOn;
    this.turnedOnChange.emit(this.turnedOn);
    this.propagateChange(this.turnedOn);
  }

  public writeValue(value: boolean): void {
    this.turnedOn = value;
  }
}
