import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';

interface DropdownModalOptions {
  buttonAction: Function; // eslint-disable-line
  buttonActionSecondary: Function; // eslint-disable-line
  buttonText: string;
  buttonTextSecondary: string;
  description: string;
  dropdownInputOptions: DropdownComponentOptions;
  selectedOption: WgDropdownOption;
  showCloseButton: boolean;
  subtitle: string;
  title: string;
  titlePlaceholder: string;
}

@Component({
  selector: 'one-dropdown-modal',
  styleUrls: ['./dropdown-modal.component.scss'],
  templateUrl: './dropdown-modal.component.html'
})
export class DropdownModalComponent implements OnInit {
  public contentViewOptions: { options: DropdownModalOptions };
  public formGroupName: UntypedFormGroup;
  public options: DropdownModalOptions;

  constructor(private _fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    this.options = { ...this.contentViewOptions?.options };
    this.formGroupName = this._fb.group({
      [this.options.dropdownInputOptions.controlName]: this._fb.control(null, Validators.required)
    });

    if (this.options.selectedOption) {
      this.options.selectedOption.value = translate(this.options.selectedOption.value);
      this.formGroupName.get(this.options.dropdownInputOptions.controlName).setValue(this.options.selectedOption);
    }
  }

  public onButtonClick(): void {
    if (this.formGroupName.valid) {
      this.options.buttonAction(this.formGroupName.get(this.options.dropdownInputOptions.controlName).value);
    }
  }
}
