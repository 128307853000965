// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

::ng-deep .c-input__text {
  line-height: normal !important;
}

::ng-deep input.c-input__number {
  border: none;
  height: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
  width: 30px;
}
::ng-deep input.c-input__number:focus, ::ng-deep input.c-input__number:active {
  box-shadow: none;
  outline: none;
}

::ng-deep .-kmMileage {
  align-items: center;
  display: flex !important;
}
.one-field-invalid ::ng-deep .-kmMileage {
  border-color: var(--danger-base);
}

.kmLabel {
  color: var(--neutral-darkest);
  white-space: pre-wrap;
}

/* TODO Rename onebp for wg */
.onebp-a-fieldInvalid {
  align-self: flex-start;
  color: var(--danger-base);
  font-size: 0.75rem;
}
.onebp-a-fieldInvalid:not(:empty) {
  margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-km-mileage/wg-km-mileage.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF;;AAGA;EACE,8BAAA;AAAF;;AAGA;EACE,YAAA;EACA,YAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAAF;AAEE;EAEE,gBAAA;EACA,aAAA;AADJ;;AAKA;EACE,mBAAA;EACA,wBAAA;AAFF;AAIE;EACE,gCAAA;AAFJ;;AAMA;EACE,6BAAA;EACA,qBAAA;AAHF;;AAMA,6BAAA;AACA;EACE,sBAAA;EACA,yBAAA;EACA,kBAAA;AAHF;AAKE;EACE,eAAA;AAHJ","sourcesContent":["\n:host {\n  width: 100%;\n}\n\n::ng-deep .c-input__text {\n  line-height: normal !important;\n}\n\n::ng-deep input.c-input__number {\n  border: none;\n  height: 100%;\n  padding-left: 0;\n  padding-right: 0;\n  text-align: right;\n  width: 30px;\n\n  &:focus,\n  &:active {\n    box-shadow: none;\n    outline: none;\n  }\n}\n\n::ng-deep .-kmMileage {\n  align-items: center;\n  display: flex !important;\n\n  .one-field-invalid & {\n    border-color: var(--danger-base);\n  }\n}\n\n.kmLabel {\n  color: var(--neutral-darkest);\n  white-space: pre-wrap;\n}\n\n/* TODO Rename onebp for wg */\n.onebp-a-fieldInvalid {\n  align-self: flex-start;\n  color: var(--danger-base);\n  font-size: 0.75rem;\n\n  &:not(:empty) {\n    margin-top: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
