import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { SelfJourneyProduct } from '@wefoxGroupOneBPPublic/modules/self-journey/models/self-journey.model';

export interface SelfJourneyState extends EntityState<SelfJourneyProduct>, ActiveState {
  broker: any; // eslint-disable-line
  campaignUUID: string;
  customFields: { [key: string]: unknown };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  idKey: 'id',
  name: 'selfJourney',
  resettable: true
})
export class SelfJourneyStore extends EntityStore<SelfJourneyState> {
  constructor() {
    super();
  }
}
