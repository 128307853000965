import { Component, Input, OnInit, WritableSignal, signal } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-multiselect-adapter',
  templateUrl: './multi-select-adapter.component.html',
  styles: `
  .multi-select-wrapper {
    position: relative;
    z-index: 3;
    one-tooltip-adapter {
      position: absolute;
      top: var(--small);
      right: 0px;
    }
  }
  `
})
export class MultiselectAdapterComponent implements OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  public options: WritableSignal<Array<{ label: string; value: string; selected: boolean }>> = signal(undefined);

  ngOnInit(): void {
    this.options.set(this.content_data?.sources[0].values);
  }
}
