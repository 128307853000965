import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerInfoType } from '@wefoxGroupOneBPCore/constants/customer.constant';
import { UiButtonAction } from '@wefoxGroupOneBPShared/constants';
import { CustomerTypeOptions } from '@wefoxGroupOneBPShared/interfaces';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { DETAILS_CARD_FOOTER_ITEM_TYPE } from '../../product/constants/page-details.constant';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'one-details-edit-modal',
  styleUrls: ['./details-edit-modal.component.scss'],
  templateUrl: './details-edit-modal.component.html'
})
export class DetailsEditModalComponent implements OnInit {
  public contentViewOptions: { options: any }; // eslint-disable-line
  public modalOptions;
  public editInfoForm: FormGroup;
  public isLegalEntity = false;
  @Output() public clickButton: EventEmitter<any> = new EventEmitter(); // eslint-disable-line
  public customerTypeRadioOptions = CustomerTypeOptions;
  public formSections;
  public footerItemTypes = DETAILS_CARD_FOOTER_ITEM_TYPE;

  private _controlsNames: string[];

  constructor(
    private _formService: FormService,
    private _modalService: WgModalService
  ) { }

  public customerTypeChanges(type: string): void {
    this.isLegalEntity = type === CustomerInfoType.LegalEntity;
    this._updateFormSections(type);
  }

  private _updateFormSections(type?): void {
    const toHide = this.modalOptions.dynamicFormConfig.has_legal_entity[type];
    this.formSections = this.modalOptions.dynamicFormConfig.form_config.content_data.config.controls.map(sec => {
      return sec.filter(s => {
        const controlName = s.columns[0].options.control_name;
        return toHide.indexOf(controlName) === -1;
      });
    });
  }

  public ngOnInit(): void {
    this.modalOptions = { ...this.contentViewOptions?.options };

    if (this.modalOptions.options.editButtonAction && !this.modalOptions.options.saveButtonAction) {
      this.modalOptions.options.editButtonAction(this.modalOptions.options.sectionId);
      return;
    }

    this._controlsNames = this._formService.mapControlNamesFromModel(this.modalOptions.dynamicFormConfig.model);
    this.formSections = this.modalOptions.dynamicFormConfig.form_config.content_data.config.controls;

    if (this._controlsNames?.length && !this.editInfoForm) {
      this._buildFormGroup();
    }

    this._setFormValues();

    if (this.modalOptions.dynamicFormConfig.has_legal_entity) {
      this._updateFormSections(this.modalOptions.options.currentValues.policy_holder);
    }

    if (this.modalOptions.options.editButtonAction) {
      this.modalOptions.options.editButtonAction();
    } 
  }

  public sendEvent(eventName: string): void {
    this.clickButton.emit({ eventName });
  }

  public onButtonClick(action: string): void {
    if (this.modalOptions.options.buttonAction) {
      this.modalOptions.options.buttonAction();
      return;
    }

    switch (action) {
      case UiButtonAction.discard:
        this._handleDiscardClick();
        break;
      case UiButtonAction.save:
        this._handleSaveClick();
    }
  }

  private _buildFormGroup(): void {
    this.editInfoForm = this._formService.generateFormGroup(this.modalOptions.dynamicFormConfig.model);
  }

  private _getListFromPristineFields(form: FormGroup): string {
    const pristineList = [];
    Object.keys(form.controls).forEach(key => {
      const pristine = form.get(key).pristine;
      if (!pristine) {
        pristineList.push(key);
      }
    });
    return pristineList.toString();
  }

  private _handleDiscardClick(): void {
    if (this.modalOptions.options.discardButtonAction) {
      this.modalOptions.options.discardButtonAction();
    }
    this._closeModal();
  }

  private _handleSaveClick(): void {
    if (this.modalOptions.dynamicFormConfig.has_legal_entity) {
      const toValidate = this.modalOptions.dynamicFormConfig.has_legal_entity[this.editInfoForm.get('policy_holder').value];
      toValidate.forEach(field => {
        this.editInfoForm.get(field).setValidators(null);
        this.editInfoForm.get(field).updateValueAndValidity();
      });
    }

    if (this.editInfoForm.valid) {
      this.modalOptions.options.saveButtonAction(
        this.editInfoForm.getRawValue(),
        this._getListFromPristineFields(this.editInfoForm)
      );
      this._closeModal();
    } else {
      this._controlsNames.forEach(name => this.editInfoForm.get(name).markAsTouched());
    }
  }

  private _setFormValues(): void {
    this._controlsNames.forEach(control => {
      const value = this.modalOptions.options?.currentValues[control]?.value
        ? {
          ...this.modalOptions.options.currentValues[control],
          value: translate(this.modalOptions.options.currentValues[control]?.value)
        }
        : this.modalOptions.options.currentValues[control];

      this.editInfoForm.get(control).setValue(value);
    });
  }

  private _closeModal() {
    this._modalService.hide();
  }
}
