// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-low {
  background: var(--success-10);
  border-radius: 100px;
}

.background-medium {
  background: var(--warning-10);
  border-radius: 100px;
}

.background-high {
  background: var(--danger-10);
  border-radius: 100px;
}

.color-low {
  color: var(--success-80);
}

.color-medium {
  color: var(--warning-80);
}

.color-high {
  color: var(--danger-80);
}`, "",{"version":3,"sources":["webpack://./libs/feature/risk-analysis/src/lib/components/risk-area-card/risk-area-card.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,oBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,oBAAA;AACJ;;AAEA;EACI,4BAAA;EACA,oBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,wBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".background-low {\n    background: var(--success-10);\n    border-radius: 100px;\n}\n\n.background-medium {\n    background: var(--warning-10);\n    border-radius: 100px;\n}\n\n.background-high {\n    background: var(--danger-10);\n    border-radius: 100px;\n}\n\n.color-low {\n    color: var(--success-80);\n}\n\n.color-medium {\n    color: var(--warning-80);\n}\n\n.color-high {\n    color: var(--danger-80);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
