import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface ToggleAdapterComponentOptions {
    class_modifiers: string,
    control_name: string,
    default_value: boolean,
    label: string,
    required: boolean,
    validators: [{ type: string }]
}

@Component({
    selector: 'one-toggle-adapter',
    templateUrl: './toggle-adapter.component.html'
})

export class ToggleAdapterComponent implements OnInit {
    @Input() public content_data: any; // eslint-disable-line
    @Input() public parentGroup: UntypedFormGroup;

    public ngOnInit(): void {
        if (this.content_data.default_value !== undefined) {
            const component = this.parentGroup.get(this.content_data.control_name);

            if (component?.value === '') {
                component?.setValue(this.content_data.default_value);
            }
        }
    }
}
