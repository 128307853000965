import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from "@angular/core";
import { TranslocoModule } from '@ngneat/transloco';


@Component({
  selector: 'wg-contact-card',
  styleUrls: ['contact-card.component.scss'],
  templateUrl: 'contact-card.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactCardComponent {

    @Input() card;
    @Input() skeleton: boolean;
    
}