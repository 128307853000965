import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { B2CLegalConfig, FeaturesOptions, Source } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { CentralizedQuery } from '@wefoxGroupOneBPCore/queries';
import {
  SelfJourneyState,
  SelfJourneyStore
} from '@wefoxGroupOneBPPublic/modules/self-journey/stores/self-journey.store';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';

@Injectable({ providedIn: 'root' })
export class SelfJourneyQuery extends QueryEntity<SelfJourneyState> {
  constructor(protected commonQuery: CentralizedQuery, protected store: SelfJourneyStore) {
    super(store);
  }

  public getActiveAllSources(): Source[] {
    return this.getActive().sources;
  }

  public getActiveSources(name: string): Source {
    return this.getActiveAllSources().find(source => source.name === name);
  }

  public getFeatureBoxOptions(): FeaturesOptions {
    return this.getValue().entities[this.getValue().active].b2c_config.features_box_options;
  }

  public getLegalConfig(): B2CLegalConfig {
    return this.getValue().entities[this.getValue().active].b2c_config.legal;
  }

  public getProductGroup(): string {
    return this.getActive().product_group.id;
  }

  public getSourceValues(name: string): WgDropdownOption[] {
    return this.getActiveSources(name).values as WgDropdownOption[];
  }

  public getTranslationPrefix(productId: string): string {
    return this.getValue().entities[productId].translation_prefix;
  }
}
