import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SourceValue } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
// import { ConsoleReporter } from 'jasmine';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PhoneData } from './phone-data';

export const isControlRequired = (control: AbstractControl): boolean => {
  if (!control) {
    return false;
  }

  if (control.validator) {
    const validator = control.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }

  return false;
};

@Component({
  selector: 'one-phone',
  styleUrls: ['./phone.component.scss'],
  templateUrl: './phone.component.html'
})
export class PhoneComponent implements OnDestroy, OnInit {
  @Input() public controlName: string;
  @Input() public hasAdditionalGender = false;
  public isInit = false;
  public isPrefilled: boolean;
  public isRequired: boolean;
  @Input() public label: string;
  public namePrefix: string;
  public nameSufix: string;
  @Input() public parentGroup: UntypedFormGroup;
  public phoneOptions: WgDropdownOption[];
  public phoneOptionsFiltered: WgDropdownOption[];
  public prefix: string;
  public sufix: string;
  private _currentLocale = this._sessionQuery.getCurrentLocale();
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(private _formService: FormService, private _sessionQuery: SessionQuery) {}

  public addIsRequired(required: boolean): void {
    if (required) {
      this.parentGroup.addControl(this.namePrefix, new UntypedFormControl('', [Validators.required]));
      this.parentGroup.addControl(
        this.nameSufix,
        new UntypedFormControl('', [Validators.required, Validators.pattern(ProductValidations.phoneSuffixPattern)])
      );
      this.parentGroup.addControl(this.controlName, new UntypedFormControl('', [Validators.required]));
    } else {
      this.parentGroup.addControl(this.namePrefix, new UntypedFormControl(''));
      this.parentGroup.addControl(this.nameSufix, new UntypedFormControl(''));
      this.parentGroup.addControl(this.controlName, new UntypedFormControl('', [Validators.required]));
    }
  }

  public getValues(): void {
    const value = this.parentGroup.get(this.controlName).value;
    if (value?.length > 0) {
      if (value.indexOf('+') < 0) {
        this.parentGroup.get(this.nameSufix).setValue(value);
        this.parentGroup.get(this.nameSufix).markAsTouched();
      } else {
        const ele = this._getValueFromKey(value.split(' ')[0]);
        this.parentGroup.get(this.namePrefix).setValue({ key: ele.key, value: ele.value });
        this.parentGroup.get(this.namePrefix).markAsTouched();
        this.parentGroup.get(this.nameSufix).setValue(value.split(' ')[1]);
        this.parentGroup.get(this.nameSufix).markAsTouched();
      }
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.phoneOptions = this._formService.translateSourceValues(
      this._addCountrySelectedOnPhoneValues(this._getPhoneValues())
    );

    setTimeout(() => {
      if (this.parentGroup.get(this.controlName)) {
        this.isInit = true;
        this.setupComponent();
      }
    }, 200);
  }

  public searchCountry(keyword: string): void {
    this.phoneOptionsFiltered = this.phoneOptions?.filter((phoneprefix: WgDropdownOption) => {
      return phoneprefix.value.toUpperCase().includes(keyword.toUpperCase());
    });
  }

  public setIsRequired(required: boolean): void {
    if (required) {
      this.parentGroup.controls[this.namePrefix].setValidators([Validators.required]);
      this.parentGroup.controls[this.nameSufix].setValidators([
        Validators.required,
        Validators.pattern(ProductValidations.phoneSuffixPattern)
      ]);
    } else {
      this.parentGroup.controls[this.namePrefix].setValidators(null);
      this.parentGroup.controls[this.nameSufix].setValidators(null);
    }
    this.parentGroup.controls[this.namePrefix].updateValueAndValidity();
    this.parentGroup.controls[this.nameSufix].updateValueAndValidity();
  }

  public setupComponent(): void {
    this.prefix = '';
    this.sufix = '';
    this.namePrefix = 'phone_prefix_for_' + this.controlName;
    this.nameSufix = 'phone_sufix_for_' + this.controlName;

    this.isRequired = isControlRequired(this.parentGroup.controls[this.controlName]);
    this.addIsRequired(this.isRequired);

    this.addIsRequired(this.isRequired);

    this.parentGroup
      .get(this.namePrefix)
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        this.prefix = val?.key ? val?.key : '';
        this.setValues();
      });

    this.parentGroup
      .get(this.nameSufix)
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        this.sufix = val;
        this.setValues();
      });

    this.parentGroup
      .get(this.controlName)
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe(val => {
        if (val?.length > 0 && !this.isRequired) {
          this.setIsRequired(true);
        } else if (val?.length === 0 && !this.isRequired) {
          this.setIsRequired(false);
        }
        setTimeout(() => {
          if (this.parentGroup.get(this.controlName).disabled) {
            this.getValues();
          }
        }, 50);
      });

    this.getValues();

    this._monitorLocaleChanges();
  }

  public setValues(): void {
    const needspace = this.prefix && this.sufix ? ' ' : '';
    this.parentGroup.get(this.controlName).setValue(this.prefix + needspace + this.sufix);
  }

  // eslint-disable-next-line
  private _addCountrySelectedOnPhoneValues(phoneValues: Array<any>): SourceValue[] {
    const country = this._sessionQuery
      .getCountryPrefix()
      .split('/')[1]
      .toUpperCase();
    phoneValues.sort((a, b) => {
      const valueA = a.value[0].toUpperCase();
      const valueB = b.value[0].toUpperCase();
      return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
    });
    let removed = null;
    for (let i = 0; i < phoneValues.length; i++) {
      if (phoneValues[i].value.indexOf(country) >= 0) {
        removed = JSON.parse(JSON.stringify(phoneValues[i]));
        phoneValues.splice(i, 1);
      }
    }
    const phoneValuesUpdated = [removed].concat(phoneValues);
    return phoneValuesUpdated;
  }

  private _getPhoneValues(): SourceValue[] {
    return JSON.parse(JSON.stringify(PhoneData));
  }

  // eslint-disable-next-line
  private _getValueFromKey(key: string): any {
    for (let index = 0; index < this.phoneOptions?.length; index++) {
      const element = this.phoneOptions[index];
      if (element?.key === key) {
        return element;
      }
    }
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(locale => {
      if (this._currentLocale !== locale) {
        this._currentLocale = locale;
      }
    });
  }
}
