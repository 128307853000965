// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
  align-content: center;
}
.slider__input {
  appearance: none;
  background: var(--neutral-base);
  height: 3px;
  outline: none;
}

.rowStep {
  color: var(--neutral-base);
  margin-top: -13px;
}

.slider__input::-webkit-slider-thumb {
  appearance: none;
  background: var(--primary-base);
  border: 1px solid var(--primary-base);
  border-radius: 12px;
  cursor: pointer;
  height: 41px;
  margin-left: -2px;
  margin-top: -16px;
  position: sticky;
  width: 18px;
  z-index: 7;
}

.range__list {
  color: var(--neutral-base);
  margin-top: -13px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/slider-range/slider-range.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;AACE;EACE,gBAAA;EACA,+BAAA;EACA,WAAA;EACA,aAAA;AACJ;;AAGA;EACE,0BAAA;EACA,iBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,+BAAA;EACA,qCAAA;EACA,mBAAA;EACA,eAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,UAAA;AAAF;;AAGA;EACE,0BAAA;EACA,iBAAA;AAAF","sourcesContent":[".slider {\n  align-content: center;\n\n  &__input {\n    appearance: none;\n    background: var(--neutral-base);\n    height: 3px;\n    outline: none;\n  }\n}\n\n.rowStep {\n  color: var(--neutral-base);\n  margin-top: -13px;\n}\n\n.slider__input::-webkit-slider-thumb {\n  appearance: none;\n  background: var(--primary-base);\n  border: 1px solid var(--primary-base);\n  border-radius: 12px;\n  cursor: pointer;\n  height: 41px;\n  margin-left: -2px;\n  margin-top: -16px;\n  position: sticky;\n  width: 18px;\n  z-index: 7;\n}\n\n.range__list {\n  color: var(--neutral-base);\n  margin-top: -13px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
