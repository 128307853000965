import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { DetailsEditConfig } from '@wefoxGroupOneBPCore/interfaces/customers.interface';
import { CustomerInfoType } from '@wefoxGroupOneBPCore/constants/customer.constant';
import { UiButtonAction } from '@wefoxGroupOneBPShared/constants';
import { CustomerTypeOptions } from '@wefoxGroupOneBPShared/interfaces';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { Subject } from 'rxjs';
import { DETAILS_CARD_FOOTER_ITEM_TYPE } from '../../product/constants/page-details.constant';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { DetailsEditModalComponent } from '../details-edit-modal/details-edit-modal.component';
import { translate } from '@ngneat/transloco';

@Component({
  selector: 'one-details-edit',
  styleUrls: ['./details-edit.component.scss'],
  templateUrl: './details-edit.component.html'
})
export class DetailsEditComponent implements OnDestroy {
  @Input() public hasBorder = true;
  @Input() public buttonIcon = 'onebp__edit';
  @Input() public buttonText = '_ST_B2C_back_to_edit';
  @Input() public columnModifier: string;
  @Input() public dynamicFormConfig: DetailsEditConfig;
  public editInfoForm: FormGroup;
  @Input() public infoBannerOptions: Partial<BannerOptions>;
  @Input() public isEditable = false;
  public isEditing = false;
  public isLegalEntity = false;
  @Input() public isLoading: string;
  @Input() public options: DetailCardOptions;
  @Output() public clickButton: EventEmitter<any> = new EventEmitter(); // eslint-disable-line
  public customerTypeRadioOptions = CustomerTypeOptions;
  public formSections;
  public footerItemTypes = DETAILS_CARD_FOOTER_ITEM_TYPE;

  private _controlsNames: string[];
  private _unsubscribe$: Subject<any> = new Subject(); // eslint-disable-line

  constructor(
    private _formService: FormService,
    private _modalService: WgModalService
  ) { }

  public customerTypeChanges(type: string): void {
    this.isLegalEntity = type === CustomerInfoType.LegalEntity;
    this._updateFormSections(type);
  }

  private _updateFormSections(type?): void {
    const toHide = this.dynamicFormConfig.has_legal_entity[type];
    this.formSections = this.dynamicFormConfig.form_config.content_data.config.controls.map(sec => {
      return sec.filter(s => {
        const controlName = s.columns[0].options.control_name;
        return toHide.indexOf(controlName) === -1;
      });
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  public sendEvent(eventName: string): void {
    this.clickButton.emit({ eventName });
  }

  public onButtonClick(action: string): void {
    if (this.options.buttonAction) {
      this.options.buttonAction();
      return;
    }

    switch (action) {
      case UiButtonAction.edit:
        this._handleEditClick();
        break;
      case UiButtonAction.discard:
        this._handleDiscardClick();
        break;
      case UiButtonAction.save:
        this._handleSaveClick();
    }
  }

  private _buildFormGroup(): void {
    this.editInfoForm = this._formService.generateFormGroup(this.dynamicFormConfig.model);
  }

  private _getListFromPristineFields(form: FormGroup): string {
    const pristineList = [];
    Object.keys(form.controls).forEach(key => {
      const pristine = form.get(key).pristine;
      if (!pristine) {
        pristineList.push(key);
      }
    });
    return pristineList.toString();
  }

  private _handleDiscardClick(): void {
    this._toggleEditMode();

    if (this.options.discardButtonAction) {
      this.options.discardButtonAction();
    }
  }

  private _handleEditClick(): void {
    if (this.options.editButtonAction && !this.options.saveButtonAction) {
      this.options.editButtonAction(this.options.sectionId);
      return;
    }

    if (!this.isLoading) {
      this._modalService.setAndShow({
        contentView: DetailsEditModalComponent,
        contentViewOptions: {
          options: {
            hasBorder: this.hasBorder,
            buttonIcon: this.buttonIcon,
            buttonText: this.buttonText,
            columnModifier: this.columnModifier,
            dynamicFormConfig: this.dynamicFormConfig,
            infoBannerOptions: this.infoBannerOptions,
            isLoading: this.isLoading,
            options: this.options,
            closeAction: () => this._modalService.hide()
          }
        }
      });
    }
  }

  private _handleSaveClick(): void {
    if (this.dynamicFormConfig.has_legal_entity) {
      const toValidate = this.dynamicFormConfig.has_legal_entity[this.editInfoForm.get('policy_holder').value];
      toValidate.forEach(field => {
        this.editInfoForm.get(field).setValidators(null);
        this.editInfoForm.get(field).updateValueAndValidity();
      });
    }

    if (this.editInfoForm.valid) {
      this.options.saveButtonAction(
        this.editInfoForm.getRawValue(),
        this._getListFromPristineFields(this.editInfoForm)
      );
      this._toggleEditMode();
    } else {
      this._controlsNames.forEach(name => this.editInfoForm.get(name).markAsTouched());
    }
  }

  private _setFormValues(): void {
    if (this.isEditing) {
      this._controlsNames.forEach(control => {
        const value = this.options?.currentValues[control]?.value
          ? {
            ...this.options.currentValues[control],
            value: translate(this.options.currentValues[control]?.value)
          }
          : this.options.currentValues[control];

        this.editInfoForm.get(control).setValue(value);
      });
    }
  }

  private _toggleEditMode(): void {
    this.isEditing = !this.isEditing;
  }
}
