// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-title {
  color: #575655;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.footer-subtitle {
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/components/details-edit/details-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":[".footer-title {\n    color: #575655;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.footer-subtitle {\n    color: #242424;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
