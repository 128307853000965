export const SORT_BY = [
  {
    label: '_DASH_products_sort_ascending',
    value: 'asc'
  },
  {
    label: '_DASH_products_sort_descending',
    value: 'desc'
  }
];

export const SEARCH_BAR_CONFIG_CARRIERS = {
  show: true,
  placeholder: '_DASH_products_search_carrier',
  label: '_DASH_products_carriers',
  noResultsMessage: '_DASH_products_no_result'
};

export const SEARCH_BAR_CONFIG_PRODUCT_GROUP = {
  show: true,
  placeholder: '_DASH_products_search_product_group',
  label: '_DASH_products_product_group',
  noResultsMessage: '_DASH_products_no_result'
};

export const SEARCH_BAR_CONFIG_PRODUCTS = {
  show: true,
  placeholder: '_DASH_products_search_product',
  label: '_DASH_products_product',
  noResultsMessage: '_DASH_products_no_result'
};
