import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'one-section-title',
  templateUrl: './section-title.component.html'
})
export class SectionTitleComponent implements OnInit {
  @Output() public buttonAction = new EventEmitter();
  @Input() public buttonIcon = 'edit';
  @Input() public buttonText = '_ST_B2C_back_to_edit';
  @Input() public required = true;
  @Input() public sectionId: string;
  @Input() public sectionIndicator: string;
  @Input() public showButton = true;
  @Input() public title: string;

  public ngOnInit(): void {
    if (this.sectionId || this.sectionIndicator) {
      this.required = false;
    }
  }

  public onButtonClick(): void {
    this.buttonAction.emit(this.sectionId);
  }
}
