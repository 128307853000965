import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { IConfig, NgxMaskModule } from 'ngx-mask';

import {
  AddressAdapterComponent,
  AmountAdapterComponent,
  BirthdateInputAdapterComponent,
  CarriersAdapterComponent,
  CheckboxAdapterComponent,
  DateInputAdapterComponent,
  DocumentUploadAdapterComponent,
  DropdownInputAdapterComponent,
  DuplicateFieldAdapterComponent,
  FileUploadAdapterComponent,
  InfoBannerAdapterComponent,
  InputAdapterComponent,
  LicensePlateAdapterComponent,
  PhoneAdapterComponent,
  SliderAdapterComponent,
  StartDateInputAdapterComponent,
  TextAreaAdapterComponent,
  ToggleAdapterComponent,
  TypeaheadAdapterComponent,
  ButtonGroupAdapterComponent,
  ButtonAdapterComponent,
  DateComparisonAdapterComponent,
  FileDownloadAdapterComponent,
  MileageAdapterComponent,
  QuestionAdapterComponent,
  RadioAdapterComponent,
  TextsAdapterComponent,
  MultiselectAdapterComponent,
  TooltipAdapterComponent,
  InsuranceCompanyAdapterComponent
} from '.';
import { SharedModule } from '@wefoxGroupOneBPShared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputBuildComponent } from './input-build/input-build.component';
import { TranslocoModule } from '@ngneat/transloco';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    DuplicateFieldAdapterComponent,
    AddressAdapterComponent,
    AmountAdapterComponent,
    BirthdateInputAdapterComponent,
    ButtonGroupAdapterComponent,
    ButtonAdapterComponent,
    CarriersAdapterComponent,
    CheckboxAdapterComponent,
    DateComparisonAdapterComponent,
    DateInputAdapterComponent,
    DocumentUploadAdapterComponent,
    DropdownInputAdapterComponent,
    FileDownloadAdapterComponent,
    FileUploadAdapterComponent,
    InfoBannerAdapterComponent,
    InputAdapterComponent,
    LicensePlateAdapterComponent,
    MileageAdapterComponent,
    PhoneAdapterComponent,
    QuestionAdapterComponent,
    RadioAdapterComponent,
    SliderAdapterComponent,
    StartDateInputAdapterComponent,
    TextAreaAdapterComponent,
    TextsAdapterComponent,
    ToggleAdapterComponent,
    TypeaheadAdapterComponent,
    InputBuildComponent,
    MultiselectAdapterComponent,
    TooltipAdapterComponent,
    InsuranceCompanyAdapterComponent
  ],
  exports: [
    DuplicateFieldAdapterComponent,
    AddressAdapterComponent,
    AmountAdapterComponent,
    BirthdateInputAdapterComponent,
    CarriersAdapterComponent,
    FileDownloadAdapterComponent,
    ButtonGroupAdapterComponent,
    ButtonAdapterComponent,
    CheckboxAdapterComponent,
    DateInputAdapterComponent,
    DocumentUploadAdapterComponent,
    DropdownInputAdapterComponent,
    FileUploadAdapterComponent,
    InfoBannerAdapterComponent,
    InputAdapterComponent,
    LicensePlateAdapterComponent,
    MileageAdapterComponent,
    PhoneAdapterComponent,
    QuestionAdapterComponent,
    RadioAdapterComponent,
    StartDateInputAdapterComponent,
    TextAreaAdapterComponent,
    TextsAdapterComponent,
    ToggleAdapterComponent,
    TypeaheadAdapterComponent,
    InputBuildComponent,
    MultiselectAdapterComponent,
    TooltipAdapterComponent,
    InsuranceCompanyAdapterComponent
  ],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, TranslocoModule, NgxMaskModule.forChild()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdaptersModule {}
