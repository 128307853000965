import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface SliderComponentOptions {
  control_name: string;
  default_value: number;
  label: string;
  max_value: number;
  min_value: number;
  step_count: number;
  step_icon?: string;
  step_interval: number;
}

@Component({
  selector: 'one-slider-adapter',
  templateUrl: './slider-adapter.component.html'
})
export class SliderAdapterComponent {
  @Input() public content_data: SliderComponentOptions;
  @Input() public parentGroup: UntypedFormGroup;
}
