import { Component, OnInit } from '@angular/core';
import { ToasterNotification, ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { WgToasterService } from '@wefoxGroupOneBPCore/services/wg-toaster.service';

@Component({
  selector: 'wg-toaster',
  styleUrls: ['./wg-toaster.component.scss'],
  templateUrl: './wg-toaster.component.html'
})
export class WgToasterComponent implements OnInit {
  public notifications: Partial<ToasterNotification>[];

  constructor(private _toasterService: WgToasterService) {}

  public getTheme(type: string): { iconId: string; iconColor: string; style: string } {
    switch (type) {
      case ToasterType.error:
        return {
          iconColor: 'danger-60',
          iconId: 'feedback-danger',
          style: 'bg-danger-light color-danger-dark border-danger-dark'
        };
      case ToasterType.info:
        return {
          iconColor: 'info-60',
          iconId: 'feedback-info',
          style: 'bg-info-light color-info-dark border-info-dark'
        };
      case ToasterType.success:
        return {
          iconColor: 'success-60',
          iconId: 'feedback-success',
          style: 'bg-success-light color-success-dark border-success-dark'
        };
      case ToasterType.warning:
        return {
          iconColor: 'warning-60',
          iconId: 'feedback-warning',
          style: 'bg-warning-light color-warning-dark border-warning-dark'
        };
    }
  }

  public ngOnInit(): void {
    this._toasterService.notificationsObservable.subscribe(
      (notifications: Partial<ToasterNotification>[]) => (this.notifications = notifications)
    );
  }

  protected dismiss(event: { toState: string }, notification: ToasterNotification): void {
    if (event) {
      if (event.toState === 'void') {
        this._toasterService.dismiss(notification);
      }
    } else {
      this._toasterService.dismiss(notification);
    }
  }
}
