import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { Platform } from '@angular/cdk/platform';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Renderer2
} from '@angular/core';
import {
  WgTooltipDefaultOffset,
  WgTooltipPosition,
  WgTooltipPositionEnum
} from '@wefoxGroupOneBPShared/modules/wg-tooltip/constants';
import { WgTooltip, WgTooltipOffset } from '@wefoxGroupOneBPShared/modules/wg-tooltip/interfaces/';
import { WgTooltipComponent } from '@wefoxGroupOneBPShared/modules/wg-tooltip/wg-tooltip.component';
import { fromEvent, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector:
    '[oneWgTooltip], [oneWgTooltipOffsetX], [oneWgTooltipOffsetY], [oneWgTooltipClass], [oneWgTooltipPosition], [oneWgTooltipWidth]'
})
export class WgTooltipDirective implements OnInit, OnDestroy {
  public _overlayRef: OverlayRef;
  /*  */
  @Input('oneWgTooltipOffsetX') public offsetX: number;
  @Input('oneWgTooltipOffsetY') public offsetY: number;
  @Input('oneWgTooltipClass') public tooltipClass = null;
  @Input('oneWgTooltipPosition') public tooltipPosition: WgTooltipPosition = WgTooltipPositionEnum.Right;
  // eslint-disable-next-line max-len
  @Input('oneWgTooltip') public tooltipText: WgTooltip = null;
  @Input('oneWgTooltipWidth') public tooltipWidth = undefined;
  private _defaultOffsets: WgTooltipOffset = WgTooltipDefaultOffset;
  private _portalhost: DomPortalOutlet;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _appRef: ApplicationRef,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _element: ElementRef,
    private _injector: Injector,
    private _ngZone: NgZone,
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _platform: Platform,
    private _renderer: Renderer2
  ) {}

  public ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
    if (this._portalhost) {
      this._portalhost.dispose();
    }

    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    if (!!this._isMobile()) {
      this._createElementMobile();
    }

    this._initializeStyle('margin-left', '16px');
    this._initializeStyle('display', 'flex', this._renderer.parentNode(this._element.nativeElement));
    this._initializeStyle('align-items', 'center', this._renderer.parentNode(this._element.nativeElement));
    this._initializeStyle('flex-direction', 'row', this._renderer.parentNode(this._element.nativeElement));

    const elementPosition = this._overlayPositionBuilder
      .flexibleConnectedTo(this._element)
      .withFlexibleDimensions(true)
      .withPositions([this._tooltipPosition(this.tooltipPosition)]);
    this._overlayRef = this._overlay.create({
      panelClass: this.tooltipClass,
      positionStrategy: elementPosition,
      scrollStrategy: this._overlay.scrollStrategies.close()
    });

    const onEnter = merge(
      fromEvent(this._element.nativeElement, 'mouseenter'),
      fromEvent(this._element.nativeElement, 'mouseleave'),
      fromEvent(this._element.nativeElement, 'touchstart'),
      fromEvent(document, 'touchmove')
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onEnter.pipe(takeUntil(this._unsubscribe$)).subscribe((result: any) =>
      this._ngZone.runOutsideAngular(() => {
        switch (result.type) {
          case 'touchstart':
            if (result.target === this._element.nativeElement && !this._portalhost.hasAttached()) {
              this._showTooltip();
            } else {
              this._hideTooltip();
            }
            break;
          case 'touchmove':
            if (!this._isMobile()) {
              result.preventDefault();
            }
            this._hideTooltip();
            break;
          case 'mouseenter':
            if (!!this._isMobile()) {
              result.preventDefault();
              return false;
            }
            this._showTooltip();
            break;
          case 'mouseleave':
            if (!!this._isMobile()) {
              return false;
            }
            this._hideTooltip();
            break;
        }
      })
    );
  }

  private _createElementMobile() {
    const template = this._renderer.createElement('ng-template');

    /* We need id for distinguish the Dom element */
    const templateId = Math.random()
      .toString(36)
      .substr(2, 9);

    this._renderer.setAttribute(template, 'id', `template_${templateId}`);
    this._renderer.setStyle(template, 'margin', '-10px 0 25px 0');

    const parentNode = this._renderer.parentNode(this._renderer.parentNode(this._element.nativeElement));

    // TODO: Check again after responsive implementation
    this._renderer.setStyle(this._renderer.parentNode(this._element.nativeElement), 'flex-direction', 'row');
    // this._renderer.appendChild(parentNode, template);
    this._renderer.insertBefore(
      parentNode,
      template,
      this._renderer.nextSibling(this._renderer.parentNode(this._element.nativeElement))
    );
    this._portalhost = new DomPortalOutlet(
      document.querySelector('#template_' + templateId),
      this._componentFactoryResolver,
      this._appRef,
      this._injector
    );
    this.tooltipClass = '-mobile';
  }

  private _hideTooltip() {
    if (this._overlayRef && this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
    }
    if (this._portalhost && this._portalhost.hasAttached()) {
      this._portalhost.detach();
    }
  }

  private _initializeStyle(style: string, value: string, element?: ElementRef) {
    this._renderer.setStyle(element || this._element.nativeElement, style, value);
  }

  private _isMobile(): boolean {
    return !!(this._platform.ANDROID || this._platform.IOS);
  }

  private _showTooltip() {
    if (!this._overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<WgTooltipComponent> = !this._isMobile()
        ? this._overlayRef.attach(new ComponentPortal(WgTooltipComponent))
        : this._portalhost.attach(new ComponentPortal(WgTooltipComponent));
      tooltipRef.instance.oneWgTooltipText = this.tooltipText;
      tooltipRef.instance.oneWgTooltipPosition = this.tooltipPosition;
      tooltipRef.instance.oneWgTooltipWidth = this.tooltipWidth;
      tooltipRef.instance.oneWgTooltipClass = this.tooltipClass;
    } else {
      this._hideTooltip();
    }
  }

  private _tooltipPosition(position: WgTooltipPosition): ConnectedPosition {
    const commonOffsetXY: Partial<ConnectedPosition> = {
      offsetX: this.offsetX || this._defaultOffsets[position].x,
      offsetY: this.offsetY || this._defaultOffsets[position].y
    };

    switch (position) {
      case WgTooltipPositionEnum.Bottom:
        return {
          ...commonOffsetXY,
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top'
        };

      case WgTooltipPositionEnum.Right:
        return {
          ...commonOffsetXY,
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top'
        };

      case WgTooltipPositionEnum.BottomLeft:
        return {
          ...commonOffsetXY,
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        };
      case WgTooltipPositionEnum.Left:
        return {
          ...commonOffsetXY,
          originX: 'start',
          originY: 'center',
          overlayX: 'end',
          overlayY: 'center'
        };
      case WgTooltipPositionEnum.Top:
        return {
          ...commonOffsetXY,
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        };
      case WgTooltipPositionEnum.TopLeft:
      default:
        return {
          ...commonOffsetXY,
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        };
    }
  }
}
