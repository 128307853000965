export const CustomerType = {
  legal_entity: 'legal_entity' as const,
  natural_person: 'natural_person' as const
};

export type CustomerType = typeof CustomerType[keyof typeof CustomerType];

export const CustomerInfoType = {
  LegalEntity: 'legalEntity',
  PrivatePerson: 'privatePerson'
};
