import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, switchMap } from 'rxjs';
import { InsertElementDirective } from '../../directives/carriersElement.directive';
import { ThirdPartyService } from '../../services/third-party.service';
import {
  SEARCH_BAR_CONFIG_CARRIERS,
  SEARCH_BAR_CONFIG_PRODUCTS,
  SEARCH_BAR_CONFIG_PRODUCT_GROUP
} from './constants/third-party-products.constants';
import { DropdownData } from './interfaces/dropdowns-data.interface';
import {
  Carrier,
  CarrierCardOption,
  Product,
  ProductGroup,
  SearchBarConfig
} from './interfaces/third-party-products.interface';
import { InsuranceCardData } from '@wefoxGroupOneBPShared/components/carrier-excerpt/interfaces/carrier-excerpt.interface';
import { mapCarriersIntoCarrierOptions } from './utils/map-carriers-into-carrier-options.util';
import { mapProductsIntoCarrierOptions } from './utils/map-products-into-carrier-options.util';

@Component({
  selector: 'wg-third-party-product-selection',
  templateUrl: './third-party-product-selection.component.html',
  styleUrls: ['./third-party-product-selection.component.scss']
})
export class ThirdPartyProductSelectionComponent implements OnInit {
  @ViewChild(InsertElementDirective, { static: true }) carrierElementHost!: InsertElementDirective;

  private _carriers$: Observable<Carrier[]>;
  private _productGroups$: Observable<ProductGroup[]>;
  private _products$: Observable<Product[]>;
  public filtersData$: Observable<{ carriers: Carrier[]; productsG: ProductGroup[]; products: Product[] }>;
  public cards$: Observable<CarrierCardOption[]>;
  public noResultsMessage = '';

  public changeDropdowns$: BehaviorSubject<DropdownData> = new BehaviorSubject<DropdownData>({
    selectedCarriersIds: [],
    selectedProductGroupId: undefined,
    selectedProductsIds: []
  });

  public searchBarConfigCarriers = this._transformConfig(SEARCH_BAR_CONFIG_CARRIERS);
  public searchBarConfigProductGroup = this._transformConfig(SEARCH_BAR_CONFIG_PRODUCT_GROUP);
  public searchBarConfigProducts = this._transformConfig(SEARCH_BAR_CONFIG_PRODUCTS);

  constructor(protected _sessionQuery: SessionQuery, private _thirdPartyService: ThirdPartyService, private translocoService: TranslocoService) {
    this.noResultsMessage = this.translocoService.translate('_GEN_no_results_found');
  }

  public ngOnInit(): void {
    this._setCarriers();
    this._setProductGroup();
    this._setProduct();
    this._mapInsuranceCarrierOption();
    this.filtersData$ = combineLatest([this._carriers$, this._productGroups$, this._products$]).pipe(
      map(([carriers, productsG, products]) => ({ carriers, productsG, products }))
    );
  }

  handleCarrierChange(event: CustomEvent) {
    const selectedCarriersIds = event?.detail?.map(p => p.value) || [];
    this.changeDropdowns$.next({ selectedCarriersIds, selectedProductGroupId: undefined, selectedProductsIds: [] });
  }

  handleProductGroupChange(event) {
    const selectedProductGroupId = event?.detail?.value || undefined;
    const currentDropdownData = this.changeDropdowns$.getValue();
    this.changeDropdowns$.next({ ...currentDropdownData, selectedProductGroupId, selectedProductsIds: [] });
  }

  handleProductChange(event: CustomEvent) {
    const selectedProductsIds = event?.detail?.map(p => p.id) || [];
    const currentDropdownData = this.changeDropdowns$.getValue();
    this.changeDropdowns$.next({ ...currentDropdownData, selectedProductsIds });
  }

  _setCarriers() {
    this._carriers$ = this._thirdPartyService.getAllCarriers$().pipe(
      map(carriers =>
        carriers.map(carrier => ({
          label: carrier.name,
          value: carrier.id,
          image_source: carrier.logo_icon
        }))
      )
    );
  }

  _setProductGroup() {
    const unformattedProductsGroups$ = this.changeDropdowns$.pipe(
      map(data => data.selectedCarriersIds),
      distinctUntilChanged(),
      switchMap(ids => this._thirdPartyService.getAllProductGroups$(ids))
    );

    const changeSelectedProductGroups$ = this.changeDropdowns$.pipe(
      map(data => data.selectedProductGroupId),
      distinctUntilChanged()
    );

    const combined$ = combineLatest([unformattedProductsGroups$, changeSelectedProductGroups$]);
    this._productGroups$ = combined$.pipe(
      map(([unformattedProductGroups, productGroupId]) =>
        unformattedProductGroups.map(productGroup => ({
          label:  this.translocoService.translate(this._formatString(productGroup.display_name)),
          value: productGroup.id,
          selected: productGroup.id === productGroupId
        }))
      )
    );
  }

  _setProduct() {
    const unformattedProducts$ = this.changeDropdowns$.pipe(
      map(({ selectedCarriersIds, selectedProductGroupId }) => ({ selectedCarriersIds, selectedProductGroupId })),
      distinctUntilChanged(),
      switchMap(({ selectedCarriersIds, selectedProductGroupId }) =>
        this._thirdPartyService.getAllProducts$(selectedCarriersIds, selectedProductGroupId)
      )
    );

    const changeSelectedProducts$ = this.changeDropdowns$.pipe(
      map(data => data.selectedProductsIds),
      distinctUntilChanged()
    );

    this._products$ = combineLatest([unformattedProducts$, changeSelectedProducts$]).pipe(
      distinctUntilChanged(),
      map(([unformattedProducts, selectedProductsIds]) =>
        unformattedProducts.map(product => ({
          id: product.name,
          label: this.translocoService.translate(this._formatString('3_party_product_' + product.name)),
          selected: selectedProductsIds?.some(name => name === product.name),
          value: product.name
        }))
      )
    );
  }

  private _mapInsuranceCarrierOption(): void {
    this.cards$ = this.changeDropdowns$.pipe(
      switchMap(({ selectedCarriersIds, selectedProductGroupId, selectedProductsIds }) => {
        if (this._emptyConditions({ selectedCarriersIds, selectedProductGroupId, selectedProductsIds })) {
          return this._thirdPartyService
          .getCarriers$()
          .pipe(map((data: InsuranceCardData[]) => mapCarriersIntoCarrierOptions(data)))
        };

        return this._thirdPartyService
          .getInsuranceCarriersTotalProducts$(selectedCarriersIds, selectedProductGroupId, selectedProductsIds)
          .pipe(map((data: AllListInfo) => mapProductsIntoCarrierOptions(data)))
      })
    );
  }

  private _emptyConditions(conditions: DropdownData) {
    const { selectedCarriersIds, selectedProductGroupId, selectedProductsIds } = conditions;
    return !(selectedProductGroupId || selectedCarriersIds.length || selectedProductsIds.length);
  }

  _transformConfig(config: SearchBarConfig): SearchBarConfig {
    return {
      show: config.show,
      placeholder: this.translocoService.translate(config.placeholder),
      label: this.translocoService.translate(config.label),
      noResultsMessage: this.translocoService.translate(config.noResultsMessage)
    };
  }

  _formatString(input: string): string {
    let result = input.replace(/ /g, '_').toLowerCase();
    result = result.replace(/[-,().:/]/g, '');
    result = result.replace(/_+/g, '_');
    result = result.replace(/^_|_$/g, '');

    return result;
  }
}
