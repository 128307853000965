import { Component, HostListener } from '@angular/core';
import { WgFieldComponent } from '../wg-field/wg-field.component';

export const KeyCodes = {
  enter: 'Enter',
  space: 'Space'
};

@Component({
  selector: 'wg-checkbox',
  styleUrls: ['./wg-checkbox.component.scss'],
  templateUrl: './wg-checkbox.component.html'
})
export class WgCheckboxComponent extends WgFieldComponent {
  @HostListener('keydown', ['$event'])
  public keyUpEvent(event: KeyboardEvent): boolean {
    if (event.code === KeyCodes.space || event.code === KeyCodes.enter) {
      this._inputControl.setValue(!this._inputControl.value);
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  }
}
