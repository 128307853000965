import { Injectable } from '@angular/core';
import { DownloadedFile } from '@wefoxGroupOneBP/app/private/product/interfaces/downloaded-file.interface';
import {
  ConsentThirdParty,
  CustomerEditItDTO,
  EditProfile,
  MandatoryMandate,
  PaymentMethodResult,
  PaymentMethodSaveData,
  Profile
} from '@wefoxGroupOneBPCore/interfaces/customer-detail.interface';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { ShareQuestionsInterface } from 'libs/feature/risk-analysis/src/lib/components/share-questions-modal/share-questions-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { ThirdPartyJobStatusResponse } from '../interfaces';
import { CustomersDataService } from './customers-data.service';

@Injectable({ providedIn: 'root' })
export class CustomersService {
  private _customerContractsListLength = 20; // Default query length is 10
  private viewHasTabsSubject = new BehaviorSubject(false);
  public readonly viewHasTabs: Observable<boolean> = this.viewHasTabsSubject.asObservable();
  public hasMandate$ = new BehaviorSubject(false);

  constructor(private _customersData: CustomersDataService) { }

  public addAdditionalCustomerContract$(customerId: string, body, options?: RequestInit) {
    return this._customersData.addAdditionalCustomerContract$(customerId, body, options);
  }

  public createThirdPartyCustomerAndContract$(body, options?: RequestInit) {
    return this._customersData.createThirdPartyCustomerAndContract$(body, options);
  }

  public setViewHasTabs(hasTabs: boolean): void {
    this.viewHasTabsSubject.next(hasTabs);
  }

  public setMandate(consent: boolean) {
    this.hasMandate$.next(consent);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadAllContracts$(contractId: string): Observable<any> {
    return this._customersData.downloadAllContracts$(contractId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadThirdPartyContracts$(contractId: string): Observable<any> {
    return this._customersData.downloadThirdPartyContracts$(contractId);
  }

  public downloadContract$(contractId: string): Observable<DownloadedFile> {
    return this._customersData.downloadContract$(contractId);
  }

  public getAccountCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
    // eslint-disable-next-line
  ): Observable<AllListInfo> {
    return this._customersData.getAccountCustomers$(
      brokerId,
      criteria,
      page,
      sort,
      size,
      customerType,
      onboardingType,
      productType,
      statusType
    );
  }

  // eslint-disable-next-line
  public getAllCustomers$(brokerId: string): Observable<AllListInfo> {
    return this._customersData.getAllCustomers$(brokerId);
  }

  // eslint-disable-next-line
  public getAllCustomersForSupport$(brokerId: string): Observable<any> {
    return this._customersData.getAllCustomersForSupport$(brokerId);
  }

  // eslint-disable-next-line
  public getContractDetails$(contractId, isThirdParty): Observable<any> {
    return this._customersData.getContractDetails$(contractId, isThirdParty);
  }

  // eslint-disable-next-line
  public getContractDetailsIT$(contractId): Observable<any> {
    return this._customersData.getContractDetailsIT$(contractId);
  }

  // eslint-disable-next-line
  public getInsuranceCarriers$(): Observable<any> {
    return this._customersData.getInsuranceCarriers$();
  }

  // eslint-disable-next-line
  public getCarriersCategories$(productTypeKey: string, insurerId: string): Observable<any> {
    return this._customersData.getCarriersCategories$(productTypeKey, insurerId);
  }

  // eslint-disable-next-line
  public getCarriersProductTypes$(insurerId: string): Observable<any> {
    return this._customersData.getCarriersProductTypes$(insurerId);
  }

  // eslint-disable-next-line
  public getPolicyHolderDetailIT$(contractId): Observable<any> {
    return this._customersData.getPolicyHolderDetailIT$(contractId);
  }

  // eslint-disable-next-line
  public getSendEmailQuestions$(customerDataToSend, isThirdParty): Observable<any> {
    return this._customersData.getSendEmailEndpoint$(customerDataToSend, isThirdParty);
  }

  public getShareQuestions$(customerId, isThirdParty): Observable<ShareQuestionsInterface> {
    return this._customersData.getShareQuestionsEndpoint$(customerId, isThirdParty);
  }

  // eslint-disable-next-line
  public getVehicleTypeIT$(contractId): Observable<any> {
    return this._customersData.getVehicleTypeIT$(contractId);
  }

  // TODO: Should be moved to contracts service
  public getContracts$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string,
    onboardingType?: string
  ): Observable<AllListInfo> {
    return this._customersData.getContracts$(brokerId, criteria, page, sort, size, type, onboardingType);
  }

  public getCustomerClaims$(
    customerId: string,
    criteria?: string,
    page?: number,
    sort?: string,
    size?: number,
    type?: string
  ): Observable<Partial<AllListInfo>> {
    return this._customersData.getCustomerClaims$(customerId, criteria, page, sort, size, type);
  }

  public getCustomerContracts$(
    customerId: string,
    criteria?: string,
    page?: number,
    sort?: string,
    size?: number,
    type?: string
  ): Observable<AllListInfo> {
    return this._customersData.getCustomerContracts$(customerId, criteria, page, sort, size, type);
  }

  public getThirdPartyCustomerContracts$(
    customerId: string,
    criteria?: string,
    page?: number,
    sort?: string,
    size?: number,
    type?: string
  ): Observable<AllListInfo> {
    return this._customersData.getThirdPartyCustomerContracts$(customerId, criteria, page, sort, size, type);
  }

  public getCustomerContractsAll$(customerId: string): Observable<AllListInfo> {
    return this._customersData.getCustomerContracts$(customerId);
  }

  // eslint-disable-next-line
  public getCustomerContractsAllForSupport$(customerId: string): Observable<any> {
    return this._customersData.getCustomerContractsForSupport$(customerId);
  }

  // eslint-disable-next-line
  public getCustomerInfo$(customerId: string, isThirdParty: boolean = false): Observable<any> {
    return this._customersData.getCustomerInfo$(customerId, isThirdParty);
  }

  // eslint-disable-next-line
  public getThirdPartyCustomerInfoCard$(customerId: string, isThirdParty: boolean): Observable<any> {
    return this._customersData.getThirdPartyCustomerInfoCard$(customerId, isThirdParty);
  }

  public getThirdPartyCustomerJobStatus$(): Observable<ThirdPartyJobStatusResponse> {
    return this._customersData.getThirdPartyCustomerJobStatus$();
  }

  public getThirdPartyCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    firstName?: string,
    lastName?: string
  ): Observable<AllListInfo> {
    return this._customersData.getThirdPartyCustomers$(brokerId, criteria, page, sort, size, firstName, lastName);
  }

  public getCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
  ): Observable<AllListInfo> {
    return this._customersData.getCustomers$(
      brokerId,
      criteria,
      page,
      sort,
      size,
      customerType,
      onboardingType,
      productType,
      statusType
    );
  }

  public getCustomersIT$(
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
  ): Observable<AllListInfo> {
    return this._customersData.getAllCustomersIT$(
      criteria,
      page,
      sort,
      size,
      customerType,
      onboardingType,
      productType,
      statusType
    );
  }

  // eslint-disable-next-line
  public getPolicyInputs$(policyId: string, productId: string): Observable<any> {
    return this._customersData.getPolicyInputs$(policyId, productId);
  }

  public saveContractPaymentMethod$(
    accountId: string,
    paymentData: Partial<PaymentMethodSaveData>,
    options?: RequestInit
  ): Observable<PaymentMethodResult> {
    return this._customersData.saveContractPaymentMethod$(accountId, paymentData, options);
  }

  public saveCustomerDataMethod$(
    customerNewData: Partial<EditProfile>,
    customerOldData: Partial<EditProfile> | Partial<Profile>,
    options?: RequestInit
  ): Observable<Profile> {
    return this._customersData.saveCustomerDataMethod$(customerNewData, customerOldData, options);
  }

  // eslint-disable-next-line
  public sendConsentApprovalThirdParty$(consent: ConsentThirdParty): Observable<any> {
    return this._customersData.sendConsentApprovalThirdParty$(consent);
  }

  // eslint-disable-next-line
  public getMandatoryMandate$(customerId): Observable<any> {
    return this._customersData.getMandatoryMandate$(customerId);
  }

  // eslint-disable-next-line
  public updateConsentApproval$(mandatoryMandate: MandatoryMandate): Observable<any> {
    return this._customersData.updateConsentApproval$(mandatoryMandate);
  }

  public updateCustomerDataIT$(
    customerId: string,
    customerData: CustomerEditItDTO
    // eslint-disable-next-line
  ): Observable<any> {
    return this._customersData.updateCustomerDataIT$(customerId, customerData);
  }

  public updateInsuredObject$(
    policyId: string,
    productId: string,
    insuredObjectData: Partial<any>, // eslint-disable-line
    options?: RequestInit
    // eslint-disable-next-line
  ): Observable<any> {
    return this._customersData.updateInsuredObject$(policyId, productId, insuredObjectData, options);
  }

  public markAsFavorite$(id: string): Observable<void> {
    return this._customersData.markAsFavorite$(id);
  }

  public unMarkAsFavorite$(id: string): Observable<void> {
    return this._customersData.unMarkAsFavorite$(id);
  }

  // eslint-disable-next-line
  public uploadRegistrationFile$(body: FormData, options?: RequestInit): Observable<any> {
    return this._customersData.uploadRegistrationFile$(body, options);
  }

  // eslint-disable-next-line
  public getDisplayOnboardingScreen(): Observable<any> {
    return this._customersData.getDisplayOnboardingScreen();
  }
}
