import { Injectable, OnDestroy } from '@angular/core';
import { AUTH_METHODS, COUNTRY_DATA } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { AccountQuery } from '@wefoxGroupOneBPPrivate/modules/account/queries/account.query';
import { Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExternalSite } from '../interfaces';
import { AccountDataService } from './account-data.service';
import { BrokerDTOIt, BrokerDTOState } from '@wefoxGroupOneBPCore/interfaces/account.store';

@Injectable({ providedIn: 'root' })
export class AccountService implements OnDestroy {
  private _unsubscribe$: Subject<void> = new Subject();
  public availableToolsChange: Subject<boolean> = new Subject();

  constructor(
    private _accountQuery: AccountQuery,
    private _accountData: AccountDataService,
    private _sessionQuery: SessionQuery
  ) {}

  // eslint-disable-next-line
  public getBroker$(token?: any): Observable<BrokerDTOState> {
    return this._accountData.getBroker$(token);
  }

  // eslint-disable-next-line
  public getBrokerIt$(token?: any): Observable<BrokerDTOIt> {
    return this._accountData.getBrokerIt$(token);
  }

  // eslint-disable-next-line
  public getBrokerPermissions$(token?: any): Observable<boolean> {
    return this._accountData.getBrokerPermissions$(token);
  }

  public getBrokerDeExternalSites$(): Observable<ExternalSite[]> {
    return this._accountData.getBrokerDeExternalSites$();
  }

  public loginDeExternalSites$(externalSiteInfo: ExternalSite): Observable<ExternalSite[]> {
    return this._accountData.loginDeExternalSites$(externalSiteInfo).pipe(
      tap(() => this.availableToolsChange.next(true))
    );
  }

  public modifyExternalSite$(externalSiteInfo: ExternalSite): Observable<ExternalSite[]> {
    return this._accountData.modifyExternalSite$(externalSiteInfo).pipe(
      tap(() => this.availableToolsChange.next(true))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deactivateDeExternalSite$(id: string): Observable<any> {
    return this._accountData.deleteBrokerDeExternalSite$(id).pipe(
      tap(() => this.availableToolsChange.next(true))
    );
  }

  public getFromStoreOrAPI$(): Observable<BrokerDTOState | boolean> {
    const country = this._sessionQuery.getCountry();
    const countryData = COUNTRY_DATA[country];
    const authMethod = countryData.authMethod;
    return this._accountQuery.isAccountReady$.pipe(
      tap((ready: boolean) => {
        if (!ready) {
          if (authMethod === AUTH_METHODS.oauth) {
            return this.getBroker$(this._sessionQuery.getValue().brokerId).subscribe();
          } else if (authMethod === AUTH_METHODS.keycloak) {
            return of({
              id: '3025511641810NZFXJ3GZ61D7',
              sfid: '0033N00000TfHErQAN',
              firstname: 'Carles',
              lastname: 'Rius',
              email: 'carles.rius+de@wefoxgroup.com',
              broker_number: '4067',
              is_ceo: true,
              language: 'de',
              account: {
                id: '29255116418680PEQ9OD3XSE8',
                country: 'DE',
                external_source: {
                  name: 'Carles BP',
                  broker_discounts: [
                    {
                      discount: '5',
                      product_group: 'Liability'
                    },
                    {
                      discount: '5',
                      product_group: 'Household'
                    },
                    {
                      discount: '5',
                      product_group: 'Motor'
                    }
                  ]
                }
              },
              activation_date: '2021-01-11T13:42:02Z'
            }).subscribe();
          }
        }
      })
    );
  }

  public getAutoLoginUrl$(url: string): Observable<{ url: string }> {
    return this._accountData.getAutoLoginUrl$(url);
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
