import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface TextsAdapterComponentOptions {
  class_modifiers?: string;
  content_subtitle?: string;
  content_title?: string;
  description?: string;
  description_placeholder?: string;
  list?: { description?: string; title?: string; };
  main_header?: string;
  main_title?: string;
  required: boolean;
}
@Component({
  selector: 'one-texts-adapter',
  templateUrl: './texts-adapter.component.html'
})
export class TextsAdapterComponent {
  @Input() public content_data: TextsAdapterComponentOptions;
  @Input() public parentGroup: UntypedFormGroup;
}
