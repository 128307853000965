import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, Input, OnInit } from "@angular/core";
import { AllListInfo } from "@wefoxGroupOneBPCore/interfaces/list-page-options.interface";
import { forkJoin } from "rxjs";
import { CustomersService } from "../../../customers/services/customers.service";
import { Contact, ContactsDTO } from "../../interfaces/contacts.interface";
import { ContactsDataService } from "../../services/contacts-data.service";
import { ContactsService } from "../../services/contacts.service";
import { ContactCardComponent } from "../contact-card/contact-card.component";
import { TranslocoModule } from '@ngneat/transloco';


@Component({
  selector: 'wg-contact-carrier-advisor',
  templateUrl: 'contact-carrier-advisor.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule, ContactCardComponent],
  providers: [ContactsService, ContactsDataService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ContactCarrierAdvisorComponent implements OnInit {

  public DIRECTION_HORIZONTAL = 'HORIZONTAL';
  public DIRECTION_VERTICAL = 'VERTICAL';

  @Input() customerId: string;
  @Input() carrierName: string;
  @Input() displayDirection = this.DIRECTION_VERTICAL;

  public criteria = '';
  public pageIndex = 1;
  public pageSize = 9;
  public loading = false;

  public contactList: Contact[];

  private _contactsService = inject(ContactsService);
  private _customersService = inject(CustomersService);

  public ngOnInit(): void {
    if (this.customerId) {
      this.getContactsByCustomer();
    }
    else if (this.carrierName) {
      this.getContactsByCarrierName();
    }
  }

  public getContactsByCustomer(): void {
    this.loading = true;
    this._customersService.getThirdPartyCustomerContracts$(this.customerId).subscribe((response: AllListInfo) => {
      const insurers = response.content.map((contract) => contract.insurer);
      if (insurers.length > 0) {
        const uniqueInsurers = [...new Set(insurers)];
        const obsArray = uniqueInsurers.map((insurer: string) => this._contactsService.getFilteredContacts$(this.pageIndex, this.pageSize, insurer, true));

        forkJoin(obsArray).subscribe((responseList: ContactsDTO[]) => {
          this.contactList = responseList.flatMap((contactList) => contactList.content);
          this.loading = false;
        });
      } else {
        this.contactList = [];
        this.loading = false;
      }
    });
  }

  public getContactsByCarrierName(): void {
    this.loading = true;
    this._contactsService.getFilteredContacts$(this.pageIndex, this.pageSize, this.carrierName, true).subscribe((response: ContactsDTO) => {
      this.contactList = response.content;
      this.loading = false;
    });
  }
}