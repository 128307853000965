// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.list-item {
  list-style: none;
  padding-bottom: 6px;
  padding-left: 8px;
  position: relative;
}
.list-item::before {
  border-radius: 50%;
  color: var(--primary-base);
  content: "•";
  font-size: 40px;
  left: -20px;
  padding-left: 4px;
  position: absolute;
  top: -22px;
}

.list-number-item {
  list-style: none;
  padding-bottom: 6px;
  padding-left: 8px;
  position: relative;
}
.list-number-item::before {
  color: var(--primary-base);
  content: counter(li) ".";
  font-size: 1em;
  font-weight: 600;
  left: -8px;
  position: absolute;
}

li {
  counter-increment: li;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/card-body-list/card-body-list.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,0BAAA;EACA,YAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,UAAA;AACJ;;AAGA;EACE,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,kBAAA;AAAF;AAEE;EACE,0BAAA;EACA,wBAAA;EACA,cAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;AAAJ;;AAIA;EACE,qBAAA;AADF","sourcesContent":["\n.list-item {\n  list-style: none;\n  padding-bottom: 6px;\n  padding-left: 8px;\n  position: relative;\n\n  &::before {\n    border-radius: 50%;\n    color: var(--primary-base);\n    content: '•';\n    font-size: 40px;\n    left: -20px;\n    padding-left: 4px;\n    position: absolute;\n    top: -22px;\n  }\n}\n\n.list-number-item {\n  list-style: none;\n  padding-bottom: 6px;\n  padding-left: 8px;\n  position: relative;\n\n  &::before {\n    color: var(--primary-base);\n    content: counter(li) '.';\n    font-size: 1em;\n    font-weight: 600;\n    left: -8px;\n    position: absolute;\n  }\n}\n\nli {\n  counter-increment: li;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
