export const UxIntervalTimes = {
  fast: 300 as const,
  medium: 500 as const,
  slow: 1000 as const,
  slower: 5000 as const,
  veryFast: 100 as const,
  verySlow: 10000 as const
};

export type UxIntervalTimes = typeof UxIntervalTimes[keyof typeof UxIntervalTimes];
