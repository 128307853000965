import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface ButtonGroupConfig {
  aria_label?: string;
  control_name: string;
  group_label: string;
  options: ButtonGroupOption[];
}

export interface ButtonGroupOption {
  checked?: boolean;
  icon: string;
  label: string;
  value: any; // eslint-disable-line
}

@Component({
  selector: 'one-button-group',
  styleUrls: ['./button-group.component.scss'],
  templateUrl: './button-group.component.html'
})
export class ButtonGroupComponent implements OnInit {
  @Input() public content_data: ButtonGroupConfig;
  public currentValue: string;
  @Input() public parentGroup: UntypedFormGroup;

  /**
   * @ignore
   */
  public ngOnInit(): void {
    this._addControlToForm();
  }

  /**
   * Sets the current value of the control to the value of the selected option.
   *
   * @param {string} value Value of the button clicked
   */
  public onButtonClick(value: string): void {
    this.currentValue = value;
    this.parentGroup.get(this.content_data.control_name).setValue(this.currentValue);
  }

  /**
   * Checks if control exists in form group else it adds the control to the group.
   */
  private _addControlToForm(): void {
    const inputControl = this.parentGroup.get(this.content_data.control_name);

    if (inputControl?.value === '' ) {
      this._setDefaultValue();
    } else {
      this.currentValue = inputControl.value;
    }
  }

  /**
   * Sets the default value by getting the checked valud from the model.
   */
  private _setDefaultValue(): void {
    this.currentValue = this.content_data.options.find(option => option.checked).value;
    this.parentGroup.get(this.content_data.control_name).setValue(this.currentValue);
  }
}
