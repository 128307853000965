import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LegalCheckbox } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { SelfJourneyQuery } from '@wefoxGroupOneBPPublic/modules/self-journey/queries/self-journey.query';
import { FormControlItem } from '@wefoxGroupOneBPShared/constants';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';

export interface LegalChecksAdapterComponentOptions {
  config: Partial<LegalChecksAdapterConfig>;
  legal_error_key: string;
  section_id: string;
  section_title: string;
}

export interface LegalChecksAdapterConfig {
  checkboxes: {
    key: string;
    placeholders: string[];
  }[];
  files: string[];
}

@Component({
  selector: 'one-legal-checks-adapter',
  templateUrl: './legal-checks-adapter.component.html'
})
export class LegalChecksAdapterComponent implements OnInit {
  @Input() public content_data: Partial<LegalChecksAdapterComponentOptions>;
  public legalCheckBoxes: LegalCheckbox[];
  public legalControlNames: string[];
  public legalError = false;
  public legalFiles: string[];
  @Input() public parentGroup: UntypedFormGroup;
  public translationPrefix: string;

  constructor(private _formService: FormService, private _selfJourneyQuery: SelfJourneyQuery) {}

  public getDownloadIcon(controlName: string): string {
    return this.parentGroup?.get(controlName)?.value === true ? 'step-done' : 'file-download';
  }

  public getFileState(controlName: string): string {
    return this.parentGroup?.get(controlName)?.valid ? '' : 'is-invalid';
  }

  public getIconColor(controlName: string): string {
    if (this.getDownloadIcon(controlName) === 'step-done') {
      return 'success-dark';
    }

    if (this.getFileState(controlName) === 'is-invalid') {
      return 'danger-base';
    }

    return 'neutral-end';
  }

  // eslint-disable-next-line
  public getPriceSummary(): { [key: string]: any } {
    return {
      label: `_PROD_payment_frequency_premium_${this.parentGroup
        .get(FormControlItem.payment_frequency.name)
        .value.key.replace('-', '_')}`,
      price: this.parentGroup.get('quote').value
    };
  }

  public markAsDownloaded(controlName: string): void {
    this.parentGroup.get(controlName).setValue(true);
  }

  public ngOnInit(): void {
    // TODO: Get from MDW for now until we move the whole JSON to MDW.
    const legalSection = this._selfJourneyQuery.getLegalConfig() || this.content_data.config;
    this.legalCheckBoxes = legalSection.checkboxes;
    this.legalFiles = legalSection.files;
    this.legalControlNames = this._formService.mapLegalControlNames(legalSection);
    this.translationPrefix = this._selfJourneyQuery.getTranslationPrefix(this._selfJourneyQuery.getActive().id);
    this._addLegalControls();
  }

  private _addLegalControls(): void {
    this.legalControlNames.forEach(name => {
      this.parentGroup.addControl(name, new UntypedFormControl());
    });
  }
}
