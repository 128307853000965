// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wipo-c-dropdownNav {
  align-items: center;
  display: flex;
}

.wipo-a-list {
  display: block;
}
.wipo-a-list .dropdown-header {
  color: var(--neutral-end);
  min-width: 20rem;
}
.wipo-a-list .dropdown-header,
.wipo-a-list .heading {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.wipo-a-list.-hidden {
  display: none;
}

.wipo-a-listItem.-selected {
  background-color: var(--hover-bg);
}
.wipo-a-listItem:hover {
  background-color: var(--hover-bg);
  cursor: pointer;
}
.wipo-a-listItem:hover.-selected {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/header/dropdown-nav/dropdown-nav.component.scss"],"names":[],"mappings":"AACA;EACE,mBAAA;EACA,aAAA;AAAF;;AAGA;EACE,cAAA;AAAF;AAEE;EACE,yBAAA;EACA,gBAAA;AAAJ;AAGE;;EAEE,gBAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAIE;EACE,aAAA;AAFJ;;AAOE;EACE,iCAAA;AAJJ;AAOE;EACE,iCAAA;EACA,eAAA;AALJ;AAOI;EACE,eAAA;AALN","sourcesContent":["\n.wipo-c-dropdownNav {\n  align-items: center;\n  display: flex;\n}\n\n.wipo-a-list {\n  display: block;\n\n  .dropdown-header {\n    color: var(--neutral-end);\n    min-width: 20rem;\n  }\n\n  .dropdown-header,\n  .heading {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n  }\n\n  &.-hidden {\n    display: none;\n  }\n}\n\n.wipo-a-listItem {\n  &.-selected {\n    background-color: var(--hover-bg);\n  }\n\n  &:hover {\n    background-color: var(--hover-bg);\n    cursor: pointer;\n\n    &.-selected {\n      cursor: default;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
