import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WgLoaderModule } from '../../components/loader/loader.module';
import { WgTooltipModule } from '../wg-tooltip/wg-tooltip.module';
import { WgUiIconModule } from '../wg-ui-icon/wg-ui-icon.module';
import { InfoBannerTableComponent } from './components/info-banner-table/info-banner-table.component';
import { ResultComponent } from '@wefoxGroupOneBPDJComponents/result/result.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    InfoBannerTableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WgUiIconModule,
    WgTooltipModule,
    WgLoaderModule,
    TranslocoModule,
    ResultComponent
  ]
})
export class WgTable2Module {}
