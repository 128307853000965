import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';

export interface TypeaheadAdapterComponentOption {
  category?: string;
  category_options?: Partial<WgDropdownOption>;
  control_name: string;
  label: string;
  placeholder?: string;
  required: boolean;
}

@Component({
  selector: 'one-typeahead-adapter',
  templateUrl: './typeahead-adapter.component.html'
})
export class TypeaheadAdapterComponent implements OnInit {
  @Input() public content_data: TypeaheadAdapterComponentOption;
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public typeaheadContentOptions: WgDropdownOption[];
  public typeaheadContentOptionsFiltered: WgDropdownOption[];

  constructor(private _formService: FormService, private _sessionQuery: SessionQuery) {}

  public ngOnInit(): void {
    if (!this.content_data.category) {
      if (!this.typeaheadContentOptions) {
        this._sessionQuery.typeaheadState$.subscribe(state => {
          this.typeaheadContentOptions = this._formService.sort(state[this.content_data.control_name], 'value');
        });
      }
    }
  }

  public searchOptions(keyword: string): void {
    this.typeaheadContentOptionsFiltered = this.typeaheadContentOptions?.filter((option: WgDropdownOption) => {
      return option.value.toUpperCase().includes(keyword.toUpperCase());
    });
  }
}
