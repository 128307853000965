import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { KeycloakToken } from '@wefoxGroupOneBPCore/interceptors/keycloak-token.interface';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { DropdownNavOptions } from '@wefoxGroupOneBPCore/interfaces';
import { Filter } from '@wefoxGroupOneBPCore/interfaces/filter.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { ConcludeSectionAction } from '@wefoxGroupOneBPCore/interfaces/conclude-adapter.interface';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { SwitchTariffResponse, TariffConcludeResponse } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import { DraftData } from '@wefoxGroupOneBPCore/interfaces/draft.interface';
import { HeaderBackOptions } from '@wefoxGroupOneBPCore/interfaces/header-back-options.interface';

export interface SessionState {
  bannerDraftOptions: BannerOptions;
  brokerDiscount: string;
  brokerId: string;
  itBrokerPermissions: boolean;
  concludeResponse: Partial<SwitchTariffResponse | TariffConcludeResponse>;
  concludeSectionActions: ConcludeSectionAction;
  countriesList: WgDropdownOption[];
  country: COUNTRIES;
  crossSellData: Partial<DraftData>;
  currentPage: string;
  dropdownOptionsState: {
    category: Partial<DropdownComponentOptions>;
    sub_category: Partial<DropdownComponentOptions>;
  };
  externalLink: {
    errorKey: string;
    type: string;
  };
  externalProductSeen: boolean;
  formCurrentState: {
    isLegalEntity: boolean;
    isOffer: boolean;
    isPrefilled: boolean;
  };
  headerBackOptions: Partial<HeaderBackOptions>;
  // eslint-disable-next-line
  interactiveTariffState: any; // TODO: Create interface
  keycloakToken: KeycloakToken;
  locale: string;
  ocrUploadVisible: boolean;
  pageStates: { [key: string]: { filters: { [key: string]: Partial<Filter> } } };
  reviewCardsDetails: Partial<DetailCardOptions>[];
  rolePermissions: string[];
  tariffState: any; // eslint-disable-line
  tariffValues: { [key: string]: any }; // eslint-disable-line
  toasterRoleOptions: BannerOptions;
  token: {
    expires_in: number;
  };
  typeaheadState: {
    contract_number: Partial<DropdownNavOptions>[];
    customer_name: Partial<DropdownNavOptions>[];
    previous_insurer: Partial<DropdownNavOptions>[];
  };
  ui: {
    loading: boolean;
    loadingMessage: string;
  };
  uploadedFiles: File[];
}

const createInitialState: SessionState = {
  bannerDraftOptions: null,
  brokerDiscount: null,
  brokerId: null,
  itBrokerPermissions: null,
  concludeResponse: null,
  concludeSectionActions: null,
  countriesList: null,
  country: null,
  crossSellData: null,
  currentPage: null,
  dropdownOptionsState: {
    category: null,
    sub_category: null
  },
  externalLink: {
    errorKey: null,
    type: null
  },
  externalProductSeen: false,
  formCurrentState: {
    isLegalEntity: false,
    isOffer: false,
    isPrefilled: false
  },
  headerBackOptions: null,
  interactiveTariffState: null,
  keycloakToken: null,
  locale: null,
  ocrUploadVisible: false,
  pageStates: {},
  reviewCardsDetails: null,
  rolePermissions: null,
  tariffState: null,
  tariffValues: null,
  toasterRoleOptions: null,
  token: {
    expires_in: null
  },
  typeaheadState: {
    contract_number: null,
    customer_name: null,
    previous_insurer: null
  },
  ui: {
    loading: false,
    loadingMessage: ''
  },
  uploadedFiles: null
};

@Injectable()
@StoreConfig({ name: 'session', resettable: true })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState);
  }
}
