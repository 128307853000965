import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TariffTypeFilters } from '@wefoxGroupOneBPCore/constants/contracts.constants';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { CustomHttpParamEncoder } from '@wefoxGroupOneBPCore/utils/custom-http-param-encoder';
import { OriginFilters } from '@wefoxGroupOneBPPrivate/modules/contracts/constants/contracts.constants';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ContractsDataService extends BaseDataService {
  private _companyEndpoint = 'contracts/company';
  private _contractsEndpoint = 'contracts';
  private _thirdPartyContractsEndpoint = 'portfolio/contracts';
  private _policyEndpoint = 'policy';
  private _termLifePolicyEndpoint = 'query/policy';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  // eslint-disable-next-line
  public downloadApplicationZipFiles$(applicationId: string): Observable<any> {
    const url = this.getUrlIT({
      path: `${this._policyEndpoint}/zipped/policy/${applicationId}/documents`
    });
    return this.get$(url, { responseType: 'blob' as 'json' });
  }

  /**
   * Get contracts for the current broker
   */
  public getAllContracts$(
    criteria?: string,
    page = 1,
    size?: number,
    sort?: string,
    dateType?: string,
    tariffType?: string,
    ownType?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({ criteria, page, size, sort, dateType, tariffType });
    const endpoint = ownType === 'Customer' ? this._companyEndpoint : this._contractsEndpoint;
    const url = this.getUrl({
      path: endpoint
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getThirdPartyContracts$(
    page = 1,
    size?: number,
    sort?: string,
    customerName?: string,
    insurer?: string
  ): Observable<AllListInfo> {

    const params = this.getURLParams({ page, size, sort, customerName, insurer });
    const url = this.getUrl({
      path: `${this._thirdPartyContractsEndpoint}`
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getTermLifePolicies$(brokerId: string, size?: number): Observable<AllListInfo> {
    const params = this.getURLParams({ size });
    const url = this.getUrlTenants({
      path: `${this._termLifePolicyEndpoint}?broker_id=${brokerId}`
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getFilteredContracts$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>,
    onlyOwnCustomers: boolean,
    hasCustomerPermission: boolean
  ): Observable<unknown> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('page', `${pageIndex - 1}`)
      .append('size', `${pageSize}`);
    if (sortField && sortOrder) {
      sortOrder = sortOrder === 'ascend' ? 'asc' : 'desc';
      params = params.append('sort', `${sortField},${sortOrder}`);
    }
    filters.forEach(filter => {
      filter.value.forEach(value => {
        if (filter.key === 'tariff') {
          const tariffs = TariffTypeFilters[value];
          if (Array.isArray(tariffs)) {
            tariffs.forEach((tariff: string) => (params = params.append(filter.key, tariff)));
          } else {
            params = params.append(filter.key, tariffs);
          }
        } else if (filter.key === 'origin') {
          const origins = OriginFilters[value];
          origins.forEach((origin: string) => (params = params.append(filter.key, origin)));
        } else if (filter.key === 'ownType') {
          params = params.append(filter.key, onlyOwnCustomers);
        } else {
          params = params.append(filter.key, value);
        }
      });
    });
    const url = this.getUrl({
      path: `${this._contractsEndpoint}${onlyOwnCustomers || !hasCustomerPermission ? '' : '/company'}`
    });
    return this.get$(url, { params }).pipe(catchError(() => of({ content: [] })));
  }

  public getFilteredPolicies$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
  ): Observable<unknown> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('page', `${pageIndex - 1}`)
      .append('size', `${pageSize}`);
    if (sortField && sortOrder) {
      sortOrder = sortOrder === 'ascend' || sortOrder === 'asc' ? 'asc' : 'desc';
      params = params.append('sort', `${sortField},${sortOrder}`);
    }
    filters.forEach(filter => {
      filter.value.forEach(value => {
        if (filter.key === 'tariff') {
          const tariffs = TariffTypeFilters[value];
          if (Array.isArray(tariffs)) {
            tariffs.forEach((tariff: string) => (params = params.append(filter.key, tariff)));
          } else {
            params = params.append(filter.key, tariffs);
          }
        } else if (filter.key === 'origin') {
          const origins = OriginFilters[value];
          origins.forEach((origin: string) => (params = params.append(filter.key, origin)));
        } else {
          params = params.append(filter.key, value);
        }
      });
    });
    const url = this.getUrl({
      path: `${this._policyEndpoint}`
    });
    return this.get$(url, { params }).pipe(catchError(() => of({ content: [] })));
  }

  public getPoliciesIT$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filters: Array<{ key: string; value: string[] }>
    // eslint-disable-next-line
  ): Observable<any> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('page', `${pageIndex - 1}`)
      .append('size', `${pageSize}`);
    if (sortField && sortOrder) {
      sortOrder = sortOrder === 'ascend' || sortOrder === 'asc' ? 'asc' : 'desc';
      params = params.append('sort', `${sortField},${sortOrder}`);
    }
    filters.forEach(filter => {
      filter.value.forEach(value => {
        params = params.append(filter.key, value);
      });
    });

    const url = this.getUrlIT({
      path: `${this._policyEndpoint}`
    });
    return this.get$(url, { params }).pipe(catchError(() => of({ content: [] })));
  }
}
