import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { CMSConfig } from '@wefoxGroupOneBPCore/interfaces/cms-config.interface';

const createInitialState: Partial<CMSConfig> = {};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cms' })
export class CMSStore extends Store<Partial<CMSConfig>> {
  constructor() {
    super(createInitialState);
  }
}
