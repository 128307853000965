import { Injectable } from '@angular/core';
import { CurrentPackage } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';

export interface ProductPackageDefinition {
  basic?: CurrentPackage;
  comfort?: CurrentPackage;
  compact?: CurrentPackage;
  green?: CurrentPackage;
  green_comfort?: CurrentPackage;
  green_compact?: CurrentPackage;
}

@Injectable()
export class TariffService {
  public getDefaultPackage(availablePackages: ProductPackageDefinition): CurrentPackage {
    return Object.values(availablePackages).find(pack => pack.default);
  }
}
