import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@wefoxGroupOneBPCore/services/base-data.service';
import { ConfigStore } from '@wefoxGroupOneBPCore/stores';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SessionQuery } from '../queries';

@Injectable({ providedIn: 'root' })
export class ConfigDataService extends BaseDataService {

  constructor(
      protected httpClient: HttpClient,
      protected sessionQuery: SessionQuery,
      private _configStore: ConfigStore) {
    super(httpClient, sessionQuery);
  }

  // eslint-disable-next-line
  public getMasterConfigData$(country: string): Observable<any> {
    const { privateConfigUrl } = this.getMasterDataUrls(country);
    const configSource$ = this.get$(privateConfigUrl).pipe(
      // eslint-disable-next-line
      tap((configData: any) => {
        this._configStore.update({
          config: configData.config,
          privateFooters: configData.config.priv.footer_options,
          privateHeaders: configData.config.priv.header_options,
          privateSidebar: configData.config.priv.sidebar_options,
        });
      })
    );

    return configSource$;
  }
}
