import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { AUTH_GRANT_TYPES } from '@wefoxGroupOneBPCore/constants/auth.constants';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { AccountStore } from '@wefoxGroupOneBPCore/interfaces/account.store';
import { OAuth2AccessToken } from '@wefoxGroupOneBPCore/interfaces/authentication.interface';
import { ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgToasterService } from '@wefoxGroupOneBPCore/services/wg-toaster.service';
import { ProductStore } from '@wefoxGroupOneBPCore/stores/product.store';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from 'ngx-auth';
import { Observable, from, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AUTH_METHODS, COUNTRY_DATA } from '../constants';
import { StatsStore } from '../stores/stats.store';
import { AppInitializerService } from './app-initializer.service';
import { SessionService } from './session.service';
import { TokenStorageService } from './token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class BaseAuthenticationService implements AuthService {
  private _authMethod: AUTH_METHODS;
  private _authTokenEndpoint = 'oauth/token';
  private _authUrl = environment.authUrl;
  private _basicAuthToken = environment.basicAuthToken;
  private _skipUrls: RegExp[];
  private _waitBeforeReloading = UxIntervalTimes.medium;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _toaster: WgToasterService,
    private _tokenStorage: TokenStorageService,
    private _sessionService: SessionService,
    private _productStore: ProductStore,
    private _accountStore: AccountStore,
    private _sessionQuery: SessionQuery,
    private _statsStore: StatsStore,
    private _appInitializerService: AppInitializerService,
    private _keycloakService: KeycloakService
  ) {
    const apiUrl = environment.apiUrl;
    const authUrl = environment.authUrl;
    const translationsUrl = environment.translations.url;
    this._skipUrls = [
      RegExp(`^${apiUrl}/\.*/main-config/public`),
      RegExp(`^${apiUrl}/\.*/application/version`),
      RegExp(`^${apiUrl}/\.*/users/create-password`),
      RegExp(`^${authUrl}/\.*/oauth`),
      RegExp(`^${authUrl}\.*/oauth`),
      RegExp(`^${translationsUrl}`),
      RegExp(`^${apiUrl}/\.*/calendar/events/available/*`),
      RegExp('files-sandbox.wefox.com*')
    ];
    const country = this._appInitializerService.getCountry();
    const countryData = COUNTRY_DATA[country];
    this._authMethod = countryData.authMethod;
  }

  public clearAccessData(): void {
    this._sessionService.setBrokerId(null);
    this._productStore.reset();
    this._accountStore.reset();
    this._tokenStorage.clear();
    this._statsStore.reset();
    // Clear AWS cognito id
    const key = Object.keys(window.localStorage).find(it => it.startsWith('aws'));
    window.localStorage.removeItem(key);
  }

  public getAccessToken(): Observable<string> {
    return this._tokenStorage.getAccessToken();
  }

  public getRefreshToken(): Observable<string> {
    return this._tokenStorage.getRefreshToken();
  }

  public isAuthorized(): Observable<boolean> {
    return this._tokenStorage.getAccessToken().pipe(map(token => !!token));
  }

  public logout(): void {
    this.clearAccessData();
    if (this._authMethod === AUTH_METHODS.keycloak) {
      this._keycloakService.logout();
    }
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/login`);
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return response.status === 401;
  }

  // eslint-disable-next-line
  public refreshToken(): Observable<any> {
    if (this._authMethod === AUTH_METHODS.oauth) {
      return this.getRefreshToken().pipe(
        switchMap((refreshToken: string) => {
          const body = new HttpParams()
            .set('grant_type', AUTH_GRANT_TYPES.refreshToken)
            .set('refresh_token', refreshToken);

          const headers = new HttpHeaders({
            Authorization: `Basic ${this._basicAuthToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
            Country: this._sessionQuery.getValue().country
          });
          return this._http.post(`${this._authUrl}/${this._authTokenEndpoint}`, body.toString(), { headers });
        }),
        tap(tokens => this.setAccessData(tokens as OAuth2AccessToken)),
        catchError(err => {
          this._toaster.show({ title: translate('_GEN_session_expired'), type: ToasterType.error });
          this.clearAccessData();
          setTimeout(() => {
            location.reload();
          }, this._waitBeforeReloading);
          return throwError(err);
        })
      );
    } else if (this._authMethod === AUTH_METHODS.keycloak) {
      return from(this._keycloakService.updateToken()).pipe(
        map(() => this._keycloakService.getKeycloakInstance().refreshToken),
        tap(() => this.setKeycloakAccessData()),
        catchError(err => {
          this._toaster.show({ title: translate('_GEN_session_expired'), type: ToasterType.error });
          this.clearAccessData();
          setTimeout(() => {
            location.reload();
          }, this._waitBeforeReloading);
          return throwError(err);
        })
      );
    }
  }

  public setAccessData({ access_token, refresh_token }: OAuth2AccessToken): void {
    this._saveAccessData({ access_token, refresh_token });
  }

  public async setKeycloakAccessData(): Promise<void> {
    const access_token: string = await this._keycloakService.getToken();
    const refresh_token: string = this._keycloakService.getKeycloakInstance().refreshToken;
    this._saveAccessData({ access_token, refresh_token });
  }

  // eslint-disable-next-line
  public skipRequest(request: HttpRequest<any>): boolean {
    const skip = this._skipUrls.find((url: RegExp) => url.test(request.url));
    return skip !== undefined;
  }

  /**
   * Save access data from API in the storage
   *
   * @private
   * @param {string} access_token
   * @param {string} refresh_token
   */
  private _saveAccessData({ access_token, refresh_token }: OAuth2AccessToken): void {
    this._tokenStorage.setTokens({ accessToken: access_token, refreshToken: refresh_token });
  }
}
