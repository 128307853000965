import { CountryDefinition } from '@wefoxGroupOneBPCore/interfaces';
import { LOCALES } from './languages.constants';

export const enum COUNTRIES {
  at = 'AT',
  ch = 'CH',
  de = 'DE',
  es = 'ES',
  fr = 'FR',
  it = 'IT',
  jp = 'JP',
  li = 'LI',
  intl = 'INTL'
}

export const enum AUTH_METHODS {
  keycloak = 'KEYCLOAK',
  oauth = 'OAUTH'
}

export const COUNTRY_DEFAULT: CountryDefinition = {
  authMethod: AUTH_METHODS.oauth,
  countryCode: COUNTRIES.de,
  currencyCode: '€',
  currencySymbol: 'EUR',
  dateFormat: 'dd.MM.YYYY',
  defaultLanguage: LOCALES.de,
  languages: [LOCALES.en, LOCALES.de]
};

export const COUNTRY_CH: CountryDefinition = {
  authMethod: AUTH_METHODS.oauth,
  countryCode: COUNTRIES.ch,
  currencyCode: 'CHF',
  currencySymbol: 'CHF',
  dateFormat: 'dd.MM.YYYY',
  defaultLanguage: LOCALES.de,
  languages: [LOCALES.fr, LOCALES.de]
};

export const COUNTRY_IT: CountryDefinition = {
  authMethod: AUTH_METHODS.keycloak,
  countryCode: COUNTRIES.it,
  currencyCode: '€',
  currencySymbol: 'EUR',
  dateFormat: 'dd.MM.YYYY',
  defaultLanguage: LOCALES.it,
  languages: [LOCALES.en, LOCALES.it]
};

export const COUNTRY_INTL: CountryDefinition = {
  authMethod: AUTH_METHODS.keycloak,
  countryCode: COUNTRIES.intl,
  currencyCode: '€',
  currencySymbol: 'EUR',
  dateFormat: 'dd.MM.YYYY',
  defaultLanguage: LOCALES.de,
  languages: [LOCALES.en, LOCALES.de]
};

export const COUNTRY_DATA: { [country in COUNTRIES]?: CountryDefinition } = {
  [COUNTRIES.de]: COUNTRY_DEFAULT,
  [COUNTRIES.ch]: COUNTRY_CH,
  [COUNTRIES.it]: COUNTRY_IT,
  [COUNTRIES.intl]: COUNTRY_INTL
};
