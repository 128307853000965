import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { map } from 'rxjs';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';


@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    const country = this._getCountryUrl().toLocaleLowerCase();
    return this.http.get(`${environment.translations.url}${country}.json`).pipe(
      map((response) => response[lang])
    );
  }

  private _getCountryUrl(): COUNTRIES {
    return new URL(location.href).pathname.split('/')[1].toUpperCase() as COUNTRIES;
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['en', 'de', 'ch', 'it', 'fr'],
        defaultLang: 'en',
        prodMode: !isDevMode(),
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
