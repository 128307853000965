// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  :host {
    &::ng-deep {
      --comet-color-disabled-label: #301254;
      --comet-color-disabled-bg: #f9f8fa;
      --comet-color-invalid: #ea3e35;
    }
  }
  .invalid-feedback {
    display: block;
    margin-top: 0px;
  }

  .comet-disabled::part(comet-multi-select-container) {
    border: none !important;
  }
  `, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/multi-select/multi-select.component.ts"],"names":[],"mappings":";EACE;IACE;MACE,qCAAqC;MACrC,kCAAkC;MAClC,8BAA8B;IAChC;EACF;EACA;IACE,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":["\n  :host {\n    &::ng-deep {\n      --comet-color-disabled-label: #301254;\n      --comet-color-disabled-bg: #f9f8fa;\n      --comet-color-invalid: #ea3e35;\n    }\n  }\n  .invalid-feedback {\n    display: block;\n    margin-top: 0px;\n  }\n\n  .comet-disabled::part(comet-multi-select-container) {\n    border: none !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
