import { Component, Input } from '@angular/core';

export interface EmptyStateComponentOptions {
  buttonAction?: Function; // eslint-disable-line
  buttonText?: string;
  subtitle: string;
  title: string;
}
@Component({
  selector: 'one-empty-state',
  templateUrl: './empty-state.component.html'
})
export class EmptyStateComponent {
  @Input() public emptyStateOptions: EmptyStateComponentOptions;
}
