export const ListCategory = {
  commissions: 'commissions',
  claims: 'claims',
  contracts: 'contracts',
  customers: 'customers',
  digital_signatures: 'digital_signatures',
  drafts: 'drafts',
  offers: 'offers',
  leads: 'leads',
  appointments: 'appointments',
  quotes: 'quotes',
  roles: 'roles',
  third_party_contracts: 'third_party_contracts',
  transfers: 'transfers',
  users: 'users',
  external_tools: 'external_tools'
};

export const ListSubCategory = {
  customer: 'customer',
  homeowner: 'homeowner',
  household: 'household',
  liability: 'liability',
  motor: 'motor',
  product: 'product'
};

export const ListType = {
  contract: 'contract',
  customer: 'customer',
  draft: 'draft',
  offer: 'offer'
};

export const ProdcutTypeParameterName = ['product_type']; // May be different for each list

export const SortingOrder = {
  asc: 'asc',
  desc: 'desc'
};

export const SortingOrderLongForm = {
  ascend: 'ascend',
  descend: 'descend'
};

export const ListCustomerType = {
  legal: 'LegalEntity',
  private: 'PersonAccount'
};

export const ListTableKey = {
  entity: {
    legal: '_GEN_legal',
    private: '_GEN_client'
  }
};

export const PAGE_DATA_DEFAULTS = {
  from: 0,
  to: 0,
  total: 0
};
