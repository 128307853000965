import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { CookieService } from '@wefoxGroupOneBPCore/services';
import { LOCALES } from '@wefoxGroupOneBPCore/constants';
import { CentralizedQuery, SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { SessionService } from '@wefoxGroupOneBPCore/services/session.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { DialogComponent } from '@wefoxGroupOneBPShared/components';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'one-language-selector',
  styleUrls: ['./language-selector.component.scss'],
  standalone: true,
  templateUrl: './language-selector.component.html',
  imports: [CommonModule, TranslocoModule]

})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  @Input() public closeDropdown: Subject<void>;
  public currentLocale$;
  public expanded = false;
  public locales$;
  @ViewChild('menuButton') public menuButton: ElementRef;
  @ViewChild('menuList') public menuList: ElementRef;

  private _COOKIE_DROPDOWN = 'dropdownLangagueDialog';
  private _hideDialog: boolean;
  private _unsubscribe$: Subject<void> = new Subject();

  public constructor(
    private _analyticsService: AnalyticsService,
    private _common: CentralizedQuery,
    private _cookieService: CookieService,
    private _session: SessionQuery,
    private _sessionService: SessionService,
    private translocoService: TranslocoService,
    private _modal: WgModalService
  ) { }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.currentLocale$ = this._session.locale$;
    this.locales$ = this._common.getLocales();
    this.closeDropdown.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      this.expanded = false;
      this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
      this.menuList.nativeElement.classList.add('-hidden');
      this.menuButton.nativeElement.classList.remove('-active');
    });
    if (this._cookieService.get(this._COOKIE_DROPDOWN)) {
      this._hideDialog = true;
    }
  }

  /**
   * Listener for be aware if the user clicks outside the menu
   * https://stackoverflow.com/questions/51616574/angular-6-click-outside-menu#answer-51639799
   * @param targetElement Current click from user
   */
  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: EventTarget): void {
    if (this.menuList) {
      const clickedInside =
        this.menuButton.nativeElement.contains(targetElement) || this.menuList.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.expanded = false;

        this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
        this.menuList.nativeElement.classList.add('-hidden');
        this.menuButton.nativeElement.classList.remove('-active');
      }
    }
  }

  public onLanguageChange(locale: string): void {
    if (this._hideDialog || new URL(location.href).searchParams.get('locale')) {
      this._setLocale(locale);
    } else {
      this._modal.setAndShow({
        contentView: DialogComponent,
        contentViewOptions: {
          options: {
            buttonAction: (checkboxValue) => this._setLocale(locale, checkboxValue),
            buttonActionSecondary: () => this._modal.hide(),
            buttonText: '_PROD_reload_button',
            buttonTextSecondary: '_GEN_discard',
            checkboxLabel: '_OCR_scan_complete_checkbox',
            description: '_GEN_reload_page_modal_text',
            isDanger: true,
            title: '_GEN_reload_page_modal_title'
          }
        }
      });
    }
  }

  public toggleMenu(event: Event): void {
    this.closeDropdown.next();
    if (event) {
      event.stopPropagation();
    }
    this.expanded = this.menuButton.nativeElement.getAttribute('aria-expanded') === 'true';
    this.menuButton.nativeElement.setAttribute('aria-expanded', `${!this.expanded}`);
    this.menuButton.nativeElement.classList.toggle('-active');
    this.menuList.nativeElement.classList.toggle('-hidden');
  }

  private _setLocale(locale: string, checkboxValue?: boolean) {
    if (new URL(location.href).searchParams.get('locale')) {
      const url = new URL(location.href);
      url.searchParams.set('locale', locale);
      location.href = url.toString();
    } else {
      if (checkboxValue) {
        this._cookieService.set(this._COOKIE_DROPDOWN, checkboxValue.toString());
      }
  
      let eventName = GAEventCategories.DifferentLanguage;
  
      if (this.translocoService.getActiveLang() === locale) {
        eventName = GAEventCategories.SameLanguage;
      }
  
      this._analyticsService.event({
        GAEventAction: GAEventActions.ClickOn,
        GAEventCategory: `${eventName} ${locale}` as GAEventCategories
      });
      
      this.translocoService.setActiveLang(locale);
      this._sessionService.setLocale(locale as LOCALES);
      this._cookieService.set('locale', locale);
      this.toggleMenu(null);
      location.reload();
    }
  }
}
