import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-document-upload-adapter',
  templateUrl: './document-upload-adapter.component.html'
})
export class DocumentUploadAdapterComponent implements AfterViewInit {
  @Input() public content_data;
  @Input() public parentGroup: UntypedFormGroup;

  public ngAfterViewInit(): void {
    if (this.content_data.required && !this.content_data.files?.length) {
      setTimeout(() => {
        this.parentGroup.get(this.content_data.control_name).setErrors({ required: true });
      }, 200);
    }
  }
}
