import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { DuplicateFieldOptions } from '@wefoxGroupOneBPCore/interfaces/dynamic-forms.interface';

export interface DuplicateFieldAdapterComponentOptions {
  control_name: string;
  label: string;
  class: string;
}

@Component({
  selector: 'one-duplicate-field-adapter',
  styleUrls: ['./duplicate-field-adapter.component.scss'],
  templateUrl: './duplicate-field-adapter.component.html'
})
export class DuplicateFieldAdapterComponent implements AfterViewInit, OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public maxDuplicateFields: number;

  constructor(private _fb: FormBuilder) {}

  public get dynamicFormArray(): FormArray {
    return this.parentGroup.get(this.content_data.options.control_name) as FormArray;
  }

  ngAfterViewInit(): void {
    this.parentGroup.addControl(this.content_data.options.control_name, this._fb.array([this.createForm()]));
  }

  public ngOnInit(): void {
    this.parentGroup.removeControl(this.content_data.options.control_name);
    this.maxDuplicateFields = (this.content_data.options as DuplicateFieldOptions)?.max_duplicate_fields;
  }

  public createForm(): FormGroup {
    return this._fb.group(this._buildFormControls());
  }

  public duplicateFields(): void {
    this.dynamicFormArray.push(this.createForm());
  }

  public removeFields(index: number): void {
    this.dynamicFormArray.removeAt(index);
  }

  private _buildFormControls(): { [key: string]: FormControl } {
    let group = {};

    this.content_data.options.config.forEach(row => {
      row.columns.forEach(c => {
        group = { ...group, [c.options.control_name]: ['', c.options.required ? Validators.required : []] };
      });
    });

    return group;
  }
}
