import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { protectedGuard } from 'ngx-auth';
import { ProductRecommendationsComponent } from './components/product-recommendations/product-recommendations.component';
import { RiskProfileComponent } from './components/risk-profile/risk-profile.component';
import { RiskAnalysisComponent } from './risk-analysis.component';

const routes: Routes = [
  {
    canActivate: [protectedGuard],
    component: RiskAnalysisComponent,
    path: ''
  },
  {
    canActivate: [protectedGuard],
    component: RiskProfileComponent,
    path: 'profile/:riskAnalysisId'
  },
  {
    canActivate: [protectedGuard],
    component: ProductRecommendationsComponent,
    path: 'profile/:riskAnalysisId/products'
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
  providers: []
})
export class RiskAnalysisRoutingModule { }
