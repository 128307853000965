import { AvailableToolsComponent } from '@wefoxGroupOneBPShared/components/available-tools/available-tools.component';
import { CarrierExcerptComponent } from '@wefoxGroupOneBPShared/components/carrier-excerpt/carrier-excerpt.component';
import { DynamicBannerComponent } from '@wefoxGroupOneBPShared/components/dynamic-banner/dynamic-banner.component';
import { PerformanceSectionComponent } from '@wefoxGroupOneBPShared/components/performance-section/performance-section.component';
import { ThirdPartyProductSelectionComponent } from '../../modules/carriers/components/third-party-product-selection/third-party-product-selection.component';
import { ContractDashboardComponent } from '../../modules/contracts/components/contract-dashboard/contract-dashboard.components';
import { PolicyTableDashboardComponent } from '../../modules/contracts/components/policy-table-dashboard/policy-table-dashboard.component';
import { LeadPricesComponent } from '../../modules/dashboard/lead-prices/lead-prices.components';

export const DETAILS_CARD_TYPE = {
  details: 'details',
  note: 'note',
  table: 'table',
  riskProfile: 'riskProfile',
  AIProductRecommendation: 'AIProductRecommendation',
  stages: 'stages',
  contactCarrierAdvisor: 'contactCarrierAdvisor',
  createFksApplication: 'createFksApplication',
  startBridgeConsultation: 'startBridgeConsultation',
  downloadDocuments: 'downloadDocuments',
  cancelPolicy: 'cancelPolicy',
  startPortfolioTransfer: 'startPortfolioTransfer'
};

export const DETAILS_CARD_CATEGORY = {
  claim: 'claim_details',
  contract: 'contract_details',
  contractTEST: 'contract_details_test',
  customer: 'customer_details',
  customerTEST: 'customer_details_test',
  draft: 'draft_details',
  lead: 'lead',
  lead_detail: 'lead_details'
};

export const DETAILS_CARD_SUBCATEGORY = {
  claim: 'claim',
  wefoxCustomer: 'wefox_customer',
  draft: 'draft',
  homeowner: 'homeowner',
  household: 'household',
  householdAndLiability: 'householdAndLiability',
  household_and_liability: 'household_and_liability',
  lead: 'lead',

  liability: 'liability',
  motor: 'motor',
  thirdParty: 'third_party'
};

export const DETAILS_CARD_SUB_SUBCATEGORY = {
  car: 'car',
  c_suite: 'c_suite',
  home: 'home',
  household: 'household',
  liability: 'liability',
  light_truck: 'light_truck',
  moped: 'moped',
  motorcycle: 'motorcycle',
  switch: 'switch_insurance'
};

export const DETAILS_CARD_SUBSUBCATEGORY = {
  liability: 'liability',
  home: 'home',
  household: 'household'
};

export const DETAILS_CARD_FOOTER_ITEM_TYPE = {
  textWithIcon: 'TEXT_WITH_ICON',
  callToAction: 'CALL_TO_ACTION'
};

export const VIEW_CONFIG_CATEGORY = {
  dashboard: 'dashboard',
  dashboard_products: 'dashboard_products'
};

export const VIEW_CONFIG_TARGET = {
  broker: 'broker',
  exclusiveAdvisor: 'exclusive_advisor'
};

export const TABLE_CONFIG_CATEGORY = {
  account: 'account',
  contract: 'contract_details',
  customer: 'customer_details',
  digitalSignature: 'digital_signature_details',
  draft: 'draft_details',
  offer: 'offer_details',
  quote: 'quote_details',
  transfer: 'transfer_details',
  lead: 'lead',
  appointmentScheduler: 'appointment-scheduler',
  userRoles: 'user_roles'
};

export const TABLE_CONFIG_SUBCATEGORY = {
  commissions: 'commissions',
  wefoxContract: 'wefox_contract',
  wefoxCustomer: 'wefox_customer',
  digitalSignature: 'digital_signature',
  draft: 'draft',
  offer: 'offer',
  quote: 'quote',
  roles: 'roles',
  lead: 'lead',
  thirdParty: 'third_party',
  users: 'users',
  appointments: 'appointments',
  contract: 'contract'
};

export const DETAIL_CARD_NAME = {
  coverages: 'coverages',
  customerInfo: 'customer_info',
  insuredObject: 'insured_object',
  leadInfo: 'lead_info',
  overview: 'overview',
  paymentData: 'payment_data'
};

export const STATUS_BADGE_STATE = {
  activated: 'activated',
  active: 'active',
  cancelled: 'cancelled',
  deactivated: 'deactivated',
  false: 'false',
  inactive: 'inactive',
  inProgress: 'in_progress',
  pending: 'pending',
  signed: 'signed',
  submitted: 'submitted',
  substituted: 'substituted',
  true: 'true',
  unknown: 'unknown'
};

export const STATUS_BADGE_COLOR = {
  activated: 'info',
  active: 'success',
  approved: 'success',
  blocked: 'error',
  cancelled: 'error',
  deactivated: 'error',
  declined: 'error',
  deleted: 'warning',
  failed: 'error',
  qualified: 'success',
  draft: 'info',
  false: 'warning',
  enabled: 'success',
  expired: 'error',
  inactive: 'error',
  in_progress: 'warning',
  new: 'success',
  paid_out: 'success',
  pending: 'warning',
  rejected: 'error',
  signed: 'success',
  sold: 'success',
  submitted: 'warning',
  substituted: 'warning',
  true: 'success',
  under_review: 'warning',
  unknown: 'error',
  withdrawn: 'warning',
  'pending backoffice validation': 'warning',
  'pending customer documentation': 'warning',
  'pending signature': 'info'
};

export const FEEDBACK_STATUS_BADGE_COLOR = {
  new: 'info',
  open: 'warning',
  positive: 'success',
  negative: 'error',
};

export const STATUS_BANNER_COLOR = {
  activated: 'color-info',
  active: 'color-success',
  approved: 'color-success',
  cancelled: 'color-danger',
  deactivated: 'color-danger',
  draft: 'color-info',
  enabled: 'color-success',
  inactive: 'color-danger',
  paid_out: 'color-success',
  pending: 'color-warning',
  rejected: 'color-danger',
  signed: 'color-success',
  submitted: 'color-warning',
  substituted: 'color-warning',
  under_review: 'color-warning',
  unknown: 'color-danger',
  withdrawn: 'color-warning',
  'pending backoffice validation': 'color-warning',
  'pending customer documentation': 'color-warning',
  'pending signature': 'color-info'
};

export const CONTRACT_DETAIL_PARAMETER_NAME = {
  bike_theft_included: 'product.household.coverage.bicycle-theft.is-included',
  home_product_type: 'product.home.product-type',
  hsn: 'object.vehicle.hsn',
  legal_protetion_included: 'product.motor.coverage.legal-protection.is-included',
  product_type: 'product.home.product-type',
  tsn: 'object.vehicle.tsn',
  ownership_type: 'product.home.ownership',
  vb_status: 'object.car.vb-status'
};

export const ENTITY_TYPE = {
  person: 'person',
  privatePerson: 'privatePerson',
  legalEntity: 'legalEntity'
};

export const BOOLEAN_STRING_VALUE = {
  false: 'false',
  true: 'true'
};

export const DETAIL_CARD_LABEL_AND_CONTROL_NAME = {
  bicycle_sum_insured: 'bicycle_sum_insured',
  motor_legal_protection: 'motor_legal_protection',
  motor_legal_protection_premium: 'motor_legal_protection_premium'
};

export const HOMEOWNER_INPUT_VALUES = {
  ownership_type: {
    tenant: 'TENANT'
  }
};

export const DATA_TYPE = {
  object: 'object',
  string: 'string'
};

export const DYNAMIC_TABLE_PROPERTY_NAME = {
  filterConfig: 'filterConfig'
};

export const DYNAMIC_COMPONENT_MAPPING = {
  leadPricesDashboard: LeadPricesComponent,
  contractDashboard: ContractDashboardComponent,
  dynamicBanner: DynamicBannerComponent,
  availableExternalTools: AvailableToolsComponent,
  carrierExcerpt: CarrierExcerptComponent,
  thirdPartyProductSelection: ThirdPartyProductSelectionComponent,
  performanceSection: PerformanceSectionComponent,
  policyTableDashboard: PolicyTableDashboardComponent
};
