import { Component, Input } from '@angular/core';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { WgIconSize, WgIconTypes, WgIconType } from './wg-ui-icon-constants';

@Component({
  selector: 'wg-ui-icon',
  templateUrl: './wg-ui-icon.component.html'
})
export class WgUiIconComponent {
  @Input() public customSize: string;
  @Input() public key: string;
  @Input() public primaryColor: string;
  public route = '';
  @Input() public secondaryColor: string;
  @Input() public size: WgIconSize = '';
  @Input() public type: WgIconTypes = 'ui';

  public TYPE_3D = WgIconType['three-dimensional'];
  public wefoxIconCDNUrl = environment.wefoxIconCDNUrl;

  public getPrimaryColorStyle(): string {
    return `var(--${this.primaryColor ? this.primaryColor : 'primary-dark'})`;
  }

  public getRoute(): string {
    return `assets/ui-icons/${this.type}.svg#${this.type}-${this.key}`;
  }

  public getSecondaryColorStyle(): string {
    return `var(--${this.secondaryColor ? this.secondaryColor : 'secondary-light'})`;
  }
}
