import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { InsuranceCardData } from '@wefoxGroupOneBPShared/components/carrier-excerpt/interfaces/carrier-excerpt.interface';
import {
  AllCarrierResponse,
  AllProductGroupResponse,
  AllProductResponse,
  ProductGroupResponse,
  ProductsResponse
} from '../interfaces/product.interface';
import { ThirdPartyDataService } from './third-party-data.service';

@Injectable({ providedIn: 'root' })
export class ThirdPartyService {
  constructor(private _thirdPartyDataService: ThirdPartyDataService) {}

  public getCarriers$(): Observable<InsuranceCardData[]> {
    return this._thirdPartyDataService.getCarriers$();
  }

  public getProductGroups$(insuranceCarrierId: string): Observable<ProductGroupResponse[]> {
    return this._thirdPartyDataService.getProductGroups$(insuranceCarrierId);
  }

  public getProducts$(insurenceCarrierId: string, productGroup: string): Observable<ProductsResponse[]> {
    return this._thirdPartyDataService.getProducts$(insurenceCarrierId, productGroup);
  }

  public getFksConsent$(brokerId) {
    return this._thirdPartyDataService.getFksConsent$(brokerId);
  }

  public getAllCarriers$(insuranceCarrierNames?: string[] | string): Observable<AllCarrierResponse[]> {
    return this._thirdPartyDataService.getAllCarriers$(insuranceCarrierNames);
  }

  public getAllProducts$(insuranceCarrierIds?: string[], productGroupName?: string): Observable<AllProductResponse[]> {
    return this._thirdPartyDataService.getAllProducts$(insuranceCarrierIds, productGroupName);
  }

  public getAllProductGroups$(insuranceCarrierIds?: string[]): Observable<AllProductGroupResponse[]> {
    return this._thirdPartyDataService.getAllProductGroups$(insuranceCarrierIds);
  }

  public getInsuranceCarriersTotalProducts$(
    insuranceCarrierIds?: string[],
    productGroupId?: string,
    productNames?: string[],
    sort?: string
  ): Observable<AllListInfo> {
    return this._thirdPartyDataService.getInsuranceCarriersTotalProducts$(
      insuranceCarrierIds,
      productGroupId,
      productNames,
      sort
    );
  }
}
