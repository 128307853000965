import { Component } from '@angular/core';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';

@Component({
  selector: 'wg-fks-background-process-modal',
  templateUrl: './fks-background-process-modal.component.html',
  styleUrls: ['./fks-background-process-modal.component.scss']
})
export class FksBackgroundProcessModalComponent {
  public contentViewOptions: { options: Partial<any> }; // eslint-disable-line

  constructor(private _modal: WgModalService) {}

  public closeModal(): void {
    this.contentViewOptions.options.buttonAction();
    this._modal.hide();
  }
}
