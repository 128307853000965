import { Component, Input } from '@angular/core';
import { LOADER_TYPE, LoaderType } from '@wefoxGroupOneBPShared/constants';

@Component({
  selector: 'one-loader',
  styleUrls: ['./loader.component.scss'],
  templateUrl: './loader.component.html'
})
export class LoaderComponent {
  @Input() public heightModifier: string;
  @Input() public loaderText = '_PROD_loading';
  @Input() public loaderType: LoaderType = LOADER_TYPE.AppLoader;
  @Input() public sectionModifier: string;
}
