import { AbstractControl } from '@angular/forms';
import { GermanPlatePrefixes } from '@wefoxGroupOneBPCore/constants/product.constants';

export class LicensePlateValidators {
  public static licensePlatePrefix(control: AbstractControl): null | { licensePlatePrefix: boolean } {
    if (control.value) {
      const prefix = control.value.split('-')[0];
      const isValid = !(GermanPlatePrefixes.indexOf(prefix.toUpperCase()) === -1);
      return isValid ? null : { licensePlatePrefix: true };
    }
    return null;
  }
}
