import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon.module';
import { WgPaginatorComponent } from './wg-paginator.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [WgPaginatorComponent],
  exports: [WgPaginatorComponent],
  imports: [CommonModule, TranslocoModule, WgUiIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WgPaginatorModule {}
