import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactsDTO } from '../interfaces/contacts.interface';
import { ContactsDataService } from './contacts-data.service';

@Injectable({ providedIn: 'root' })
export class ContactsService {
  constructor(private _contactsData: ContactsDataService) { }

  public getFilteredContacts$(
    pageIndex: number,
    pageSize: number,
    criteria: string,
    insurer: boolean
  ): Observable<ContactsDTO> {
    return this._contactsData.getFilteredContacts$(pageIndex, pageSize, criteria, insurer);
  }
}
