import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormControl, FormGroupDirective, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { RadioOption } from '@wefoxGroupOneBPCore/interfaces/radio-option.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wg-radio-button',
  styleUrls: ['./wg-radio-button.component.scss'],
  templateUrl: './wg-radio-button.component.html'
})
export class WgRadioButtonComponent implements AfterContentInit, AfterViewInit, OnInit, OnChanges {
  @Input() public dataControlName: string;
  @Input() public disabled: boolean;
  public inputControl: AbstractControl;
  @Input() public name: string;
  @Input() public options: Partial<RadioOption>[];
  @Input() public rowModifiers: string;
  @Output() public selectedValueChanges = new EventEmitter<number>();
  @Input() public showTooltip = true;
  @Input() public tooltipText: string;

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _errorMap: { [key: string]: string };
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private _controlContainer: FormGroupDirective,
    private _sessionQuery: SessionQuery
  ) {}

  public getCurrentError(): {className: string, errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this.inputControl.errors || (this.inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: '-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this.inputControl.errors)[0]]) {
      return {
        className: '-invalid',
        errorMessage: this._errorMap[Object.keys(this.inputControl.errors)[0]]
      };
    }
    return { className: '-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this.inputControl = this._controlContainer.form.get(this.dataControlName);
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});

        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });

    this.inputControl.valueChanges.subscribe(value => {
      this.selectedValueChanges.emit(value);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const optionsChange = changes?.options;

    if (optionsChange && !optionsChange?.firstChange) {
      this._configureOptions();
    }
  }

  public ngOnInit(): void {
    const checked = this.options.find(option => option.checked)?.value;

    this._configureOptions();

    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(() => {
      this._configureOptions();
    });

    this._controlContainer.form.addControl(
      this.dataControlName,
      new UntypedFormControl(checked ? checked : '', [Validators.required])
    );
  }

  private _configureOptions(): void {
    this.options = this.options.map(option => {
      option.translatedLabel = translate(option.label);
      return option;
    });
  }
}
