import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountStore, BrokerDTOIt, BrokerDTOState } from '@wefoxGroupOneBPCore/interfaces/account.store';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseAuthenticationService, BaseDataService, SessionService } from '@wefoxGroupOneBPCore/services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ExternalSite } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class AccountDataService extends BaseDataService {
  private _brokersEndpoint = 'brokers';
  private _brokersItEndpoint = 'personal-data';
  private _permissionEndpoint = 'permission';
  private _externalToolsEndpoint = 'external-tool-credential';

  private _token: string;

  constructor(
    protected httpClient: HttpClient,
    protected sessionQuery: SessionQuery,
    private _accountStore: AccountStore,
    private _sessionService: SessionService,
    private _authenticationService: BaseAuthenticationService
  ) {
    super(httpClient, sessionQuery);
    this._authenticationService.getAccessToken().subscribe((token: string) => {
      this._token = token;
    });
  }

  // eslint-disable-next-line
  public getBroker$(token?: any): Observable<BrokerDTOState> {
    const url = this.getUrl({
      path: `${this._brokersEndpoint}`
    });

    if (token) {
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.get$(url, options).pipe(
        // eslint-disable-next-line
        tap((data: any) => {
          const { broker_number, email, firstname, lastname } = data;
          this._accountStore.update({
            brokerNumber: broker_number,
            email,
            firstname,
            lastname,
            loading: true
          });
        })
      ) as Observable<BrokerDTOState>;
    } else {
      return this.get$(url).pipe(
        // eslint-disable-next-line
        tap((data: any) => {
          const { broker_number, email, firstname, lastname } = data;
          this._accountStore.update({
            brokerNumber: broker_number,
            email,
            firstname,
            lastname,
            loading: true
          });
        })
      ) as Observable<BrokerDTOState>;
    }
  }

  // eslint-disable-next-line
  public getBrokerIt$(token?: any): Observable<BrokerDTOIt> {
    const url = this.getUrl({
      path: `${this._brokersItEndpoint}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };

    return this.get$(url, !!token ? options : null).pipe(
      // eslint-disable-next-line
      tap((data: any) => {
        const { emails, first_name, last_name, id } = data;
        this._accountStore.update({
          email: emails[0].email,
          firstname: first_name,
          lastname: last_name,
          id: id
        });
      })
    ) as Observable<BrokerDTOIt>;
  }

  // eslint-disable-next-line
  public getBrokerPermissions$(token?: any): Observable<boolean> {
    const url = this.getUrl({
      path: `${this._permissionEndpoint}`
    });

    if (token) {
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      return this.get$(url, options).pipe(
        // eslint-disable-next-line
        tap((data: any) => {
          const { intermediary_admin } = data;
          this._sessionService.setBrokerPermissions(intermediary_admin);
        })
      ) as Observable<boolean>;
    } else {
      return this.get$(url).pipe(
        // eslint-disable-next-line
        tap((data: any) => {
          const { intermediary_admin } = data;
          this._sessionService.setBrokerPermissions(intermediary_admin);
        })
      ) as Observable<boolean>;
    }
  }

  // eslint-disable-next-line
  public loginDeExternalSites$(externalSiteInfo: ExternalSite): Observable<ExternalSite[]> {
    const url = this.getUrl({
      path: `${this._externalToolsEndpoint}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${this._token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.post$(url, externalSiteInfo, options) as Observable<ExternalSite[]>;
  }

  public modifyExternalSite$(externalSiteInfo: ExternalSite): Observable<ExternalSite[]> {
    const url = this.getUrl({
      path: `${this._externalToolsEndpoint}/${externalSiteInfo.id}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${this._token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.put$(url, externalSiteInfo, options) as Observable<ExternalSite[]>;
  }

  public getBrokerDeExternalSites$(): Observable<ExternalSite[]> {
    const url = this.getUrl({
      path: `${this._externalToolsEndpoint}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${this._token}`,
        'Content-Type': 'application/json'
      }
    };
    return this.get$(url, options) as Observable<ExternalSite[]>;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deleteBrokerDeExternalSite$(id: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._externalToolsEndpoint}/${id}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Bearer ${this._token}`
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.delete$(url, options) as Observable<any>;
  }

  public getAutoLoginUrl$(url: string): Observable<{ url: string }> {
    return this.get$(this.getUrl({ path: url }));
  }
}
