import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { CustomHttpParamEncoder } from '@wefoxGroupOneBPCore/utils/custom-http-param-encoder';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContactsDTO } from '../interfaces/contacts.interface';

@Injectable()
export class ContactsDataService extends BaseDataService {
  private _contactsEndpoint = 'external-advisor';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  public getFilteredContacts$(
    pageIndex: number,
    pageSize: number,
    criteria: string,
    insurer: boolean
  ): Observable<ContactsDTO> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('page', `${pageIndex - 1}`)
      .append('size', `${pageSize}`);
    if (criteria) {
      params = params.append('criteria', criteria);
    }

    const endpoint = insurer ? '/insurer' : '';
    const url = this.getUrl({
      path: `${this._contactsEndpoint}${endpoint}`
    });
    return this.get$(url, { params }).pipe(catchError(() => of({ content: [] })));
  }
}
