import { Component, Input } from '@angular/core';

export interface WgErrorComponentOptions {
  key: string;
  placeholders?: string[];
  value: string;
}

@Component({
  selector: 'wg-error',
  template: ``
})
export class WgErrorComponent {
  @Input() public key: string;
  @Input() public placeholders: string[];
  @Input() public value: string;
}
