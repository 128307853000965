export const LEADS_PRODUCT_TYPE = {
  AV: {
    ID_Type: '85',
    Name: 'Altersvorsorge (LV, RV, Riester, Rürup)',
    label: '_product_type_av',
    value: 'AV'
  },
  '360': {
    ID_Type: '1004',
    Name: '360 Grad Leads',
    label: '_product_type_360',
    value: '360'
  },
  'Ag-St': {
    ID_Type: '47',
    Name: 'Abgeltungsteuer',
    label: '_product_type_ag-st',
    value: 'Ag-St'
  },
  'AV55+': {
    ID_Type: '805',
    Name: 'Altersvorsorge für über 55 jährige',
    label: '_product_type_av55+',
    value: 'AV55+'
  },
  BAV: {
    ID_Type: '1',
    Name: 'Betriebliche Altersvorsorge',
    label: '_product_type_bav',
    value: 'BAV'
  },
  BL: {
    ID_Type: '1008',
    Name: 'Blacklist',
    label: '_product_type_bl',
    value: 'BL'
  },
  BLV: {
    ID_Type: '2',
    Name: 'Britische Lebensversicherung',
    label: '_product_type_blv',
    value: 'BLV'
  },
  BSV: {
    ID_Type: '32',
    Name: 'Bausparvertrag',
    label: '_product_type_bsv',
    value: 'BSV'
  },
  BUV: {
    ID_Type: '3',
    Name: 'Berufsunfähigkeitsversicherung',
    label: '_product_type_buv',
    value: 'BUV'
  },
  'BUV55+': {
    ID_Type: '46',
    Name: 'Berufsunfähigkeitsversicherung für über 55 Jährige',
    label: '_product_type_buv55+',
    value: 'BUV55+'
  },
  BV: {
    ID_Type: '997',
    Name: 'Brillenversicherung ',
    label: '_product_type_bv',
    value: 'BV'
  },
  DD: {
    ID_Type: '4',
    Name: 'Dread Disease',
    label: '_product_type_dd',
    value: 'DD'
  },
  FWF: {
    ID_Type: '5',
    Name: 'Fremdwährungsfinanzierung',
    label: '_product_type_fwf',
    value: 'FWF'
  },
  Gew: {
    ID_Type: '1005',
    Name: 'Gewinnspiel Lead',
    label: '_product_type_gew',
    value: 'Gew'
  },
  GfV: {
    ID_Type: '44',
    Name: 'Grundfähigkeitsversicherung',
    label: '_product_type_gfv',
    value: 'GfV'
  },
  GKV: {
    ID_Type: '18',
    Name: 'gesetzliche Krankenversicherung ',
    label: '_product_type_gkv',
    value: 'GKV'
  },
  GVP: {
    ID_Type: '48',
    Name: 'Gebäudeversicherung',
    label: '_product_type_gvp',
    value: 'GVP'
  },
  GwS: {
    ID_Type: '66',
    Name: 'gewerbliche Sachversicherungen ',
    label: '_product_type_gws',
    value: 'GwS'
  },
  HRV: {
    ID_Type: '206',
    Name: 'Hausratversicherung',
    label: '_product_type_hrv',
    value: 'HRV'
  },
  IF: {
    ID_Type: '6',
    Name: 'Immobilienfinanzierung',
    label: '_product_type_if',
    value: 'IF'
  },
  'IF-Mini': {
    ID_Type: '45',
    Name: 'Immobilienfinanzierung unter 50.000 Euro ',
    label: '_product_type_if-mini',
    value: 'IF-Mini'
  },
  IFTop: {
    ID_Type: '49',
    Name: 'Immobilienfinanzierung mit Zusatzinteresse an BU und RLV',
    label: '_product_type_iftop',
    value: 'IFTop'
  },
  Inv: {
    ID_Type: '17',
    Name: 'Investmentanlagen',
    label: '_product_type_inv',
    value: 'Inv'
  },
  InvCh: {
    ID_Type: '50',
    Name: 'Investment-Check(TÜV)',
    label: '_product_type_invch',
    value: 'InvCh'
  },
  'K-DGI': {
    ID_Type: '51',
    Name: 'Kapitalanlagen Denkmalgeschützte Immobilien',
    label: '_product_type_k-dgi',
    value: 'K-DGI'
  },
  'K-LZM': {
    ID_Type: '52',
    Name: 'Kapitalanlagen LV-Zweitmarkt',
    label: '_product_type_k-lzm',
    value: 'K-LZM'
  },
  'K-PE': {
    ID_Type: '53',
    Name: 'Kapitalanlagen Private Equity',
    label: '_product_type_k-pe',
    value: 'K-PE'
  },
  'K-SB': {
    ID_Type: '54',
    Name: 'Kapitalanlagen Schiffsbeteiligung',
    label: 'k-sb',
    value: 'K-SB'
  },
  KFZ: {
    ID_Type: '37',
    Name: 'KFZ Versicherung',
    label: '_product_type_kfz',
    value: 'KFZ'
  },
  Kombi: {
    ID_Type: '972',
    Name: 'Kombi',
    label: '_product_type_kombi',
    value: 'Kombi'
  },
  KSK: {
    ID_Type: '35',
    Name: 'Konsumentenkredit',
    label: '_product_type_ksk',
    value: 'KSK'
  },
  'KV55+': {
    ID_Type: '19',
    Name: 'Krankenversicherung für über 55 jährige',
    label: '_product_type_kv55+',
    value: 'KV55+'
  },
  KVB: {
    ID_Type: '9',
    Name: 'Krankenversicherung für Beamte',
    label: '_product_type_kvb',
    value: 'KVB'
  },
  KVS: {
    ID_Type: '8',
    Name: 'Private Krankenversicherung für Studenten',
    label: '_product_type_kvs',
    value: 'KVS'
  },
  KVV: {
    ID_Type: '7',
    Name: 'Private Krankenvollversicherung',
    label: '_product_type_kvv',
    value: 'KVV'
  },
  KVZ: {
    ID_Type: '20',
    Name: 'Krankenzusatzversicherung',
    label: '_product_type_kvz',
    value: 'KVZ'
  },
  LV: {
    ID_Type: '55',
    Name: 'Lebensversicherung',
    label: '_product_type_lv',
    value: 'LV'
  },
  'LV-VK': {
    ID_Type: '33',
    Name: 'Verkauf von Lebensversicherungen',
    label: '_product_type_lv-vk',
    value: 'LV-VK'
  },
  LVV: {
    ID_Type: '450',
    Name: 'Lebensversicherung Verkauf',
    label: '_product_type_lvv',
    value: 'LVV'
  },
  PEV: {
    ID_Type: '15',
    Name: 'Pflegeergänzung',
    label: '_product_type_pev',
    value: 'PEV'
  },
  PHV: {
    ID_Type: '56',
    Name: 'Privathaftpflichtversicherung',
    label: '_product_type_phv',
    value: 'PHV'
  },
  'PKV-EXP': {
    ID_Type: '984',
    Name: 'Krankenversicherung für Expatriates',
    label: '_product_type_pkv-exp',
    value: 'PKV-EXP'
  },
  PKVK: {
    ID_Type: '985',
    Name: 'Private Krankenversicherung Kombi ',
    label: '_product_type_pkvk',
    value: 'PKVK'
  },
  PRV: {
    ID_Type: '10',
    Name: 'Pflegerentenversicherung',
    label: '_product_type_prv',
    value: 'PRV'
  },
  'Q-AV': {
    ID_Type: '166',
    Name: 'qualifizierte Altersvorsorge (LV, RV, Riester, Rürup)',
    label: '_product_type_q-av',
    value: 'Q-AV'
  },
  'Q-BAV': {
    ID_Type: '21',
    Name: 'vorqualifizierte betriebliche Altersvorsorge',
    label: '_product_type_q-bav',
    value: 'Q-BAV'
  },
  'Q-BLV': {
    ID_Type: '57',
    Name: 'vorqualifizierte britische Lebensversicherung',
    label: '_product_type_q-blv',
    value: 'Q-BLV'
  },
  'Q-BSV': {
    ID_Type: '506',
    Name: 'vorqualifizierte Anfragen zu Bausparern',
    label: '_product_type_q-bsv',
    value: 'Q-BSV'
  },
  'Q-BUV': {
    ID_Type: '22',
    Name: 'vorqualifizierte Berufsunfähigkeitsversicherung',
    label: '_product_type_q-buv',
    value: 'Q-BUV'
  },
  'Q-DD': {
    ID_Type: '23',
    Name: 'vorqualifizierte Dread Disease',
    label: '_product_type_q-dd',
    value: 'Q-DD'
  },
  'Q-GwS': {
    ID_Type: '24',
    Name: 'vorqualifizierte gewerbliche Sachversicherung',
    label: '_product_type_q-gws',
    value: 'Q-GwS'
  },
  'Q-I': {
    ID_Type: '58',
    Name: 'vorqualifizierte Immobilienfinanzierung',
    label: '_product_type_q-if',
    value: 'Q-IF'
  },
  'Q-IF1': {
    ID_Type: '25',
    Name: 'vorqualifizierte Immobilienfinanzierung unter 100.000 EURO Finanzierungssumme',
    label: '_product_type_q-if1',
    value: 'Q-IF1'
  },
  'Q-IF2': {
    ID_Type: '26',
    Name: 'vorqualifizierte Immobilienfinanzierung ab 100.000 EURO Finanzierungssumme',
    label: '_product_type_q-if2',
    value: 'Q-IF2'
  },
  'Q-IF3': {
    ID_Type: '27',
    Name: 'vorqualifizierte Immobilienfinanzierung ab 200.000 EURO Finanzierungssumme',
    label: '_product_type_q-if3',
    value: 'Q-IF3'
  },
  'Q-IF4': {
    ID_Type: '28',
    Name: 'vorqualifizierte Immobilienfinanzierung ab 300.000 EURO Finanzierungssumme',
    label: '_product_type_q-if4',
    value: 'Q-IF4'
  },
  'Q-KVB': {
    ID_Type: '141',
    Name: 'Qualifizierte Krankenversicherung für Beamte',
    label: '_product_type_q-kvb',
    value: 'Q-KVB'
  },
  'Q-KVV': {
    ID_Type: '29',
    Name: 'vorqualifizierte private Krankenversicherung',
    label: '_product_type_q-kvv',
    value: 'Q-KVV'
  },
  'Q-KVZ': {
    ID_Type: '59',
    Name: 'premium qualifzierte Krankenzusatzversicherung',
    label: '_product_type_q-kvz',
    value: 'Q-KVZ'
  },
  'Q-LV': {
    ID_Type: '60',
    Name: 'vorqualifizierte Lebensversicherung',
    label: '_product_type_q-lv',
    value: 'Q-LV'
  },
  'Q-LV-VK': {
    ID_Type: '507',
    Name: 'vorqaulifzierte Lebensversicherung Verkauf',
    label: '_product_type_q-lv-vk',
    value: 'Q-LV-VK'
  },
  'Q-PEV': {
    ID_Type: '833',
    Name: 'vorqualifizierte Pflegeergänzung',
    label: '_product_type_q-pev',
    value: 'Q-PEV'
  },
  'Q-PKVK': {
    ID_Type: '994',
    Name: 'Qualifizierte Private Krankenversicherung Kombi ',
    label: '_product_type_q-pkvk',
    value: 'Q-PKVK'
  },
  'Q-PRV': {
    ID_Type: '61',
    Name: 'vorqualifizierte Pflegerentenversicherungen',
    label: '_product_type_q-prv',
    value: 'Q-PRV'
  },
  'Q-RRV': {
    ID_Type: '36',
    Name: 'vorqualifizierte Riester-Rentenversicherung',
    label: '_product_type_q-rrv',
    value: 'Q-RRV'
  },
  'Q-Rue': {
    ID_Type: '62',
    Name: 'vorqualifizierte Rüruprentenversicherung',
    label: '_product_type_q-rue',
    value: 'Q-Rue'
  },
  'Q-RV': {
    ID_Type: '30',
    Name: 'vorqualifizierte Rentenversicherung',
    label: '_product_type_q-rv',
    value: 'Q-RV'
  },
  'Q-SR': {
    ID_Type: '445',
    Name: 'vorqualifizierte Sofortrente',
    label: '_product_type_q-sr',
    value: 'Q-SR'
  },
  'Q-StS': {
    ID_Type: '63',
    Name: 'tvorqualifizierte Steuerorientierte Kapitalanlagen',
    label: '_product_type_q-sts',
    value: 'Q-StS'
  },
  'Q-Zahn': {
    ID_Type: '991',
    Name: 'Vorqualifizierte Zahnzusatzversicherung',
    label: '_product_type_q-zahn',
    value: 'Q-Zahn'
  },
  RLV: {
    ID_Type: '43',
    Name: 'Risikolebensversicherung',
    label: '_product_type_rlv',
    value: 'RLV'
  },
  RRV: {
    ID_Type: '12',
    Name: 'Riester Rente',
    label: '_product_type_rrv',
    value: 'RRV'
  },
  RsV: {
    ID_Type: '64',
    Name: 'Rechtschutzversicherung',
    label: 'rsv',
    value: 'RsV'
  },
  Rürup: {
    ID_Type: '42',
    Name: 'Rüruprentenversicherung',
    label: '_product_type_rürup',
    value: 'Rürup'
  },
  RV: {
    ID_Type: '11',
    Name: 'Private Rentenversicherung',
    label: '_product_type_rv',
    value: 'RV'
  },
  'RV55+': {
    ID_Type: '69',
    Name: 'RV55+',
    label: '_product_type_rv55+',
    value: 'RV55+'
  },
  SR: {
    ID_Type: '434',
    Name: 'Sofortrente',
    label: '_product_type_sr',
    value: 'SR'
  },
  'St-Geld': {
    ID_Type: '41',
    Name: 'Sterbegeldversicherung',
    label: '_product_type_st-geld',
    value: 'St-Geld'
  },
  StSp: {
    ID_Type: '38',
    Name: 'Steuersparkonzepte',
    label: '_product_type_stsp',
    value: 'StSp'
  },
  Test: {
    ID_Type: '546',
    Name: 'Testsparte',
    label: '_product_type_test',
    value: 'Test'
  },
  Unv: {
    ID_Type: '65',
    Name: 'Unfallversicherung',
    label: '_product_type_unv',
    value: 'Unv'
  },
  VerCh: {
    ID_Type: '40',
    Name: 'Versicherungs-Check',
    label: 'verch',
    value: 'VerCh'
  },
  VWL: {
    ID_Type: '39',
    Name: 'Vermögenswirksame Leistungen',
    label: '_product_type_vwl',
    value: 'VWL'
  },
  Zahn: {
    ID_Type: '14',
    Name: 'Zahnzusatzversicherung',
    label: '_product_type_zahn',
    value: 'Zahn'
  },
  'Zahn-BV': {
    ID_Type: '999',
    Name: 'Zahnzusatzversicherung BV',
    label: '_product_type_zahn-bv',
    value: 'Zahn-BV'
  },
  'Zahn-Pool': {
    ID_Type: '979',
    Name: 'Zahnzusatzversicherung',
    label: '_product_type_zahn-pool',
    value: 'Zahn-Pool'
  }
};
