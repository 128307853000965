import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RiskAnalysisComponent } from 'libs/feature/risk-analysis/src/lib/risk-analysis.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SuccessComponent } from './components/success/success.component';

const routes: Routes = [
  {
    children: [
      {
        path: 'customer-flow/:token',
        children: [
          {
            component: RiskAnalysisComponent,
            data: { isThirdParty: false },
            path: 'risk-analysis/wefox-customers'
          },
          {
            component: RiskAnalysisComponent,
            data: { isThirdParty: true },
            path: 'risk-analysis/third-party/fks'
          }
        ]
      },
      {
        component: SuccessComponent,
        path: 'risk-analysis/success'
      }
    ],
    path: ':country'
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' })]
})
export class AppRoutingModule { }