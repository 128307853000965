import { Injectable } from '@angular/core';
import { CookieService as ngxCookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private _namespace = 'onebp.';

  constructor(private _cookieService: ngxCookieService) {}

  public delete(name: string): void {
    this._cookieService.delete(`${this._namespace}${name}`, '/');
  }

  public get(name: string): string {
    return this._cookieService.get(`${this._namespace}${name}`);
  }

  public set(name: string, value: string, expiration: number | Date = 365): void {
    this._cookieService.set(`${this._namespace}${name}`, value, expiration, '/', null, true, null);
  }
}
