import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AbstractControlOptions,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { ModelDefinition } from '@wefoxGroupOneBPCore/interfaces';
import { PaymentDetails } from '@wefoxGroupOneBPCore/interfaces/customer-detail.interface';
import { CustomerPaymentData, DynamicFormModel } from '@wefoxGroupOneBPCore/interfaces/customers.interface';
import { DynamicFormSection, ProductFormConfig } from '@wefoxGroupOneBPCore/interfaces/dynamic-forms.interface';
import { SourceValue } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { SourceTranslationOptions } from '@wefoxGroupOneBPCore/interfaces/source-translation.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import { PaymentMethodRequestBodyReplacement } from '@wefoxGroupOneBPShared/constants';
import { BaseModelUtils } from '@wefoxGroupOneBPShared/models/common.models';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormValidatorService } from './validators.service';

const combinedAddressLength = {
  ch: null,
  de: 255,
  it: 60
};

export const translationTextPosition = {
  prefix: 'prefix',
  suffix: 'suffix'
};

@Injectable({
  providedIn: 'root'
})
export class FormService {
  constructor(
    private _fb: UntypedFormBuilder,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _formValidatorService: FormValidatorService
  ) { }

  public buildDropdownOptions(control: { options }): void {
    const { control_name, helper_text, label, required, sources, source_name, translation_options } = control.options;

    this._sessionService.setDropdownOptionsState({
      [control_name]: {
        controlName: control_name,
        helper_text,
        label,
        required,
        sourceName: source_name,
        sources,
        translationOptions: translation_options
      }
    });
  }

  /**
   * Builds the form group based on the model configurations
   * @param model { DynamicFormModel}
   * @param options { AbstractControlOptions }
   * @returns { FormGroup }
   */
  public generateFormGroup(model: DynamicFormModel[], options?: AbstractControlOptions | null): UntypedFormGroup {
    const group = this._getDataFromModel(model);

    return this._fb.group(group, options);
  }

  /**
   * Builds the form group based on the product form configurations
   * @param config { ProductFormConfig}
   * @returns { FormGroup }
   */
  public generateFormGroupFromControls(
    config: ProductFormConfig,
    options?: AbstractControlOptions | null
  ): UntypedFormGroup {
    const group = this.getAttributeListFromControls(config);

    return this._fb.group(group, options);
  }

  public getAttributeListFromControls(config: ProductFormConfig): { [key: string]: ['', ValidatorFn[]] } {
    let group = {};

    config.form_sections.forEach(section => {
      group = { ...group, ...this.getAttributeListFromSection(section) };
      group = { ...group, ...this._getDataFromModel(section.content_data.model) };
    });

    group = { ...group, ...this._getDataFromModel(config.model) };

    return group;
  }

  public getAttributeListFromSection(section: DynamicFormSection): { [key: string]: ['', ValidatorFn[]] } {
    let group = {};

    section.content_data.config.controls?.forEach(control => {
      control.columns.forEach(column => {
        group = { ...group, ...this._getDataFromModel(column.options.model) };
        const validators = column.options.validators || [];
        group = {
          ...group,
          [column.options.control_name]: ['', this._formValidatorService.getValidators(validators)]
        };
      });
    });
    group = { ...group, ...this._getDataFromModel(section.content_data.model) };

    return group;
  }

  private _getDataFromModel(model: DynamicFormModel[]): { [key: string]: ['', ValidatorFn[]] } {
    let group = {};
    if (model) {
      model.forEach(element => {
        const validators = element.validators || [];
        group = {
          ...group,
          [element.attribute]: ['', this._formValidatorService.getValidators(validators)]
        };
      });
    }
    return group;
  }

  public getCombinedAddressLength(): number {
    switch (this._sessionQuery.getCountry()) {
      case COUNTRIES.ch:
        return combinedAddressLength.ch;
      case COUNTRIES.it:
        return combinedAddressLength.it;
      default:
        return combinedAddressLength.de;
    }
  }

  /**
   *
   * @param  { DynamicFormSection[] } formSections An array of DynamicFormSection fronm the model
   * @param  { string }  sectionId ID of the section we want to retrieve 'section_id' in the content_data
   * @returns { DynamicFormSection } return the section object from the sections array
   */
  public getFormSection(formSections: DynamicFormSection[], sectionId: string): DynamicFormSection {
    return formSections.find(sect => sect.content_data.section_id === sectionId);
  }

  public getInputLabel(prefix: string, fieldName: string): string {
    return `${prefix}${fieldName}`;
  }

  /**
   *
   * @param  { DynamicFormSection[] } formSections An array of DynamicFormSection fronm the model
   * @returns { [key: string]: string } return an array of key: value pairs of the section ID
   */
  public getSectionIds(formSections: DynamicFormSection[]): { [key: string]: string } {
    return formSections.reduce((acc, sect) => {
      return { ...acc, [sect.content_data.section_id]: sect.content_data.section_id };
    }, {});
  }

  public getValidatorsFromModel(model: ModelDefinition, name: string): Validators {
    return model.validators[name];
  }

  public mapControlNamesFromModel(model: { attribute }[]): string[] {
    return model.map(control => control.attribute);
  }

  // eslint-disable-next-line
  public mapLegalControlNames(legalConfig): string[] {
    const { checkboxes, files } = legalConfig;
    return [...checkboxes.map(check => check.key), ...files.map((file, index) => `legal_file_${index + 1}`)];
  }

  public monitorAddressChanges(
    parentGroup: UntypedFormGroup,
    streetFieldName: string,
    houseNumberFieldName: string
  ): void {
    const streetInput = parentGroup.get(streetFieldName);
    const houseNumberInput = parentGroup.get(houseNumberFieldName);
    if (!streetInput || !houseNumberInput) {
      return;
    }
    const streetString = streetInput.valueChanges;
    const houseNumberString = houseNumberInput.valueChanges;
    const combinedAddressStrings = combineLatest([streetString, houseNumberString]).pipe(
      map(([street, number]) => {
        return { street, number };
      })
    );

    combinedAddressStrings.subscribe(result => {
      if (
        result.number?.length + result.street?.length > this.getCombinedAddressLength() &&
        this.getCombinedAddressLength() !== null
      ) {
        houseNumberInput.setErrors({ addressToLong: true });
        streetInput.setErrors({ addressToLong: true });
      } else {
        houseNumberInput.setErrors({ addressToLong: null });
        houseNumberInput.updateValueAndValidity({ emitEvent: false });
        streetInput.setErrors({ addressToLong: null });
        streetInput.updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  public removeFileUploadRequirement(productData: { form_sections; model }): { form_sections; model } {
    const docsSection = productData.form_sections.find(section => section.type === 'documentsSection');

    if (docsSection) {
      const removedRequired = [
        {
          ...docsSection,
          content_data: {
            ...docsSection.content_data,
            config: { ...docsSection.content_data.config, is_required: false }
          }
        }
      ];

      const newData = {
        ...productData,
        form_sections: BaseModelUtils.modifyFormSections(productData.form_sections, removedRequired),
        model: productData.model.map(model => {
          if (model.attribute === 'file') {
            model.validators = [];
          }

          return model;
        })
      };

      return newData;
    }

    return productData;
  }

  public scrollToFirstInvalidControl(): void {
    const exceptions = ['form', 'div'];
    const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('ng-invalid');
    for (const iterator of Object.assign(elements)) {
      if (exceptions.indexOf(iterator.localName) === -1) {
        iterator.scrollIntoView({ behavior: 'smooth', block: 'center' });
        iterator.focus({ preventScroll: true });
        return;
      }
    }
  }

  public setPaymentAccountHolderName(paymentDetails: Partial<PaymentDetails>): string {
    return paymentDetails.last_name === PaymentMethodRequestBodyReplacement.legalEntity
      ? paymentDetails?.first_name
      : paymentDetails?.account_holder;
  }

  public setPaymentAccountHolderNamePaymentMethod(paymentDetails: Partial<CustomerPaymentData>): string {
    return paymentDetails.last_name === PaymentMethodRequestBodyReplacement.legalEntity
      ? paymentDetails?.first_name
      : `${paymentDetails?.first_name} ${paymentDetails?.last_name}`;
  }

  // eslint-disable-next-line
  public sort(list: any, sortBy: string): any {
    if (!list) {
      return;
    }

    return list.map(items => items).sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
  }

  // eslint-disable-next-line
  public translateDropdownValue(value: any, options: Partial<SourceTranslationOptions>): string {
    if (options?.hasCurrency) {
      value = options?.hasCurrency
        ? this._transformWithCurrency(translate(value))
        : translate(value);
    }

    value = options?.i18nPrefix ? translate(options.i18nPrefix + value) : value;

    switch (options?.position) {
      case translationTextPosition.prefix: {
        return `${translate(options.additionalKey ? options.additionalKey : '')}${options.hasColon ? ':' : ''
          } ${value}`;
      }
      case translationTextPosition.suffix: {
        return `${value} ${translate(options.additionalKey ? options.additionalKey : '')} `;
      }
      default:
        return value;
    }
  }

  // eslint-disable-next-line
  public translateSourceValues(values: SourceValue[], options?: Partial<SourceTranslationOptions>): any {
    if (!values?.length) {
      return;
    }

    if (values[0] === undefined) {
      return;
    }

    // TODO: Use this for when we isolate various dropdowns and typeaheads instead of in the product class.
    // options.type will tell how to transform the value like if it has a currency symbol or add Vollkasko etc.
    return values.map((data: SourceValue) => {
      let value = options?.hasCurrency
        ? this._transformWithCurrency(translate(data.value))
        : translate(data.value);

      value = options?.i18nPrefix ? translate(options.i18nPrefix + data.value) : value;

      switch (options?.position) {
        case translationTextPosition.prefix:
          return {
            ...data,
            value: `${translate(options.additionalKey ? options.additionalKey : '')}${options.hasColon ? ':' : ''
              } ${value}`
          };
        case translationTextPosition.suffix:
          return {
            ...data,
            value: `${value} ${translate(options.additionalKey ? options.additionalKey : '')} `
          };
        default:
          return { ...data, value: value };
      }
    });
  }

  public updateInput(formControl: AbstractControl, value?: string): void {
    if (!formControl) {
      return;
    }

    if (value) {
      formControl.patchValue(value);
      formControl.markAsTouched();
    } else {
      formControl.patchValue('');
      formControl.markAsUntouched();
    }
  }

  public updateTranslations(formGroup: UntypedFormGroup, controlName: string, selectionValues: SourceValue[]): void {
    const formControl = formGroup.get(controlName);
    const formValue = formControl.value;

    if (formValue) {
      const selectionOption = selectionValues.find(
        val => val.key.toString().toLowerCase() === formValue.key.toString().toLowerCase()
      );

      formControl.patchValue({
        key: selectionOption.key,
        value: translate(selectionOption.value)
      });
    }
  }

  private _transformWithCurrency(value: string): string {
    return this._sessionQuery.getCountry() === COUNTRIES.ch ? `CHF ${value}` : `${value} €`;
  }
}
