import { Component, Input, OnDestroy } from '@angular/core';
import { AccountService } from '@wefoxGroupOneBP/app/private/modules/account/services/account.service';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories, GAEventPages } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { BaseRouteName, RedirectionService } from '@wefoxGroupOneBPShared/services/redirection.service';
import { Subject, takeUntil } from 'rxjs';
import { AvailableToolsOptions } from '../available-tools/available-tools.component';

@Component({
  selector: 'one-available-tool-card',
  templateUrl: './available-tool-card.component.html',
  styleUrls: ['./available-tool-card.component.scss']
})
export class AvailableToolCardComponent implements OnDestroy {
  @Input() availableTool: AvailableToolsOptions;
  @Input() index = 0;
  @Input() page = GAEventPages.Dashboard;
  @Input() fullWidth = false;
  protected _unsubscribe$: Subject<void> = new Subject<void>();

  public loading = false;

  constructor(
    private _redirectionService: RedirectionService,
    private _accountService: AccountService,
    private _analytics: AnalyticsService
  ) { }

  public navigateToProfilePage(): void {
    this._redirectionService.navigate(BaseRouteName.profile);
  }

  public navigateToExternalSite(availableTool: AvailableToolsOptions): void {
    if (availableTool.has_auto_login) {
      this.loading = true;
      this._accountService.getAutoLoginUrl$(availableTool.retrieve_auto_login_endpoint)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe({
          next: (response: { url?: string, render?: string }) => {
            if (response.url) {
              window.open(response.url, '_blank');
            } else if (response.render) {
              const windowUrl = URL.createObjectURL(
                new Blob([response.render], { type: "text/html" })
              );
              window.open(windowUrl, '_blank'); 
            }
            this._analytics.event({
              GAEventCategory: availableTool.id as GAEventCategories,
              GAEventAction: GAEventActions.DirectingTo,
              GAEventType: AnalyticsCustomEvents.ctaEvent,
              GAEventPage: this.page
            });
            this.loading = false;
          },
          error: () => this.loading = false
        }
        );
    } else {
      window.open(availableTool.url, '_blank');
    }
  }

  public copyToClipboard(textToCopy: string): void {
    navigator.clipboard.writeText(textToCopy);
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
