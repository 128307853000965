// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
}

.wg-o-paginatorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}
.wg-o-paginatorWrapper.-interior {
  justify-content: space-between;
  margin: -2rem 3rem 0;
}

.-spaced-between {
  justify-content: space-between;
}

.wg-m-paginator,
.wg-m-itemsPerPage {
  display: flex;
  font-size: 14px;
  -webkit-user-select: none;
          user-select: none;
}

.wg-a-movePage {
  cursor: pointer;
  height: 25px;
  margin-right: 4px;
  padding: 0 5px;
  width: 25px;
}
.wg-a-movePage .wg-a-icon {
  fill: var(--primary-base);
  height: 24px;
  padding: 0;
  width: 24px;
}

.wg-a-info {
  margin-right: 10px;
}

.wg-a-page,
.wg-a-number {
  border: 1px solid transparent;
  border-radius: 3px;
  color: var(--neutral-darkest);
  cursor: pointer;
  height: 25px;
  margin: 0 2px;
  padding: 0 6px;
  text-align: center;
  transition: 0.2s;
}
.wg-a-page:hover,
.wg-a-number:hover {
  border: 1px solid var(--neutral-darkest);
}

.-turned {
  transform: rotate(180deg);
}

.-selected {
  background-color: var(--primary-base);
  color: var(--neutral-light);
}

.wg-a-limitPage {
  display: flex;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-paginator/wg-paginator.component.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,WAAA;AAAF;;AAGA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,WAAA;AAAF;AAEE;EACE,8BAAA;EACA,oBAAA;AAAJ;;AAIA;EACE,8BAAA;AADF;;AAIA;;EAEE,aAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;AADF;;AAIA;EACE,eAAA;EACA,YAAA;EACA,iBAAA;EACA,cAAA;EACA,WAAA;AADF;AAGE;EACE,yBAAA;EACA,YAAA;EACA,UAAA;EACA,WAAA;AADJ;;AAKA;EACE,kBAAA;AAFF;;AAKA;;EAEE,6BAAA;EACA,kBAAA;EACA,6BAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AAFF;AAIE;;EACE,wCAAA;AADJ;;AAKA;EACE,yBAAA;AAFF;;AAKA;EACE,qCAAA;EACA,2BAAA;AAFF;;AAKA;EACE,aAAA;AAFF","sourcesContent":["\n:host {\n  display: flex;\n  width: 100%;\n}\n\n.wg-o-paginatorWrapper {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  width: 100%;\n\n  &.-interior {\n    justify-content: space-between;\n    margin: -2rem 3rem 0;\n  }\n}\n\n.-spaced-between {\n  justify-content: space-between;\n}\n\n.wg-m-paginator,\n.wg-m-itemsPerPage {\n  display: flex;\n  font-size: 14px;\n  user-select: none;\n}\n\n.wg-a-movePage {\n  cursor: pointer;\n  height: 25px;\n  margin-right: 4px;\n  padding: 0 5px;\n  width: 25px;\n\n  .wg-a-icon {\n    fill: var(--primary-base);\n    height: 24px;\n    padding: 0;\n    width: 24px;\n  }\n}\n\n.wg-a-info {\n  margin-right: 10px;\n}\n\n.wg-a-page,\n.wg-a-number {\n  border: 1px solid transparent;\n  border-radius: 3px;\n  color: var(--neutral-darkest);\n  cursor: pointer;\n  height: 25px;\n  margin: 0 2px;\n  padding: 0 6px;\n  text-align: center;\n  transition: 0.2s;\n\n  &:hover {\n    border: 1px solid var(--neutral-darkest);\n  }\n}\n\n.-turned {\n  transform: rotate(180deg);\n}\n\n.-selected {\n  background-color: var(--primary-base);\n  color: var(--neutral-light);\n}\n\n.wg-a-limitPage {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
