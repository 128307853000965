import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TariffTypeFilters } from '@wefoxGroupOneBPCore/constants/contracts.constants';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { StringUtil } from '@wefoxGroupOneBPCore/utils/string-util';
import { Observable } from 'rxjs';
import { AUTH_METHODS, COUNTRIES, COUNTRY_DATA } from '../constants';
import { SessionQuery } from '../queries';
import { CustomHttpParamEncoder } from '../utils/custom-http-param-encoder';

/**
 * Request url object definition
 */
export interface RequestUrlDefinition {
  base?: string;
  options?: unknown; // TODO think about this options
  path: string;
  version?: string;
}

@Injectable()
export class BaseDataService {
  protected options: RequestInit = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };
  private _apiUrl = environment.apiUrl;
  private _apiUrlIT = environment.apiUrlIT;
  private _apiUrlTenants = environment.termLifeApiUrl;
  private _keycloakMsUrl = environment.keycloakMsUrl;
  private _apiVersion = 'v1';
  private _privateConfigEndpoint = 'main-config/private/country/';
  private _privateSidebarEndpoint = 'sidebar-config/country/';
  private _productMasterdataEndpoint = 'product/country/';

  constructor(private _http: HttpClient, private _sessionQuery: SessionQuery) {
    this.setHeaders(this.options.headers);
  }

  /**
   * Get request body deserialized content
   */
  // eslint-disable-next-line
  public getBody(): any {
    return JSON.parse(<string>this.options.body || '{}');
  }

  /**
   * Set request headers
   */
  // eslint-disable-next-line
  public getHeaders(): any {
    return this.options.headers || {};
  }

  public getMasterDataUrls(country: string): { privateConfigUrl: string; productDataUrl: string, privateSidebarUrl: string } {
    const productDataUrl = this.getUrl({
      path: `${this._productMasterdataEndpoint}${country}`
    });

    const privateConfigUrl = this.getUrl({
      path: `${this._privateConfigEndpoint}${country}`
    });

    const privateSidebarUrl = this.getUrl({
      path: `${this._privateSidebarEndpoint}${country}`
    });

    return { privateConfigUrl, productDataUrl, privateSidebarUrl };
  }

  /**
   * Get request URL
   */

  public getUrl(props: RequestUrlDefinition): string {
    const country = this._sessionQuery.getCountry();
    const countryData = COUNTRY_DATA[country];
    const authMethod = countryData?.authMethod;
    const apiVersion = props.version ? props.version : this._apiVersion;
    if (authMethod === AUTH_METHODS.keycloak) {
      return `${this._keycloakMsUrl}/${apiVersion}/${props.path}`;
    } else {
      return `${this._apiUrl}/${apiVersion}/${props.path}`;
    }
  }

  public getUrlByCountry(props: RequestUrlDefinition, country: COUNTRIES): string {
    const countryData = COUNTRY_DATA[country];
    const authMethod = countryData?.authMethod;
    const apiVersion = props.version ? props.version : this._apiVersion;
    if (authMethod === AUTH_METHODS.keycloak) {
      return `${this._keycloakMsUrl}/${apiVersion}/${props.path}`;
    } else {
      return `${this._apiUrl}/${apiVersion}/${props.path}`;
    }
  }

  /**
   * Get request URL
   */
  public getUrlIT(props: RequestUrlDefinition): string {
    const apiVersion = props.version ? props.version : this._apiVersion;
    return `${this._apiUrlIT}/${apiVersion}/${props.path}`;
  }

  /**
   * Get request URL
   */
  public getUrlTenants(props: RequestUrlDefinition): string {
    const apiVersion = props.version ? props.version : this._apiVersion;
    return `${this._apiUrlTenants}/api/${apiVersion}/${props.path}`;
  }

  // FIXME Define interface
  // eslint-disable-next-line
  public getURLParams(params: any): HttpParams {
    const {
      type,
      cursor,
      customerType,
      sort,
      page,
      size,
      criteria,
      onboardingType,
      productType,
      status,
      firstName,
      lastName,
      customerName,
      insurer,
      leadId,
      dateType,
      tariffType,
      id
    } = params;
    let urlSearchParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    urlSearchParams = urlSearchParams.append('page', 1);
    urlSearchParams = urlSearchParams.append('size', 10);
    if (sort) {
      const fields = sort.split(',');
      const order = fields.pop();

      fields.forEach(field => {
        urlSearchParams = urlSearchParams.append('sort', `${StringUtil.snakeToCamel(field)},${order}`);
      });
    }

    if (page && page > 0) {
      urlSearchParams = urlSearchParams.set('page', page - 1);
    }

    if (size) {
      urlSearchParams = urlSearchParams.set('size', size);
    }

    if (criteria) {
      urlSearchParams = urlSearchParams.append('criteria', criteria);
    }

    if (type) {
      urlSearchParams = urlSearchParams.append('type', type);
    }

    if (customerType) {
      urlSearchParams = urlSearchParams.append('customerType', customerType);
    }

    if (cursor) {
      urlSearchParams = urlSearchParams.append('cursor', cursor);
    }

    if (onboardingType) {
      urlSearchParams = urlSearchParams.append('onboardingType', onboardingType);
    }

    if (productType) {
      urlSearchParams = urlSearchParams.append('productType', productType);
    }

    if (tariffType) {
      tariffType.forEach(tariff => {
        if (Array.isArray(TariffTypeFilters[tariff])) {
          TariffTypeFilters[tariff].values().forEach(tariffValue => {
            urlSearchParams = urlSearchParams.append('productType', tariffValue);
          });
        } else {
          urlSearchParams = urlSearchParams.append('productType', TariffTypeFilters[tariff]);
        }
      });
    }

    if (status) {
      urlSearchParams = urlSearchParams.append('status', status);
    }

    if (firstName) {
      urlSearchParams = urlSearchParams.append('firstName', firstName);
    }

    if (lastName) {
      urlSearchParams = urlSearchParams.append('lastName', lastName);
    }

    if (customerName) {
      urlSearchParams = urlSearchParams.append('customerName', customerName);
    }

    if (insurer) {
      urlSearchParams = urlSearchParams.append('insurer', insurer);
    }

    if (id) {
      urlSearchParams = urlSearchParams.append('id', id);
    }

    if (leadId) {
      urlSearchParams = urlSearchParams.append('leadId', leadId);
    }

    if (dateType && Array.isArray(dateType)) {
      dateType.forEach(dateItem => {
        if (dateItem.value) {
          urlSearchParams = urlSearchParams.append(dateItem.key, dateItem.value);
        }
      });
    }
    return urlSearchParams;
  }

  /**
   * Parser parameters and include on the URL path
   * @param props Request definition object
   */
  public getUrlWithParams(props: RequestUrlDefinition): string {
    const { options } = props;
    let updatedProps = props;

    if (options) {
      const urlParams = this.setURLParams(options);

      updatedProps = {
        ...props,
        path: `${props.path}?${urlParams.toString()}`
      };
    }

    return this.getUrl(updatedProps);
  }
  /**
   * Set request body
   */
  // eslint-disable-next-line
  public setBody(body: any): void {
    this.options.body = JSON.stringify({
      ...this.getBody(),
      ...body
    });
  }

  /**
   * Set request headers
   */
  public setHeaders(headersObject: HeadersInit): void {
    this.options.headers = { ...this.options.headers, ...headersObject };
  }

  /**
   * Transform and prepare the paremeters into URLSearchParams
   * @param params Parameters options
   */
  // eslint-disable-next-line
  public setURLParams(params: any): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    Object.keys(params).forEach(param => {
      urlSearchParams.append(param, params[param]);
    });

    return urlSearchParams;
  }

  // eslint-disable-next-line
  protected delete$(url: string, options?: RequestInit | unknown): Observable<any> {
    return this._http.delete(url, options);
  }

  // eslint-disable-next-line
  protected get$(url: string, options?: RequestInit | unknown): Observable<any> {
    return this._http.get(url, options);
  }

  // eslint-disable-next-line
  protected patch$(url: string, body: any, options: RequestInit | unknown): Observable<any> {
    return this._http.patch(url, body, options);
  }

  // eslint-disable-next-line
  protected post$(url: string, body: any, options: RequestInit | unknown): Observable<any> {
    return this._http.post(url, body, options);
  }

  // eslint-disable-next-line
  protected put$(url: string, body: any, options: RequestInit | unknown): Observable<any> {
    return this._http.put(url, body, options);
  }
}
