import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-file-upload-adapter',
  templateUrl: './file-upload-adapter.component.html'
})
export class FileUploadAdapterComponent {
  @Output() public emitFilePath = new EventEmitter();
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
}
