import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface PhoneAdapterComponentOptions {
  control_name: string;
  placeholder?: string;
  label?: string;
  required: boolean,
  validators: [{ type: string }];
}

@Component({
  selector: 'one-phone-adapter',
  templateUrl: './phone-adapter.component.html'
})
export class PhoneAdapterComponent {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
}
