import { Injectable } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { LDClient, LDFlagSet, initialize, LDFlagValue } from 'launchdarkly-js-client-sdk';
import { Subject, Subscription } from 'rxjs';

@Injectable()
export class LaunchDarklyService {
    public flagChange: Subject<LDFlagSet> = new Subject<LDFlagSet>();
    public flags: LDFlagSet;
    private _ldClient: LDClient;
  constructor(protected _sessionQuery: SessionQuery) {
    this._ldClient = initialize(environment.launchdarkly.clientSideId,
      { key: environment.launchdarkly.userKey, anonymous: false });

    this._ldClient.on('change', (allFlagChanges) => {
        Object.keys(allFlagChanges).forEach((key) => {
            this.flags[key] = allFlagChanges[key].current;
        });
        this.flagChange.next(this.flags);
   });

   this._ldClient.on('ready', () => {
    this.flags = this._ldClient.allFlags();
    this.flagChange.next(this.flags);
   });
  }

  public getFlagValue(key: string): LDFlagValue {
    if (this.flags) {
      const flagKey = this._addLocationPrefix(key);
      return this.flags[flagKey];
    }
  }

  // eslint-disable-next-line
  public subscribeToFlagChange(key: string, callback): Subscription {
    const flagKey = this._addLocationPrefix(key);
    return this.flagChange.subscribe((flags: Record<string, unknown>) => callback(flags[flagKey]));
  }

  private _addLocationPrefix(key: string) {
    const countryPrefix = this._sessionQuery.getCountry();
    return `${countryPrefix}-${key}`;
  }

 }
