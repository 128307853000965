import { Component, Input, OnInit } from '@angular/core';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { ProdcutRecommendation } from '@wefoxGroupOneBPRiskAnalysis/interfaces/risk-analysis.interface';
import { RiskAnalysisService } from '../../services/risk-analysis.service';

@Component({
  selector: 'wg-ai-product-recommendation-card',
  templateUrl: './ai-product-recommendation-card.component.html'
})
export class AIProductRecommendationCardComponent implements OnInit {
  @Input() public options: DetailCardOptions;
  @Input() public customerId: string;
  @Input() public source: string;
  
  public products: ProdcutRecommendation[];
  public hasRiskProfile;

  public loading: boolean;

  constructor(
    private _riskAnalysisService: RiskAnalysisService
  ) { }

  public ngOnInit(): void {
    this.loading = true;

    this._riskAnalysisService.getProductRecommendation$(this.customerId, this.source).subscribe({
      next: response =>  this.products = response.recommendations,
      complete: () => (this.loading = false)
    });
  }

}
