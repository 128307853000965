import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { AbstractControl, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'one-radio',
  templateUrl: './radio.component.html'
})
export class RadioComponent implements AfterContentInit, AfterViewInit, OnChanges, OnInit {
  @Input() public content_data: any; // eslint-disable-line
  public inputControl: AbstractControl;
  @Input() public isDisabled: boolean;
  @Input() public parentGroup: UntypedFormGroup;
  @Output() public selectedValueChanges = new EventEmitter<string>();

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _errorMap: { [key: string]: string };
  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _controlContainer: FormGroupDirective) { }

  public getCurrentError(): { className: string; errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (!this.inputControl.errors || (this.inputControl.untouched && !this._controlContainer.submitted)) {
      return { className: '-valid', errorMessage: '' };
    }
    if (this._errorMap && this._errorMap[Object.keys(this.inputControl.errors)[0]]) {
      return {
        className: '-invalid is-invalid',
        errorMessage: this._errorMap[Object.keys(this.inputControl.errors)[0]]
      };
    }
    return { className: '-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this.inputControl = this._controlContainer.form.get(this.content_data.control_name);
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: translate(error.value) } };
        }, {});

        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });

    this.inputControl.valueChanges.subscribe(value => {
      this.selectedValueChanges.emit(value);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDisabled) {
      const control = this.parentGroup.get(this.content_data.control_name);

      this.isDisabled = changes.isDisabled.currentValue;

      if (this.isDisabled) {
        control.disable();
      } else {
        control.enable();
      }
    }
  }

  public ngOnInit(): void {
    const defaultValue = this.content_data.radio_options.find(option => option.checked)?.value;

    if (defaultValue) {
      const radioControl = this.parentGroup.get(this.content_data.control_name);
      if (!radioControl.value) {
        radioControl?.setValue(defaultValue);
      }
    }
  }
}
