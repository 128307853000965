import { Injectable } from '@angular/core';
import { CommmissionsDatesDTO } from '@wefoxGroupOneBPPrivate/modules/account/components/commissions-table.component';
import { Observable } from 'rxjs';
import { CommissionsDataService } from './commissions-data.service';

@Injectable({ providedIn: 'root' })
export class CommissionsService {
  constructor(private _commissionsData: CommissionsDataService) {}

  // eslint-disable-next-line
  public downloadApplicationZipFiles$(year: string, month: string): Observable<any> {
    return this._commissionsData.downloadApplicationZipFiles$(year, month);
  }

  public getFilteredCommissions$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filterDate: { year: string; month: string } | null
    // eslint-disable-next-line
  ): Observable<any> {
    return this._commissionsData.getFilteredCommissions$(pageIndex, pageSize, sortField, sortOrder, filterDate);
  }

  public getStartingDate(): Observable<CommmissionsDatesDTO> {
    return this._commissionsData.getStartingDate();
  }
}
