export interface DraftFormData {
  // eslint-disable-next-line
  inputs: { [key: string]: any }; // from form.getRawValue()
  is_offer: boolean;
  is_prefilled: boolean;
  product_url: string;
  selected_locale: string;
}

export interface CrossSellInfo {
  cross_sell_product_id?: string;
  cross_sell_product_version?: string;
}
