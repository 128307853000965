import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules';

@Component({
  standalone: true,
  selector: 'one-lead-prices',
  template: `
    <div [ngClass]="config.class">
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <h3 [attr.data-testid]="'lead_prices_title'">{{ '_DASH_lead_prices_title' | transloco }}</h3>
          <div [attr.data-testid]="'lead_prices_subtitle'">{{ '_DASH_lead_prices_subtitle' | transloco }}</div>
        </div>

        <comet-button
          class="mb-gigantic"
          (click)="navigateToLeads()"
          [attr.data-testid]="'see-all-leads-button'"
          label="{{ '_DASH_all_leads_button' | transloco }}"
          theme="flushed"
        >
          <wg-ui-icon slot="comet-button-trailing-icon" key="arrow-right" primaryColor="primary-base"></wg-ui-icon>
        </comet-button>
      </div>
      <div class="card">
        <div class="card-body d-flex justify-content-around align-items-center p-huge">
          <div class="image">
            <img src="assets/images/lead-prices.png" alt="Lead prices image" />
          </div>
          <ul class="item-list w-100 p-huge">
            <li *ngFor="let item of items; let i = index" class="d-flex align-items-center mb-small">
              <div [style.background-color]="item.color" class="bullet"></div>
              <div class="d-flex justify-content-between align-items-center w-100">
                <div [attr.data-testid]="'item' + i + '_text'" class="item-text">{{ item.text | transloco }}</div>
                <strong [attr.data-testid]="'item' + i + '_price'" class="item-price">{{ item.price | transloco }}</strong>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      .item-list {
        width: 100%;
        max-width: 30rem;
        list-style-type: none;
      }

      .bullet {
        width: 0.375rem;
        height: 0.375rem;
        border-radius: 100%;
        margin-right: 0.375rem;
      }
    `
  ],
  imports: [CommonModule, TranslocoModule, WgUiIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LeadPricesComponent {
  @Input() config: { class: string };

  private _sessionQuery = inject(SessionQuery);
  private _router = inject(Router);

  items = [
    { text: '_DASH_lead_price_item_1_text', color: '#CEC0E0', price: '_DASH_lead_price_item_1_price' },
    { text: '_DASH_lead_price_item_2_text', color: '#A0E1DC', price: '_DASH_lead_price_item_2_price' },
    { text: '_DASH_lead_price_item_3_text', color: '#FEE4A7', price: '_DASH_lead_price_item_3_price' },
    { text: '_DASH_lead_price_item_4_text', color: '#5A9EED', price: '_DASH_lead_price_item_4_price' },
    { text: '_DASH_lead_price_item_5_text', color: '#B0F2CB', price: '_DASH_lead_price_item_5_price' },
    { text: '_DASH_lead_price_item_6_text', color: '#FEB9B4', price: '_DASH_lead_price_item_6_price' },
    { text: '_DASH_lead_price_item_7_text', color: '#A5A4A1', price: '_DASH_lead_price_item_7_price' }
  ];

  public navigateToLeads(): void {
    this._router.navigate([`${this._sessionQuery.getCountryPrefix()}/leads/${this._sessionQuery.getBrokerPath()}`]);
  }
}
