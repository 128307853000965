import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SliderComponentOptions } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/inputs';

@Component({
  selector: 'one-slider-range',
  styleUrls: ['././slider-range.component.scss'],
  templateUrl: './slider-range.component.html'
})
export class SliderRangeComponent implements OnInit {
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public sliderOption: SliderComponentOptions;
  public steps: number[];

  /**
   * Sets the default or initial value.
   * Sets the number of step ticks.
   */
  public ngOnInit(): void {
    this._setDefaultValue();
    this._setRangeSteps();
  }

  /**
   * Sets the default or initial value.
   */
  private _setDefaultValue(): void {
    if (this.sliderOption.default_value) {
      this.parentGroup
        .get(this.sliderOption.control_name)
        .setValue(this.sliderOption.default_value || this.sliderOption.min_value);
    }
  }

  /**
   * Sets the number of step ticks.
   */
  private _setRangeSteps(): void {
    this.steps = Array(
      (this.sliderOption?.max_value - this.sliderOption?.min_value) / this.sliderOption.step_count + 1
    );
  }
}
