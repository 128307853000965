import { AfterViewInit, Component, Input, OnDestroy, inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { OfferCreationService } from '@wefoxGroupOneBPPrivate/components/offer-creation/services/offer-creation.service';
import { Subject, Subscription, debounceTime, map, merge, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'one-insurance-company-adapter',
  templateUrl: './insurance-company-adapter.component.html',
  styleUrl: './insurance-company-adapter.component.scss'
})
export class InsuranceCompanyAdapterComponent implements OnDestroy, AfterViewInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: FormGroup;

  insuranceTypeOptions: DropdownComponentOptions;
  insuranceCompanyOptions: DropdownComponentOptions;
  languageOptions: DropdownComponentOptions;
  optionalDocuments: Array<{ id: string; name: string }> = [];

  loading: boolean = true;
  timeOutId;
  protected _unsubscribe$: Subject<void> = new Subject<void>();

  // Services
  private _offerCreationService = inject(OfferCreationService);

  optionalDocumentsFormData: Array<{ control_name: string; label: string }> = [];
  optionalDocumentsFormGroup: FormGroup;
  optionalDocumentsSubscription: Subscription;

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    clearTimeout(this.timeOutId);
  }

  ngAfterViewInit(): void {
    this.timeOutId = setTimeout(() => {
      this._initAdapter();
      this._getInsuranceCompanyData();
    }, 100);
  }

  private _initAdapter(): void {
    merge(
      this.parentGroup.get('insurance_type').valueChanges.pipe(map(_ => ({ ..._, controlName: 'insuranceType' }))),
      this.parentGroup
        .get('insurance_company')
        .valueChanges.pipe(map(_ => ({ ..._, controlName: 'insuranceCompany' }))),
      this.parentGroup.get('language').valueChanges.pipe(map(_ => ({ ..._, controlName: 'language' })))
    )
      .pipe(
        tap(() => (this.loading = true)),
        debounceTime(100),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(this._getInsuranceCompanyData.bind(this));
  }

  private _getInsuranceCompanyData(): void {
    this.loading = true;

    // Selected values
    const insuranceCompanySelected = this.parentGroup.get('insurance_company').value ?? {};
    const insuranceTypeSelected = this.parentGroup.get('insurance_type').value ?? {};
    const languageSelected = this.parentGroup.get('language').value ?? {};

    // Api request body
    const requestBody = {
      insurance_type_id: insuranceTypeSelected.key,
      insurance_company_id: insuranceCompanySelected.key,
      language: languageSelected.key
    };

    Object.keys(requestBody)
      .filter(key => !requestBody[key])
      .forEach(key => delete requestBody[key]);

    this._offerCreationService
      .getStaticDocuments$(requestBody)
      .pipe(
        // PARSE LANGUAGE RESPONSE BODY
        map(response => {
          if (response['languages']) {
            response['languages'] = response['languages'].map(lang => ({ name: lang, id: lang }));
          }
          return response;
        })
      )
      .subscribe(response => {
        this.loading = false;
        const { insurance_companies, insurance_types, languages, optional_documents } = response;

        this.insuranceTypeOptions = this._getOptionsStructure('insurance_type', insurance_types);
        this.insuranceCompanyOptions = this._getOptionsStructure(
          'insurance_company',
          insurance_companies,
          'insurance_comp'
        );
        this.languageOptions = this._getOptionsStructure('language', languages);
        this.optionalDocuments = optional_documents;
        this.loading = false;

        if (
          this.parentGroup.get('insurance_type').value &&
          this.parentGroup.get('insurance_company').value &&
          this.parentGroup.get('language').value
        )
          this._initOptionalDocumentsForm();
      });
  }

  private _getOptionsStructure(
    name: string,
    sourceValues: Array<{ id: string; name: string }>,
    label?: string
  ): DropdownComponentOptions {
    return {
      controlName: name,
      defaultValue: '',
      label: `${this.content_data.i18n_prefix_key}${label ? label : name}_label`,
      optionsPanelModifiers: '-compact',
      required: true,
      sourceName: name,
      sources: [
        {
          name: name,
          validations: [],
          values: sourceValues.sort((a, b) => a.name.localeCompare(b.name)).map(_ => ({ key: _.id, value: _.name }))
        }
      ]
    };
  }

  private _initOptionalDocumentsForm(): void {
    const optionalDocuments: Array<string> = this.parentGroup.get('optional_documents').value || [];

    this.optionalDocumentsSubscription?.unsubscribe();

    const formContent = {};
    this.optionalDocumentsFormData = this.optionalDocuments.map(oDoc => {
      oDoc.id = String(oDoc.id);
      formContent[oDoc.id] = new FormControl(optionalDocuments.includes(oDoc.id));
      return { control_name: oDoc.id, label: oDoc.name };
    });

    this.optionalDocumentsFormGroup = new FormGroup(formContent);
    if (this.parentGroup.get('optional_documents').disabled) this.optionalDocumentsFormGroup.disable();

    this.optionalDocumentsSubscription = this.optionalDocumentsFormGroup.valueChanges.subscribe(
      this.onOptionalFilesChange.bind(this)
    );
    this.onOptionalFilesChange();
  }

  onOptionalFilesChange(): void {
    const optionalDocuments: Array<string> = Object.keys(this.optionalDocumentsFormGroup.value).filter(
      controlName => this.optionalDocumentsFormGroup.get(controlName).value
    );
    this.parentGroup.get('optional_documents').reset(optionalDocuments);
  }
}
