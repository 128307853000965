import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[oneClickOutside]'
})
export class ClickOutsideDirective {
  @Output() public oneClickOutside: EventEmitter<any> = new EventEmitter(); // eslint-disable-line 

  constructor(private _elementRef: ElementRef) {}

  // eslint-disable-next-line
  @HostListener('document:click', ['$event.target', '$event']) public onMouseEnter(targetElement, event) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.oneClickOutside.emit(event);
    }
  }
}
