import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';

export const BaseRouteName = {
  appointmentScheduler: 'appointment-scheduler',
  brokerRoles: 'broker-roles',
  carriers: 'carriers',
  claims: 'claims',
  contract: 'contract',
  contracts: 'contracts',
  customers: 'customers',
  dashboard: 'dashboard',
  signature: 'digital-signature',
  drafts: 'drafts',
  faq: 'faq',
  leads: 'leads',
  links: 'links',
  login: 'login',
  offers: 'offers',
  product: 'product',
  products: 'products',
  profile: 'profile',
  registration: 'registration',
  support: 'support',
  quotes: 'quotes',
  transfers: 'transfers',
  thirdParty: 'third-party',
  termLife: 'term-life',
};

export const RequiresBrokerIdRoutes = [
  BaseRouteName.claims,
  BaseRouteName.contract,
  BaseRouteName.contracts,
  BaseRouteName.customers,
  BaseRouteName.drafts,
  BaseRouteName.leads,
  BaseRouteName.links,
  BaseRouteName.offers,
  BaseRouteName.quotes,
  BaseRouteName.transfers
];

@Injectable({
  providedIn: 'root'
})
export class RedirectionService {
  private _countryPrefix = this._sessionQuery.getCountryPrefix();

  constructor(private _router: Router, private _sessionQuery: SessionQuery) {}

  public getBaseRoute(routeName: string): string {
    return `${this._countryPrefix}/${BaseRouteName[routeName]}/${
      this._requiresBrokerId(routeName) ? this._sessionQuery.getBrokerPath() : ''
    }/`;
  }

  public navigate(destination: string, params?: string, extras?: NavigationExtras): void {
    this._router.navigate([`${this.getBaseRoute(destination)}${params ? params : ''}`], extras);
  }

  private _requiresBrokerId(routeName: string): boolean {
    return RequiresBrokerIdRoutes.indexOf(routeName) !== -1;
  }
}
