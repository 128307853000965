import { Injectable } from '@angular/core';
import {
  AnalyticsCustomEvents,
  GAEventActions,
  GAEventCategories,
  GAEventPages
} from '@wefoxGroupOneBPCore/analytics/constants/analytics.constants';
import {
  GAEventBase,
  GAEventOptions,
  UserIdEvent,
  VirtualPageViewEvent
} from '@wefoxGroupOneBPCore/analytics/interfaces/analytics.interface';
import { LOCALES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  private _dataLayer: any; // eslint-disable-line
  private _userId: string = undefined;

  constructor(
    private _sessionQuery: SessionQuery
  ) {
    this._dataLayer = (<any>window).dataLayer; // eslint-disable-line
    this._sessionQuery.brokerId$.subscribe(brokerId => {
      this._setUserId(brokerId);
    });
  }

  public event(event: GAEventBase): void {
    this.sendGAEvent(event.GAEventCategory, event.GAEventAction, event.GAEventLabel, event.GAEventValue, event.GAEventType, event.GAEventPage);
  }

  public sendBrokerId(userId: string): void {
    const event: UserIdEvent = {
      event: AnalyticsCustomEvents.setBrokerId,
      userId
    };
    this._pushEvent(event);
  }

  public sendGAEvent(
    category: GAEventCategories,
    action: GAEventActions,
    label?: string,
    value?: number | string,
    eventType = AnalyticsCustomEvents.singleEvent,
    page?: GAEventPages
  ): void {
    const event: Partial<GAEventOptions> = {
      GAEventAction: action,
      GAEventCategory: category,
      GAEventLabel: label,
      GAEventValue: value,
      GAEventPage: page,
      event: eventType
    };

    this._pushEvent(event);
  }

  public sendVirtualPageView(path: string): void {
    const event: VirtualPageViewEvent = {
      event: AnalyticsCustomEvents.pageView,
      pageViewUrl: path
    };

    this._pushEvent(event);
  }

  private _setUserId(userId: string): void {
    this._userId = userId;
  }
  /**
   * Prevent old duplicate values from dataLayer management
   * @param options
   */
  private _pushEvent(options: Partial<GAEventOptions>) {
    const finalOptions: GAEventOptions = {
      GAEventAction: options.GAEventAction,
      GAEventCategory: options.GAEventCategory,
      GAEventLabel: options.GAEventLabel,
      GAEventValue: options.GAEventValue,
      GAEventPage: options.GAEventPage,
      GAEventCountry: this._sessionQuery.getCountry(),
      GAEventLanguage: this._sessionQuery.getCurrentLocale() as LOCALES,
      event: options.event,
      pageViewUrl: options.pageViewUrl,
      userId: this._userId || options.userId
    };

    this._dataLayer?.push(finalOptions);
  }
}
