import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { Observable } from 'rxjs';
import {
  CountLeadStageResponse,
  LeadDetails,
  LeadStageResponse,
  LeadsListOptions
} from '@wefoxGroupOneBPPrivate/modules/leads/interfaces';

@Injectable({ providedIn: 'root' })
export class LeadsDataService extends BaseDataService {
  private _leadsListEndpoint = 'leads';
  private _comparisonEndpoint = 'comparison';
  private _stageEndpoint = 'stage';
  private _stageCountEndpoint = 'count';
  private _riskAnalysisLeadEndpoint = 'risk-analysis/leads';
  private _archive = 'archive';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  public getListOfLeads$(options?: LeadsListOptions): Observable<AllListInfo> {
    let params = new HttpParams().append('page', `${options.page}`).append('size', `${options.size}`);
    if (options?.sort) {
      params = params.append('sort', `${options.sort}`);
    }
    if (options?.status) {
      params = params.append('status', `${options.status}`);
    }
    if (options?.leadId) {
      params = params.append('leadId', options.leadId);
    }
    if (options?.firstName) {
      params = params.append('firstName', `${options.firstName}`);
    }
    if (options?.lastName) {
      params = params.append('lastName', `${options.lastName}`);
    }

    if (options?.favorites) {
      params = params.append('favorites', `${options.favorites}`);
    }
    if (options?.stage) {
      params = params.append('stage', `${options.stage}`);
    }

    const url = this.getUrl({
      path: this._leadsListEndpoint
    });

    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getLead$(id: string, lang: string): Observable<LeadDetails> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${id}/language/${lang}`
    });

    return this.get$(url) as Observable<LeadDetails>;
  }

  public getComparisonUrl$(data: LeadDetails, site: string): Observable<{ url: string }> {
    const url = this.getUrl({
      path: `${this._comparisonEndpoint}/${site}`
    });

    return this.post$(url, data, {}) as Observable<{ url: string }>;
  }

  // eslint-disable-next-line
  public getRiskAnalysisProfileByLeadId$(adapterLeadId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._riskAnalysisLeadEndpoint}/${adapterLeadId}`
    });
    return this.get$(url);
  }

  public markAsFavorite$(lead: Partial<LeadDetails>): Observable<void> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${lead.adapter_lead_id}/favorite`
    });

    return this.post$(url, {}, {}) as Observable<void>;
  }

  public unMarkAsFavorite$(lead: Partial<LeadDetails>): Observable<void> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${lead.adapter_lead_id}/favorite`
    });

    return this.delete$(url) as Observable<void>;
  }

  public getLeadStage$(adapterLeadId: string): Observable<LeadStageResponse> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${adapterLeadId}/${this._stageEndpoint}`
    });

    return this.get$(url) as Observable<LeadStageResponse>;
  }

  public setLeadStage$(adapterLeadId: string, stage: string): Observable<void> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${adapterLeadId}/${this._stageEndpoint}?leadStageType=${stage}`
    });

    return this.patch$(url, {}, {}) as Observable<void>;
  }

  public getCountLeadStage$(): Observable<CountLeadStageResponse[]> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${this._stageCountEndpoint}`
    });

    return this.get$(url, {}) as Observable<CountLeadStageResponse[]>;
  }

  public patchArchive$(adapterLeadId: string): Observable<void> {
    const url = this.getUrl({
      path: `${this._leadsListEndpoint}/${adapterLeadId}/${this._archive}`
    });

    return this.patch$(url, {}, {}) as Observable<void>;
  }
}
