import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RiskAnalysisDataService } from "./risk-analysis-data.service";

@Injectable({
    providedIn: 'root'
})
export class RiskAnalysisService {
    constructor(private _riskAnalysisDataService: RiskAnalysisDataService) { }

    // eslint-disable-next-line
    public submitRiskAnalysis$(riskRequestData: any, country: string, customerId: string, isThirdParty: boolean): Observable<any> {
        return this._riskAnalysisDataService.submitRiskAnalysis$(riskRequestData, country, customerId, isThirdParty);
    }

    // eslint-disable-next-line
    public submitRiskAnalysisPublic$(riskRequestData: any, country: string, token: string, isThirdParty: boolean): Observable<any> {
        return this._riskAnalysisDataService.submitRiskAnalysisPublic$(riskRequestData, country, token, isThirdParty);
    }

    // eslint-disable-next-line
    public getRiskAnalysisProfile$(riskAnalysisId: string): Observable<any> {
        return this._riskAnalysisDataService.getRiskAnalysisProfile$(riskAnalysisId);
    }

    // eslint-disable-next-line
    public getRiskAnalysisProfileByCustomerId$(customerId: string, isThirdParty: boolean): Observable<any> {
        return this._riskAnalysisDataService.getRiskAnalysisProfileByCustomerId$(customerId, isThirdParty);
    }

    // eslint-disable-next-line
    public resendCustomerEmail(customerId: string, isThirdParty: boolean): Observable<void> {
        return this._riskAnalysisDataService.resendCustomerEmail(customerId, isThirdParty);
    }

    // eslint-disable-next-line
    public getProductRecommendation$(customerId: string, source: string): Observable<any> {
        return this._riskAnalysisDataService.getProductRecommendation$(customerId, source);
    }

}