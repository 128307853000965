import { SelectTariffAction } from '@wefoxGroupOneBPShared/constants/select-tariff-actions.constant';
import { UiButtonTheme } from '@wefoxGroupOneBPShared/constants/ui-types.constants';

export interface TariffBlock {
  buttons: { basic: TariffBlockButton; green?: TariffBlockButton };
  id: string;
  include: ComparisonIcons[];
  mainTitle: string;
  paymentFrequency: string;
  prices: { basic: string; green?: string };
  selected: boolean;
  subtitle: string;
}

export interface ComparisonIcons {
  icon?: string;
  text?: string;
}

export interface TariffEvent {
  action?: SelectTariffAction;
  final_price?: number;
  name?: string;
  package_definition_value?: string;
  value?: any; // eslint-disable-line
}

export interface TariffBlockButton extends TariffEvent {
  theme?: UiButtonTheme;
  title: string;
}

export interface SelectTariff {
  blocks: TariffBlock[];
  descriptions: string[];
}
