import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[oneWgFileDragDrop]'
})
export class WgFileDragDropDirective {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onFileDrop: EventEmitter<File[]> = new EventEmitter<File[]>();

  /* On nExt Versions we will add some drag effects than this dragover and dragleave listeners will be useful  */

  @HostListener('dragleave', ['$event'])
  // eslint-disable-next-line
  public dragLeave(event) {
    this._stopPropagation(event);
  }

  @HostListener('dragover', ['$event'])
  // eslint-disable-next-line
  public dragOver(event) {
    this._stopPropagation(event);
  }

  @HostListener('drop', ['$event'])
  // eslint-disable-next-line
  public drop(event) {
    this._stopPropagation(event);
    if (event.dataTransfer.files.length > 0) {
      const files: File[] = Array.from(event.dataTransfer.files);
      this.onFileDrop.emit(files);
    }
  }

  private _stopPropagation(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
