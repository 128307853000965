import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import { ClaimsDetailsMapped } from '@wefoxGroupOneBPPrivate/modules/claims/interfaces/claims-details.interface';
import { ClaimsDetailsService } from '@wefoxGroupOneBPPrivate/modules/claims/services/claims-details.service';
import {
  DETAILS_CARD_CATEGORY,
  DETAILS_CARD_SUBCATEGORY
} from '@wefoxGroupOneBPPrivate/product/constants/page-details.constant';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { BaseBreadcrumbKey } from '@wefoxGroupOneBPShared/constants/breadcrumbs.constants';
import { BreadcrumbsService } from '@wefoxGroupOneBPShared/services/breadcrumbs.service';
import { BaseRouteName } from '@wefoxGroupOneBPShared/services/redirection.service';
import { concatMap } from 'rxjs/operators';
import { ContractsService } from '../../../contracts/services/contracts.service';
import { CustomersService } from '../../../customers/services/customers.service';

@Component({
  templateUrl: 'claims-details-page.component.html'
})
export class ClaimsDetailsPageComponent implements OnDestroy, OnInit {
  public detailsCards: Partial<DetailCardOptions>[];
  public dynamicConfig;
  public claims: ClaimsDetailsMapped;

  private _claimId: string;
  private _customerId: string;
  private _lokalisePrefix = '_CLM_';

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _claimsDetailsService: ClaimsDetailsService,
    private _pageDetailsService: PageDetailsService,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _breadcrumbsService: BreadcrumbsService,
    private _contractsService: ContractsService,
    private _customersService: CustomersService
  ) {
    this._customersService.setViewHasTabs(false);
  }

  public ngOnDestroy(): void {
    this._breadcrumbsService.resetBreadcrumbConfig();
  }

  public ngOnInit(): void {
    const { customerId, claimId } = this._activatedRoute.snapshot.params;
    this._customerId = customerId;
    this._claimId = claimId;
    this._loadClaimDetails();
  }

  private _setBreadcrumbs(): void {
    const brokerPath = this._sessionQuery.getBrokerPath();
    const customerName = `${this.claims.first_name} ${this.claims.last_name}`;
    this._breadcrumbsService.setBreadcrumbConfig({
      breadcrumbs: [
        {
          label: BaseBreadcrumbKey.customers,
          routerLink: `${BaseRouteName.customers}/${brokerPath}/wefox-customers`
        },
        {
          label: customerName,
          routerLink: `${BaseRouteName.customers}/${brokerPath}/detail/${this._customerId}`
        },
        {
          label: this.claims.claim_number
        }
      ],
      mainTitle: this.claims.claim_number
    });
  }

  private _loadClaimDetails(): void {
    this._sessionService.setLoading(true, '_CLM_fetching_claim_data');
    this._pageDetailsService
      .getCardDetailsConfig$(DETAILS_CARD_CATEGORY.claim, DETAILS_CARD_SUBCATEGORY.wefoxCustomer)
      .pipe(
        concatMap(details => {
          this.dynamicConfig = this._pageDetailsService.parseConfig(details);
          return this._claimsDetailsService.getClaimsDetails$(this._claimId, this.dynamicConfig);
        })
      )
      .subscribe({
        next: (response) => {
          this.claims = response;
          this._setPageData(response);
          this._setBreadcrumbs();
        },
        error: () => {
          // TODO:  how to manage error in fetching data?
          this._sessionService.setLoading(false);
        },
        complete: () => this._sessionService.setLoading(false)
      });
  }

  private _setPageData(response): void {
    this.detailsCards = this._pageDetailsService.buildDetailCards(this.dynamicConfig, response, this._lokalisePrefix);
  }
}
