import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { CustomersService } from '@wefoxGroupOneBPPrivate/modules/customers/services/customers.service';
import { emailPattern } from '@wefoxGroupOneBPShared/constants';
import { WgDynamicComponentsModule } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/wg-dynamic-components.module';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { Subject, takeUntil } from 'rxjs';
import { ShareQuestionsModalConfirmationComponent } from '../share-questions-modal-confirmation/share-questions-modal-confirmation.component';
import { TranslocoModule } from '@ngneat/transloco';

export interface ShareQuestionsInterface {
  customer_full_name?: string;
  customer_email?: string;
  customer_id?: string;
  locale?: string;
  country?: string;
  url?: string;
}

interface ShareQuestionsModalOptions {
  customerId: string;
  closeAction: () => void;
  isThirdParty: boolean;
  url: string;
}

@Component({
  selector: 'wg-share-questions-modal',
  styleUrls: ['./share-questions-modal.component.scss'],
  templateUrl: './share-questions-modal.component.html',
  standalone: true,
  imports: [CommonModule, WgDynamicComponentsModule, TranslocoModule, ReactiveFormsModule, ShareQuestionsModalConfirmationComponent],
})
export class ShareQuestionsModalComponent implements OnInit {
  public contentViewOptions: { options: ShareQuestionsModalOptions };
  public customer: Partial<ShareQuestionsInterface>;
  public fetchingCustomerData = false;
  public isThirdParty: boolean;
  public loading = false;
  public options: ShareQuestionsModalOptions;
  public shareRiskAnalysisForm: UntypedFormGroup;

  public name = {
    control_name: 'name',
    type: 'text',
    label: '_RA_name_input',
    readonly: true,
    required: true
  };

  public email = {
    control_name: 'email',
    type: 'text',
    label: '_RA_email_input',
    readonly: true,
    required: true
  };

  public url = {
    control_name: 'url',
    type: 'text',
    label: '_RA_url_link',
    icon: {
      key: 'copy',
      size: 'icon-md',
      class: 'onebp-u-pointer fw-bold color-primary border-left pl-small'
    },
    readonly: true
  };

  private _unsubscribe$: Subject<void> = new Subject();
  private _currentLocale = this._sessionQuery.getCurrentLocale();
  private _country = this._sessionQuery.getCountry();

  constructor(
    private _fb: UntypedFormBuilder,
    private _modal: WgModalService,
    private _customersService: CustomersService,
    private _analytics: AnalyticsService,
    private _sessionQuery: SessionQuery,
    private _route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.options = this.contentViewOptions.options;
    this.isThirdParty = this.options.isThirdParty;
    this.shareRiskAnalysisForm = this._fb.group({
      ['name']: this._fb.control('', [Validators.required]),
      ['email']: this._fb.control('', [Validators.required, Validators.pattern(emailPattern)]),
      ['url']: this._fb.control(null)
    });
    this._getCustomerData();
  }

  public closeModal(): void {
    this._analytics.event({
      GAEventCategory: GAEventCategories.CustomerFlowRiskAnalysis,
      GAEventAction: GAEventActions.Cancel,
      GAEventType: AnalyticsCustomEvents.interactionEvent
    });
    this._modal.hide();
  }

  // eslint-disable-next-line
  public copyToClipboard(urlObject: any): void {
    const urlToCopy = this.shareRiskAnalysisForm.get(urlObject.control_name).value;
    navigator.clipboard.writeText(urlToCopy);

    urlObject.icon.key = 'check';
    setTimeout(() => {
      urlObject.icon.key = 'copy';
    }, 2000);
  }

  public handleSendEmail(customerDataToSend): void {
    if (this.shareRiskAnalysisForm.valid) {
      this._analytics.event({
        GAEventCategory: GAEventCategories.CustomerFlowRiskAnalysis,
        GAEventAction: GAEventActions.Share,
        GAEventType: AnalyticsCustomEvents.ctaEvent
      });
      this.loading = true;
      const { customerId } = this.contentViewOptions.options;
      customerDataToSend = {
        customer_full_name: customerDataToSend.customer_full_name,
        customer_email: customerDataToSend.customer_email,
        customer_id: customerId,
        locale: this._currentLocale,
        country: this._country,
        url: customerDataToSend.url
      };
      this._customersService
        .getSendEmailQuestions$(customerDataToSend, this.isThirdParty)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe({
          next: () => {
            this.loading = false;
            this.openConfirmationModal();
          },
          error: () => {
            this.loading = false;
          }
        });
    }
  }

  public openConfirmationModal(): void {
    this._modal.setAndShow({
      contentView: ShareQuestionsModalConfirmationComponent,
      contentViewOptions: {
        options: {
          buttonAction: () => this._modal.hide(),
          buttonText: '_RA_share_button',
          description: '_RA_modal_confirmation_description',
          title: '_RA_modal_title'
        }
      }
    });
  }

  private _getCustomerData() {
    this.fetchingCustomerData = true;
    const { customerId } = this.contentViewOptions.options;
    this._customersService.getShareQuestions$(customerId, this.isThirdParty).subscribe({
      next: (customerData: ShareQuestionsInterface) => {
        this.customer = customerData;
        this.fetchingCustomerData = false;
        this.shareRiskAnalysisForm.patchValue({
          name: customerData.customer_full_name,
          email: customerData.customer_email,
          url: customerData.url
        });
      },
      complete: () => (this.loading = false)
    });
  }
}
