// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep one-card-content-toggle mark {
  background-color: transparent;
  color: var(--primary-base);
  display: inline;
  font-weight: bold;
}
::ng-deep one-card-content-toggle a {
  color: var(--base);
  text-decoration: underline;
}

:host {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/card-content-toggle/card-content-toggle.component.scss"],"names":[],"mappings":"AAEI;EACE,6BAAA;EACA,0BAAA;EACA,eAAA;EACA,iBAAA;AADN;AAII;EACE,kBAAA;EACA,0BAAA;AAFN;;AAOA;EACE,WAAA;AAJF","sourcesContent":["::ng-deep {\n  one-card-content-toggle {\n    mark {\n      background-color: transparent;\n      color: var(--primary-base);\n      display: inline;\n      font-weight: bold;\n    }\n\n    a {\n      color: var(--base);\n      text-decoration: underline;\n    }\n  }\n}\n\n:host {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
