import { Component, Input } from '@angular/core';

@Component({
  selector: 'wg-page-data-counter',
  templateUrl: './page-data-counter.component.html'
})
export class PageDataCounterComponent {
  @Input() public pageData;
  @Input() public listType: string;
}
