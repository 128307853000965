import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { VariablesStore } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/stores/variables.store';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'one-custom-section-component',
  templateUrl: './custom-section-component.html'
})
export class CustomSectionComponent implements AfterContentInit, OnDestroy {
  @Input() public adapter_class: string;
  @Input() public content_data: any; // eslint-disable-line
  @Input() public index: number;
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public type: string;
  @Input() public variables: any; // eslint-disable-line
  @Input() public variablesDependencies: any; // eslint-disable-line

  @Output() public emitFilePath = new EventEmitter();
  @Output() init: EventEmitter<any> = new EventEmitter(); // eslint-disable-line
  private _unsubscribe$: Subject<void> = new Subject();
  public visible = true;

  constructor(private _varStore: VariablesStore) {}

  public selectedFiles(event) {
    this.emitFilePath.emit(event);
  }

  ngAfterContentInit(): void {
    this.init.emit(this.content_data);
    this._checkInitialVisibility();
    this._varStore.lastUpdate$.pipe(takeUntil(this._unsubscribe$)).subscribe(variable => {
      if (this.content_data.section_hide === variable.key) {
        this.visible = variable.value as boolean;
      }
    });
  }

  private _checkInitialVisibility(): void {
    if (this.content_data.section_hide) {
      this.visible = this._varStore.getBoolVariableValue(this.content_data.section_hide);
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
