import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { RouteParams } from '@wefoxGroupOneBPCore/constants/route.constant';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { DialogComponent } from '@wefoxGroupOneBPShared/components';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { finalize } from 'rxjs';
import { RiskAnalysisService } from '../../services/risk-analysis.service';

@Component({
  selector: 'wg-risk-profile',
  templateUrl: './risk-profile.component.html',
  styleUrls: ['./risk-profile.component.scss']
})
export class RiskProfileComponent implements OnInit {
  public isThirdParty: boolean;
  public loading: boolean;

  public riskExposure = {
    riskLevel: 'high' // it will be high until we have another behaviour specified
  };

  public profile;

  private _riskAnalysisId: string;

  constructor(
    private _modal: WgModalService,
    private _riskAnalysisService: RiskAnalysisService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sessionQuery: SessionQuery,
    private _analytics: AnalyticsService
  ) { }

  public ngOnInit(): void {
    this._riskAnalysisId = this._route.snapshot.params[RouteParams.riskAnalysisId];
    this.isThirdParty = this._route.snapshot.data.isThirdParty;
    this.loading = true;
    this._riskAnalysisService.getRiskAnalysisProfile$(this._riskAnalysisId)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((profile) => this.profile = profile);
  }

  public openRiskCalcModal(): void {
    this._modal.setAndShow({
      contentView: DialogComponent,
      contentViewOptions: {
        options: {
          buttonAction: () => this._modal.hide(),
          buttonText: '_RA_determine_risk_got_it_button',
          description: '_RA_determine_risk_modal_description',
          showCloseButton: true,
          title: '_RA_determine_risk_modal_title'
        }
      }
    });
  }

  public navigateToProducts() {
    this._analytics.event({
      GAEventCategory: GAEventCategories.BrokerFlowRiskAnalysis,
      GAEventAction: GAEventActions.XProductAvailable,
      GAEventType: AnalyticsCustomEvents.ctaEvent,
    });
    const customerId = this._route.snapshot.params[RouteParams.customerId];
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/risk-analysis/${this.isThirdParty ? 'third-party/' : 'wefox-customers/'}${customerId}/profile/${this._riskAnalysisId}/products`);
  }

}
