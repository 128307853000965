// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  max-height: 95vh;
  max-width: 800px;
  min-width: 600px;
}

.card-body {
  overflow: scroll;
}

.remove-right-margin {
  margin-right: -8px;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/account/components/fks-background-process-modal/fks-background-process-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".card {\n  max-height: 95vh;\n  max-width: 800px;\n  min-width: 600px;\n}\n\n.card-body {\n  overflow: scroll;\n}\n\n.remove-right-margin {\n  margin-right: -8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
