import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { translate } from '@ngneat/transloco';
import { ListCategory, PAGE_DATA_DEFAULTS } from '@wefoxGroupOneBP/app/private/product/constants/list.constant';
import { TABLE_CONFIG_CATEGORY, TABLE_CONFIG_SUBCATEGORY } from '@wefoxGroupOneBP/app/private/product/constants/page-details.constant';
import { PageDetailsService } from '@wefoxGroupOneBP/app/private/product/services/page-details/page-details.service';
import { AllListInfo, DynamicTableColumnHeader, DynamicTableConfigParsed } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { Observable, Subject, map, tap } from 'rxjs';
import { Policy } from '../../interfaces/contracts.interface';
import { ContractsService } from '../../services/contracts.service';

@Component({
    selector: 'one-policy-table-dashboard',
    templateUrl: 'policy-table-dashboard.component.html',
    styleUrls: []
})
export class PolicyTableDashboardComponent implements OnInit {
    @Input() config: DynamicTableConfigParsed;

    public feedbackConfig = null;
    public listOfColumns: DynamicTableColumnHeader[];
    public loading: boolean;
    public locale = 'de';
    public pageData = PAGE_DATA_DEFAULTS;
    public refreshList: Subject<void> = new Subject<void>();
    public sortOrder = '';
    public sortField = '';
    public selectedFilters = {};
    public size: number;
    public searchbarPlaceholder = '';

    constructor(
        private _contractsService: ContractsService,
        private _pageDetailsService: PageDetailsService,
        private _router: Router,
        private _sessionQuery: SessionQuery
    ) { }

    public loadData = (): Observable<AllListInfo> => {
        const brokerId = this._sessionQuery.getBrokerId();
        const request$ = this._getTermLifePolicies(brokerId, this.size);

        return request$
            .pipe(
                map((response: AllListInfo) => {
                    return { ...response, total_elements: response.totalElements };
                }),
                tap((response: AllListInfo) => this._updatePageData(response)));
    }

    public navigateToContracts(): void {
        this._router.navigate([
            `${this._sessionQuery.getCountryPrefix()}/contracts/${this._sessionQuery.getBrokerPath()}${'/policies'}`
        ]);
    }

    public ngOnInit(): void {
        this.locale = this._sessionQuery.getCurrentLocale();
        this._setListOfColumns();
    }

    public transformData = (
        data: Policy[]
    ): Partial<Policy>[] => {
        return data.map((element: Policy) => {
            const item = {
                ...this._pageDetailsService.transformTableData(
                    this._pageDetailsService.getTableRowItems([this.config], ListCategory.contracts),
                    this._pageDetailsService.mapListToHeader([this.config], element, ListCategory.contracts)
                ),
                original_data: element
            };
            Array.of('policy_type')
                .filter(col => item[col])
                .forEach(col => (item[col] = translate(item[col])));
            return item;
        });
    };

    private _setListOfColumns() {
        this._pageDetailsService
            .getTableConfig$(TABLE_CONFIG_CATEGORY.contract, TABLE_CONFIG_SUBCATEGORY.contract)
            .pipe(
                tap(response => {
                    this.config = this._translateData(
                        this._pageDetailsService.parseConfig(response)[0] as DynamicTableConfigParsed
                    );
                    this.listOfColumns = this.config.column_headers;
                })
            )
            .subscribe();
    }

    private _translateData(data: DynamicTableConfigParsed): DynamicTableConfigParsed {
        data.column_headers = data.column_headers.map(col => {
            col.headerLabel = translate(col.headerLabel);
            if (col.filterConfig) {
                col.filterConfig.options = col.filterConfig.options?.map(option => ({
                    ...option,
                    label: translate(option.label)
                }));
            }
            return col;
        });

        this.searchbarPlaceholder = translate(`${data?.searchbar_options?.i18nPrefixKey || ''}searchbar_placeholder`);

        return data;
    }

    private _getTermLifePolicies(brokerId, size) {
        return this._contractsService.getTermLifePolicies$(brokerId, size);
    }

    private _updatePageData(response: AllListInfo): void {
        this.pageData = response?.content?.length ? this._pageDetailsService.updatePageData(response) : PAGE_DATA_DEFAULTS;
    }
}
