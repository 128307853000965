import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '@wefoxGroupOneBP/app/private/modules/customers/services/customers.service';
import { AnalyticsCustomEvents, GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { RouteParams } from '@wefoxGroupOneBPCore/constants/route.constant';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import { finalize } from 'rxjs';
import { RiskAnalysisService } from '../../services/risk-analysis.service';

@Component({
  selector: 'wg-product-recommendations',
  templateUrl: './product-recommendations.component.html',
  styleUrls: ['./product-recommendations.component.scss']
})
export class ProductRecommendationsComponent implements OnInit {

  public isThirdParty: boolean;
  public riskExposure = {
    riskLevel: 'high' // it will be high until we have another behaviour specified
  };

  public products: string[];

  public loading: boolean;
  private _riskAnalysisId: string;

  constructor(
    private _riskAnalysisService: RiskAnalysisService,
    private _customerService: CustomersService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _analytics: AnalyticsService
  ) { }

  public ngOnInit(): void {
    this._riskAnalysisId = this._route.snapshot.params[RouteParams.riskAnalysisId];
    this.isThirdParty = this._route.snapshot.data.isThirdParty;
    this.loading = true;
    this._riskAnalysisService.getRiskAnalysisProfile$(this._riskAnalysisId)
      .pipe(
        finalize(() => this.loading = false)
      )
      .subscribe((profile) => {
        this.products = profile.risk_analysis_extra_dto.recommendations.map(productItem => productItem.product);
      });
  }

  public navigateBack() {
    const customerId = this._route.snapshot.params[RouteParams.customerId];
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/risk-analysis/${this.isThirdParty ? 'third-party' : 'wefox-customers'}/${customerId}/profile/${this._riskAnalysisId}`);
  }

  public navigateToCustomerDetail() {
    const customerId = this._route.snapshot.params[RouteParams.customerId];
    this._analytics.event({
      GAEventCategory: GAEventCategories.BrokerFlowRiskAnalysis,
      GAEventAction: GAEventActions.Continue,
      GAEventType: AnalyticsCustomEvents.ctaEvent,
    });
    this._router.navigateByUrl(
      `${this._sessionQuery.getCountryPrefix()}/customers/${this._sessionQuery.getBrokerPath()}/${this.isThirdParty ? 'third-party' : 'wefox-customers'}/detail/${customerId}`
    );
  }

  public resendCustomerEmail() {
    const customerId = this._route.snapshot.params[RouteParams.customerId];
    this._riskAnalysisService.resendCustomerEmail(customerId, this.isThirdParty).subscribe();
  }
}
