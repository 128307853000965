import { Directive, OnDestroy, Optional, Self, SkipSelf } from '@angular/core';
import { FormGroupDirective as AngularFormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formGroup]'
})
export class WgFormGroupDirective implements OnDestroy {
  private destroyed = new Subject<void>();

  constructor(
    @Self() formGroupDirective: AngularFormGroupDirective,
    @Optional() @SkipSelf() parentFormGroupDirective: AngularFormGroupDirective
  ) {
    if (parentFormGroupDirective) {
      parentFormGroupDirective.ngSubmit.pipe(takeUntil(this.destroyed)).subscribe((event: Event) => {
        const formParent = document.getElementsByTagName('form')[0];
        if (formParent.classList.contains('-submitted') === false) {
          formParent.classList.add('-submitted');
        }
        formGroupDirective.onSubmit({ ...event, target: formParent });
      });
    } else {
      formGroupDirective.ngSubmit.pipe(takeUntil(this.destroyed)).subscribe(() => {
        formGroupDirective.form.markAllAsTouched();
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
