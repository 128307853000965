import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { WgErrorComponentOptions } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';

export interface TextAreaAdapterComponentOptions {
  cols?: number; // but numerical value
  control_name: string;
  errors?: WgErrorComponentOptions[];
  label: string;
  max_length?: number;
  placeholder?: string;
  required: boolean;
  rows?: number; // but numerical value
}

@Component({
  selector: 'one-text-area-adapter',
  templateUrl: './text-area-adapter.component.html'
})
export class TextAreaAdapterComponent {
  @Input() public content_data: TextAreaAdapterComponentOptions;
  @Input() public parentGroup: UntypedFormGroup;
}
