import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicRowComponent } from '../row/dynamic-row.component';
import { SharedModule } from '@wefoxGroupOneBPShared/shared.module';

@Component({
  selector: 'one-dynamic-section-component',
  standalone: true,
  templateUrl: './dynamic-section.component.html',
  imports: [CommonModule, DynamicRowComponent, SharedModule]
})
export class DynamicSectionComponent {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public variables: any; // eslint-disable-line
  @Input() public variablesDependencies: any; // eslint-disable-line
  @Output() public emitFilePath = new EventEmitter();

  public selectedFiles(event) {
    this.emitFilePath.emit(event);
  }
}
