import { registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { ANGULAR_LOCALE_DATA, COUNTRIES, LOCALES, AUTH_METHODS, COUNTRY_DATA } from '@wefoxGroupOneBPCore/constants';
import { MasterDataDefinition } from '@wefoxGroupOneBPCore/interfaces';
import { CMSConfig } from '@wefoxGroupOneBPCore/interfaces/cms-config.interface';
import { CentralizedQuery, SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { AWSService } from '@wefoxGroupOneBPCore/services/aws.service';
import { CookieService } from '@wefoxGroupOneBPCore/services/cookie.service';
import { IpLocationService } from '@wefoxGroupOneBPCore/services/ip-location.service';
import { MasterDataService } from '@wefoxGroupOneBPCore/services/master-data.service';
import { SessionService } from '@wefoxGroupOneBPCore/services/session.service';
import * as dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { AnalyticsService } from '../analytics/services/analytics.service';
import { TokenStorageService } from './token-storage.service';
import { KeycloakToken } from '@wefoxGroupOneBPCore/interceptors/keycloak-token.interface';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class AppInitializerService {
  public initConfig$: Observable<any>; // eslint-disable-line

  private _activeCountry: string;
  private _availableCountries = environment.availableCountries;
  private _country: COUNTRIES;
  private _locale: LOCALES;
  private _locationPath: string;

  constructor(
    private _aws: AWSService,
    private _centralizedQuery: CentralizedQuery,
    private _masterDataService: MasterDataService,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _ipLocation: IpLocationService,
    private _cookieService: CookieService,
    private _analytics: AnalyticsService,
    private _tokenStorageService: TokenStorageService,
    private translocoService: TranslocoService
  ) {
    this._sessionQuery
      .select(state => state.locale)
      .pipe(distinctUntilChanged())
      .subscribe((locale: string) => {
        if (locale) {
          this.setupLocale(locale as LOCALES);
        }
      });
  }

  public checkAllowedRoutes(): string {
    this._locationPath = window.location.pathname + window.location.search;

    const pathArray = this._locationPath.split('/');
    const firstPathSegment = pathArray[1].toUpperCase(); // Can be country or any allowed routes, self-journey etc or random.
    const segmentAfterCountry = pathArray[2]; // Can be allowed routes or random
    // get master data here and then check the route.

    if (this._availableCountries.includes(firstPathSegment)) {
      this._activeCountry = firstPathSegment.toLowerCase();

      const currentRoute = segmentAfterCountry?.split('?')[0].toLowerCase();
      const allowedRoute = this._centralizedQuery.getAllowedRoutes(this._sessionQuery.isLoggedIn()).includes(currentRoute);

      if (allowedRoute) {
        return firstPathSegment;
      }
      this._constructWindowHref();
      return undefined;
    } else {
      // Do not redirect ot login if path contains self-journey
      if (firstPathSegment.toLowerCase() === 'self-journey') {
        this._constructWindowHref(this._locationPath);
        return;
      }
      this._constructWindowHref(); // Redirect to login page // TODO: Maybe use a 404 not found page?
      return undefined;
    }
  }

  public getCountry(): COUNTRIES {
    return this._country ?? COUNTRIES.de;
  }

  public getCountryAuthMethod(): AUTH_METHODS {
    const country = this.getCountry();
    const countryData = COUNTRY_DATA[country];
    return countryData.authMethod;
  }

  public getLocale(): LOCALES {
    return this._locale;
  }

  // eslint-disable-next-line
  public init(): Observable<any> {
    this.initConfig$ = new Observable(observer => {
      const country: string = this._getCountryFromRoute();

      if (country && this._availableCountries.includes(country)) {
        this._country = country as COUNTRIES;

        const gmapsUrl = `${environment.thirdParty.gmapsUrl}&language=${country}`;
        this._injectScript(gmapsUrl).then(() => {
          observer.next(null);
          observer.complete();
        });
      } else {
        this._manageDefaultCountry();
        observer.complete();
      }
    });

    return this.initConfig$;
  }

  public initAWS(): void {
    this._aws.init();
  }

  public setCountry(country: COUNTRIES): void {
    this._sessionService.setCountry(country);
  }

  /**
   * Trigger initial get for master data based on the current country code
   */
  public setLegacyMasterData$(): Observable<MasterDataDefinition> {
    return this._masterDataService.getData(this._country);
  }

  public setCMSMasterData$(accessToken?: string): Observable<CMSConfig> {
    return this._masterDataService.getCMSConfig(accessToken);
  }

  // eslint-disable-next-line
  public setKeycloakData(parsedToken: KeycloakToken, accessToken: string, refreshToken: string): void {
    this._tokenStorageService.setTokens({ accessToken, refreshToken });
    this._sessionService.setBrokerId('temporalPKExternal'); // TODO change when we have a field similar to an ID
    this._sessionService.setTokenExpiration(parsedToken.exp);
    this._sessionService.setKeycloakToken(parsedToken);
    this._cookieService.set('brokerId', 'temporalPKExternal'); // TODO change when we have a field similar to an ID
    this._cookieService.set('defaultCountry', this._centralizedQuery.getCountryCode());
    this._analytics.sendBrokerId('temporalPKExternal'); // TODO change when we have a field similar to an ID
  }

  public setupLocale(locale: LOCALES): void {
    this._locale = locale.toLowerCase() as LOCALES;
    this._setAppLocale(locale);
    dayjs.locale(locale);
  }

  // eslint-disable-next-line
  public startupApp(countryConfig: any): Promise<any> {
    // Check allowed routes else redirect to login or dashboard
    this.checkAllowedRoutes();
    this.setupLocale(countryConfig.default_language);
    this.setCountry(countryConfig.country_code);
    this.initAWS();
    // Translations setup

    let locale = countryConfig.default_language;
    if (this.getCountry() === COUNTRIES.intl) {
      locale = COUNTRIES.de.toLocaleLowerCase();
      // FIXME temporal fix to use de.json, to be removed when we migrate the i18n library
      // FIXME we need only the defaultLocale translation file, for INTL on login all the languages will be available, and we can't load everything on startup
    }
    if (!!this._cookieService.get('locale')) {
      locale = this._cookieService.get('locale').toString() as LOCALES;
    }
    this.translocoService.setActiveLang(locale);
    return this.translocoService.load(locale).toPromise();
  }

  private _assignDefaultCountry(countryCode: string, path?: string) {
    const hostOrigin = window.location.origin;
    const defaultCountry = this._getDefaultCountry(countryCode).toLowerCase();
    window.location.href = `${hostOrigin}/${this._activeCountry ? this._activeCountry : defaultCountry}${
      path ? path : this._sessionQuery.isLoggedIn() ? '/dashboard' : '/login'
    }`;
  }

  private _constructWindowHref(path?: string): void {
    const hostOrigin = window.location.origin;
    const defaultCountry = COUNTRIES.de.toLowerCase();

    window.location.href = `${hostOrigin}/${this._activeCountry ? this._activeCountry : defaultCountry}${
      path ? path : this._sessionQuery.isLoggedIn() ? '/dashboard' : '/login'
    }`;
  }

  private _getCountryFromRoute(): string {
    this._locationPath = window.location.pathname + window.location.search;

    return this._locationPath.split('/')[1].toUpperCase();
  }

  private _getDefaultCountry(countryCode: string) {
    const isValid = this._availableCountries.includes(countryCode.toUpperCase());
    return isValid ? countryCode : COUNTRIES.de;
  }

  private _injectScript(url: string, useCached?: (url: string) => boolean) {
    return new Promise((resolve, reject) => {
      if (useCached && useCached(url)) {
        resolve(url);
        return;
      }
      const script: HTMLScriptElement = document.createElement('script');
      script.onload = () => {
        resolve(url);
      };
      script.onerror = () => {
        reject();
      };
      script.src = url;
      document.body.appendChild(script);
    });
  }

  private _manageDefaultCountry(): void {
    const defaultCountry = this._cookieService.get('defaultCountry');
    if (defaultCountry) {
      this._assignDefaultCountry(defaultCountry);
    } else {
      this._ipLocation.getGeolocationData().subscribe(
        res => this._assignDefaultCountry(res.countryCode),
        () => this._assignDefaultCountry(window.location.host.startsWith("distribution") ? COUNTRIES.intl : COUNTRIES.de)
      );
    }
  }

  private _setAngularLocale(locale: LOCALES): void {
    registerLocaleData(ANGULAR_LOCALE_DATA[locale], locale);
  }

  private _setAppLocale(locale: LOCALES): void {
    this._setAngularLocale(locale);
  }
}
