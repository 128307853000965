import { CommonModule } from "@angular/common";
import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { TranslocoModule } from '@ngneat/transloco';
import { GAEventActions, GAEventCategories } from "@wefoxGroupOneBPCore/analytics/constants";
import { AnalyticsService } from "@wefoxGroupOneBPCore/analytics/services/analytics.service";
import { environment } from "@wefoxGroupOneBPCore/environments/environment";


@Component({
  selector: 'wg-cancel-policy',
  styleUrls: ['cancel-policy.component.scss'],
  templateUrl: 'cancel-policy.component.html',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  providers: [AnalyticsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CancelPolicyComponent {

  @Input() contract: any; // eslint-disable-line
  @Input() isThirdParty: boolean;
  @Output() dialogClosed = new EventEmitter();

  public loadingDownload = false;
  public showCancelDialog = false;
  @ViewChild('iframeContainer') public iframeContainer: ElementRef;

  constructor(
    private _analyticsService: AnalyticsService
  ) {}

  public closeDialog() {
    this.showDialog(false);
    this.dialogClosed.emit();
  }

  public showDialog(state: boolean): void {
    const contract = this.contract.contract;
    this.showCancelDialog = state;
    if (state) {
      this._analyticsService.event({
        GAEventAction: GAEventActions.OpenModal,
        GAEventCategory: GAEventCategories.CancelContract
      });
      setTimeout(() => {
        const container = this.iframeContainer.nativeElement;
        const src = `${environment.domains.cancel}${contract.id}`;
        const IframeAttribute = {
          frameBorder: '0',
          height: '680px',
          src,
          title: 'wefox-policy-microapp',
          width: '100%'
        };

        const iframe = this._setAttributes(document.createElement('iframe'), IframeAttribute);
        container.appendChild(iframe);
      }, 1);
    }
  }

  // eslint-disable-next-line
  private _setAttributes(iframe: HTMLIFrameElement, iframeAttribute: any): HTMLIFrameElement {
    const total = Object.entries(iframeAttribute).length;
    for (let index = 0; index < total; index++) {
      const attribute = Object.keys(iframeAttribute)[index] as string;
      const value = Object.values(iframeAttribute)[index] as string;
      iframe.setAttribute(attribute, value);
    }
    return iframe;
  }
}