import { Price, ShortCoverage } from '@wefoxGroupOneBPPrivate/product/services/quotation/dtos/packages-info.dto';

export const getManagedGuarantees = (price: Price): ShortCoverage[] => {
  if (!price.sections) {
    return price.guarantees.map(g => {
      return {
        id: g.id,
        price: g.price
      };
    });
  }

  const guaranteesInSections = price.sections
    .map(s => s.guarantees)
    .reduce(function (pre, cur) {
      return pre.concat(cur);
    });

  const sections: ShortCoverage[] = price.sections.map(s => ({
    id: s.guarantees.join('_'),
    price: s.price,
    price_without_discount: s.price_without_discount
  }));

  return sections.concat(price.guarantees.filter(g => !guaranteesInSections.includes(g.id)));
};
