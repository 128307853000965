import { Component, ComponentFactoryResolver, ComponentRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalAction, ModalOptions } from '@wefoxGroupOneBPShared/modules/wg-modal/interfaces/wg-modal.interface';
import { WgModalService } from '@wefoxGroupOneBPShared/modules/wg-modal/services/wg-modal.service';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { WgModalDirective } from '../../directives/wg-modal.directive';

@Component({
  selector: 'wg-modal',
  styleUrls: ['./wg-modal.component.scss'],
  templateUrl: './wg-modal.component.html'
})
export class WgModalComponent implements OnDestroy, OnInit {
  @ViewChild(WgModalDirective, { static: true }) public modalDirective: WgModalDirective;
  public options: Partial<ModalOptions>;

  private _componentName: any; // eslint-disable-line
  private _componentRef: ComponentRef<any>; // eslint-disable-line
  private _contentViewOptions: any; // eslint-disable-line
  private _isShown: boolean;
  private _subscription: Subscription;

  constructor(
    private _modalService: WgModalService,
    private _resolver: ComponentFactoryResolver
  ) {
    this.options = {};
    this._isShown = false;
    this._subscription = new Subscription();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this._subscription.add(
      this._modalService.modalOptions$.subscribe((options: Partial<ModalOptions>) => {
        this.options = options;
        this._contentViewOptions = options.contentViewOptions;
        this._componentName = options.contentView;

        this._loadContent();
      })
    );

    this._subscription.add(
      this._modalService.isShown$.subscribe((visible: boolean) => {
        this._isShown = visible;
      })
    );
  }

  public onCloseClick(): void {
    if (this.options.showCloseConfirm) {
      if (window.confirm(this.options.closeConfirmText || translate('_GEN_closeModalConfirm'))) {
        this._finishModal();
      }
    } else {
      this._finishModal();
    }
  }

  public onCompleteClick(): void {
    // extra behavior if needed
    this._finishModal();
  }

  public setVisibility(): { display: string } {
    return {
      display: this._isShown === true ? 'block' : 'none'
    };
  }

  private _finishModal(): void {
    this._modalService.hide();

    this.options = {};

    if (this._componentRef) {
      this._componentRef.destroy();

      this._componentRef = undefined;
    }
  }

  private _loadContent() {
    const viewContainerRef = this.modalDirective.viewContainerRef;

    viewContainerRef.clear();

    if (this.options && this._componentName) {
      const componentFactory = this._resolver.resolveComponentFactory(this._componentName);
      this._componentRef = viewContainerRef.createComponent(componentFactory);
      this._componentRef.instance.contentViewOptions = this._contentViewOptions;
      if (this._componentRef.instance.actionEvent) {
        this._componentRef.instance.actionEvent.subscribe((action: ModalAction) => {
          if (!!action) {
            this._modalService.setActionListener(action);
          }
        });
      }
    }
  }
}
