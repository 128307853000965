import { Component, Input } from '@angular/core';

@Component({
  selector: 'one-license-plate-adapter',
  templateUrl: './license-plate-adapter.component.html'
})
export class LicensePlateAdapterComponent {
  @Input() public content_data;
  @Input() public parentGroup;

}
