import { Injectable } from "@angular/core";
import { BaseDataService } from "@wefoxGroupOneBPCore/services";

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService extends BaseDataService {
  private _registerDeviceEndpoint = 'push-configuration/add';
  
  public registerDevice$(token: string) {
    const url = this.getUrl({
      path: `${this._registerDeviceEndpoint}`
    });
    const body = {
      device_id: token
    }
    return this.post$(url, body, {});
  }
}