// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-selection.-withTooltip {
  margin-right: 40px;
  position: relative;
}

.form-check-selection-label_tooltip {
  position: absolute;
  right: -40px;
  top: 14px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/coverage-type-selector/coverage-type-selector.component.scss"],"names":[],"mappings":"AAEE;EACE,kBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,kBAAA;EACA,YAAA;EACA,SAAA;AAFF","sourcesContent":["\n.form-check-selection {\n  &.-withTooltip {\n    margin-right: 40px;\n    position: relative;\n  }\n}\n\n.form-check-selection-label_tooltip {\n  position: absolute;\n  right: -40px;\n  top: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
