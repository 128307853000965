import { Component, Input } from '@angular/core';
import { CustomerErrorData } from '@wefoxGroupOneBPShared/interfaces';

@Component({
  selector: 'one-customer-error-list',
  styles: [],
  templateUrl: './customer-error-list.component.html'
})
export class CustomerErrorListComponent {
  @Input() public errorKeys: CustomerErrorData[] = [];
  private _patternErrorProp = [
    'firstname',
    'lastname',
    'phone_number',
    'mobile_number',
    'postal_code',
    'house_number',
    'place'
  ];

  public checkPatternError(prop: string): boolean {
    return this._patternErrorProp.includes(prop);
  }

  public checkRequiredError(): boolean {
    return this.errorKeys.some(error => error.key === 'required');
  }
}
