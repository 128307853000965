import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

const DISABLED_STYLE = {
  'pointer-events': 'none',
  opacity: '0.5'
};

export interface QuestionAdapterComponentOptions {
  class_modifiers: string,
  control_name: string,
  default_value: boolean,
  label: string,
  required: boolean,
  validators: [{ type: string }]
}

@Component({
  selector: 'one-question-adapter',
  templateUrl: './question-adapter.component.html'
})
export class QuestionAdapterComponent implements OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public getDisabledState(): { [key: string]: string } {
    if (!this.content_data.disable) {
      return;
    }

    if (this.content_data.disable.immediately) {
      return DISABLED_STYLE;
    }

    if (this.content_data.disable.depends_on) {
      const dependsOn = this.content_data.disable.depends_on;
      return this.parentGroup.get(dependsOn.control_name).value === dependsOn.value ? DISABLED_STYLE : null;
    }
  }

  public ngOnInit(): void {
    if (this.content_data.default_value !== undefined) {
      const component = this.parentGroup.get(this.content_data.control_name);

      if (component?.value === '') {
        component?.setValue(this.content_data.default_value);
      }
    }
  }
}
