// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-divider {
  animation: slow-in 0.7s linear;
  border-radius: 8px;
  flex: 1;
  height: 4px;
  margin: 0 10px;
  transition: background-color 0.7s ease-in-out;
}
.step-divider.feedback-success {
  animation: slow-in 0.7s linear;
  background-color: var(--primary-base);
}
.step-divider.step-done {
  animation: slow-in 0.7s linear;
  background-color: var(--primary-base);
}
.step-divider.feedback-danger {
  animation: slow-in 0.7s linear;
  background-color: var(--danger-base);
}
.step-divider.step-next {
  animation: slow-in 0.7s linear;
  background-color: var(--neutral-light);
}

.step-label {
  top: 40px;
}
.step-label p {
  animation: slow-in 0.5s linear;
  width: max-content;
}
.step-label p.active {
  color: var(--primary-base);
}
.step-label p.complete {
  color: var(--primary-base);
}
.step-label p.error {
  color: var(--danger-base);
}
.step-label p.pending {
  color: var(--neutral-base);
}

svg {
  animation: slow-in 0.3s linear;
}

@keyframes slow-in {
  from {
    flex: 0;
  }
  to {
    flex: 1;
  }
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/steps/steps.component.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,kBAAA;EACA,OAAA;EACA,WAAA;EACA,cAAA;EACA,6CAAA;AACF;AACE;EACE,8BAAA;EACA,qCAAA;AACJ;AAEE;EACE,8BAAA;EACA,qCAAA;AAAJ;AAGE;EACE,8BAAA;EACA,oCAAA;AADJ;AAIE;EACE,8BAAA;EACA,sCAAA;AAFJ;;AAMA;EACE,SAAA;AAHF;AAKE;EACE,8BAAA;EACA,kBAAA;AAHJ;AAKI;EACE,0BAAA;AAHN;AAMI;EACE,0BAAA;AAJN;AAOI;EACE,yBAAA;AALN;AAQI;EACE,0BAAA;AANN;;AAWA;EACE,8BAAA;AARF;;AAWA;EACE;IACE,OAAA;EARF;EAWA;IACE,OAAA;EATF;AACF","sourcesContent":[".step-divider {\n  animation: slow-in 0.7s linear;\n  border-radius: 8px;\n  flex: 1;\n  height: 4px;\n  margin: 0 10px;\n  transition: background-color 0.7s ease-in-out;\n\n  &.feedback-success {\n    animation: slow-in 0.7s linear;\n    background-color: var(--primary-base);\n  }\n\n  &.step-done {\n    animation: slow-in 0.7s linear;\n    background-color: var(--primary-base);\n  }\n\n  &.feedback-danger {\n    animation: slow-in 0.7s linear;\n    background-color: var(--danger-base);\n  }\n\n  &.step-next {\n    animation: slow-in 0.7s linear;\n    background-color: var(--neutral-light);\n  }\n}\n\n.step-label {\n  top: 40px;\n\n  & p {\n    animation: slow-in 0.5s linear;\n    width: max-content;\n\n    &.active {\n      color: var(--primary-base);\n    }\n\n    &.complete {\n      color: var(--primary-base);\n    }\n\n    &.error {\n      color: var(--danger-base);\n    }\n\n    &.pending {\n      color: var(--neutral-base);\n    }\n  }\n}\n\nsvg {\n  animation: slow-in 0.3s linear;\n}\n\n@keyframes slow-in {\n  from {\n    flex: 0;\n  }\n\n  to {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
