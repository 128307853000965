import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

export interface CheckboxAdapterComponentOptions {
  checked?: boolean;
  control_name: string;
  options_panel_modifiers?: string;
  has_border?: boolean;
  label: string;
  required?: boolean;
  validators: [{ type: string }];
}
@Component({
  selector: 'one-checkbox-adapter',
  templateUrl: './checkbox-adapter.component.html'
})
export class CheckboxAdapterComponent implements OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public ngOnInit(): void {
    const checkboxControl = this.parentGroup.get(this.content_data.control_name);
    const checkedByDefault = this.content_data.checked;

    if (checkboxControl.value === '') {
      checkboxControl.setValue(!!checkedByDefault);
    }

    if (this.content_data.disabled) {
      checkboxControl.disable();
    }
  }
}
