// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onebp-m-pageHeader__title {
  margin: 0;
  text-align: inherit;
}

.onebp-a-pageHeader {
  margin: 0 auto;
  max-width: 900px;
  position: relative;
  width: 100%;
}
.onebp-a-pageHeader .onebp-a-productTitle {
  font-size: 32px;
}
.onebp-a-pageHeader .onebp-a-productDescription {
  font-size: 20px;
  font-weight: normal;
}
.onebp-a-pageHeader.-fullWidth {
  max-width: unset;
}
.onebp-a-pageHeader.-fullWidth .onebp-a-productDescription,
.onebp-a-pageHeader.-fullWidth .onebp-a-productTitle {
  margin-left: 0;
  max-width: 960px;
}

.onebp-m-pageHeader__back {
  color: var(--primary-base);
  cursor: pointer;
  display: flex;
  margin-bottom: 24px;
  width: max-content;
}
.onebp-m-pageHeader__back svg {
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/page-header/page-header.component.scss"],"names":[],"mappings":"AACA;EACE,SAAA;EACA,mBAAA;AAAF;;AAGA;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AAAF;AAEE;EACE,eAAA;AAAJ;AAGE;EACE,eAAA;EACA,mBAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAII;;EAEE,cAAA;EACA,gBAAA;AAFN;;AAOA;EACE,0BAAA;EACA,eAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAJF;AAME;EACE,eAAA;AAJJ","sourcesContent":["\n.onebp-m-pageHeader__title {\n  margin: 0;\n  text-align: inherit;\n}\n\n.onebp-a-pageHeader {\n  margin: 0 auto;\n  max-width: 900px;\n  position: relative;\n  width: 100%;\n\n  .onebp-a-productTitle {\n    font-size: 32px;\n  }\n\n  .onebp-a-productDescription {\n    font-size: 20px;\n    font-weight: normal;\n  }\n\n  &.-fullWidth {\n    max-width: unset;\n\n    .onebp-a-productDescription,\n    .onebp-a-productTitle {\n      margin-left: 0;\n      max-width: 960px;\n    }\n  }\n}\n\n.onebp-m-pageHeader__back {\n  color: var(--primary-base);\n  cursor: pointer;\n  display: flex;\n  margin-bottom: 24px;\n  width: max-content;\n\n  & svg {\n    margin-top: 6px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
