import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { translate } from '@ngneat/transloco';
import {
  GAEventActions,
  GAEventCategories,
  PaymentAnalyticsType,
  ProfileAnalyticsType
} from '@wefoxGroupOneBPCore/analytics/constants';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { RouteParams } from '@wefoxGroupOneBPCore/constants/route.constant';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { ProductQuery } from '@wefoxGroupOneBPCore/queries/product.query';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { SessionService } from '@wefoxGroupOneBPCore/services';
import {
  ProductType,
  ProductTypeSnakeCase
} from '@wefoxGroupOneBPPrivate/modules/contracts/constants/contracts.constants';
import { ContractsService } from '@wefoxGroupOneBPPrivate/modules/contracts/services/contracts.service';
import { CustomersService } from '@wefoxGroupOneBPPrivate/modules/customers/services/customers.service';
import { CustomerStore } from '@wefoxGroupOneBPPrivate/modules/customers/store/customer.store';
import {
  BOOLEAN_STRING_VALUE,
  CONTRACT_DETAIL_PARAMETER_NAME,
  DETAILS_CARD_CATEGORY,
  DETAILS_CARD_SUBCATEGORY,
  DETAILS_CARD_SUB_SUBCATEGORY,
  DETAILS_CARD_TYPE,
  DETAIL_CARD_LABEL_AND_CONTROL_NAME,
  DETAIL_CARD_NAME,
  ENTITY_TYPE,
  HOMEOWNER_INPUT_VALUES
} from '@wefoxGroupOneBPPrivate/product/constants/page-details.constant';
import { PageDetailsService } from '@wefoxGroupOneBPPrivate/product/services/page-details/page-details.service';
import { DateFormat, ErrorKeys, FormControlItem, SuccessKeys } from '@wefoxGroupOneBPShared/constants';
import { BaseBreadcrumbKey } from '@wefoxGroupOneBPShared/constants/breadcrumbs.constants';
import { DynamicDetailCardConfig, DynamicDetailColumnConfig } from '@wefoxGroupOneBPShared/interfaces/dynamic-details-card.interface';
import { BreadcrumbsService } from '@wefoxGroupOneBPShared/services/breadcrumbs.service';
import { DetailCardService } from '@wefoxGroupOneBPShared/services/detail-card.service';
import { BaseRouteName } from '@wefoxGroupOneBPShared/services/redirection.service';
import * as dayjs from 'dayjs';
import { Subject, catchError, of, switchMap } from 'rxjs';

@Component({
  styleUrls: ['./contract-detail-dynamic-page.component.scss'],
  templateUrl: './contract-detail-dynamic-page.component.html'
})
export class ContractDetailDynamicPageComponent implements OnDestroy, OnInit {
  public bannerOptions: Partial<BannerOptions>;
  public canCancel = false;
  public cardType = DETAILS_CARD_TYPE;
  public columns: DynamicDetailColumnConfig[];
  public DIRECTION_VERTICAL = 'VERTICAL';
  public dynamicConfig: DynamicDetailColumnConfig[];
  public contract: Partial<any>; //  eslint-disable-line
  public contractId: string;
  public customerId: string;
  public isLoading = { payment_info: false };
  public isThirdParty: boolean;
  public showCancelDialog = false;
  public valid_to: string;
  public country: COUNTRIES;
  public wefoxLogoUrl = 'https://cdn-web.wefox.com/public/pkg/@wefox/icons/1.5.8/svg/company-logos/wefox.svg';

  private _contractType: string;
  private _contractMapped: any; // eslint-disable-line
  private _insuredObjectInputs: any; // eslint-disable-line
  private _savedPaymentData;
  private _savedInsuredObjectData;
  private _subSubtype: string;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _contractsService: ContractsService,
    private _customersService: CustomersService,
    private _customerStore: CustomerStore,
    private _detailCardService: DetailCardService,
    private _pageDetailsService: PageDetailsService,
    private _productQuery: ProductQuery,
    private _route: ActivatedRoute,
    private _sessionQuery: SessionQuery,
    private _sessionService: SessionService,
    private _breadcrumbsService: BreadcrumbsService
  ) {
    this._customersService.setViewHasTabs(false);
    this._contractsService.setViewHasTabs(false);
  }

  public getLoadingState(name): boolean {
    if (!name) {
      return false;
    }

    return this.isLoading[name];
  }

  public ngOnDestroy(): void {
    this._breadcrumbsService.resetBreadcrumbConfig();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.country = this._sessionQuery.getCountry();
    this.contract = this._route.snapshot.data.contractData;
    this.contractId = this._route.snapshot.params[RouteParams.contractId];
    this.isThirdParty = this._route.snapshot.data.isThirdParty;

    if (this.contract) {
      if (this.country === COUNTRIES.it) {
        this._contractType = this.contract.contract.product.type;
        this._subSubtype = this._getProductSubtype(this._contractType);
      } else if (!this.isThirdParty) {
        this._contractType = this.contract.contract.insurance_product.product_type;
        this._subSubtype = this._getProductSubtype(this._contractType);
        this._canCancelPolicy(this.contract.contract);
      } else {
        this.contract = { ...this.contract.contractDetail, customer: { ...this.contract.customerInfoCard } };
        this._contractType = this.contract?.product_type;
      }
      this._getPageDetails();
    }
  }

  // eslint-disable-next-line
  private _buildInsuredObjectRequestBody(inputs: any, formData: { [key: string]: any }): Partial<any> {
    // TODO: put editable inputs in the dynamic config
    const requestBody = inputs.map(i => {
      if (i.name === 'object.vehicle.license-plate') {
        i.value = formData['license_plate'];
      }
      if (i.name === 'object.vehicle.id') {
        i.value = formData['vehicle_id'];
      }

      return i;
    });

    return requestBody;
  }

  private _canCancelPolicy(contract): void {
    this.valid_to = contract?.valid_to ? dayjs(contract.valid_to).format(DateFormat.default) : null;
    this.canCancel =
      this._productQuery.getCancelableProduct(contract.insurance_product.api_key) && this.valid_to == null
        ? true
        : false;
    this.bannerOptions = {
      textKey: translate('_COD_cancel_policy_cancelled_banner', { placeHolders: [this.valid_to] }),
      type: '-warning'
    };
  }

  private _getCurrentValue(detail_items, control_name): string {
    return translate(detail_items.find(i => i.control_name === control_name)?.value);
  }

  private _getPageDetails(): void {
    this.isThirdParty = this._route.snapshot.data.isThirdParty;
    const cardSubCategory = this.isThirdParty ? DETAILS_CARD_SUBCATEGORY.thirdParty : ProductTypeSnakeCase[this._contractType];
    const cardSubSubCategory = this.isThirdParty ? null : this._subSubtype;
    this._pageDetailsService
      .getCardDetailsConfig$(DETAILS_CARD_CATEGORY.contract, cardSubCategory, cardSubSubCategory)
      .pipe(
        switchMap(details => {
          this.dynamicConfig = this._pageDetailsService.parseConfigListOfColumns(details);
          this._contractMapped = {
            ...this._pageDetailsService.mapDetailsCards(this.contract, this._getListOfConfigs()),
            original_data: this.contract
          };

          if (this._getListOfConfigs().find(c => c.name === DETAIL_CARD_NAME.insuredObject).is_editable) {
            return this._customersService.getPolicyInputs$(
              this.contract?.contract.id,
              this.contract?.contract.insurance_product?.api_key
            );
          }
        }),
        catchError(err => {
          this._sessionService.setLoading(false);
          return of(err);
        })
      )
      .subscribe({
        next: inputs => {
          this._insuredObjectInputs = inputs;
          this._setPageDetails();
        },
        error: () => this._setPageDetails()
      });
  }

  private _getListOfConfigs(): DynamicDetailCardConfig[] {
    return this.dynamicConfig.flatMap(column => column.cards) as DynamicDetailCardConfig[];
  }

  private _getProductSubtype(subtype: string): string {
    if (this.country === COUNTRIES.it) {
      if (subtype === ProductType.motor) {
        return;
      }
    }

    if (subtype === ProductType.homeowner) {
      return;
    }

    const type = this.contract.contract.product_type.toLowerCase();
    if (this.contract.contract_detail_parameters[CONTRACT_DETAIL_PARAMETER_NAME.home_product_type]) {
      return this.contract.contract_detail_parameters[CONTRACT_DETAIL_PARAMETER_NAME.home_product_type];
    }
    return type.includes('switch') ? DETAILS_CARD_SUB_SUBCATEGORY.switch : DETAILS_CARD_SUB_SUBCATEGORY.c_suite;
  }

  private _mapCardDetailItems(detailItems, data) {
    return detailItems.map(c => {
      c.value = data[c.control_name];
      c.values = [data[c.control_name]];
      return c;
    });
  }

  // eslint-disable-next-line
  private _mapCurrentCustomerForFormValues(): { [key: string]: any } {
    if (this._savedPaymentData) {
      return this._savedPaymentData;
    }
    const data = this.contract.contract?.payment_details;
    const type = data.customer_type || data.type;
    const isLegalEntity = type.toLowerCase() !== ENTITY_TYPE.person;
    return {
      first_name: data.first_name,
      last_name: data.last_name,
      legal_name: data.legal_name ? data.legal_name : isLegalEntity ? data.first_name : '',
      direct_debit_iban: data.direct_debit_iban || data.iban,
      policy_holder: !isLegalEntity ? ENTITY_TYPE.privatePerson : ENTITY_TYPE.legalEntity
    };
  }

  private _mapInsuredObjectCurrentValues(detailItems) {
    if (this._savedInsuredObjectData) {
      return this._savedInsuredObjectData;
    }

    const insuredObjectConfig = this._getListOfConfigs().find(c => c.name === DETAIL_CARD_NAME.insuredObject);
    if (insuredObjectConfig.is_editable) {
      const formInputs = insuredObjectConfig.edit_config.model.map(m => m.attribute);
      const currentValues = formInputs.map(i => {
        let value = this._getCurrentValue(detailItems, i);
        if (i === FormControlItem.hsn.name) {
          value = this.contract.contract_detail_parameters[CONTRACT_DETAIL_PARAMETER_NAME.hsn];
        }
        if (i === FormControlItem.tsn.name) {
          value = this.contract.contract_detail_parameters[CONTRACT_DETAIL_PARAMETER_NAME.tsn];
        }
        return { control_name: i, value: value };
      });
      return currentValues.reduce((acc, v) => {
        return { ...acc, [v.control_name]: v.value };
      }, {});
    }
  }

  // eslint-disable-next-line
  private _savePaymentMethods(formData: { [key: string]: any }): void {
    this._setLoadingState(DETAIL_CARD_NAME.paymentData, true);
    this._detailCardService.setTrackingEvent(GAEventActions.Save, PaymentAnalyticsType.contract, GAEventCategories.EditPaymentMethod);

    this._customersService
      .saveContractPaymentMethod$(
        this.contract.contract.account.id,
        this._detailCardService.buildEditPaymentRequestBody(formData, this.contract.contract.symass_id)
      )
      .subscribe({
        next: () => {
          this._updateCustomerContract(formData);
          setTimeout(() => {
            this._setLoadingState(DETAIL_CARD_NAME.paymentData, false);
            this._detailCardService.setToasterMessage(SuccessKeys.save, ToasterType.success);
            this._detailCardService.setTrackingEvent(GAEventActions.Success, PaymentAnalyticsType.contract, GAEventCategories.EditPaymentMethod);
          }, UxIntervalTimes.medium + UxIntervalTimes.fast); // To avoid seeing the old data before changing to the new.
        },
        error: () => {
          this._setLoadingState(DETAIL_CARD_NAME.paymentData, false);
          this._detailCardService.setToasterMessage(ErrorKeys.save, ToasterType.error);
          this._detailCardService.setTrackingEvent(GAEventActions.Error, PaymentAnalyticsType.contract, GAEventCategories.EditPaymentMethod);
        }
      });
  }

  public closeDialog(): void {
    const contract = this.contract.contract;
    this._customersService.getContractDetails$(contract.id, this.isThirdParty).subscribe(res => {
      this._customerStore.update({ customerContract: res.contract });
      this.valid_to = res.contract?.valid_to
        ? dayjs(res.contract.valid_to).format(DateFormat.default)
        : null;
      this.canCancel = this.valid_to == null ? true : false;
      this.bannerOptions = {
        textKey: translate('_COD_cancel_policy_cancelled_banner', { value: [this.valid_to] }),
        type: '-warning'
      };
    });
  }

  private _setBreadcrumbs(): void {
    const brokerPath = this._sessionQuery.getBrokerPath();
    const contractNumber = this.contract?.contract?.symass_id || this.contract?.contract?.policy_number || this.contract.contract_number;
    const customerId = this._route.snapshot.params['customerId'];

    let type;
    if (this.isThirdParty) {
      type = `_COL_product_type_${this._contractType}`;
    } else {
      type = `_PROD_contract_product_type_${this._contractType}`;
    }
    let name;
    if (this.country === COUNTRIES.it) {
      name = `${this.contract.policy_holder.name} ${this.contract.policy_holder.last_name}`;
    } else {
      if (this.isThirdParty) {
        name = `${this.contract?.customer?.firstname} ${this.contract?.customer?.lastname}`;
      } else {
        name = this.contract?.contract?.account?.legal_name
          ? this.contract?.contract?.account?.legal_name
          : `${this.contract?.contract?.account?.first_name} ${this.contract?.contract?.account?.last_name}`;
      }
    }
    const breadcrumbs = customerId
      ? [
        {
          label: BaseBreadcrumbKey.customers,
          routerLink: `${BaseRouteName.customers}/${brokerPath}/${this.isThirdParty ? 'third-party' : 'wefox-customers'}`
        },
        {
          label: name,
          routerLink: `${BaseRouteName.customers}/${brokerPath}/${this.isThirdParty ? 'third-party' : 'wefox-customers'}/detail/${customerId}`
        },
        {
          label: contractNumber
        }
      ]
      : [
        {
          label: BaseBreadcrumbKey.contracts,
          routerLink: `${BaseRouteName.contracts}/${brokerPath}/${this.isThirdParty ? 'third-party' : 'wefox-contracts'}`
        },
        {
          label: contractNumber
        }
      ];

    this._breadcrumbsService.setBreadcrumbConfig({
      breadcrumbs,
      subTitle: type,
      mainTitle: translate('_CON_contract_number_title') + ' ' + contractNumber
    });
  }

  private _setLoadingState(name, isLoading): void {
    this.isLoading = { ...this.isLoading, [name]: isLoading };
  }

  private _setPageDetails(): void {
    this._updateDetailsCards();
    this._setBreadcrumbs();
    this._sessionService.setLoading(false);
  }

  // eslint-disable-next-line
  private _updateContractInsuredObjectOptions(formData: { [key: string]: any }) {
    this._savedInsuredObjectData = formData;
    this._savedInsuredObjectData.hsn_tsn_combined_label = `${formData.hsn} / ${formData.tsn}`;
    this._savedInsuredObjectData.vehicle_id = this._savedInsuredObjectData.vehicle_id.toUpperCase();
    this._updateDetailsCards();
  }

  // eslint-disable-next-line
  private _updateCustomerContract(response: Partial<any>): void {
    const legalEntity = response.policy_holder !== ENTITY_TYPE.privatePerson ? true : false;
    this._savedPaymentData = {
      account_holder: legalEntity ? response.legal_name : `${response.first_name} ${response.last_name}`,
      first_name: response.first_name,
      direct_debit_iban: response.direct_debit_iban,
      last_name: response.last_name,
      legal_name: response.legal_name,
      policy_holder: response.policy_holder
    };
    setTimeout(() => {
      this._updateDetailsCards();
    }, UxIntervalTimes.medium);
  }

  // eslint-disable-next-line
  private _updateDetailsCards(): void {
    this.columns = this.dynamicConfig.map((column: DynamicDetailColumnConfig) => {
      const cards = this._pageDetailsService
        .buildDetailCards(column.cards, this._contractMapped, '_CON_')
        .map(card => {
          const contractDetailParameters = this.contract.contract_detail_parameters;
          if (card.name === DETAIL_CARD_NAME.coverages) {
            const ownerhipType = contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.ownership_type];
            if (ownerhipType === HOMEOWNER_INPUT_VALUES.ownership_type.tenant) {
              card.detailItems = card.detailItems.filter(item => !item.control_name.includes('glass'));
            }

            if (
              !contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.bike_theft_included] ||
              contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.bike_theft_included] === BOOLEAN_STRING_VALUE.false
            ) {
              card.detailItems = card.detailItems.filter(
                i => i.control_name !== DETAIL_CARD_LABEL_AND_CONTROL_NAME.bicycle_sum_insured
              );
            }
          }
          if (card.name === DETAIL_CARD_NAME.paymentData) {
            card.currentValues = this._mapCurrentCustomerForFormValues();
            card.editButtonAction = () =>
              this._detailCardService.setTrackingEvent(
                GAEventActions.ClickOn,
                ProfileAnalyticsType.edit,
                GAEventCategories.EditCustomerInfo
              );
            card.saveButtonAction = (
              formData: { [key: string]: any } // eslint-disable-line
            ) => this._savePaymentMethods(formData);
            if (this._savedPaymentData) {
              card.detailItems = this._mapCardDetailItems(card.detailItems, this._savedPaymentData);
            }
          }
          if (card.name === DETAIL_CARD_NAME.coverages) {
            if (
              contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.legal_protetion_included] ===
              BOOLEAN_STRING_VALUE.true
            ) {
              card.detailItems = card.detailItems.filter(
                i => i.control_name !== DETAIL_CARD_LABEL_AND_CONTROL_NAME.motor_legal_protection
              );
            }
            if (
              contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.legal_protetion_included] ===
              BOOLEAN_STRING_VALUE.false
            ) {
              card.detailItems = card.detailItems.filter(
                i => i.control_name !== DETAIL_CARD_LABEL_AND_CONTROL_NAME.motor_legal_protection_premium
              );
            }
            if (
              contractDetailParameters[CONTRACT_DETAIL_PARAMETER_NAME.bike_theft_included] === BOOLEAN_STRING_VALUE.false
            ) {
              card.detailItems = card.detailItems.filter(
                i => i.control_name !== DETAIL_CARD_LABEL_AND_CONTROL_NAME.bicycle_sum_insured
              );
            }
          }
          if (card.name === DETAIL_CARD_NAME.insuredObject) {
            if (card.isEditable) {
              card.currentValues = this._mapInsuredObjectCurrentValues(card.detailItems);
              card.isEditable =
                this._insuredObjectInputs.find(i => i.name === CONTRACT_DETAIL_PARAMETER_NAME.vb_status)?.value ===
                'failed' && card.isEditable;
              // eslint-disable-next-line
              card.saveButtonAction = (formData: { [key: string]: any }, pristine) => {
                this._updateInsuredObject(formData, pristine);
              };
            }
            if (this._savedInsuredObjectData) {
              card.detailItems = this._mapCardDetailItems(card.detailItems, this._savedInsuredObjectData);
            }
          }
          return card;
        });
      return {
        ...column,
        cards
      }
    });
  }

  // eslint-disable-next-line
  private _updateInsuredObject(formData: { [key: string]: any }, pristine: string): void {
    // set loading state insured object
    this._setLoadingState(DETAIL_CARD_NAME.insuredObject, true);
    this._detailCardService.setTrackingEvent(
      GAEventActions.ClickOn,
      ProfileAnalyticsType.save,
      GAEventCategories.EditInsuredObject
    );
    const body = this._buildInsuredObjectRequestBody(this._insuredObjectInputs, formData);
    this._customersService
      .updateInsuredObject$(this.contract.contract.id, this.contract.contract.insurance_product.api_key, {
        inputs: body
      })
      .subscribe({
        next: () => {
          this._updateContractInsuredObjectOptions(formData);
          setTimeout(() => {
            this._setLoadingState(DETAIL_CARD_NAME.insuredObject, false);
            this._detailCardService.setToasterMessage(SuccessKeys.save, ToasterType.success);
            this._detailCardService.setTrackingEvent(
              GAEventActions.Success,
              pristine,
              GAEventCategories.EditInsuredObject
            );
          }, UxIntervalTimes.medium + UxIntervalTimes.fast); // To avoid seeing the old data before changing to the new.
        },
        error: () => {
          this._setLoadingState(DETAIL_CARD_NAME.insuredObject, false);
          this._detailCardService.setToasterMessage(ErrorKeys.save, ToasterType.error);
          this._detailCardService.setTrackingEvent(GAEventActions.Error, pristine, GAEventCategories.EditInsuredObject);
        }
      });
  }
}
