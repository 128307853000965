// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep one-info-banner {
  display: block;
}

.column-sticky {
  position: sticky;
  top: 90px;
}

.size-80-px {
  height: 80px;
  width: 80px;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/customers/pages/contract-details/contract-detail-dynamic-page.component.scss"],"names":[],"mappings":"AAGI;EACE,cAAA;AAFN;;AAOA;EACE,gBAAA;EACA,SAAA;AAJF;;AAOA;EACE,YAAA;EACA,WAAA;AAJF","sourcesContent":["\n:host {\n  ::ng-deep {\n    one-info-banner {\n      display: block;\n    }\n  }\n}\n\n.column-sticky {\n  position: sticky;\n  top: 90px;\n}\n\n.size-80-px {\n  height: 80px;\n  width: 80px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
