import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { DynamicModelValidator } from '@wefoxGroupOneBPCore/interfaces/dynamic-forms.interface';
import { FormValidatorService } from '@wefoxGroupOneBPShared/services/validators.service';
import { WgTooltip } from '../../../wg-tooltip/interfaces';
import { takeUntil, timer } from 'rxjs';

export interface InputAdapterComponentOptions {
  auto_select: boolean;
  class_modifiers: string;
  control_name: string;
  disable_error_message: boolean;
  dynamic_attributes?: DynamicInputAttributes;
  errors: [{ key: string; value: string }];
  error_keys: any; // eslint-disable-line
  i18n_prefix_key: string;
  icon: string;
  logo?: string;
  mask?: string;
  mask_options: any; // eslint-disable-line
  label?: string;
  placeholder: string;
  tooltip?: WgTooltip;
  type: string;
  validators: [{ type: string }];
}

export interface DynamicInputAttributes {
  depends_on: string;
  placeholder_prefix?: string;
  validators: { [key: string]: DynamicModelValidator }[];
}

@Component({
  selector: 'one-input-adapter',
  templateUrl: './input-adapter.component.html'
})
export class InputAdapterComponent implements AfterViewInit, OnInit {
  @Input() public content_data: Partial<InputAdapterComponentOptions>; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  public inputMask: string;
  public isRequired: boolean = false;
  @Output() public iconInsideFieldClicked = new EventEmitter();

  constructor(private _formValidatorService: FormValidatorService, private _sessionQuery: SessionQuery) {}

  public ngAfterViewInit(): void {
    if (this.content_data.dynamic_attributes) {
      const dynamicAttributes = this.content_data.dynamic_attributes;
      if (dynamicAttributes.depends_on) {
        this._monitorInputChanges();
      }
    }

    const inputControl = this.parentGroup.get(this.content_data.control_name);
    inputControl.statusChanges
      .pipe(takeUntil(timer(3000)))
      .subscribe(() => (this.isRequired = inputControl.hasValidator(Validators.required)));
  }

  public ngOnInit(): void {
    if (this.content_data.dynamic_attributes) {
      const dynamicAttributes = this.content_data.dynamic_attributes;
      const inputControl = this.parentGroup.get(dynamicAttributes?.depends_on);
      const currentValue = inputControl?.value;
      this.inputMask = this.content_data.mask;
      this._setDynamicAttributes(dynamicAttributes, currentValue);
    }
  }

  // eslint-disable-next-line
  public onInputFocus(event: any): void {
    if (this.content_data.auto_select) {
      event.target.select();
    }
  }

  public toggleIcon(): void {
    this.iconInsideFieldClicked.emit();
  }

  private _monitorInputChanges(): void {
    const dynamicAttributes = this.content_data.dynamic_attributes;
    const inputControl = this.parentGroup.get(dynamicAttributes?.depends_on);
    const currentValue = inputControl.value;

    this._setDynamicAttributes(dynamicAttributes, currentValue);

    if (inputControl) {
      inputControl.valueChanges.subscribe(val => {
        const inputControl = this.parentGroup.get(this.content_data.control_name);
        inputControl.markAsUntouched();
        inputControl.markAsPristine();
        inputControl.setValue('');
        this._setDynamicAttributes(dynamicAttributes, val);
      });
    }
  }

  private _setDynamicAttributes(dynamicAttributes: DynamicInputAttributes, currentValue: string): void {
    if (dynamicAttributes.placeholder_prefix) {
      const controlInput = document.getElementById(this.content_data.control_name);
      controlInput?.setAttribute('placeholder', translate(`${dynamicAttributes.placeholder_prefix}${currentValue}`));
    }

    if (dynamicAttributes.validators) {
      const validations = dynamicAttributes.validators[currentValue];
      const control = this.parentGroup.get(this.content_data.control_name);
      control.clearValidators();
      control.setValidators(this._formValidatorService.getValidators(validations));
    }
  }
}
