import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { WgUiIconModule } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon.module';
import { ComponentInfo } from 'libs/dj-types';
import { ResultMetadata } from './interfaces/result-metadata.interface';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'wg-result',
  standalone: true,
  template: `
    <div class="d-flex card shadow-none align-items-center justify-content-center p-gigantic2x container-empty">
      <wg-ui-icon
        [type]="metadata.icon.type"
        [key]="metadata.icon.key"
        [primaryColor]="metadata.icon.primary_color"
        [secondaryColor]="metadata.icon.secondary_color"
        [customSize]="metadata.icon.size"
        class="mb-gigantic"
      ></wg-ui-icon>
      <h3 class="m-huge" [attr.t-selector]="'result-title'">{{ metadata.title | transloco }}</h3>
      <p>{{ metadata.description | transloco }}</p>
      <ng-content select="[primary-action-button]"></ng-content>
      <ng-content select="[secondary-action-button]"></ng-content>
    </div>
  `,
  imports: [CommonModule, WgUiIconModule, TranslocoModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultComponent implements OnInit {
  @Input() componentInfo!: ComponentInfo;

  public metadata: ResultMetadata = {} as ResultMetadata;

  public ngOnInit(): void {
    this.metadata = this.componentInfo.metadata as ResultMetadata;
  }
}
