// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn,
.btn-group {
  border-radius: 30px;
  height: var(--huge2x);
  margin-left: 0;
}

.btn-group > .btn-check:checked + .btn {
  background-color: var(--primary-base) !important;
  border-radius: 30px;
  color: var(--neutral-start) !important;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/button-group/button-group.component.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAA;EACA,qBAAA;EACA,cAAA;AACF;;AAEA;EACE,gDAAA;EACA,mBAAA;EACA,sCAAA;AACF","sourcesContent":[".btn,\n.btn-group {\n  border-radius: 30px;\n  height: var(--huge2x);\n  margin-left: 0;\n}\n\n.btn-group > .btn-check:checked + .btn {\n  background-color: var(--primary-base) !important;\n  border-radius: 30px;\n  color: var(--neutral-start) !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
