// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: 100%;
}

/* TODO Rename onebp for wg */
.onebp-a-fieldInvalid {
  align-self: flex-start;
  color: var(--danger-dark);
}
.onebp-a-fieldInvalid:not(:empty) {
  margin-top: 4px;
}

.wg-a-icon {
  height: 20px;
  position: absolute;
  right: 16px;
  width: 20px;
}
.wg-a-icon.-inputIcon {
  top: 17px;
}

.field-icon {
  position: absolute;
  right: 15px;
  top: 44px;
}

.tooltip-icon {
  position: absolute;
  right: -4px;
  top: -4px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-input/components/wg-field/wg-field.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;AAAF;;AAGA,6BAAA;AACA;EACE,sBAAA;EACA,yBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,YAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AADF;AAGE;EACE,SAAA;AADJ;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAFF;;AAKA;EACE,kBAAA;EACA,WAAA;EACA,SAAA;AAFF","sourcesContent":["\n:host {\n  width: 100%;\n}\n\n/* TODO Rename onebp for wg */\n.onebp-a-fieldInvalid {\n  align-self: flex-start;\n  color: var(--danger-dark);\n\n  &:not(:empty) {\n    margin-top: 4px;\n  }\n}\n\n.wg-a-icon {\n  height: 20px;\n  position: absolute;\n  right: 16px;\n  width: 20px;\n\n  &.-inputIcon {\n    top: 17px;\n  }\n}\n\n.field-icon {\n  position: absolute;\n  right: 15px;\n  top: 44px;\n}\n\n.tooltip-icon {\n  position: absolute;\n  right: -4px;\n  top: -4px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
