import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoaderComponent } from '../../components';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  imports: [CommonModule, TranslocoModule]
})
export class WgLoaderModule {}
