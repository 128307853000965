import { Component, HostListener, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BannerOptions } from '@wefoxGroupOneBPCore/interfaces/banner-options.interface';
import { DetailCardOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { RadioModelConfig } from '@wefoxGroupOneBPCore/interfaces/radio-option.interface';
import { CustomerInfoType } from '@wefoxGroupOneBPCore/constants/customer.constant';
import { AddressControlNamesDefault } from '@wefoxGroupOneBPShared/components/address/address.component';
import {
  FormControlItem,
  UiButtonAction
} from '@wefoxGroupOneBPShared/constants';
import { CustomerTypeOptions } from '@wefoxGroupOneBPShared/interfaces';
import { AddressService } from '@wefoxGroupOneBPShared/services/address.service';
import { Subject } from 'rxjs';
import { IbanMask } from '@wefoxGroupOneBPShared/constants/mask.constants';

@Component({
  selector: 'one-detail-card',
  templateUrl: './detail-card.component.html'
})
export class DetailCardComponent implements OnDestroy {
  @Input() public buttonIcon = 'onebp__edit';
  @Input() public buttonText = '_ST_B2C_back_to_edit';
  @Input() public columnModifier: string;
  public customerTypeRadioOptions: Partial<RadioModelConfig> = CustomerTypeOptions;
  public editInfoForm: UntypedFormGroup;
  public editOptions = [];
  @Input() hasBorder = true;
  public ibanMaskInput: string;
  @Input() public infoBannerOptions: Partial<BannerOptions>;
  public isDisabled = true;
  @Input() public isEditable = false;
  public isEditing = false;
  @Input() public isLoading = false;
  @Input() public options: DetailCardOptions;

  private _controls: string[];
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _addressService: AddressService,
    private _fb: UntypedFormBuilder,
  ) {}

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public onButtonClick(action: string): void {
    if (this.options.buttonAction) {
      this.options.buttonAction();
      return;
    }

    switch (action) {
      case UiButtonAction.edit:
        this._handleEditClick();
        break;
      case UiButtonAction.discard:
        this._handleDiscardClick();
        break;
      case UiButtonAction.save:
        this._handleSaveClick();
    }
  }

  // eslint-disable-next-line
  public onInputFocus(event: any): void {
    if (event.target.name === FormControlItem.street.name) {
      this._addressService.selectInputText(event.target);
    }
  }

  @HostListener('paste', ['$event']) public onPaste(): void {
    // Reset the iban input to max length of 40 characters
    if (this.editOptions.find(item => item.formControlName === FormControlItem.iban.name)) {
      this.options.detailItems.find(item =>
        item.editOptions.some(option => option.formControlName === FormControlItem.iban.name)
      ).editOptions[0].inputMask = IbanMask;
    }
  }

  // eslint-disable-next-line
  public onRadioOptionChange(value: any): void {
    this.options.radioOptions.toggleBoolean = value === CustomerInfoType.LegalEntity;
    this._toggleInputs(
      this.options.radioOptions.toggleInputs.fieldsToShow,
      this.options.radioOptions.toggleInputs.fieldsToHide
    );
  }

  private _buildFormGroup(): void {
    let group = {};
    this.options.detailItems.forEach(item => {
      item.editOptions.forEach(option => {
        this.editOptions.push(option);
        group = {
          ...group,
          [option.formControlName]: ['', option.validators]
        };
      });
    });
    this.editInfoForm = this._fb.group(group);
  }

  private _getFormControlNames(): string[] {
    const controls = [];
    this.options.detailItems.forEach(item => {
      item.editOptions?.forEach(option => {
        controls.push(option.formControlName);
      });
    });
    return controls;
  }

  private _getListFromPristineFields(form: UntypedFormGroup): string {
    const pristineList = [];
    Object.keys(form.controls).forEach(key => {
      const pristine = form.get(key).pristine;
      if (!pristine) {
        pristineList.push(key);
      }
    });
    return pristineList.toString();
  }

  private _handleDiscardClick(): void {
    this._toggleEditMode();
    this._resetControls();
    this._resetRadioButtons();

    if (this.options.discardButtonAction) {
      this.options.discardButtonAction();
    }
  }

  private _handleEditClick(): void {
    if (this.options.editButtonAction && !this.options.saveButtonAction) {
      this.options.editButtonAction(this.options.sectionId);
      return;
    }

    if (!this.isLoading) {
      this._toggleEditMode();
      this._controls = this._getFormControlNames();

      if (this._controls?.length && !this.editInfoForm) {
        this._buildFormGroup();
      }

      this._setFormValues();

      if (this.options.radioOptions) {
        this.editInfoForm.addControl(this.options.radioOptions.controlName, new UntypedFormControl());
        this._toggleInputs(
          this.options.radioOptions.toggleInputs.fieldsToShow,
          this.options.radioOptions.toggleInputs.fieldsToHide
        );
      }

      if (this.options.editButtonAction) {
        this.options.editButtonAction();
      }
    }

    if (this.editInfoForm.get(FormControlItem.street.name)) {
      this._setAddressAutocomplete();
    }
  }

  private _handleSaveClick(): void {
    if (this.options.radioOptions) {
      if (this.options.radioOptions.toggleBoolean) {
        this.options.radioOptions.toggleInputs.fieldsToHide.forEach(field => {
          this.editInfoForm.get(field).setErrors(null);
        });
      } else {
        this.options.radioOptions.toggleInputs.fieldsToShow.forEach(field =>
          this.editInfoForm.get(field).setErrors(null)
        );
      }
    }

    if (this.editInfoForm.valid) {
      this.options.saveButtonAction(
        this.editInfoForm.getRawValue(),
        this._getListFromPristineFields(this.editInfoForm)
      );
      this._toggleEditMode();
      this._resetControls();
      this._resetRadioButtons();
    } else {
      this._controls.forEach(name => this.editInfoForm.get(name).markAsTouched());
    }
  }

  private _resetControls(): void {
    if (!!this.options.radioOptions) {
      [
        ...this.options.radioOptions.toggleInputs.fieldsToHide,
        ...this.options.radioOptions.toggleInputs.fieldsToShow
      ].forEach(field => {
        this.editInfoForm.get(field).reset();
      });
    }
  }

  private _resetRadioButtons(): void {
    if (this.options.radioOptions) {
      this.editInfoForm
        .get(this.options.radioOptions.controlName)
        .setValue(this.options.radioOptions.optionsType.radio_options.find(type => type.checked).value);
    }
  }

  private _setAddressAutocomplete(): void {
    this._addressService.monitorStreetInputChanges(this.editInfoForm, AddressControlNamesDefault);
  }

  private _setFormValues(): void {
    if (this.isEditing) {
      this.options.detailItems.forEach(item => {
        item.editOptions.forEach(option => {
          const value = option?.initialValue;

          if (value) {
            const input = this.editInfoForm.get(option.formControlName);

            input.setValue(value);
            input.markAsTouched();
            input.markAsPristine();

            if (option.disabled) {
              input.disable(); // Disable fields that are not editable
            }
          }
        });
      });

      const radioInput = this.editInfoForm.get(FormControlItem.policyHolder.name);

      if (radioInput) {
        radioInput.setValue(this.options.radioOptions.optionsType.radio_options.find(type => type.checked).value);
      }
    }
  }

  private _toggleEditMode(): void {
    this.isEditing = !this.isEditing;
  }

  private _toggleInputDisplay(name, display): void {
    const fieldInput = document.getElementById(name + '_container');

    if (fieldInput) {
      fieldInput.style.display = display;
    }
  }

  private _toggleInputs(fieldsToShow: string[], filedsToHide: string[]): void {
    setTimeout(() => {
      if (!this.options.radioOptions.toggleBoolean) {
        fieldsToShow.forEach(field => this._toggleInputDisplay(field, 'none'));
        filedsToHide.forEach(field => this._toggleInputDisplay(field, 'block'));
      } else {
        fieldsToShow.forEach(field => this._toggleInputDisplay(field, 'block'));
        filedsToHide.forEach(field => this._toggleInputDisplay(field, 'none'));
      }
    }, 0);
  }
}
