import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';

@Component({
  selector: 'one-dropdown-input-adapter',
  templateUrl: './dropdown-input-adapter.component.html'
})
export class DropdownInputAdapterComponent implements OnChanges, OnInit {
  @Input() public content_data: any; // eslint-disable-line
  public dropdownOptions: Partial<DropdownComponentOptions>;
  public dynamicSources;
  @Input() public parentGroup: UntypedFormGroup;

  private _translatedValues;

  constructor(private _formService: FormService, private _sessionQuery: SessionQuery) { }

  public ngOnChanges(changes: SimpleChanges): void {
    this.content_data = changes.content_data.currentValue;
  }

  public ngOnInit(): void {
    this._sessionQuery.dropdownOptionsState$.subscribe(state => {
      this.dropdownOptions = state[this.content_data.control_name];

      if (!this.dropdownOptions) {
        this.dropdownOptions = this._buildDropdownOptions();
      }
      this._translatedValues = this._formService.translateSourceValues(
        this.content_data.sources[0].values,
        this.dropdownOptions.translationOptions
      );
    });

    if (this.content_data.dynamic_sources) {
      this._monitorDynamicControlChanges();
    }
  }

  private _monitorDynamicControlChanges(): void {
    this.parentGroup.get(this.content_data.dynamic_sources.depends_on_control_name).valueChanges.subscribe(val => {
      const dynamicSourcesConfig = this.content_data.dynamic_sources;

      this.dropdownOptions = this._buildDropdownOptions(true, val);
      this.dynamicSources = dynamicSourcesConfig.value[val].sources;
      const control = this.parentGroup.get(this.content_data.control_name);
      if (control.value) {
        const controlValue = Number(control.value.key);
        const minValue = Number(dynamicSourcesConfig.value[val].min_value);
        if (controlValue < minValue) {
          this.parentGroup.get(this.content_data.control_name).setValue(null);
        }
      }
    });
  }

  private _buildDropdownOptions(dynamic?: boolean, val?: string): Partial<DropdownComponentOptions> {
    const dynamicSourcesConfig = this.content_data.dynamic_sources;
    const sources = dynamic ? dynamicSourcesConfig.value[val].sources : this.content_data.sources;

    const {
      control_name,
      controlName,
      helper_text,
      label,
      required,
      selector_label,
      source_name,
      sourceName,
      default_value,
      defaultValue,
      options_panel_modifiers,
      tooltip_text,
      topActionRow,
      translation_options
    } = this.content_data;
    return {
      controlName: control_name || controlName,
      defaultValue: dynamic ? dynamicSourcesConfig.value[val].default_value : default_value || defaultValue,
      helper_text,
      label,
      optionsPanelModifiers: options_panel_modifiers,
      required,
      selector_label,
      sourceName: source_name || sourceName,
      sources,
      tooltip_text,
      topActionRow,
      translationOptions: translation_options
    };
  }
}
