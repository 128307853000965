import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { GAEventActions, GAEventCategories } from '@wefoxGroupOneBPCore/analytics/constants';
import { AnalyticsService } from '@wefoxGroupOneBPCore/analytics/services/analytics.service';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { ModelDefinition } from '@wefoxGroupOneBPCore/interfaces';
import { DetailItems, DetailCardEditOptions } from '@wefoxGroupOneBPCore/interfaces/contract-details.interface';
import { RadioModelConfig } from '@wefoxGroupOneBPCore/interfaces/radio-option.interface';
import { ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { WgToasterService } from '@wefoxGroupOneBPCore/services/wg-toaster.service';
import {
  EditProfile,
  PaymentMethodSaveData,
  Profile,
  ProfilePhone
} from '@wefoxGroupOneBPCore/interfaces/customer-detail.interface';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { CustomerInfoType } from '@wefoxGroupOneBPCore/constants/customer.constant';
import { SWITCH_MOTOR_MODEL_DEFINITION } from '@wefoxGroupOneBPPrivate/product/models/switch-motor-model';
import {
  ErrorKeys,
  ErrorType,
  FormControlItem,
  FormInputType,
  FormInsuredObjects,
  PaymentMethodFormData,
  PaymentMethodRequestBodyReplacement
} from '@wefoxGroupOneBPShared/constants';
import { CustomerTypeOptions } from '@wefoxGroupOneBPShared/interfaces';
import { IbanTransform } from '@wefoxGroupOneBPShared/pipes';
import { LicensePlateValidators } from '@wefoxGroupOneBPShared/validators/licence-plate-prefix.validator';
import { FormService } from './form.service';

@Injectable({
  providedIn: 'root'
})
export class DetailCardService {
  private _isLegalEntity;

  constructor(
    private _analytics: AnalyticsService,
    private _formService: FormService,
    public _iban: IbanTransform,
    private _sessionQuery: SessionQuery,
    private _toaster: WgToasterService
  ) {
    this._sessionQuery.isLegalEntity$.subscribe(status => (this._isLegalEntity = status));
  }

  public buildDetailItemsByFormData(
    formData: { [key: string]: string },
    controlNames: string[],
    additionalValues?: { [key: string]: string[] },
    returnAllLabels?: boolean
  ): DetailItems[] {
    const details = [];

    controlNames.forEach(name => {
      const data = additionalValues && additionalValues[name];
      name = this._isLegalEntity && name === 'name' ? 'legal_name' : name;

      if (returnAllLabels) {
        details.push({
          label: `_PROD_${name}`,
          values: data ? data : this._transformValues(name, formData)
        });
      } else {
        if (formData[name] || data) {
          details.push({
            label: `_PROD_${name}`,
            values: data ? data : this._transformValues(name, formData)
          });
        }
      }
    });

    return details;
  }

  public buildEditCustomerRequestBody(
    formData: { [key: string]: any }, // eslint-disable-line
    addressId: string,
    addressType: string,
    country: string,
    gender: string,
    profileId: string,
    phones: ProfilePhone[]
  ): Partial<EditProfile> {
    return {
      addresses: [
        {
          city: formData[FormControlItem.city.name],
          country: country,
          house_number: formData[FormControlItem.house_number.name],
          id: addressId,
          postal_code: formData[FormControlItem.postal_code.name],
          street: formData[FormControlItem.street.name],
          type: addressType
        }
      ],
      birthdate: formData[FormControlItem.birthday.name],
      email: formData[FormControlItem.email.name],
      firstname: formData[FormControlItem.firstname.name],
      gender: gender,
      id: profileId,
      lastname: formData[FormControlItem.lastname.name],
      legal_name: `${formData[FormControlItem.firstname.name]} ${formData[FormControlItem.lastname.name]}`,
      phones: [{
        id: phones[0].id,
        number: `${formData.phone_prefix_for_mobile_phone.key} ${formData.phone_sufix_for_mobile_phone}`,
        type: phones[0].type,
        version: phones[0].version
      }]
    };
  }

  public buildEditPaymentRequestBody(
    formData: { [key: string]: string },
    symass_id?: string
  ): Partial<PaymentMethodSaveData> {
    const ibanHolderName =
      formData[FormControlItem.policyHolder.name] === CustomerInfoType.LegalEntity
        ? {
          payment_method_first_name: formData[FormControlItem.legalName.name],
          payment_method_last_name: PaymentMethodRequestBodyReplacement.legalEntity
        }
        : {
          payment_method_first_name: formData[FormControlItem.first_name.name],
          payment_method_last_name: formData[FormControlItem.last_name.name]
        };
    const bodyResult = {
      ...ibanHolderName,
      iban: formData[FormControlItem.iban.name],
      payment_method_mode: PaymentMethodFormData.mode.premia,
      payment_method_type: this._setPaymentType()
    };
    return !symass_id ? bodyResult : { ...bodyResult, contract_ids: [symass_id] };
  }

  public buildEditProfileRequestBody(
    addressId: string,
    addressType: string,
    coutry: string,
    formData: { [key: string]: string }
  ): Partial<Profile> {
    const bodyResult: Partial<Profile> = {
      addresses: [
        {
          city: formData[FormControlItem.city.name],
          country: coutry,
          house_number: formData[FormControlItem.house_number.name],
          id: addressId,
          postal_code: formData[FormControlItem.postal_code.name],
          street: formData[FormControlItem.street.name],
          type: addressType
        }
      ],
      email: formData[FormControlItem.email.name]
    };
    return bodyResult;
  }

  public getBooleanLokaliseKey(value: boolean): string {
    return value ? '_PROD_yes_value' : '_PROD_no_value';
  }

  // eslint-disable-next-line
  public getGenderTitleKey(gender): string {
    return `_PROD_gender_title_${gender}`;
  }

  public getInputOptions(
    controlItem: { name: string; label: string; validationKey?: string },
    initialValue: string,
    productPrefix: string,
    disabled?: boolean,
    inputMask?: string,
    errors?: { key: string; value?: string }[],
    validators?: Validators,
    model?: ModelDefinition
  ): Partial<DetailCardEditOptions> {
    switch (controlItem) {
      case FormControlItem.firstname: {
        return this._getInputByControl(controlItem, initialValue, productPrefix, disabled);
      }
      case FormControlItem.lastname: {
        return this._getInputByControl(controlItem, initialValue, productPrefix, disabled);
      }
      case FormControlItem.legalName: {
        return this._getInputByControl(controlItem, initialValue, productPrefix, disabled);
      }
      case FormInsuredObjects.license_plate: {
        return {
          ...this._getInputByControl(controlItem, initialValue, productPrefix, disabled),
          errors: [
            { key: ErrorType.required, value: ErrorKeys.required },
            {
              key: ErrorType.pattern,
              value: this._formService.getInputLabel(productPrefix, ErrorKeys.pattern.license_plate)
            },
            {
              key: ErrorType.license_plate,
              value: this._formService.getInputLabel('', ErrorKeys.licensePlatePrefix)
            }
          ],
          validators: [
            Validators.required,
            LicensePlateValidators.licensePlatePrefix,
            Validators.pattern(ProductValidations.licensePlatePatternDE)
          ]
        };
      }
      case FormControlItem.iban: {
        // eslint-disable-next-line max-len
        return {
          ...this._getInputByControl(controlItem, initialValue, productPrefix, disabled),
          errors: [
            { key: ErrorType.required, value: ErrorKeys.required },
            { key: ErrorType.iban, value: this._formService.getInputLabel(productPrefix, ErrorKeys.iban) }
          ],
        };
      }
      default: {
        return this._getInputByControl(
          controlItem,
          initialValue,
          productPrefix,
          disabled,
          inputMask,
          errors,
          validators,
          model
        );
      }
    }
  }

  public getLokaliseKeyFromBooleanValue(value: string | boolean): string {
    return !value ? '_PROD_no_value' : '_PROD_yes_value';
  }

  // eslint-disable-next-line
  public getPaymentFrequencyKey(value): string {
    return `_PROD_payment_frequency_${value.toLowerCase().replace('-', '_')}`;
  }

  public setCustomerTypeRadioOptions(isLegalEntity: boolean): Partial<RadioModelConfig> {
    return !isLegalEntity
      ? {
        ...CustomerTypeOptions,
        modifiers: { grid: 'cols-tablet-4 cols-laptop-6 pt-large pl-gigantic', label: 'mt-large' }
      }
      : {
        ...CustomerTypeOptions,
        modifiers: { grid: 'cols-tablet-4 cols-laptop-6 pt-large pl-gigantic', label: 'mt-large' },
        radio_options: CustomerTypeOptions.radio_options.map(option => {
          return option.value === CustomerInfoType.LegalEntity
            ? { ...option, checked: true }
            : { ...option, checked: false };
        })
      };
  }

  public setToasterMessage(title: string, type: ToasterType): void {
    this._toaster.show({
      lifeTime: UxIntervalTimes.verySlow,
      title: translate(title),
      type
    });
  }

  public setTrackingEvent(
    action: GAEventActions,
    actionMessage: string,
    category: GAEventCategories
  ): void {
    this._analytics.event({
      GAEventAction: action as GAEventActions,
      GAEventCategory: category,
      GAEventLabel: actionMessage
    });
  }

  private _getCommonErrors(
    controlItem: { name: string; label: string; validationKey?: string },
    productPrefix: string
  ) {
    const requiredError = [{ key: ErrorType.required, value: ErrorKeys.required }];
    return {
      errors: [
        ...requiredError,
        {
          key: ErrorType.pattern,
          value: this._formService.getInputLabel(productPrefix, ErrorKeys.pattern[controlItem.validationKey])
        },
        {
          key: ErrorType.maxlength,
          value: this._formService.getInputLabel(productPrefix, ErrorKeys.maxLength[controlItem.validationKey])
        }
      ]
    };
  }

  private _getInputByControl(
    controlItem: { name: string; label: string; validationKey?: string },
    initialValue: string,
    productPrefix: string,
    disabled?: boolean,
    inputMask?: string,
    errors?: { key: string; value?: string }[],
    validators?: Validators,
    model?: ModelDefinition
  ): Partial<DetailCardEditOptions> {
    return {
      disabled: disabled,
      errors: errors,
      ...this._getCommonErrors(controlItem, productPrefix),
      formControlName: controlItem.name,
      initialValue: initialValue,
      inputLabel: this._formService.getInputLabel(productPrefix, controlItem.label),
      inputMask: inputMask,
      inputType: FormInputType.text,
      validators: !!validators
        ? validators
        : this._formService.getValidatorsFromModel(!!model ? model : SWITCH_MOTOR_MODEL_DEFINITION, controlItem.name)
    };
  }

  private _setPaymentType(): string {
    switch (this._sessionQuery.getCountry()) {
      case COUNTRIES.ch:
        return PaymentMethodFormData.type.invoice;
      default:
        return PaymentMethodFormData.type.iban;
    }
  }

  private _transformValues(name, formData) {
    let value = formData[name]?.key ? [formData[name]?.key] : [formData[name]];

    if (!value) {
      return;
    }

    const formDataValueInputs = ['previous_insurer'];
    const currencyInputs = [
      'vehicle_liability_deductible',
      'fully_comprehensive_deductible',
      'partial_comprehensive_deductible'
    ];
    const upperCaseInputs = ['vehicle_id'];
    // mileage inputs, and other types of inputs that need transforming

    if (formDataValueInputs.indexOf(name) !== -1) {
      value = [formData[name].value];
    }
    if (currencyInputs.indexOf(name) !== -1) {
      value = value;
    }
    if (upperCaseInputs.indexOf(name) !== -1) {
      value = [formData[name].toUpperCase()];
    }
    if (name.includes('gender')) {
      value = [this.getGenderTitleKey(value)];
    }
    if (name === 'payment_frequency') {
      value = [this.getPaymentFrequencyKey(value)];
    }

    return value;
  }
}
