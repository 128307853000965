import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SessionQuery } from "@wefoxGroupOneBPCore/queries";
import { BaseDataService } from "@wefoxGroupOneBPCore/services";
import { Observable } from "rxjs";
import { Situation } from "../interfaces/risk-analysis.interface";

@Injectable({
    providedIn: 'root'
})
export class RiskAnalysisDataService extends BaseDataService {
    private _riskAnalysisEndpoint = 'risk-analysis';
    private _riskAnalysisCustomerEndpoint = 'risk-analysis/customers';
    private _riskAnalysisCustomerPublicEndpoint = 'risk-analysis/customer-form';
    private _riskAnalysisCustomerEmailEndpoint = 'risk-analysis/recommendation/send';
    private _productRecommendationEndpoint = 'product-recommendation';

    constructor(
        protected httpClient: HttpClient,
        protected sessionQuery: SessionQuery,
    ) {
        super(httpClient, sessionQuery);
    }

    // eslint-disable-next-line
    public submitRiskAnalysis$(riskRequestData: Situation, country: string, customerId: string, isThirdParty: boolean): Observable<any> {
        const url = this.getUrl({
            path: `${this._riskAnalysisCustomerEndpoint}/${customerId}?country=${country}${isThirdParty ? '&portfolioExternalTool=fks' : ''}`
        });

        return this.post$(url, riskRequestData, {});
    }

    // eslint-disable-next-line
    public submitRiskAnalysisPublic$(riskRequestData: Situation, country: string, token: string, isThirdParty: boolean): Observable<any> {
        const url = this.getUrl({
            path: `${this._riskAnalysisCustomerPublicEndpoint}/${token}?country=${country}${isThirdParty ? '&portfolioExternalTool=fks' : ''}`
        });


        return this.post$(url, riskRequestData, {});
    }

    // eslint-disable-next-line
    public getRiskAnalysisProfile$(riskAnalysisId: string): Observable<any> {
        const url = this.getUrl({
            path: `${this._riskAnalysisEndpoint}/${riskAnalysisId}`
        });

        return this.get$(url);
    }

    // eslint-disable-next-line
    public getRiskAnalysisProfileByCustomerId$(customerId: string, isThirdParty: boolean): Observable<any> {
        let url = '';
        if (isThirdParty) {
            url = this.getUrl({
                path: `${this._riskAnalysisCustomerEndpoint}/${customerId}?portfolioExternalTool=fks`
            });
        } else {
            url = this.getUrl({
                path: `${this._riskAnalysisCustomerEndpoint}/${customerId}`
            });
        }
        return this.get$(url);
    }

    public resendCustomerEmail(customerId: string, isThirdParty: boolean): Observable<void> {
        const country = this.sessionQuery.getCountry();
        const currentLocale = this.sessionQuery.getCurrentLocale();

        const url = this.getUrl({
            path: `${this._riskAnalysisCustomerEmailEndpoint}/${country}?customerId=${customerId}&locale=${currentLocale}${isThirdParty ? '&portfolioExternalTool=fks' : ''}`
        });

        return this.post$(url, {}, {});
    }

    // eslint-disable-next-line
    public getProductRecommendation$(customerId: string, source: string): Observable<any> {
        const country = this.sessionQuery.getCountry();
        const url = this.getUrl({
            path: `${this._productRecommendationEndpoint}/${customerId}?source=${source}&country=${country}`
        });
        return this.post$(url, {}, {});
    }

}