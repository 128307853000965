import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MotorDeductibleType } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import { DropdownComponent } from '@wefoxGroupOneBPShared/components/dropdown/dropdown.component';
import { CoverageOptions } from '@wefoxGroupOneBPShared/interfaces/radio-option.interface';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';

export interface CheckboxesOptions {
  controls: CheckboxControlOptions[];
  group_label: string;
}

export interface CheckboxControlOptions {
  control_name: string;
  default_checked: boolean;
  helper_text?: string;
  label: string;
}

@Component({
  selector: 'one-coverage-type-selector',
  styleUrls: ['./coverage-type-selector.component.scss'],
  templateUrl: './coverage-type-selector.component.html'
})
export class CoverageTypeSelectorComponent implements AfterViewInit, OnChanges {
  @Input() public checkboxOptions: CheckboxesOptions;
  @Input() public coverageTypeRadioOptions = CoverageOptions;
  @Input() public disableTooltip = false;
  @ViewChildren('dropdowns')
  public dropdownComponents: QueryList<DropdownComponent>;
  @Input() public fullyOptions: Partial<DropdownComponentOptions>;
  @Input() public isVehicleLiabilityVisible = false;
  @Input() public layoutModifier: { desktop?: string; mobile?: string };
  @Input() public liabilityOptions: Partial<DropdownComponentOptions>;
  @Input() public parentGroup: UntypedFormGroup;
  @Input() public partialOptions: Partial<DropdownComponentOptions>;
  @Input() public selectorLabelFully: string;
  @Input() public selectorLabelPartial: string;

  private _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef) {}

  public getDropdownItems(sourceName: string): WgDropdownOption[] {
    this._cdr.detectChanges();
    return this.dropdownComponents.find(cmp => cmp.options.sourceName === sourceName).selections;
  }

  public ngAfterViewInit(): void {
    this.parentGroup
      .get('coverage_type')
      .valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe(val => {
        const partialDeductibleInput = this.parentGroup.get(MotorDeductibleType.partialComprehensive);
        const fullyDeductibleInput = this.parentGroup.get(MotorDeductibleType.fullyComprehensive);
        const partialDeductibleChecked = this.parentGroup.get(MotorDeductibleType.partialComprehensiveChecked);
        const fullyDeductibleChecked = this.parentGroup.get(MotorDeductibleType.fullyComprehensiveChecked);

        if (val === MotorDeductibleType.vehicleLiability) {
          partialDeductibleInput.patchValue(null);
          fullyDeductibleInput.patchValue(null);
          partialDeductibleChecked.patchValue(false);
          fullyDeductibleChecked.patchValue(false);
        }
        if (val === MotorDeductibleType.partialComprehensive) {
          fullyDeductibleInput.patchValue(null);
          partialDeductibleChecked.patchValue(true);
          fullyDeductibleChecked.patchValue(false);
        }
        if (val === MotorDeductibleType.fullyComprehensive) {
          partialDeductibleChecked.patchValue(true);
          fullyDeductibleChecked.patchValue(true);
        }
      });

    this._setDefaultChecked();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const liabilityChange = changes?.liabilityOptions;
    const partialChange = changes?.partialOptions;
    const fullyChange = changes?.fullyOptions;

    if (liabilityChange && !liabilityChange?.firstChange) {
      this.liabilityOptions = liabilityChange.currentValue as any; // eslint-disable-line
    }

    if (partialChange && !partialChange?.firstChange) {
      this.partialOptions = partialChange?.currentValue as any; // eslint-disable-line
    }

    if (fullyChange && !fullyChange?.firstChange) {
      this.fullyOptions = fullyChange.currentValue as any; // eslint-disable-line
    }
  }

  private _setDefaultChecked(): void {
    if (this.checkboxOptions?.controls) {
      this.checkboxOptions.controls.forEach(control => {
        this.parentGroup.get(control.control_name).setValue(control.default_checked ? true : false);
      });
    }
    this._cdr.detectChanges();
  }
}
