export const DraftTranslationKey = {
  concludeComponentButtonText: '_SAD_action_button',
  deleteError: '_SAD_delete_draft_error',
  deleteSuccess: '_SAD_delete_draft_success',
  hint: '_SAD_draft_hint',
  modalDeleteDescription: '_SAD_delete_draft_confirm_description_NEW',
  modalDeleteTitle: '_SAD_delete_draft_confirm_title_NEW',
  ocrUploadFettchingTitle: '_OCR_document_upload_scanning_message',
  policyDetailHint: '_NT_MOTOR_policy_details_hint',
  saveSuccess: '_PROD_save_draft_success'
};
