// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    .horizontal-layout {
      display: flex;
      justify-content: space-between;
      gap: var(--huge);
      div {
        flex-grow: 1;
        margin-top: 0px !important;
      }
      #loading-content, #error-content {
        align-self: center;
      }
    }
  `, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-dynamic-components/inputs/carriers/carriers-adapter.component.ts"],"names":[],"mappings":";IACI;MACE,aAAa;MACb,8BAA8B;MAC9B,gBAAgB;MAChB;QACE,YAAY;QACZ,0BAA0B;MAC5B;MACA;QACE,kBAAkB;MACpB;IACF","sourcesContent":["\n    .horizontal-layout {\n      display: flex;\n      justify-content: space-between;\n      gap: var(--huge);\n      div {\n        flex-grow: 1;\n        margin-top: 0px !important;\n      }\n      #loading-content, #error-content {\n        align-self: center;\n      }\n    }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
