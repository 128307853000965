// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep #url-input .form-control {
  padding-right: 52px;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./libs/feature/risk-analysis/src/lib/components/share-questions-modal/share-questions-modal.component.scss"],"names":[],"mappings":"AAGI;EACE,mBAAA;EACA,uBAAA;AAFN","sourcesContent":["\n::ng-deep {\n  #url-input {\n    .form-control {\n      padding-right: 52px;\n      text-overflow: ellipsis;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
