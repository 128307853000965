import { Injectable } from '@angular/core';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { ProductQuery } from '@wefoxGroupOneBPCore/queries/product.query';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { ProductDataService } from '@wefoxGroupOneBPCore/services/product-data.service';
import { b64toBlob } from '@wefoxGroupOneBPCore/utils/file-util';
import { PreviousInsurerDropdown } from '@wefoxGroupOneBPPrivate/product/constants/insurance.constant';
import { PreviousInsurer } from '@wefoxGroupOneBPPrivate/product/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(
    private _productQuery: ProductQuery,
    private _productData: ProductDataService,
    private _sessionQuery: SessionQuery
  ) {}

  // eslint-disable-next-line
  public downloadFile(file: any, fileName?: string): void {
    const blob = b64toBlob(file.body, file.content_type);
    fileName = fileName ? fileName : file.name;

    this._openFile(blob, fileName);
  }

  // eslint-disable-next-line
  public downloadZipFile(file: any, fileName?: string, fileType?: string): void {
    const blob = new Blob([file], { type: fileType || 'application/zip' });
    fileName = fileName ? fileName : file.name;

    this._openFile(blob, fileName);
  }

  // eslint-disable-next-line
  public downloadProductDocuments$(productId: string, version: string): Observable<any> {
    return this._productData.downloadProductDocuments$(productId, version);
  }

  private _openFile(blob, fileName): void {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    document.body.removeChild(link);
  }

  public getCountriesList(): Observable<unknown> {
    return this._productData.getCountriesList(this._productQuery.getActive().id);
  }

  public getPreviousInsurerList$(
    productId?: string,
    params?: Partial<PreviousInsurer>,
    token?: any // eslint-disable-line
  ): Observable<WgDropdownOption[]> {
    productId = productId ? productId : this._productQuery.getActive().id;

    return this._productData.getPreviousInsurerList$(productId, this._sessionQuery.getCountry(), params, token).pipe(
      map((insurers: PreviousInsurer[]) => {
        const insurerDropdownOptions = this.mapDataToDropdown(
          insurers,
          PreviousInsurerDropdown.key,
          PreviousInsurerDropdown.value
        );
        return insurerDropdownOptions;
      })
    );
  }

  // eslint-disable-next-line
  public mapDataToDropdown(data: any[], keyName: string, valueName: string): WgDropdownOption[] {
    return (
      data
        // eslint-disable-next-line
        .map((item: any) => {
          const { [keyName]: key, [valueName]: value, ...additionalData } = item;
          return { additionalData, key, value };
        })
        .sort((a: any, b: any) => a.value.localeCompare(b.value)) //eslint-disable-line
    ); // eslint-disable-line
  }
}
