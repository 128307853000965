import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { WgDynamicComponentsModule } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/wg-dynamic-components.module';
import { SharedModule } from '@wefoxGroupOneBPShared/shared.module';
import { AddressSectionAdapterComponent } from './adapters/address/address-section-adapter.component';
import { DocumentsAdapterComponent } from './adapters/documents/documents-adapter.component';
import { LegalChecksAdapterComponent } from './adapters/legal-checks/legal-checks-adapter.component';
import { ReviewCardsAdapterComponent } from './adapters/review-cards/review-cards-adapter.component';
import { OwnerDataCardComponent } from './adapters/summary-section/card-types/owner-data/owner-data-card.component';
import { PriceCardComponent } from './adapters/summary-section/card-types/price-card/price-card.component';
import {
  GetValuePipe,
  SummarySectionAdapterComponent
} from './adapters/summary-section/summary-section-adapter.component';
import { CustomSectionComponent } from './custom-section-component';

@NgModule({
  declarations: [
    AddressSectionAdapterComponent,
    CustomSectionComponent,
    DocumentsAdapterComponent,
    GetValuePipe,
    LegalChecksAdapterComponent,
    ReviewCardsAdapterComponent,
    SummarySectionAdapterComponent,
    PriceCardComponent,
    OwnerDataCardComponent,
  ],
  exports: [
    AddressSectionAdapterComponent,
    CustomSectionComponent,
    DocumentsAdapterComponent,
    GetValuePipe,
    LegalChecksAdapterComponent,
    ReviewCardsAdapterComponent,
    SummarySectionAdapterComponent,
    PriceCardComponent,
    OwnerDataCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    WgDynamicComponentsModule,
    TranslocoModule
  ],
  providers: [GetValuePipe]
})
export class CustomSectionBuildModule { }
