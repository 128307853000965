// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  max-height: 95vh;
  max-width: 750px;
}

.card-body {
  overflow: scroll;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/account/login-to-external-site-modal/login-to-external-site-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".card {\n  max-height: 95vh;\n  max-width: 750px;\n}\n\n.card-body {\n  overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
