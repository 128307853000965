import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { StepModel } from '@wefoxGroupOneBPShared/services/step.service';

@Component({
  selector: 'one-product-action-buttons',
  templateUrl: './product-action-buttons.component.html'
})
export class ProductActionButtonsComponent {
  @Output() public action = new EventEmitter<string>();
  @Input() public buttonTextOffer = '_OFL_column_certificate';
  @Input() public buttonTextConclude = '_CT_HOME_finish_now';
  // eslint-disable-next-line
  @Input() public concludeSubmit: Function;
  @Input() public currentStep: StepModel;
  @Input() public hasDraft = true;
  @Input() public disabledInputState: boolean;
  @Input() public loading = false;
  public isOffer$ = this.sessionQuery.isOffer$;
  @Input() public buttonTextPortfolio = '_PORTFOLIO_finish_button_text';
  @Input() public showPortfolioButton = false;

  constructor(private sessionQuery: SessionQuery) { }

  public emitValue(action: string): void {
    this.action.emit(action);
  }

  public onConcludeSubmit(): void {
    this.concludeSubmit();
  }
}
