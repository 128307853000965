import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DownloadedFile } from '@wefoxGroupOneBP/app/private/product/interfaces/downloaded-file.interface';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants/countries.constants';
import {
  ConsentThirdParty,
  CustomerEditItDTO,
  EditProfile,
  MandatoryMandate,
  PaymentMethodResult,
  PaymentMethodSaveData,
  Profile
} from '@wefoxGroupOneBPCore/interfaces/customer-detail.interface';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { ShareQuestionsInterface } from 'libs/feature/risk-analysis/src/lib/components/share-questions-modal/share-questions-modal.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ThirdPartyJobStatusResponse } from '../interfaces';

@Injectable()
export class CustomersDataService extends BaseDataService {
  // TODO: Should be moved to contracts service
  private _customersEndpoint = 'customers';
  private _contractEndpoint = 'contracts';
  private _customerPaymentEndpoint = 'payment-method/account';
  private _customersBrokerListEndpoint = 'customers/broker';
  private _customersITDataEndpoint = 'policy/customers';
  private _customersListEndpoint = 'customers/list';
  private _getAccountCustomersEndpoint = 'customers/contracts/account';
  // TODO: Should be moved to contracts service
  private _getContractsEndpoint = 'contracts/broker';
  private _getCustomerClaimsEndpoint = 'claims/customer';
  private _getCustomerContractsEndpoint = 'contracts/customer';
  private _getCustomerContractsITEndpoint = 'policy/customer';
  private _getCustomerContractsOffersEndpoint = 'contracts/offers/customer';
  private _getCustomerInfoEndpoint = 'customers/customer';
  private _getCustomerInfoITEndpoint = 'customer-data';
  private _getCustomersEndpoint = 'customers/contracts';
  private _getThirdPartyCustomersEndpoint = 'portfolio/customers';
  private _updateCustomerDataEndpoint = 'customers/edit';
  private _getShareQuestionsEndpoint = 'risk-analysis';
  private _getSendEmailEndpoint = 'risk-analysis/send';
  private _thirdPartyContractsEndpoint = 'portfolio/contracts/customers';
  private _getThirdPartyContractsEndpoint = 'portfolio/contracts';
  private _mandatoryMandateEndpoint = 'user-consent';
  private _insuranceCarriersEndpoint = 'portfolio/contracts/insurers';
  private _insuranceProductTypesEndpoint = 'portfolio/contracts/product-types';
  private _getThirdPartyCustomerJobStatus = 'portfolio/contracts/job/status';
  private _uploadCustomerRegistrationEndpoint = 'portfolio/contracts/file/upload'; // TODO: get from BE when ready
  private _getDisplayOnboardingScreen = 'banner-management';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  // eslint-disable-next-line
  public addAdditionalCustomerContract$(customerID, body, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: `portfolio/customers/${customerID}/contracts`
    });
    return this.post$(url, body, options) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public createThirdPartyCustomerAndContract$(body, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: `${this._getThirdPartyContractsEndpoint}`
    });
    return this.post$(url, body, options) as Observable<any>; // eslint-disable-line
  }

  public downloadAllContracts$(contractId: string): Observable<unknown> {
    const url = this.getUrl({
      path: `${this._contractEndpoint}/${contractId}/content/all`
    });

    return this.get$(url);
  }

  public downloadThirdPartyContracts$(contractId: string): Observable<unknown> {
    const url = this.getUrl({
      path: `${this._getThirdPartyContractsEndpoint}/${contractId}/documents`
    });

    return this.get$(url, {
      headers: {
        'Content-Type': 'application/zip'
      },
      responseType: 'arraybuffer'
    });
  }

  // TODO: Should be moved to contracts service
  public downloadContract$(contractId: string): Observable<DownloadedFile> {
    const url = this.getUrl({
      path: `${this._contractEndpoint}/${contractId}/content`
    });
    return this.get$(url, {
      headers: {
        Accept: 'application/pdf',
        'Content-Type': 'application/json'
      },
      observe: 'response',
      responseType: 'blob'
    }).pipe(
      // eslint-disable-next-line
      map((response: any) => {
        const contentDisposition = response.headers.get('Content-disposition');
        const headerExp = /filename[^;\n=]*=((['"]).*?\2|[^;\n]*)/;
        const matches = headerExp.exec(contentDisposition);
        const file = { filename: matches[1], content: new Blob([response.body], { type: 'application/pdf' }) };
        return file;
      })
    );
  }

  public getAccountCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({
      criteria,
      customerType,
      onboardingType,
      page,
      productType,
      size,
      sort,
      status: statusType
    });
    const url = this.getUrl({
      path: `${this._getAccountCustomersEndpoint}/${brokerId}`
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getAllCustomersIT$(
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
    // eslint-disable-next-line
  ): Observable<any> {
    const params = this.getURLParams({
      criteria,
      customerType,
      onboardingType,
      page,
      productType,
      size,
      sort,
      status: statusType
    });
    const url = this.getUrl({
      path: this._customersITDataEndpoint
    });
    return this.get$(url, { params }) as Observable<any>; // eslint-disable-line
  }

  // TODO: Should be moved to contracts service
  /**
   * Get contracts for a given broker
   * @param brokerId Current user
   * @param page Default 1, on server they start on 0
   * @param sort Sort parameters, ex: 'account.firsname,account.lastname,desc'
   * @param size Amount of items
   */

  // eslint-disable-next-line
  public getAllCustomers$(brokerId: string): Observable<AllListInfo> {
    const url = this.getUrl({
      path: `${this._customersListEndpoint}/${brokerId}`
    });
    return this.get$(url) as Observable<AllListInfo>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getAllCustomersForSupport$(brokerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._customersBrokerListEndpoint}/${brokerId}`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getContractDetails$(contractId: any, isThirdParty: boolean): Observable<any> {
    const contractPath = isThirdParty ? this._getThirdPartyContractsEndpoint : this._contractEndpoint;
    const url = this.getUrl({
      path: `${contractPath}/${contractId}`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getContractDetailsIT$(contractId: any): Observable<any> {
    const url = this.getUrl({
      path: `policy/${contractId}/details`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getInsuranceCarriers$(): Observable<any> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersEndpoint}`
    });

    return this.get$(url) as Observable<any[]>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getCarriersCategories$(productTypeKey: string, insurerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._insuranceProductTypesEndpoint}/${productTypeKey}/categories?insurer_id=${insurerId}`
    });

    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getCarriersProductTypes$(insurerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._insuranceProductTypesEndpoint}?insurer_id=${insurerId}`
    });

    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getPolicyHolderDetailIT$(contractId: any): Observable<any> {
    const url = this.getUrl({
      path: `policy/policy-holder/${contractId}`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  public getSendEmailEndpoint$(customerDataToSend, isThirdParty: boolean, options?: RequestInit) {
    let url = '';
    if (isThirdParty) {
      url = this.getUrl({
        path: `${this._getSendEmailEndpoint}?portfolioExternalTool=fks`
      });
    } else {
      url = this.getUrl({
        path: `${this._getSendEmailEndpoint}`
      });
    }
    return this.post$(url, customerDataToSend, options) as Observable<ShareQuestionsInterface>;
  }

  public getShareQuestionsEndpoint$(
    customerId: string,
    isThirdParty: boolean,
    options?: RequestInit
  ): Observable<ShareQuestionsInterface> {
    const country = this.sessionQuery.getCountry().toLowerCase();

    const url = this.getUrl({
      path: `${this._getShareQuestionsEndpoint}/${customerId}/link?country=${country}${isThirdParty ? '&portfolioExternalTool=fks' : ''
        }`
    });

    return this.post$(url, customerId, options) as Observable<ShareQuestionsInterface>;
  }

  // eslint-disable-next-line
  public getVehicleTypeIT$(contractId: any): Observable<any> {
    const url = this.getUrl({
      path: `policy/${contractId}`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  public getContracts$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string,
    onboardingType?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({ type, sort, page, size, criteria, onboardingType });
    const url = this.getUrl({
      path: `${this._getContractsEndpoint}/${brokerId}`
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getCustomerClaims$(
    customerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string
  ): Observable<AllListInfo> {
    if (this.sessionQuery.getCountry() !== COUNTRIES.it) {
      const params = this.getURLParams({ sort, criteria, page, size, type });
      const url = this.getUrl({
        path: `${this._getCustomerClaimsEndpoint}/${customerId}`
      });
      return this.get$(url, { params }) as Observable<AllListInfo>;
    } else {
      return of(null);
    }
  }

  public getCustomerContracts$(
    customerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string
    // eslint-disable-next-line
  ): Observable<AllListInfo> {
    const params = this.getURLParams({ sort, criteria, page, size, type });
    let url = '';
    if (this.sessionQuery.getCountry() !== COUNTRIES.it) {
      url = this.getUrl({
        path: `${this._getCustomerContractsEndpoint}/${customerId}`
      });
    } else {
      url = this.getUrl({
        path: `${this._getCustomerContractsITEndpoint}/${customerId}`
      });
    }
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getThirdPartyCustomerContracts$(
    customerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({ sort, criteria, page, size, type });
    const url = this.getUrl({
      path: `${this._thirdPartyContractsEndpoint}/${customerId}`
    });

    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getCustomerContractsAll$(customerId: string): Observable<AllListInfo> {
    const url = this.getUrl({
      path: `${this._getCustomerContractsEndpoint}/${customerId}`
    });

    return this.get$(url) as Observable<AllListInfo>;
  }

  public getCustomerContractsForSupport$(
    customerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    type?: string
    // eslint-disable-next-line
  ): Observable<AllListInfo> {
    const params = this.getURLParams({ sort, criteria, page, size, type });
    const url = this.getUrl({
      path: `${this._getCustomerContractsOffersEndpoint}/${customerId}`
    });

    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  // eslint-disable-next-line
  public getCustomerInfo$(customerId: string, isThirdParty: boolean): Observable<any> {
    let url = '';
    if (isThirdParty) {
      url = this.getUrl({
        path: `${this._getThirdPartyCustomersEndpoint}/${customerId}`
      });
    } else if (this.sessionQuery.getCountry() !== COUNTRIES.it) {
      url = this.getUrl({
        path: `${this._getCustomerInfoEndpoint}/${customerId}`
      });
    } else {
      url = this.getUrl({
        path: `${this._getCustomerInfoITEndpoint}/${customerId}?customerType=natural_person`
      });
    }
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getThirdPartyCustomerInfoCard$(customerId: string, isThirdParty: boolean): Observable<any> {
    if (isThirdParty) {
      const url = this.getUrl({
        path: `${this._getThirdPartyCustomersEndpoint}/${customerId}`
      });
      return this.get$(url) as Observable<any>; // eslint-disable-line
    }
  }

  public getThirdPartyCustomerJobStatus$(): Observable<ThirdPartyJobStatusResponse> {
    const url: string = this.getUrl({
      path: `${this._getThirdPartyCustomerJobStatus}`
    });
    return this.get$(url) as Observable<ThirdPartyJobStatusResponse>;
  }

  public getCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    customerType?: string,
    onboardingType?: string,
    productType?: string[],
    statusType?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({
      criteria,
      customerType,
      onboardingType,
      page,
      productType,
      size,
      sort,
      status: statusType
    });
    const url = this.getUrl({
      path: `${this._getCustomersEndpoint}/${brokerId}`
    });
    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  public getThirdPartyCustomers$(
    brokerId: string,
    criteria?: string,
    page = 1,
    sort?: string,
    size?: number,
    firstName?: string,
    lastName?: string
  ): Observable<AllListInfo> {
    const params = this.getURLParams({
      brokerId,
      criteria,
      page,
      size,
      sort,
      firstName,
      lastName
    });
    const url = this.getUrl({
      path: `${this._getThirdPartyCustomersEndpoint}`
    });

    return this.get$(url, { params }) as Observable<AllListInfo>;
  }

  // eslint-disable-next-line
  public getPolicyInputs$(policyId: string, productId: string): Observable<any> {
    const url = this.getUrl({
      path: `policies/${policyId}/product/${productId}/inputs`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // TO DO: Change endpoint
  public saveContractPaymentMethod$(
    accountId: string,
    paymentData: Partial<PaymentMethodSaveData>,
    options?: RequestInit
  ): Observable<PaymentMethodResult> {
    const url = this.getUrl({
      path: `${this._customerPaymentEndpoint}/${accountId}/contract`
    });

    return this.post$(url, paymentData, options) as Observable<PaymentMethodResult>;
  }

  public saveCustomerDataMethod$(
    customerNewData: Partial<EditProfile>,
    customerOldData: Partial<EditProfile> | Partial<Profile>,
    options?: RequestInit
  ): Observable<Profile> {
    const url = this.getUrl({
      path: `${this._updateCustomerDataEndpoint}`
    });

    return this.put$(
      url,
      { current_customer: customerOldData, modified_customer: customerNewData },
      options
    ) as Observable<Profile>;
  }

  // eslint-disable-next-line
  public sendConsentApprovalThirdParty$(consent: ConsentThirdParty, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: `${this._mandatoryMandateEndpoint}/fks`
    });
    return this.put$(url, consent, options) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public getMandatoryMandate$(customerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._mandatoryMandateEndpoint}/${customerId}/type?consent_type=privacy`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public updateConsentApproval$(mandatoryMandate: MandatoryMandate, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: `${this._mandatoryMandateEndpoint}`
    });
    return this.put$(url, mandatoryMandate, options) as Observable<any>; // eslint-disable-line
  }

  public updateCustomerDataIT$(
    customerId: string,
    customerData: CustomerEditItDTO
    // eslint-disable-next-line
  ): Observable<any> {
    const url = this.getUrl({
      path: `${this._getCustomerInfoITEndpoint}/${customerId}`
    });
    const body = customerData;
    return this.put$(url, body, {}) as Observable<any>; // eslint-disable-line
  }

  public updateInsuredObject$(
    policyId: string,
    productId: string,
    insuredObjectData: Partial<any>, // eslint-disable-line
    options?: RequestInit
    // eslint-disable-next-line
  ): Observable<any> {
    const url = this.getUrl({
      path: `policies/${policyId}/product/${productId}`
    });
    return this.patch$(url, insuredObjectData, options) as Observable<any>; // eslint-disable-line
  }

  public markAsFavorite$(id: string): Observable<void> {
    const url = this.getUrl({
      path: `${this._customersEndpoint}/${id}/favorite`
    });
    return this.post$(url, {}, {}) as Observable<void>;
  }

  public unMarkAsFavorite$(id: string): Observable<void> {
    const url = this.getUrl({
      path: `${this._customersEndpoint}/${id}/favorite`
    });
    return this.delete$(url) as Observable<void>;
  }

  // eslint-disable-next-line
  public uploadRegistrationFile$(body: FormData, options?: RequestInit): Observable<any> {
    const url = this.getUrl({
      path: 'portfolio/contracts/upload'
    });
    return this.post$(url, body, options);
  }

  // eslint-disable-next-line
  public getDisplayOnboardingScreen(topic: string = 'customer_care'): Observable<any> {
    const url = this.getUrl({
      path: `${this._getDisplayOnboardingScreen}?topic=${topic}`
    });
    return this.get$(url) as Observable<any>; // eslint-disable-line
  }

  // eslint-disable-next-line
  public postDisplayOnboardingScreen(topic: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._getDisplayOnboardingScreen}?topic=${topic}`
    });
    return this.post$(url, {}, {}) as Observable<any>; // eslint-disable-line
  }
}
