import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PerformanceDataService } from './performance-data.service';

@Injectable({
    providedIn: 'root'
})
export class PerformanceService {
    constructor(private _performanceDataService: PerformanceDataService) { }

    public getPoliciesSold$(): Observable<number> {
        return this._performanceDataService.getPoliciesSold$();
    }

    public getActiveCustomers$(): Observable<number> {
        return this._performanceDataService.getActiveCustomers$();
    }

    public getCommissionsAmountCurrentMonth$(): Observable<number> {
        return this._performanceDataService.getCommissionsAmountCurrentMonth$();
    }

    public getCommissionsAmountLastWeek$(): Observable<number> {
        return this._performanceDataService.getCommissionsAmountLastWeek$();
    }

}
