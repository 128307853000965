// ng-barrel

import { WgCheckboxComponent } from './wg-checkbox/wg-checkbox.component';
import { WgDateComponent } from './wg-date/wg-date.component';
import { WgDropdownComponent } from './wg-dropdown/wg-dropdown.component';
import { WgErrorComponent } from './wg-error/wg-error.component';
import { WgFieldComponent } from './wg-field/wg-field.component';
import { WgDocumentUploadComponent } from './wg-file-upload/wg-document-upload.component';
import { WgFileUploadComponent } from './wg-file-upload/wg-file-upload.component';
import { WgKmMileageComponent } from './wg-km-mileage/wg-km-mileage.component';
import { WgRadioButtonComponent } from './wg-radio-button/wg-radio-button.component';
import { WgSwitchComponent } from './wg-switch/wg-switch.component';
import { WgTypeaheadComponent } from './wg-typeahead/wg-typeahead.component';

export const wgInputComponents = [
  WgCheckboxComponent,
  WgDateComponent,
  WgDropdownComponent,
  WgErrorComponent,
  WgFieldComponent,
  WgFileUploadComponent,
  WgDocumentUploadComponent,
  WgKmMileageComponent,
  WgRadioButtonComponent,
  WgSwitchComponent,
  WgTypeaheadComponent
];

export * from './wg-checkbox/wg-checkbox.component';
export * from './wg-date/wg-date.component';
export * from './wg-dropdown/wg-dropdown.component';
export * from './wg-error/wg-error.component';
export * from './wg-field/wg-field.component';
export * from './wg-file-upload/wg-document-upload.component';
export * from './wg-file-upload/wg-file-upload.component';
export * from './wg-km-mileage/wg-km-mileage.component';
export * from './wg-radio-button/wg-radio-button.component';
export * from './wg-switch/wg-switch.component';
export * from './wg-typeahead/wg-typeahead.component';
