import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'one-card-content-toggle',
  styleUrls: ['./card-content-toggle.component.scss'],
  templateUrl: './card-content-toggle.component.html'
})
export class CardContentToggleComponent implements OnInit {
  public isOpen = false;
  @Input() public searchTerm: string;
  @Input() public slug: string;
  @Input() public text: string;
  @Input() public title: string;

  public ngOnInit(): void {
    if (this.searchTerm) {
      const content = this.text.toString();
      if (content.toLowerCase().includes(this.searchTerm?.toLowerCase())) {
        this.isOpen = true;
      }
      this.title = this.title.replace(new RegExp(this.searchTerm, 'gi'), match => `<mark>${match}</mark>`);
      this.text = this.text.replace(new RegExp(this.searchTerm, 'gi'), match => `<mark>${match}</mark>`);
    }
  }

  public toggleContent(): void {
    this.isOpen = !this.isOpen;
  }
}
