import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { InsuranceCardData } from '@wefoxGroupOneBPShared/components/carrier-excerpt/interfaces/carrier-excerpt.interface';
import { Observable } from 'rxjs';
import {
  AllCarrierResponse,
  AllProductGroupResponse,
  AllProductResponse,
  ProductGroupResponse,
  ProductsResponse
} from '../interfaces/product.interface';

@Injectable()
export class ThirdPartyDataService extends BaseDataService {
  private _insuranceCarriersEndpoint = 'third-party-products/insurance-carriers';
  private _productGroupEndpoint = 'product-groups';
  private _consentEndpoint = 'user-consent';
  private _insuranceCarriersTotalEndpoint = 'third-party-products/insurance-carriers-total-products';
  private _productsEndpoint = 'third-party-products/product-groups';
  private _thirdPartyProductsEndpoint = 'third-party-products';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  public getCarriers$(): Observable<InsuranceCardData[]> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersEndpoint}`
    });

    return this.get$(url) as Observable<InsuranceCardData[]>;
  }

  public getProductGroups$(insuranceCarrierId: string): Observable<ProductGroupResponse[]> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersEndpoint}/${insuranceCarrierId}/${this._productGroupEndpoint}`
    });

    return this.get$(url) as Observable<ProductGroupResponse[]>;
  }

  public getProducts$(insurenceCarrierId: string, productGroup: string): Observable<ProductsResponse[]> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersEndpoint}/${insurenceCarrierId}/product-groups/${productGroup}`
    });

    return this.get$(url) as Observable<ProductsResponse[]>;
  }

  public getFksConsent$(brokerId) {
    const url = this.getUrl({
      path: `${this._consentEndpoint}?consentType=PRIVILEGE&brokerId=${brokerId}&impactType=fks`
    });
    return this.get$(url);
  }

  public getAllCarriers$(insuranceCarrierNames?: string[] | string): Observable<AllCarrierResponse[]> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersEndpoint}`
    });

    let params = new HttpParams();

    if (insuranceCarrierNames) {
      if (Array.isArray(insuranceCarrierNames)) {
        insuranceCarrierNames.forEach(id => {
          params = params.append('insuranceCarrierId', id);
        });
      } else {
        params = params.set('insuranceCarrierName', insuranceCarrierNames);
      }
    }

    return this.get$(url, { params }) as Observable<AllCarrierResponse[]>;
  }

  public getAllProducts$(insuranceCarrierIds?: string[], productGroupId?: string): Observable<AllProductResponse[]> {
    const url = this.getUrl({
      path: `${this._thirdPartyProductsEndpoint}`
    });
    let params = new HttpParams();

    if (insuranceCarrierIds) {
      insuranceCarrierIds.forEach(id => {
        params = params.append('insuranceCarrierId', id);
      });
    }

    if (productGroupId) {
      params = params.set('productGroupId', productGroupId);
    }

    return this.get$(url, { params }) as Observable<AllProductResponse[]>;
  }

  public getAllProductGroups$(insuranceCarrierIds?: string[]): Observable<AllProductGroupResponse[]> {
    const url = this.getUrl({
      path: `${this._productsEndpoint}`
    });
    let params = new HttpParams();

    if (insuranceCarrierIds) {
      insuranceCarrierIds.forEach(id => {
        params = params.append('insuranceCarrierId', id);
      });
    }

    return this.get$(url, { params }) as Observable<AllProductGroupResponse[]>;
  }

  public getInsuranceCarriersTotalProducts$(
    insuranceCarrierIds?: string[],
    productGroupId?: string,
    productNames?: string[],
    sort?: string,
    size = 400
  ): Observable<AllListInfo> {
    const url = this.getUrl({
      path: `${this._insuranceCarriersTotalEndpoint}`
    });

    let params = new HttpParams();

    if (insuranceCarrierIds) {
      insuranceCarrierIds.forEach(id => {
        params = params.append('insuranceCarrierId', id);
      });
    }

    if (productGroupId) {
      params = params.set('productGroupId', productGroupId);
    }

    if (productNames) {
      productNames.forEach(name => {
        params = params.append('name', name);
      });
    }

    if (sort) {
      params = params.set('sort', `_id,${sort}`);
    }

    if (size) {
      params = params.set('size', size);
    }

    return this.get$(url, { params }) as Observable<AllListInfo>;
  }
}
