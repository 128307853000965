import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Source, SourceValue } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { ProductQuery } from '@wefoxGroupOneBPCore/queries/product.query';
import { SelfJourneyQuery } from '@wefoxGroupOneBPPublic/modules/self-journey/queries/self-journey.query';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-dropdown',
  templateUrl: './dropdown.component.html'
})
export class DropdownComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public disableErrorMessage = false;
  public locale$: Observable<string>;
  @Input() public options: Partial<DropdownComponentOptions>;
  @Input() public parentGroup: UntypedFormGroup;
  public selections: WgDropdownOption[];
  @Input() public rounded = false;
  @Input() public setValue: Observable<any>; // eslint-disable-line
  @Input() public sources: Source[];
  @Input() public sourceValues: SourceValue[]; // If sources do not come from MDW
  @Input() public theme = '';

  private _currentLocale: string;
  private _defaultOptions: DropdownComponentOptions;
  private _unsubscribe$: Subject<void> = new Subject();

  constructor(
    private _formService: FormService,
    private _productQuery: ProductQuery,
    private _selfJourneyQuery: SelfJourneyQuery,
    private _sessionQuery: SessionQuery
  ) {
    this._defaultOptions = {
      controlName: null,
      helper_text: '',
      label: '',
      optionsPanelModifiers: '',
      required: true,
      selector_label: '',
      sourceName: null,
      sources: null,
      tooltip_text: '',
      topActionRow: null,
      translationOptions: null
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const newOptions = changes?.options;

    if (changes && newOptions && !newOptions?.firstChange) {
      this.selections = null;
      this.sourceValues = changes.sourceValues?.currentValue;
      this.options = { ...this._defaultOptions, ...newOptions.currentValue };
      this.sourceValues = this._getSourceValues();
      this.selections = this._updateSelections();
    }
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.sources = this.options.sources ? this.options.sources : this.sources;

    if (!this.sources) {
      this.sources = this._sessionQuery.isLoggedIn()
        ? this._productQuery.getActiveAllSources()
        : this._selfJourneyQuery.getActiveAllSources();
    }

    this._currentLocale = this._sessionQuery.getCurrentLocale();
    this.options = { ...this._defaultOptions, ...this.options };
    this.sourceValues = this._getSourceValues();
    this.selections = this._updateSelections();

    this._setDefaultValue();
    this._monitorLocaleChanges();
    this.setValue?.pipe(takeUntil(this._unsubscribe$)).subscribe(key => {
      this._setValueByKey(key);
    });
  }

  private _getSourceValues() {
    return this.sourceValues
      ? this.sourceValues
      : this.sources.find(source => source.name === this.options.sourceName).values;
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.pipe(takeUntil(this._unsubscribe$)).subscribe(locale => {
      if (this._currentLocale !== locale) {
        this.selections = this._updateSelections();
        if (!this.sourceValues) {
          this._updateTranslation();
        }
        this._currentLocale = locale;
      }
    });
  }

  private _setDefaultValue(): void {
    if (this.options.defaultValue || this.options.defaultValue === false) {
      this.parentGroup
        .get(this.options.controlName)
        .setValue(this.selections.find(option => option.key === this.options.defaultValue));
    }
  }

  private _setValueByKey(key): void {
    this.parentGroup.get(this.options.controlName).setValue(this.selections.find(option => option.key === key));
  }

  private _updateSelections(): WgDropdownOption[] {
    return this._formService.translateSourceValues(this.sourceValues, this.options.translationOptions);
  }

  private _updateTranslation(): void {
    this._formService.updateTranslations(this.parentGroup, this.options.controlName, this.selections);
  }
}
