import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { WgDropdownComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-dropdown/wg-dropdown.component';
import { WgDropdownOption } from '@wefoxGroupOneBPCore/interfaces/wg-dropdown-option.interface';
import { WgTypeaheadSuggestion } from '@wefoxGroupOneBPShared/modules/wg-input/interfaces/wg-typeahead-suggest';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

@Component({
  selector: 'wg-typeahead',
  templateUrl: './wg-typeahead.component.html'
})
export class WgTypeaheadComponent extends WgDropdownComponent implements AfterContentInit, OnInit, OnChanges {
  public _debouncer: Subject<string> = new Subject<string>();
  @Input() public controlName: string;
  @Input() public currentOption = '';
  @Input() public debounceTime = UxIntervalTimes.veryFast;
  @Input() public override disabled = false;
  public hasSearchTerm: boolean;
  @Input() public override options: any; // eslint-disable-line
  @Input() public placeholder: string;
  @Output() public search = new EventEmitter<string>();
  @Input() public suggest: WgTypeaheadSuggestion;
  private _loading = false;

  public constructor(_controlContainer: FormGroupDirective) {
    super(_controlContainer);
  }

  public override getCurrentValue(): string {
    if (this._inputControl?.value) {
      return this._inputControl?.value?.value;
    }

    return this.currentOption;
  }

  // eslint-disable-next-line
  public inputChanged(event: any): void {
    this._inputControl.setValue(null);
    this._inputControl.markAsTouched();
    this.currentOption = event.srcElement.value;
    this.chosenOption = null;
    this._debouncer.next(this.currentOption);
    this.showOptions = true;
  }

  public override isDisabled(): boolean {
    return this._inputControl ? this._inputControl.disabled : this.disabled;
  }

  public override ngAfterContentInit(): void {
    super.ngAfterContentInit();
    const value = this._inputControl.value;
    if (value) {
      this.selectOption(value);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.suggest && !changes.suggest.firstChange) {
      if (changes.suggest.currentValue.trigger) {
        this._inputControl.setValue(null);
        this._inputControl.markAsTouched();
        this.currentOption = changes.suggest.currentValue.value;
        this.chosenOption = null;
        this._loading = true;
        this._debouncer.next(this.currentOption);
        this.showOptions = true;
      } else {
        this.currentOption = changes.suggest.currentValue.value;
        this.options = [];
      }
    }
    if (changes.options) {
      this._loading = false;
    }
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }

  public ngOnInit(): void {
    this._debouncer.pipe(debounceTime(this.debounceTime)).subscribe(value => {
      this.search.emit(value);
      this.hasSearchTerm = !!value;
    });
    this.placeholder = translate(this.placeholder);
  }

  public onClearSearch(): void {
    if (this.disabled) {
      return;
    }
    this.currentOption = '';
    this.hasSearchTerm = false;
    this._inputControl.setValue(null);
    this.onInputFocus();
  }

  public onInputFocus(): void {
    this._debouncer.next(this.currentOption);
    setTimeout(() => {
      this.showOptions = true;
    }, UxIntervalTimes.veryFast);
    this.setFocus(true);
  }

  public override selectOption(option: WgDropdownOption): void {
    super.selectOption(option);
    this.currentOption = option.value;
    this._inputControl.setValue(this.chosenOption);
    this.hasSearchTerm = true;
  }

  public override setFocus(value: boolean): void {
    super.setFocus(value);
    if (!value && this.options?.length === 1) {
      this.selectOption(this.options[0]);
    }
  }

  public ToogleOptions(): void {
    if (this.showOptions) {
      this.showOptions = false;
      this.setFocus(false);
    } else {
      this.showOptions = true;
      this.onInputFocus();
    }
  }
}
