import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-file-download-adapter',
  templateUrl: './file-download-adapter.component.html'
})
export class FileDownloadAdapterComponent {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public getDownloadIcon(controlName: string): string {
    return this.parentGroup?.get(controlName)?.value === true ? 'step-done' : 'file-download';
  }

  public getFileState(controlName: string): string {
    return this.parentGroup?.get(controlName)?.valid ? '' : 'is-invalid';
  }

  public getIconColor(controlName: string): string {
    if (this.getDownloadIcon(controlName) === 'step-done') {
      return 'success-dark';
    }

    if (this.getFileState(controlName) === 'is-invalid') {
      return 'danger-base';
    }

    return 'neutral-end';
  }
  public markAsDownloaded(controlName: string): void {
    if (this.parentGroup.get(controlName)) {
      this.parentGroup.get(controlName).setValue(true);
    }
  }
}
