export const PhoneData = [
  { key: '+880', value: 'BD +880' },
  { key: '+32', value: 'BE +32' },
  { key: '+226', value: 'BF +226' },
  { key: '+359', value: 'BG +359' },
  { key: '+387', value: 'BA +387' },
  { key: '+1246', value: 'BB +1-246' },
  { key: '+681', value: 'WF +681' },
  { key: '+590', value: 'BL +590' },
  { key: '+1441', value: 'BM +1-441' },
  { key: '+673', value: 'BN +673' },
  { key: '+591', value: 'BO +591' },
  { key: '+973', value: 'BH +973' },
  { key: '+257', value: 'BI +257' },
  { key: '+229', value: 'BJ +229' },
  { key: '+975', value: 'BT +975' },
  { key: '+1876', value: 'JM +1-876' },
  // { key: '+', value: 'BV +' },
  { key: '+267', value: 'BW +267' },
  { key: '+685', value: 'WS +685' },
  { key: '+599', value: 'BQ +599' },
  { key: '+55', value: 'BR +55' },
  { key: '+1242', value: 'BS +1-242' },
  { key: '+441534', value: 'JE +44-1534' },
  { key: '+375', value: 'BY +375' },
  { key: '+501', value: 'BZ +501' },
  { key: '+7', value: 'RU +7' },
  { key: '+250', value: 'RW +250' },
  { key: '+381', value: 'RS +381' },
  { key: '+670', value: 'TL +670' },
  { key: '+262', value: 'RE +262' },
  { key: '+993', value: 'TM +993' },
  { key: '+992', value: 'TJ +992' },
  { key: '+40', value: 'RO +40' },
  { key: '+690', value: 'TK +690' },
  { key: '+245', value: 'GW +245' },
  { key: '+1671', value: 'GU +1-671' },
  { key: '+502', value: 'GT +502' },
  // { key: '+', value: 'GS +' },
  { key: '+30', value: 'GR +30' },
  { key: '+240', value: 'GQ +240' },
  { key: '+590', value: 'GP +590' },
  { key: '+81', value: 'JP +81' },
  { key: '+592', value: 'GY +592' },
  { key: '+441481', value: 'GG +44-1481' },
  { key: '+594', value: 'GF +594' },
  { key: '+995', value: 'GE +995' },
  { key: '+1473', value: 'GD +1-473' },
  { key: '+44', value: 'GB +44' },
  { key: '+241', value: 'GA +241' },
  { key: '+503', value: 'SV +503' },
  { key: '+224', value: 'GN +224' },
  { key: '+220', value: 'GM +220' },
  { key: '+299', value: 'GL +299' },
  { key: '+350', value: 'GI +350' },
  { key: '+233', value: 'GH +233' },
  { key: '+968', value: 'OM +968' },
  { key: '+216', value: 'TN +216' },
  { key: '+962', value: 'JO +962' },
  { key: '+385', value: 'HR +385' },
  { key: '+509', value: 'HT +509' },
  { key: '+36', value: 'HU +36' },
  { key: '+852', value: 'HK +852' },
  { key: '+504', value: 'HN +504' },
  { key: '+ ', value: 'HM + ' },
  { key: '+58', value: 'VE +58' },
  { key: '+1787', value: 'PR +1-787 and 1-939' },
  { key: '+970', value: 'PS +970' },
  { key: '+680', value: 'PW +680' },
  { key: '+351', value: 'PT +351' },
  { key: '+47', value: 'SJ +47' },
  { key: '+595', value: 'PY +595' },
  { key: '+964', value: 'IQ +964' },
  { key: '+507', value: 'PA +507' },
  { key: '+689', value: 'PF +689' },
  { key: '+675', value: 'PG +675' },
  { key: '+51', value: 'PE +51' },
  { key: '+92', value: 'PK +92' },
  { key: '+63', value: 'PH +63' },
  { key: '+870', value: 'PN +870' },
  { key: '+48', value: 'PL +48' },
  { key: '+508', value: 'PM +508' },
  { key: '+260', value: 'ZM +260' },
  { key: '+212', value: 'EH +212' },
  { key: '+372', value: 'EE +372' },
  { key: '+20', value: 'EG +20' },
  { key: '+27', value: 'ZA +27' },
  { key: '+593', value: 'EC +593' },
  { key: '+39', value: 'IT +39' },
  { key: '+84', value: 'VN +84' },
  { key: '+677', value: 'SB +677' },
  { key: '+251', value: 'ET +251' },
  { key: '+252', value: 'SO +252' },
  { key: '+263', value: 'ZW +263' },
  { key: '+966', value: 'SA +966' },
  { key: '+34', value: 'ES +34' },
  { key: '+291', value: 'ER +291' },
  { key: '+382', value: 'ME +382' },
  { key: '+373', value: 'MD +373' },
  { key: '+261', value: 'MG +261' },
  { key: '+590', value: 'MF +590' },
  { key: '+212', value: 'MA +212' },
  { key: '+377', value: 'MC +377' },
  { key: '+998', value: 'UZ +998' },
  { key: '+95', value: 'MM +95' },
  { key: '+223', value: 'ML +223' },
  { key: '+853', value: 'MO +853' },
  { key: '+976', value: 'MN +976' },
  { key: '+692', value: 'MH +692' },
  { key: '+389', value: 'MK +389' },
  { key: '+230', value: 'MU +230' },
  { key: '+356', value: 'MT +356' },
  { key: '+265', value: 'MW +265' },
  { key: '+960', value: 'MV +960' },
  { key: '+596', value: 'MQ +596' },
  { key: '+1670', value: 'MP +1-670' },
  { key: '+1664', value: 'MS +1-664' },
  { key: '+222', value: 'MR +222' },
  { key: '+441624', value: 'IM +44-1624' },
  { key: '+256', value: 'UG +256' },
  { key: '+255', value: 'TZ +255' },
  { key: '+60', value: 'MY +60' },
  { key: '+52', value: 'MX +52' },
  { key: '+972', value: 'IL +972' },
  { key: '+33', value: 'FR +33' },
  { key: '+246', value: 'IO +246' },
  { key: '+290', value: 'SH +290' },
  { key: '+358', value: 'FI +358' },
  { key: '+679', value: 'FJ +679' },
  { key: '+500', value: 'FK +500' },
  { key: '+691', value: 'FM +691' },
  { key: '+298', value: 'FO +298' },
  { key: '+505', value: 'NI +505' },
  { key: '+31', value: 'NL +31' },
  { key: '+47', value: 'NO +47' },
  { key: '+264', value: 'NA +264' },
  { key: '+678', value: 'VU +678' },
  { key: '+687', value: 'NC +687' },
  { key: '+227', value: 'NE +227' },
  { key: '+672', value: 'NF +672' },
  { key: '+234', value: 'NG +234' },
  { key: '+64', value: 'NZ +64' },
  { key: '+977', value: 'NP +977' },
  { key: '+674', value: 'NR +674' },
  { key: '+683', value: 'NU +683' },
  { key: '+682', value: 'CK +682' },
  // { key: '+', value: 'XK +' },
  { key: '+225', value: 'CI +225' },
  { key: '+41', value: 'CH +41' },
  { key: '+57', value: 'CO +57' },
  { key: '+86', value: 'CN +86' },
  { key: '+237', value: 'CM +237' },
  { key: '+56', value: 'CL +56' },
  { key: '+61', value: 'CC +61' },
  { key: '+1', value: 'CA +1' },
  { key: '+242', value: 'CG +242' },
  { key: '+236', value: 'CF +236' },
  { key: '+243', value: 'CD +243' },
  { key: '+420', value: 'CZ +420' },
  { key: '+357', value: 'CY +357' },
  { key: '+61', value: 'CX +61' },
  { key: '+506', value: 'CR +506' },
  { key: '+599', value: 'CW +599' },
  { key: '+238', value: 'CV +238' },
  { key: '+53', value: 'CU +53' },
  { key: '+268', value: 'SZ +268' },
  { key: '+963', value: 'SY +963' },
  { key: '+599', value: 'SX +599' },
  { key: '+996', value: 'KG +996' },
  { key: '+254', value: 'KE +254' },
  { key: '+211', value: 'SS +211' },
  { key: '+597', value: 'SR +597' },
  { key: '+686', value: 'KI +686' },
  { key: '+855', value: 'KH +855' },
  { key: '+1869', value: 'KN +1-869' },
  { key: '+269', value: 'KM +269' },
  { key: '+239', value: 'ST +239' },
  { key: '+421', value: 'SK +421' },
  { key: '+82', value: 'KR +82' },
  { key: '+386', value: 'SI +386' },
  { key: '+850', value: 'KP +850' },
  { key: '+965', value: 'KW +965' },
  { key: '+221', value: 'SN +221' },
  { key: '+378', value: 'SM +378' },
  { key: '+232', value: 'SL +232' },
  { key: '+248', value: 'SC +248' },
  { key: '+7', value: 'KZ +7' },
  { key: '+1345', value: 'KY +1-345' },
  { key: '+65', value: 'SG +65' },
  { key: '+46', value: 'SE +46' },
  { key: '+249', value: 'SD +249' },
  // { key: '+1809 and 1-829', value: 'DO +1-809 and 1-829' },
  { key: '+1767', value: 'DM +1-767' },
  { key: '+253', value: 'DJ +253' },
  { key: '+45', value: 'DK +45' },
  { key: '+1284', value: 'VG +1-284' },
  { key: '+49', value: 'DE +49' },
  { key: '+967', value: 'YE +967' },
  { key: '+213', value: 'DZ +213' },
  { key: '+1', value: 'US +1' },
  { key: '+598', value: 'UY +598' },
  { key: '+262', value: 'YT +262' },
  { key: '+1', value: 'UM +1' },
  { key: '+961', value: 'LB +961' },
  { key: '+1758', value: 'LC +1-758' },
  { key: '+856', value: 'LA +856' },
  { key: '+688', value: 'TV +688' },
  { key: '+886', value: 'TW +886' },
  { key: '+1868', value: 'TT +1-868' },
  { key: '+90', value: 'TR +90' },
  { key: '+94', value: 'LK +94' },
  { key: '+423', value: 'LI +423' },
  { key: '+371', value: 'LV +371' },
  { key: '+676', value: 'TO +676' },
  { key: '+370', value: 'LT +370' },
  { key: '+352', value: 'LU +352' },
  { key: '+231', value: 'LR +231' },
  { key: '+266', value: 'LS +266' },
  { key: '+66', value: 'TH +66' },
  // { key: '+', value: 'TF +' },
  { key: '+228', value: 'TG +228' },
  { key: '+235', value: 'TD +235' },
  { key: '+1649', value: 'TC +1-649' },
  { key: '+218', value: 'LY +218' },
  { key: '+379', value: 'VA +379' },
  { key: '+1784', value: 'VC +1-784' },
  { key: '+971', value: 'AE +971' },
  { key: '+376', value: 'AD +376' },
  { key: '+1268', value: 'AG +1-268' },
  { key: '+93', value: 'AF +93' },
  { key: '+1264', value: 'AI +1-264' },
  { key: '+1340', value: 'VI +1-340' },
  { key: '+354', value: 'IS +354' },
  { key: '+98', value: 'IR +98' },
  { key: '+374', value: 'AM +374' },
  { key: '+355', value: 'AL +355' },
  { key: '+244', value: 'AO +244' },
  // { key: '+', value: 'AQ +' },
  { key: '+1684', value: 'AS +1-684' },
  { key: '+54', value: 'AR +54' },
  { key: '+61', value: 'AU +61' },
  { key: '+43', value: 'AT +43' },
  { key: '+297', value: 'AW +297' },
  { key: '+91', value: 'IN +91' },
  { key: '+35818', value: 'AX +358-18' },
  { key: '+994', value: 'AZ +994' },
  { key: '+353', value: 'IE +353' },
  { key: '+62', value: 'ID +62' },
  { key: '+380', value: 'UA +380' },
  { key: '+974', value: 'QA +974' },
  { key: '+258', value: 'MZ +258' }
];
