import { Injectable } from '@angular/core';
import { COUNTRIES, LOCALES } from '@wefoxGroupOneBPCore/constants';
import { FooterConfigOptions, HeaderConfigOptions } from '@wefoxGroupOneBPCore/interfaces';
import { CMSQuery, CommonQuery } from '@wefoxGroupOneBPCore/queries';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CentralizedQuery {
  public LEGACY_QUERY = 'LEGACY_QUERY';
  public CMS_QUERY = 'CMS_QUERY';
  private activeQuery: CommonQuery | CMSQuery;

  constructor(private _commonQuery: CommonQuery, private _cmsQuery: CMSQuery) {
    this.activeQuery = this._commonQuery;
  }

  public setActiveQuery(query: string) {
    if (query === this.LEGACY_QUERY) {
      this.activeQuery = this._commonQuery;
    } else {
      this.activeQuery = this._cmsQuery;
    }
  }

  public getAllowedRoutes(isLoggedIn: boolean): string[] {
    return [...this.activeQuery.getAllowedRoutes(isLoggedIn), 'appointment-scheduler'];
  }

  public getCountry(): COUNTRIES {
    return this.activeQuery.getCountry();
  }

  public getCountryCode(): string {
    return this.activeQuery.getCountryCode();
  }

  public getCountryPrefix(): string {
    return `/${this.getCountry().toLowerCase()}`;
  }

  public getHeaderOptions(): Partial<HeaderConfigOptions> {
    return this.activeQuery.getHeaderOptions();
  }

  public getFooterOptions(): FooterConfigOptions {
    return this.activeQuery.getFooterOptions();
  }

  public getLocales(): Observable<LOCALES[]> {
    return this.activeQuery.getLocales();
  }

  public getLanguages(): LOCALES[] {
    return this.activeQuery.getLanguages();
  }

  public getRegistrationLink(): string | null {
    return this.activeQuery.getRegistrationLink();
  }
}
