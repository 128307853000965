import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';

export interface BirthdateInputAdapterComponentOptions {
  control_name: string;
  format?: string; // Default is 'DD.MM.YYYY'
  i18n_prefix_key: string;
  label: string;
  mask?: string; // Default is 'date'
  max_age: number;
  min_age: number;
  placeholder?: string; // Default is '_PROD_date_placeholder'
  required: boolean;
}
@Component({
  selector: 'one-birthdate-input-adapter',
  templateUrl: './birthdate-input-adapter.component.html'
})
export class BirthdateInputAdapterComponent implements OnInit {
  public age: { greaterThan: Date; lessThan: Date };
  @Input() public content_data: Partial<BirthdateInputAdapterComponentOptions>;
  @Input() public parentGroup: UntypedFormGroup;

  public ngOnInit(): void {
    this.age = {
      greaterThan: dayjs().startOf('day').subtract(this.content_data.max_age, 'year').add(1, 'day').toDate(),
      lessThan: dayjs().subtract(this.content_data.min_age, 'year').toDate()
    };
  }
}
