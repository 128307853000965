import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { CurrencyInputValidator, CurrencyRangeParams } from '@wefoxGroupOneBPShared/validators/currency-input.validator';
import { LicensePlateValidators } from '@wefoxGroupOneBPShared/validators/licence-plate-prefix.validator';
import { ValidatorService } from 'angular-iban';

export interface ValidatorsOptions {
  is_custom?: boolean;
  param?: number | string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {
  /**
   *Builds the ValidatorFn array from the dynamic form model configurations
   * @param options { ValidatorsOptions[] }
   * @returns { Validators[] }
   */
  public getValidators(options: ValidatorsOptions[]): ValidatorFn[] {
    if (!options || !options?.length) {
      return [];
    }

    const validators = [];

    options.forEach(option => {
      const type = option.type;
      const param = option.param;
      const isCustom = option.is_custom;

      if (isCustom) {
        this._addCustomValidators(type, validators, param);
      } else {
        if (param || param === 0) {
          // This is needed to push the pattern with the back slash \d
          if (param === '/^(?!01000|99999)(0[1-9]d{3}|[1-9]d{4})$/') {
            validators.push(Validators[type](ProductValidations.postalCodePattern));
            return;
          }
          validators.push(Validators[type](param));
        } else {
          validators.push(Validators[type]);
        }
      }
    });

    return validators;
  }

  /**
   *
   * @param type { string } name of the custom validator
   * @param validators { ValidatorFn[] } the validator function array where the validators will be concatenated
   * @param param { number | string } Validators parameter
   */
  private _addCustomValidators(type: string, validators: ValidatorFn[], param?: number | string | object) {
    switch (type) {
      case 'currencyInputMax':
        validators.push(CurrencyInputValidator.max(param as number));
        break;
      case 'currencyInputMin':
        validators.push(CurrencyInputValidator.min(param as number));
        break;
      case 'currencyRange':
        validators.push(CurrencyInputValidator.currencyRange(param as CurrencyRangeParams));
        break;
      case 'validateIban':
        validators.push(ValidatorService.validateIban);
        break;
      case 'licensePlatePrefix':
        validators.push(LicensePlateValidators.licensePlatePrefix);
        break;
      default:
        break;
    }
  }
}
