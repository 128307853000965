import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ShortCoverage } from '@wefoxGroupOneBP/app/private/product/services/quotation/dtos/packages-info.dto';
import { getManagedGuarantees } from '@wefoxGroupOneBPShared/components/custom-section-build/adapters/utils/guarantees.utils';

@Component({
  selector: 'wg-price-card',
  templateUrl: './price-card.component.html'
})
export class PriceCardComponent implements OnInit {
  @Input() public parentGroup: UntypedFormGroup;
  public total_price = '0'
  public guarantees: ShortCoverage[] = [];

  ngOnInit(): void {
    const price = this.parentGroup.get('price').value;
    this.total_price = price.total_price;
    this.guarantees = getManagedGuarantees(price);
  }
}

