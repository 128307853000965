import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { COUNTRIES, LOCALES } from '@wefoxGroupOneBPCore/constants';
import { FooterConfigOptions, HeaderConfigOptions } from '@wefoxGroupOneBPCore/interfaces';
import { CMSConfig } from '@wefoxGroupOneBPCore/interfaces/cms-config.interface';
import { SidebarOptions } from '@wefoxGroupOneBPCore/interfaces/sidebar-options';
import { CMSStore } from '@wefoxGroupOneBPCore/stores/cms.store';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CMSQuery extends Query<Partial<CMSConfig>> {
  constructor(protected store: CMSStore) {
    super(store);
  }

  public getConfig() {
    return this.getValue();
  }

  public getAllowedRoutes(): string[] {
    return this.getValue()
      ?.routes.filter((route: { id?: string; value: string; status: string }) => route.status === 'Active')
      .map((route: { id?: string; value: string; status: string }) => route.value);
  }

  public getCountry(): COUNTRIES {
    return COUNTRIES.intl;
  }

  public getCountryCode(): string {
    return COUNTRIES.intl;
  }

  public getCountryPrefix(): string {
    return `/${this.getCountry().toLowerCase()}`;
  }

  public getHeaderOptions(): Partial<HeaderConfigOptions> {
    return { header_links: this.getValue().headerLinks };
  }

  public getFooterOptions(): FooterConfigOptions {
    return { footer_links: this.getValue().footerLinks };
  }

  public getSidebarOptions(): SidebarOptions {
    return { sidebar_links: this.getValue().sidebarLinks };
  }

  public getLocales(): Observable<LOCALES[]> {
    return this.select(state => state.supportedLanguages);
  }

  public getLanguages(): LOCALES[] {
    return this.getValue().supportedLanguages;
  }

  public getRegistrationLink(): string | null {
    return this.getValue().registrationFlowUrl;
  }
}
