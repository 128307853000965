import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { Observable } from 'rxjs';

@Injectable()
export class PortfolioTransferDataService extends BaseDataService {
    private _getPortfolioTransferEndpoint = 'portfolio/contracts';
    private _postPortfolioTransferEndpoint = 'portfolio/customers';

    constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
        super(httpClient, sessionQuery);
    }

    public getPortfolioTransfer$(
        page = 1,
        sort?: string,
        sortOrder?: string,
        size?: number,
        id?: string,
        customerName?: string,
        insurer?: string,
        status?: string
    ): Observable<AllListInfo> {
        const params = this.getURLParams({
            page,
            sort,
            sortOrder,
            size,
            id,
            customerName,
            insurer,
            status
        });
        const url = this.getUrl({
            path: `${this._getPortfolioTransferEndpoint}/transfer`
        });
        return this.get$(url, { params }) as Observable<any>; // eslint-disable-line
    }

    // eslint-disable-next-line
    public postPortfolioTransfer$(customerId: number, body: any, options?: RequestInit): Observable<any> {
        const url = this.getUrl({
            path: `${this._postPortfolioTransferEndpoint}/${customerId}/contract/transfer`
        });
        return this.post$(url, body, options);
    }

    // eslint-disable-next-line
    public createCustomerPortfolioTransfer(body: any, options?: RequestInit): Observable<any> {
        const url = this.getUrl({
            path: `${this._postPortfolioTransferEndpoint}/create/customer/transfer`
        });
        return this.post$(url, body, options);
    }
}