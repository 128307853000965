import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { AddressService } from '@wefoxGroupOneBPShared/services/address.service';
import { FormService } from '@wefoxGroupOneBPShared/services/form.service';
import { AddressControlNamesDefault, AddressOptions } from './address.component';

@Component({
  selector: 'one-address-clone',
  templateUrl: './address-clone.component.html'
})
export class AddressCloneComponent implements OnInit {
  public controlNames;
  public customLabels;
  @Input() public disabled: boolean;
  @Input() public i18nPrefixKey = '_ST_MOTOR_';
  @Input() public invalidAddress: boolean;
  public maxAddressLength: number;
  @Input() public options: AddressOptions;
  @Input() public parentGroup: UntypedFormGroup;

  private _defaultOptions: AddressOptions;

  constructor(
    private _addressService: AddressService,
    private _formService: FormService,
    protected _sessionQuery: SessionQuery,
  ) {
    this._defaultOptions = {
      controlNames: AddressControlNamesDefault
    };
  }

  public getInputLabel(inputName: string): string {
    if (this.customLabels) {
      return this.customLabels[inputName];
    }

    return `${this.i18nPrefixKey}${inputName === 'city' ? 'place' : inputName}`;
  }

  public ngOnInit(): void {
    this.options = { ...this._defaultOptions, ...this.options };
    this.maxAddressLength = this._formService.getCombinedAddressLength();
    this.controlNames = this.options.control_names ? this.options.control_names : this.options.controlNames;
    this.customLabels = this.options.custom_labels;
    this._addressService.monitorStreetInputChanges(this.parentGroup, this.controlNames);
    this._addressService.monitorAddressChanges(this.parentGroup, this.controlNames);
    this._addressService.fillCityFromPostalCode(this.parentGroup, this.controlNames);
  }

  // eslint-disable-next-line
  public onStreetInputFocus(event): void {
    this._addressService.selectInputText(event.target);
  }
}
