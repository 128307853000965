import { Injectable } from '@angular/core';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SidebarOptions } from '@wefoxGroupOneBPCore/interfaces/sidebar-options';
import { CMSQuery } from '@wefoxGroupOneBPCore/queries/cms.query';
import { ConfigQuery } from '@wefoxGroupOneBPCore/queries/config.query';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigDataService } from './config-data.service';

export const EditCardType = {
  customer: 'customer'
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(
    private _configDataService: ConfigDataService,
    private _configQuery: ConfigQuery,
    private _sessionQuery: SessionQuery,
    private _cmsQuery: CMSQuery
  ) { }

  // eslint-disable-next-line
  public getConfigFromStoreOrAPI$(): any {
    if (this._sessionQuery.getCountry() === COUNTRIES.intl) {
      return this._cmsQuery.getConfig();
    }
    const config = this._configQuery.getPrivateConfig();

    if (!config) {
      this._configDataService.getMasterConfigData$(this._sessionQuery.getCountry()).subscribe(data => {
        return data;
      });
    } else {
      return config;
    }
  }

  public getSidebarConfig$(): Observable<SidebarOptions> {
    const country = this._sessionQuery.getCountry();
    if (country === COUNTRIES.intl) {
      return of(this._cmsQuery.getSidebarOptions());
    } else {
      return this._configDataService.getMasterConfigData$(country).pipe(map(data => data.config.priv.sidebar_options))
    }
  }
}
