// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
  min-width: 250px;
}

.filters-container {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 24px;
  width: 100%;
}

::part(comet-filter-container) {
  width: 100%;
}

::part(comet-filter-options-container) {
  left: unset;
}

.loading-container {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.loading-container .card {
  height: 173px;
  margin-bottom: 16px;
  min-width: 300px;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/carriers/components/third-party-product-selection/third-party-product-selection.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;EACA,qCAAA;EACA,mBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,4DAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AACJ","sourcesContent":[".filters {\n  min-width: 250px;\n}\n\n.filters-container {\n  display: grid;\n  grid-gap: 8px;\n  grid-template-columns: repeat(3, 1fr);\n  margin-bottom: 24px;\n  width: 100%;\n}\n\n::part(comet-filter-container) {\n  width: 100%;\n}\n\n::part(comet-filter-options-container) {\n  left: unset;\n}\n\n.loading-container {\n  display: grid;\n  grid-gap: 24px;\n  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n\n  .card {\n    height: 173px;\n    margin-bottom: 16px;\n    min-width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
