import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup
} from '@angular/forms';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { WgToasterService } from '@wefoxGroupOneBPCore/services/wg-toaster.service';
import { WgErrorComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-error/wg-error.component';
import { FileUploadOptions } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-file-upload/wg-file.interfaces';

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: WgFileUploadComponent
    }
  ],
  selector: 'wg-file-upload',
  styleUrls: ['./wg-file-upload.component.scss'],
  templateUrl: './wg-file-upload.component.html'
})
export class WgFileUploadComponent implements ControlValueAccessor, AfterContentInit, AfterViewInit {
  @Input() set fileUploadOptions(fileOptions: FileUploadOptions) {
    this.options = { ...this._defaultOptions, ...fileOptions };
  }
  public get getUploadOptions(): FileUploadOptions {
    return this.options;
  }
  @Input() public controlName: string;
  @Input() public currentFiles: File[];
  public inputControl: AbstractControl;
  @Input() public isRequired = false;
  public options: FileUploadOptions;
  @Input() public parentGroup: UntypedFormGroup;
  @Output() public selected = new EventEmitter<File[]>();
  public selectedFiles = [];

  @ContentChildren(WgErrorComponent) private _contentErrors: QueryList<WgErrorComponent>;
  private _defaultOptions: FileUploadOptions = {
    allowedFileTypes: null,
    disabled: null,
    inputFieldText: {
      label: undefined,
      secondLabel: undefined,
      subText: undefined
    },
    limitErrorMessage: undefined,
    maximumUploadLimit: 10,
    maximumUploadSize: 75, // NOTE: Same what we have on aws.service.ts
    multipleFiles: null,
    singleSizeErrorMessage: undefined,
    sizeErrorMessage: undefined,
    fileMaxSize: 10
  };
  private _errorMap: { [key: string]: string };

  constructor(
    private _host: ElementRef<HTMLInputElement>,
    private _controlContainer: FormGroupDirective,
    private _toaster: WgToasterService
  ) {}

  public addFiles(event: File[]): void {
    // Checking the allowed files limit
    if (this.selectedFiles.length + event?.length > this.getUploadOptions.maximumUploadLimit) {
      this._setLimitError();
      return;
    }

    const differentFiles = {};
    const selectedArrays = Array.from<File>([...this.selectedFiles, ...event])
      .filter(({ name }) => (differentFiles[name] ? false : !!(differentFiles[name] = true)))
      // Checking the file type and size
      .filter(file => this.checkAllowedFile(file, 'type') && this.checkAllowedFile(file, 'size'));

    // Filtering the selected files and checking their combined weihgt
    this.selectedFiles = [...selectedArrays];
    this._updateFile(this.selectedFiles);
    this._checkUploadSize();
  }

  public checkAllowedFile(file: File, checkAs: 'type' | 'size'): boolean {
    switch (checkAs) {
      case 'type':
        return this.getUploadOptions.allowedFileTypes.includes(file.type);
      case 'size':
        const value = !!(file.size <= this.getUploadOptions.fileMaxSize * 1024 * 1024);

        if (!value) {
          this.parentGroup.get('file').setValue(null);
          this._toaster.show({
            title: `${this.getUploadOptions.singleSizeErrorMessage}`,
            type: ToasterType.error,
            lifeTime: UxIntervalTimes.slower
          });
        }

        return value;
      default:
        return false;
    }
  }

  @HostListener('change', ['$event.target.files'])
  public emitFiles(event: File[]): void {
    this.addFiles(event);
  }

  public getButtonDisabledState(): string | null {
    const a =
      this.inputControl?.disabled ||
      this.options.disabled ||
      this.selectedFiles.length === this.getUploadOptions.maximumUploadLimit;
    return a ? 'true' : null;
  }

  public getFilesDisabledState(): string | null {
    const a = this.inputControl?.disabled || this.options.disabled;
    return a ? 'true' : null;
  }

  public getCurrentError(): { className: string; errorMessage: string } {
    this._contentErrors.notifyOnChanges();
    if (this._errorMap) {
      if (!this.inputControl.errors || (this.inputControl.untouched && !this._controlContainer.submitted)) {
        return { className: '-valid', errorMessage: '' };
      }
      return { className: '-invalid', errorMessage: this._errorMap[Object.keys(this.inputControl.errors)[0]] };
    }

    return { className: '-valid', errorMessage: '' };
  }

  public ngAfterContentInit(): void {
    this.parentGroup.controls['file'].statusChanges.subscribe(() => {
      if (!this.parentGroup.controls['file'].valid && this.parentGroup.controls['file'].hasError('virus')) {
        this.onRemoveFile(this.parentGroup.controls['file'].value);
      }
    });
    this.inputControl = this._controlContainer.control.get(this.controlName);

    setTimeout(() => {
      if (this.currentFiles) {
        this.addFiles(this.currentFiles);
      }
    }, 0);
  }

  public ngAfterViewInit(): void {
    this._contentErrors.changes.subscribe(res => {
      if (res) {
        const contentErrors = this._contentErrors.reduce((acc, error) => {
          return { ...acc, ...{ [error.key]: error.value } };
        }, {});
        this._errorMap = { ...this._errorMap, ...contentErrors };
      }
    });

    if (this.currentFiles) {
      this.addFiles(this.currentFiles);
    }
  }

  // eslint-disable-next-line
  public onAddFileClick(event): void {
    event.target.value = null;
  }

  // eslint-disable-next-line
  public onChange = () => {
    return {};
  };
  public onRemoveFile(item: File): void {
    if (this.options.disabled) {
      return;
    }
    this.selectedFiles.splice(this.selectedFiles.indexOf(!!item['value'] ? item['value'] : item), 1);
    this.parentGroup.get('file').setValue(null);
    this._updateFile(this.selectedFiles);
    this._checkUploadSize();

    if (this.selectedFiles?.length === 0 && this.isRequired) {
      this.inputControl.setErrors({ required: true });
    }
  }

  // eslint-disable-next-line
  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(): void {
    return;
  }

  public writeValue(): void {
    this._host.nativeElement.value = '';
  }

  private _checkUploadSize(): void {
    const totalFileSize = this.selectedFiles.reduce((size, file) => size + file.size, 0);
    const maxUploadSize = totalFileSize >= this.getUploadOptions.maximumUploadSize * 1024 * 1024;

    if (maxUploadSize) {
      this.inputControl.setErrors({
        errorMessage: `${this.getUploadOptions.sizeErrorMessage}`
      });
      return;
    } else {
      this.inputControl.setErrors(null);
    }
  }

  private _setLimitError(): void {
    this.inputControl.setErrors({
      errorMessage: `${this.getUploadOptions.limitErrorMessage} ${this.getUploadOptions.maximumUploadLimit}`
    });
  }

  private _updateFile(event?: File[]): void {
    this.selected.emit(event);
  }
}
