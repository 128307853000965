import { Injectable } from '@angular/core';

@Injectable()
export class DynamicOperationService {

  public solve(operation: string, values: { [key: string]: number | boolean | string }): boolean {
    let variables = "";
    Object.keys(values).forEach(key => {
      variables = variables.concat(`const $${key} = ${JSON.stringify(values[key])};\n`)
    });

    const value = Function(variables.concat(`return  ${operation}`))();
    return value;
  }
}
