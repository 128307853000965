// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* TODO refactor to use modal component */
.cancel-policy {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2000;
}
.cancel-policy::after {
  background-color: rgba(0, 0, 0, 0.5);
  content: "";
  height: 100%;
  width: 100%;
  z-index: 1;
}

.cancel-policy-dialog {
  min-height: 680px;
  min-width: 800px;
  z-index: 3;
}`, "",{"version":3,"sources":["webpack://./apps/broker-portal-web/src/app/private/modules/contracts/components/cancel-policy/cancel-policy.component.scss"],"names":[],"mappings":"AAAA,yCAAA;AACA;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,QAAA;EACA,MAAA;EACA,WAAA;EACA,aAAA;AACF;AACE;EACE,oCAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;AACJ;;AAGA;EACE,iBAAA;EACA,gBAAA;EACA,UAAA;AAAF","sourcesContent":["/* TODO refactor to use modal component */\n.cancel-policy {\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  position: fixed;\n  right: 0;\n  top: 0;\n  width: 100%;\n  z-index: 2000;\n\n  &::after {\n    background-color: rgb(0 0 0 / 50%);\n    content: '';\n    height: 100%;\n    width: 100%;\n    z-index: 1;\n  }\n}\n\n.cancel-policy-dialog {\n  min-height: 680px;\n  min-width: 800px;\n  z-index: 3;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
