import { Component, Input } from '@angular/core';

export interface CommissionCardOptions {
  title: string;
  value: number;
  percentage: string;
  date: string;
}

@Component({
  selector: 'one-commission-card',
  templateUrl: './commission-card.component.html',
  styleUrls: ['./commission-card.component.scss']
})
export class CommissionCardComponent {
  @Input() public cardOptions: CommissionCardOptions;
  @Input() public bgColor = 'bg-neutral-start';
  @Input() public fontColor = 'color-neutral-start';
}
