import { Directive, ElementRef, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[wgTrimWhitespace]'
})
export class TrimWhiteSpaceDirective {
  constructor(private _el: ElementRef, @Optional() private _ngControl: NgControl) {}

  @HostListener('blur') public onBlur(): void {
    this._ngControl.control.setValue(this._el.nativeElement.value.trim());
  }
}
