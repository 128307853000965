// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-title {
  color: #575655;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.footer-subtitle {
  color: #242424;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.btn-subtle {
  background: linear-gradient(to right, var(--secondary-base), var(--secondary-base)), linear-gradient(to right, var(--color-transparent), var(--color-transparent));
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 2px, 0 2px;
  margin-bottom: -8px;
  transition: background-size 0.3s ease;
}

.btn-subtle:hover {
  background-size: 0 2px, 100% 2px;
}`, "",{"version":3,"sources":["webpack://./libs/feature/risk-analysis/src/lib/components/risk-profile-customer-card/risk-profile-customer-card.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EAEI,kKAAA;EACA,sCAAA;EACA,4BAAA;EACA,gCAAA;EACA,mBAAA;EACA,qCAAA;AACJ;;AAEA;EACI,gCAAA;AACJ","sourcesContent":[".footer-title {\n    color: #575655;\n    font-size: 14px;\n    font-weight: 400;\n    line-height: 24px;\n}\n\n.footer-subtitle {\n    color: #242424;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px;\n}\n\n.btn-subtle {\n    background: -webkit-gradient(linear,left top, right top,from(var(--secondary-base)),to(var(--secondary-base))),-webkit-gradient(linear,left top, right top,from(var(--color-transparent)),to(var(--color-transparent)));\n    background: linear-gradient(to right,var(--secondary-base),var(--secondary-base)),linear-gradient(to right,var(--color-transparent),var(--color-transparent));\n    background-position: 100% 100%,0 100%;\n    background-repeat: no-repeat;\n    background-size: 100% 2px,0 2px;\n    margin-bottom: -8px;\n    transition: background-size .3s ease;\n}\n\n.btn-subtle:hover {\n    background-size: 0 2px,100% 2px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
