import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'IbanTransform'
})
export class IbanTransform implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return;
    }

    return value
      .toString()
      .match(/.{1,4}/g)
      .join(' ')
      .toUpperCase();
  }
}
