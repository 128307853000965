// ng-barrel

export * from '../../../../../apps/broker-portal-web/src/app/private/modules/auth/authentication.module';
export * from './wg-input/wg-input.module';
export * from './wg-modal/wg-modal.module';
export * from './wg-paginator/wg-paginator.module';
export * from './wg-table/wg-table.module';
export * from './wg-table2/wg-table2.module';
export * from './wg-toaster/wg-toaster.module';
export * from './wg-tooltip/wg-tooltip.module';
export * from './wg-ui-icon/wg-ui-icon.module';
