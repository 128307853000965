import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { WgFileUploadComponent } from '@wefoxGroupOneBPShared/modules/wg-input/components';

@Component({
  selector: 'one-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements AfterViewInit {
  protected _fileMaxSize = 10;
  protected _maximumUploadLimit = 10;
  protected _multipleFiles = true;
  @Input() public allowedFileTypes = ProductValidations.allowedFileTypes;
  @Input() public disabled = false;
  @Input() public = true;
  @Input() set fileMaxSize(fileMaxSize: number) {
    this._fileMaxSize = fileMaxSize ?? 10;
  }
  @Input() set maximumUploadLimit(maximumUploadLimit: number) {
    this._maximumUploadLimit = maximumUploadLimit ?? 10;
  }
  @Input() set multipleFiles(multipleFiles: boolean) {
    this._multipleFiles = multipleFiles ?? true;
  }
  @Input() public maximumUploadSize = 75;
  @Input() public isRequired: boolean;
  @Input() public parentGroup: UntypedFormGroup;
  @Output() public selectedFiles = new EventEmitter();
  @Input() public tempFiles: File[];
  @ViewChild(WgFileUploadComponent)
  public wgFileUpload: WgFileUploadComponent;

  // eslint-disable-next-line
  public emitFilePath(event: any): void {
    this.selectedFiles.emit(event);
  }

  public ngAfterViewInit(): void {
    if (this.isRequired && !this.tempFiles?.length) {
      setTimeout(() => {
        this.parentGroup.get('file').setErrors({ required: true });
      }, 200);
    }
  }
}
