import { Component, Input } from '@angular/core';

@Component({
  selector: 'wg-risk-product-card',
  templateUrl: './risk-product-card.component.html',
  styleUrls: ['./risk-product-card.component.scss']
})
export class RiskProductCardComponent {

  @Input() public isSmallCard = false;
  @Input() public product: string;
  @Input() public hasBorder: boolean;
  @Input() public icon: string;
  @Input() public package: { type: string, name: string };

}
