import { FormControlItem } from '@wefoxGroupOneBPShared/constants';
import { RadioModelConfig } from '@wefoxGroupOneBPCore/interfaces/radio-option.interface';

export const PolicyHolderToggleInputs = {
  fieldsToHide: [FormControlItem.firstname.name, FormControlItem.lastname.name],
  fieldsToShow: [FormControlItem.legalName.name]
};

export const CancellationLetterOptions: Partial<RadioModelConfig> = {
  control_name: 'cancellation_letter_checked',
  radio_options: [
    {
      checked: true,
      label: '_PROD_ST_cancellation_letter_yes',
      value: 'yes'
    },
    {
      label: '_PROD_ST_cancellation_letter_no',
      value: 'no'
    }
  ]
};

export const CoverageOptions: Partial<RadioModelConfig> = {
  control_name: 'coverage_type',
  radio_options: [
    {
      checked: true,
      label: '_NT_MOTOR_liability',
      value: 'vehicle_liability_deductible'
    },
    {
      label: '_NT_MOTOR_partial_comprehensive',
      value: 'partial_comprehensive_deductible'
    },
    {
      label: '_NT_MOTOR_fully_comprehensive',
      value: 'fully_comprehensive_deductible'
    }
  ]
};

export const CustomerTypeOptions: Partial<RadioModelConfig> = {
  control_name: 'policy_holder',
  modifiers: {
    grid: 'g-none g-tablet-large cols-2',
    label: 'mt-none'
  },
  radio_options: [
    {
      checked: true,
      label: '_GEN_private_person',
      value: 'privatePerson'
    },
    {
      label: '_GEN_legal_entity',
      value: 'legalEntity'
    }
  ]
};

export const PKWSearchCategories: Partial<RadioModelConfig> = {
  control_name: 'search_by',
  group_label: {
    text: '_ST_MOTOR_vehicle_search'
  },
  modifiers: {
    grid: 'g-none g-tablet-large cols-tablet-2',
    label: 'mt-tablet-none'
  },
  radio_options: [
    {
      checked: true,
      label: '_ST_MOTOR_vehicle_search_typenr',
      tooltip: {
        text: '_ST_MOTOR_B2C_CH_typenr_tooltip'
      },
      value: 'typenr'
    },
    {
      label: '_ST_MOTOR_vehicle_search_brand',
      value: 'brand'
    }
  ]
};
