import { Injectable } from '@angular/core';
import { BreadcrumbConfig } from '@wefoxGroupOneBPShared/interfaces/breadcrumb.interface';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private breadcrumbConfigSubject: BehaviorSubject<BreadcrumbConfig> = new BehaviorSubject({ breadcrumbs: [] });
  public readonly breadcrumbConfig: Observable<BreadcrumbConfig> = this.breadcrumbConfigSubject.asObservable();

  public resetBreadcrumbConfig(): void {
    this.setBreadcrumbConfig({ breadcrumbs: [] });
  }

  public setBreadcrumbConfig(breadcrumbConfig: BreadcrumbConfig): void {
    this.breadcrumbConfigSubject.next(breadcrumbConfig);
  }
}
