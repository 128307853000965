import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import { CarrierCardOption } from '../interfaces/third-party-products.interface';

export const mapProductsIntoCarrierOptions = (data: AllListInfo): CarrierCardOption[] => {
  return data.content.map(item => ({
    carrier_name: item.insurance_carrier.name,
    carrier_id: item.insurance_carrier.id,
    carrier_logo_icon: item.insurance_carrier.logo_icon,
    product_group_name: item.product_group.name?.toString().toLowerCase(),
    product_group_id: item.product_group.id,
    product_group_logo_icon: item.product_group.logo_icon,
    total_products: item.total_products
  }));
};
