// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  position: relative;
}

.d-flex > div {
  width: 100%;
}

.language-wrapper {
  max-width: calc(33.3333333333% - 3rem);
}

#loading-content {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.blur {
  filter: blur(1px) opacity(0.3);
}

.optional-files-container {
  margin-top: var(--huge);
}
.optional-files-container .optional-files-grid {
  align-items: center;
  display: grid;
  gap: var(--medium);
  grid-template-columns: repeat(5, 1fr);
  margin-top: var(--medium);
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/modules/wg-dynamic-components/inputs/isurance-company/insurance-company-adapter.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA;EACE,sCAAA;AACF;;AAEA;EACE,YAAA;EACA,OAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAEA;EACE,uBAAA;AACF;AACE;EACE,mBAAA;EACA,aAAA;EACA,kBAAA;EACA,qCAAA;EACA,yBAAA;AACJ","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  position: relative;\n}\n\n.d-flex > div {\n  width: 100%;\n}\n\n.language-wrapper {\n  max-width: calc((100% / 3 - 3rem));\n}\n\n#loading-content {\n  height: 100%;\n  left: 0;\n  position: absolute;\n  top: 0;\n  width: 100%;\n}\n\n.blur {\n  filter: blur(1px) opacity(0.3);\n}\n\n.optional-files-container {\n  margin-top: var(--huge);\n\n  .optional-files-grid {\n    align-items: center;\n    display: grid;\n    gap: var(--medium);\n    grid-template-columns: repeat(5, 1fr);\n    margin-top: var(--medium);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
