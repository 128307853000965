import { Component, Input } from '@angular/core';
import { WgIconSize } from '@wefoxGroupOneBPShared/modules/wg-ui-icon/wg-ui-icon-constants';

export interface TooltipAdapterComponentOptions {
  tooltip_content?: string;
  label?: string;
  control_name?: string;
  icon?: {
    name?: string;
    size?: string;
    color?: WgIconSize;
  };
}

@Component({
  selector: 'one-tooltip-adapter',
  templateUrl: './tooltip-adapter.component.html',
  styles: `
    .icon-wrapper {
        display: flex;
        gap: var(--small);
        align-items: center;
    }
  `
})
export class TooltipAdapterComponent {
  @Input() public content_data: TooltipAdapterComponentOptions; // eslint-disable-line
}
