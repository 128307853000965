import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StepModel, StepService } from '@wefoxGroupOneBPShared/services/step.service';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export const StepState = {
  active: 'feedback-success',
  complete: 'step-done',
  error: 'feedback-danger',
  pending: 'step-next'
};
@Component({
  selector: 'wg-steps',
  styleUrls: ['./steps.component.scss'],
  templateUrl: './steps.component.html'
})
export class StepsComponent implements OnInit {
  @Output() public stepClick: EventEmitter<{ current: StepModel; target: StepModel }> = new EventEmitter();
  public vm$: any; // eslint-disable-line
  constructor(private stepService: StepService) {}

  public getIconStatus(currentStep: StepModel, step: StepModel): string {
    return currentStep.stepIndex === step.stepIndex ? StepState.active : step.status;
  }
  // eslint-disable-next-line
  public getPrimaryColorByStatus(currentStep, step): string {
    const status = currentStep.stepIndex === step.stepIndex ? StepState.active : step.status;
    switch (status) {
      case StepState.pending:
        return 'neutral-base';
      case StepState.error:
        return 'danger-base';
      case StepState.complete:
        return 'primary-base';
      case StepState.active:
        return 'primary-base';
      default:
        return 'primary-base';
    }
  }

  public ngOnInit(): void {
    const steps$ = this.stepService.getSteps();
    const currentStep$ = this.stepService.getCurrentStep();
    this.vm$ = combineLatest([currentStep$, steps$]).pipe(
      map(([currentStep, steps]) => ({ currentStep, steps }))
    );
  }

  public onStepClick(step: StepModel, stepForm: StepModel): void {
    if (step.status === StepState.pending || step.navigationDisabled) {
      return;
    }

    this.stepClick.emit({ current: stepForm, target: step });
  }
}
