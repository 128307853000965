import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedPrivateModule } from '@wefoxGroupOneBPPrivate/modules/shared-private/shared-private.module';
import { CustomSectionBuildModule } from '@wefoxGroupOneBPShared/components/custom-section-build/custom-section-build.module';
import { WgDynamicComponentsModule } from '@wefoxGroupOneBPShared/modules/wg-dynamic-components/wg-dynamic-components.module';
import { SharedModule } from 'libs/shared/src/lib/shared.module';
import { ProductRecommendationsComponent } from './components/product-recommendations/product-recommendations.component';
import { RiskAreaCardComponent } from './components/risk-area-card/risk-area-card.component';
import { RiskProductCardComponent } from './components/risk-product-card/risk-product-card.component';
import { RiskProfileCustomerCardComponent } from './components/risk-profile-customer-card/risk-profile-customer-card.component';
import { RiskProfileComponent } from './components/risk-profile/risk-profile.component';
import { ShareQuestionsModalComponent } from './components/share-questions-modal/share-questions-modal.component';
import { RiskAnalysisRoutingModule } from './risk-analysis-routing.module';
import { RiskAnalysisComponent } from './risk-analysis.component';
import { AIProductRecommendationCardComponent } from './components/ai-product-recommendation-card/ai-product-recommendation-card.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [
    RiskAnalysisComponent,
    RiskProfileComponent,
    ProductRecommendationsComponent,
    RiskProfileCustomerCardComponent,
    RiskAreaCardComponent,
    RiskProductCardComponent,
    AIProductRecommendationCardComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    SharedModule,
    SharedPrivateModule,
    RiskAnalysisRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ShareQuestionsModalComponent,
    WgDynamicComponentsModule,
    CustomSectionBuildModule,
    NzDrawerModule,
    TranslocoModule
  ],
  exports: [
    RiskAnalysisComponent,
    RiskProfileComponent,
    ProductRecommendationsComponent,
    RiskProfileCustomerCardComponent,
    AIProductRecommendationCardComponent
  ],
  providers: [RiskProfileCustomerCardComponent]
})
export class RiskAnalysisModule {}
