export const Icons = {
  circleCloseIcon: 'circle-close-icon' as const,
  circleTick: 'circle-tick' as const,
  household: 'household' as const,
  liability: 'liability' as const,
  motorInsurance: 'motor-insurance' as const,
  support: 'support' as const,
  tick: 'tick' as const
};

export type Icons = (typeof Icons)[keyof typeof Icons];
