import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

interface CopilotData {

    customerId?: string;
    contextType?: string;
    customerName?: string;
    customerType?: string;
    
}

@Injectable({
    providedIn: 'root'
})
export class CopilotService {

    copilotData$: BehaviorSubject<CopilotData> = new BehaviorSubject<CopilotData>({customerId: undefined, contextType: undefined, customerName: undefined, customerType: undefined});

    setCopilotData( copilotData: CopilotData ): void {

        this.copilotData$.next( copilotData );

    }

    getCopilotData$(): Observable<CopilotData> {

        return this.copilotData$.asObservable();

    }

}