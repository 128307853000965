// ng-barrel

import { ClickOutsideDirective } from './click-outside.directive';
import { DisableControlDirective } from './disable-control.directive';
import { NumericTransformDirective } from './numeric-transform.directive';
import { TrimWhiteSpaceDirective } from './trim-whitespace.directive';
import { WgFormGroupDirective } from './wg-form-group.directive';
import { WgPreventDoubleClickDirective } from './wg-prevent-double-click.directive';

export const sharedDirectives = [
  ClickOutsideDirective,
  NumericTransformDirective,
  TrimWhiteSpaceDirective,
  WgFormGroupDirective,
  WgPreventDoubleClickDirective,
  DisableControlDirective
];

export * from './click-outside.directive';
export * from './disable-control.directive';
export * from './numeric-transform.directive';
export * from './trim-whitespace.directive';
export * from './wg-form-group.directive';
export * from './wg-prevent-double-click.directive';
