import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { AWSService } from '@wefoxGroupOneBPCore/services';
import { BaseDataService } from '@wefoxGroupOneBPCore/services/base-data.service';
import { PreviousInsurer } from '@wefoxGroupOneBPPrivate/product/interfaces';
import { OcrData } from '@wefoxGroupOneBPCore/interfaces/ocr.interface';
import {
  SwitchTariff,
  SwitchTariffCalculation
} from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import {
  CampaignCustomParams,
  CampaignMasterData
} from '@wefoxGroupOneBPPrivate/product/interfaces/campaign.interface';
import { OfferDocument } from '@wefoxGroupOneBPPrivate/product/interfaces/policy.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Product } from '@wefoxGroupOneBPCore/interfaces/product.interface';
import { TariffConclude, TariffConcludeResponse, ConcludeResponse } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import { DraftData } from '@wefoxGroupOneBPCore/interfaces/draft.interface';

@Injectable()
export class ProductDataService extends BaseDataService {
  private _basicAuthToken = environment.basicAuthToken;
  private _brandModelEndpoint = 'data-store/brand-model/product';
  private _campaignEndpoint = 'campaign';
  private _concludeContractEndpoint = 'switchTariff/conclude';
  private _countriesEndpoint = 'data-store/country/product';
  private _draftEndpoint = 'drafts';
  private _insuranceEndpoint = 'insurance/product';
  private _newCarRequestEndpoint = 'newtariff';
  private _offersEndpoint = 'offers';
  private _policiesEndpoint = 'policies';

  constructor(
    protected httpClient: HttpClient,
    protected sessionQuery: SessionQuery,
    private _awsS3: AWSService,
  ) {
    super(httpClient, sessionQuery);
  }

  public calculate$(
    contract: Partial<SwitchTariffCalculation> | Partial<SwitchTariffCalculation>,
    options?: RequestInit
    // eslint-disable-next-line
  ): Observable<Partial<any>> {
    const url = this.getUrl({
      path: `calculate/product/${contract.product_id}`
    });
    return this.post$(url, contract, options) as Observable<any>; // eslint-disable-line
  }

  public calculateMultiPackages$(
    contract: Partial<SwitchTariffCalculation>[] | Partial<SwitchTariffCalculation>,
    options?: RequestInit
    // eslint-disable-next-line
  ): Observable<Partial<any>> {
    const url = this.getUrl({
      path: `calculate/multi/product/${contract[0].product_id}`
    });

    return this.post$(url, contract, options) as Observable<any>; // eslint-disable-line
  }

  public conclude$(
    contract: Partial<TariffConclude>,
    productId: ID,
    brokerId: ID,
    options?: RequestInit
  ): Observable<Partial<TariffConcludeResponse>> {
    const url = this.getUrl({
      path: `conclude/broker/${brokerId}/product/${productId}`
    });
    return this.post$(url, contract, options) as Observable<Partial<TariffConcludeResponse>>;
  }

  public concludeContract$(
    contract: Partial<SwitchTariff>,
    options?: RequestInit
  ): Observable<Partial<ConcludeResponse>> {
    const url = this.getUrl({
      path: `${this._concludeContractEndpoint}`
    });
    return this.post$(url, contract, options).pipe(
      map(response => {
        return { concludeResponse: response };
      })
    ) as Observable<ConcludeResponse>;
  }

  // eslint-disable-next-line
  public downloadProductDocuments$(productId: string, version: string): Observable<any> {
    const url = this.getUrlWithParams({
      options: {
        productId: productId,
        productVersion: version
      },
      path: `product/zipped/documents`
    });

    return this.get$(url, { responseType: 'blob' as 'json' });
  }

  // eslint-disable-next-line
  public async getBrandAndModels$(productId: string, country: string, queryParams?: string): Promise<any> {
    const url = this.getUrl({
      path: `${this._brandModelEndpoint}/${productId}`
    });
    return await this.get$(`${url}?criteria=${queryParams}&country=${country}`).toPromise();
  }

  /**
   * Load campaign data
   * @param id Unique id for campaigns
   * @param queryParams Customizable parameters for partners
   */
  public getByCampaign$(id: string, queryParams?: CampaignCustomParams): Observable<CampaignMasterData> {
    const url = this.getUrlWithParams({
      options: queryParams,
      path: `${this._campaignEndpoint}/${id}/product`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Basic ${this._basicAuthToken}`
      }
    };

    return this.get$(url, options) as Observable<CampaignMasterData>;
  }

  public getCountriesList(productId: string): Observable<unknown> {
    const url = this.getUrl({
      path: `${this._countriesEndpoint}/${productId}`
    });

    return this.get$(url);
  }

  // eslint-disable-next-line
  public getMasterProductdata$(country: string): Observable<Product[]> {
    const { productDataUrl } = this.getMasterDataUrls(country);
    const masterDataSource$ = this.get$(productDataUrl);

    return masterDataSource$;
  }

  // eslint-disable-next-line
  public getOCRFieldsData$(uuid: string): Observable<any> {
    const url = this.getUrl({
      path: 'ocr/documents/' + uuid
    });
    return this.get$(url);
  }

  // eslint-disable-next-line
  public getOCRUuid$(data: Partial<OcrData>): Observable<any> {
    const url = this.getUrl({
      path: 'ocr/documents'
    });
    return this.post$(url, data, {});
  }

  // eslint-disable-next-line
  public getONEApiProducts$(productId: string): Observable<any> {
    const url = this.getUrl({
      path: `product/${productId}`
    });
    return this.get$(url);
  }

  // eslint-disable-next-line
  public getPolicies$(productId: string, externalId: string, token?): Observable<any> {
    const url = this.getUrl({
      path: `${this._policiesEndpoint}/${externalId}/product/${productId}`
    });

    if (token) {
      const { access_token } = token;
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      };
      return this.get$(url, options);
    } else {
      return this.get$(url);
    }
  }

  public getPreviousInsurerList$(
    productId: string,
    country: COUNTRIES,
    params: Partial<PreviousInsurer>,
    token: any // eslint-disable-line
    // eslint-disable-next-line
  ): Observable<any> {
    const defaultParams = {
      selling_motor: 'TRUE'
    };

    const url = this.getUrlWithParams({
      options: {
        data_country_code: country,
        ...defaultParams,
        ...params
      },
      path: `${this._insuranceEndpoint}/${productId}`,
      version: 'v1'
    });

    if (token) {
      const { access_token } = token;
      const options: RequestInit = {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      };
      return this.get$(url, options);
    } else {
      return this.get$(url);
    }
  }

  public offer$(
    contract: Partial<TariffConclude>,
    productId: ID,
    brokerId: ID,
    options?: RequestInit
  ): Observable<Partial<TariffConcludeResponse>> {
    const url = this.getUrl({
      path: `${this._offersEndpoint}/product/${productId}`
    });
    return this.post$(url, contract, options) as Observable<Partial<TariffConcludeResponse>>;
  }

  public offerDocument$(productId: string, id: string): Observable<OfferDocument> {
    const url = this.getUrl({
      path: `${this._offersEndpoint}/${id}/product/${productId}`
    });
    return this.get$(url) as Observable<OfferDocument>;
  }

  // eslint-disable-next-line
  public saveDraft(data: Partial<DraftData>, brokerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._draftEndpoint}/broker/${brokerId}`
    });

    return this.post$(url, data, {});
  }

  // eslint-disable-next-line
  public sendOCRMachineLearning$(data: any, uuid: string): Observable<any> {
    const url = this.getUrl({
      path: 'ocr/documents/' + uuid
    });
    return this.post$(url, data, {});
  }

  // eslint-disable-next-line
  public submitNewCarRequest$(data: any, brokerId: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._newCarRequestEndpoint}/${brokerId}`
    });
    return this.post$(url, data, {});
  }

  public uploadDocument$(
    pathName: string[],
    options: { [key: string]: File[] | string }
  ): Observable<Partial<ConcludeResponse>> {
    let total = 0;
    return new Observable(observer => {
      this._awsS3.upload(pathName, options).subscribe(
        () => {
          total++;
          if (total === options.file.length) {
            observer.next({ preConcludeSuccessfully: true });
            observer.complete();
          } else {
            observer.next(this._awsS3.uploadingProgress?.value);
          }
        },
        () => {
          observer.error({ error: { error_key: 'ERR_s3_upload_fail' } });
          observer.complete();
        }
      );
    });
  }

  // eslint-disable-next-line
  private _concatQueryParams(params: { key: string; value: any }[]): string {
    let urlParams = '';
    // eslint-disable-next-line
    params.forEach((param: { key: string; value: any }) => {
      urlParams = `${urlParams}&${param.key}=${param.value}`;
    });
    return urlParams;
  }

  // eslint-disable-next-line
  private _mapQueryParams(data: any): { key: string; value: any }[] {
    return Object.entries(data).map(([key, value]) => ({ key, value }));
  }
}
