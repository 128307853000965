import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-button-adapter',
  templateUrl: './button-adapter.component.html'
})
export class ButtonAdapterComponent {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
}
