import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import * as dayjs from 'dayjs';

export interface StartDateErrors {
  dateFormat: { key: string; value: string };
  dateGreaterThan: { key: string; value: string };
  dateLessThan: { key: string; value: string };
  dateOutOfRange: { key: string; value: string };
  required: { key: string; value: string };
}

export const maxStartDateDefaults = {
  ch: { duration: 6, type: 'month' },
  de: { duration: 3, type: 'year' }
};

@Component({
  selector: 'one-start-date',
  styleUrls: ['./start-date.component.scss'],
  templateUrl: './start-date.component.html'
})
export class StartDateComponent implements OnInit {
  @Input() public customAgeValidation: { maxAge: number; minAge: number };
  @Input() public dateFormat = 'DD.MM.YYYY';
  @Input() public dateGreaterThan = new Date();
  public dateLessThan: Date;
  @Input() public description = '_ST_MOTOR_start_date_subtitle';
  @Input() public errorsOptions: Partial<StartDateErrors>;
  @Input() public fullWidth = false;
  @Input() public maxStartDate: { duration: number; type: any }; // eslint-disable-line
  @Input() public parentGroup: FormGroup;
  @Input() public showDescription = false;
  @Input() public showTitle = true;
  @Input() public smallTitle = false;
  @Input() public title = '_ST_MOTOR_start_date_title';

  private _country = this._sessionQuery.getCountry();
  private _defaultErrors: Partial<StartDateErrors>;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _sessionQuery: SessionQuery
  ) {
    this._defaultErrors = {
      dateFormat: { key: 'dateFormat', value: '_PROD_start_date_format' },
      dateGreaterThan: { key: 'dateGreaterThan', value: '_PROD_start_date_greaterthan' },
      dateLessThan: { key: 'dateLessThan', value: '_PROD_start_date_lessthan' },
      required: { key: 'required', value: '_PROD_required_error' }
    };
  }

  public ngOnInit(): void {
    this.errorsOptions = { ...this._defaultErrors, ...this.errorsOptions };
    this.maxStartDate = {
      ...maxStartDateDefaults[this._country.toLowerCase()],
      ...this.maxStartDate
    };
    this.dateLessThan = this._getMaximumStartDate();
    this._cdr.detectChanges();
  }

  private _getMaximumStartDate(): Date {
    switch (this._country) {
      case COUNTRIES.ch: {
        return (
          dayjs(this.dateGreaterThan)
            .add(this.maxStartDate.duration, this.maxStartDate.type)
            // .subtract(1, 'day')
            .toDate()
        );
      }
      default: {
        return dayjs().add(this.maxStartDate.duration, this.maxStartDate.type).toDate();
      }
    }
  }
}
