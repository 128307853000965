import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { SessionState, SessionStore } from '@wefoxGroupOneBPCore/stores/session.store';
import { ProductQuery } from '@wefoxGroupOneBPCore/queries/product.query';
import { CentralizedQuery } from '@wefoxGroupOneBPCore/queries';
import { KeycloakToken } from '@wefoxGroupOneBPCore/interceptors/keycloak-token.interface';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {
  public bannerDraftOptions$ = this.select(state => state.bannerDraftOptions);
  public brokerId$ = this.select(state => state.brokerId);
  public brokerPermissions$ = this.select(state => state.itBrokerPermissions);
  public concludeResponse$ = this.select(state => state.concludeResponse);
  public concludeSectionActions$ = this.select(state => state.concludeSectionActions);
  public dropdownOptionsState$ = this.select(state => state.dropdownOptionsState);
  public externalProductSeen$ = this.select(state => state.externalProductSeen);
  public headerBackOptions$ = this.select(state => state.headerBackOptions);
  public interactiveTariffState$ = this.select(state => state.interactiveTariffState);
  public isLegalEntity$ = this.select(state => state.formCurrentState.isLegalEntity);
  public isOffer$ = this.select(state => state.formCurrentState.isOffer);
  public isPrefilled$ = this.select(state => state.formCurrentState.isPrefilled);
  public loading$ = this.select(state => state.ui.loading);
  public loadingMessage$ = this.select(state => state.ui.loadingMessage);
  public locale$ = this.select(state => state.locale);
  public ocrUploadVisible$ = this.select(state => state.ocrUploadVisible);
  public reviewCardsDetails$ = this.select(state => state.reviewCardsDetails);
  public rolePermissions$ = this.select(state => state.rolePermissions);
  public tariffState$ = this.select(state => state.tariffState);
  public tariffValues$ = this.select(state => state.tariffValues);
  public toasterRoleOptions$ = this.select(state => state.toasterRoleOptions);
  public typeaheadState$ = this.select(state => state.typeaheadState);
  public uploadedFiles$ = this.select(state => state.uploadedFiles);

  constructor(private _common: CentralizedQuery, private _productQuery: ProductQuery, protected store: SessionStore) {
    super(store);
  }

  public getBrokerId(): string {
    return this.getValue().brokerId;
  }

  public getBrokerPath(): string {
    return `broker/${this.getValue().brokerId}`;
  }
  
  // eslint-disable-next-line
  public getBrokerPermissions(): any {
    return this.getValue().itBrokerPermissions;
  }

  public getCountry(): COUNTRIES {
    return this.getValue().country;
  }

  public getCountryPrefix(): string {
    return `/${this.getCountry()?.toLowerCase()}`;
  }

  public getCurrentDropdownState(): {
    category: Partial<DropdownComponentOptions>;
    sub_category: Partial<DropdownComponentOptions>;
  } {
    return this.getValue().dropdownOptionsState;
  }

  public getCurrentLocale(): string {
    return this.getValue().locale;
  }

  public getKeycloakToken(): KeycloakToken {
    return this.getValue().keycloakToken;
  }

  public hasLanguageOptions(): boolean {
    return this._common.getLanguages()?.length > 1;
  }

  public isLoggedIn(): boolean {
    return this.getValue()?.brokerId ? true : false;
  }
}
