import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { SearchBarOptions } from '@wefoxGroupOneBPShared/interfaces';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'one-search-bar',
  templateUrl: './search-bar.component.html'
})
export class SearchBarComponent implements OnDestroy, OnInit {
  public form: UntypedFormGroup;
  public hasSearchTerm: boolean;
  @Input() public options: Partial<SearchBarOptions>;
  @Input() public rounded = true;
  @Output() public searchValue: EventEmitter<string>;
  private _defaultOptions: SearchBarOptions;
  private _unsubscribe$: Subject<void>;

  constructor() {
    this.searchValue = new EventEmitter();
    this._unsubscribe$ = new Subject();
    this._defaultOptions = {
      delayTime: UxIntervalTimes.fast,
      i18nPrefixKey: undefined
    };
    this.form = new UntypedFormGroup({
      search: new UntypedFormControl('', [Validators.min(1)])
    });
  }

  public ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  public ngOnInit(): void {
    this.options = { ...this._defaultOptions, ...this.options };

    this._emitOnChanges();
  }

  public onClearSearch(): void {
    this.form.get('search').setValue('');
  }

  private _emitOnChanges(): void {
    this.form
      .get('search')
      .valueChanges.pipe(
        map(value => value.trim()),
        distinctUntilChanged(),
        debounceTime(this.options.delayTime),
        takeUntil(this._unsubscribe$)
      )
      .subscribe(value => {
        this.searchValue.emit(value);
        this.hasSearchTerm = !!value;
      });
  }
}
