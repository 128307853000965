import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BreadcrumbConfig, BreadcrumbElement } from '@wefoxGroupOneBPShared/interfaces/breadcrumb.interface';
import { BreadcrumbsService } from '@wefoxGroupOneBPShared/services/breadcrumbs.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'one-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  
  public breadcrumbConfig: BreadcrumbConfig;
  private breadcrumbConfigSubscription: Subscription;

  constructor(
    private _location: Location,
    private _router: Router,
    private _breadcrumbsService: BreadcrumbsService,
    private _sessionQuery: SessionQuery
  ) {}

  public navigateToBreadcrumb(breadcrumb: BreadcrumbElement): void {
    this._router.navigateByUrl(`${this._sessionQuery.getCountryPrefix()}/${breadcrumb.routerLink}`);
  }

  public ngOnInit(): void {
    this.breadcrumbConfigSubscription = this._breadcrumbsService.breadcrumbConfig.subscribe((breadcrumbConfig: BreadcrumbConfig) => {
      this.breadcrumbConfig = breadcrumbConfig;
    });
  }

  public ngOnDestroy() {
    this.breadcrumbConfigSubscription?.unsubscribe();
  }

  public onBackArrowClick(): void {
    if (this.breadcrumbConfig?.breadcrumbs.length > 0) {
      this._location.back();
    }
  }
}
