import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WgModalComponent } from './components/wg-modal/wg-modal.component';
import { WgModalDirective } from './directives/wg-modal.directive';

@NgModule({
  declarations: [
    WgModalComponent,
    WgModalDirective
  ],
  exports: [
    WgModalComponent,
    WgModalDirective
  ],
  imports: [
    CommonModule    
  ]
})
export class WgModalModule {}
