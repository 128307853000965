import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { ProductValidations } from '@wefoxGroupOneBPCore/constants/product.constants';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { FileUploadOptions } from '@wefoxGroupOneBPShared/modules/wg-input/components/wg-file-upload/wg-file.interfaces';

export interface DocumentsAdapterComponentOptions {
  config: Partial<DocumentsAdapterComponentConfig>;
  constructFile?: Function; // eslint-disable-line
  disabled?: boolean;
  section_id: string;
  section_title: string;
}

export interface DocumentsAdapterComponentConfig {
  alert_text?: string;
  content_subtitle: string;
  content_title: string;
  control_name: string;
  file_upload_options?: Partial<FileUploadOptions>;
  is_required: boolean;
}

@Component({
  selector: 'one-documents-adapter',
  templateUrl: './documents-adapter.component.html'
})
export class DocumentsAdapterComponent implements AfterViewInit, OnInit {
  @Input() public content_data: Partial<DocumentsAdapterComponentOptions>;
  public fileUploadOptions: Partial<FileUploadOptions>;
  @Input() public parentGroup: UntypedFormGroup;
  public uploadedFiles: File[];

  private _defaultContentData: Partial<DocumentsAdapterComponentOptions>;
  private _defaultOptions: FileUploadOptions;

  constructor(private _cdr: ChangeDetectorRef, private _sessionQuery: SessionQuery) {
    this._defaultContentData = {
      config: {
        alert_text: '',
        content_subtitle: '_ST_MOTOR_upload_description',
        content_title: '_ST_MOTOR_upload_title',
        control_name: 'file',
        file_upload_options: {},
        is_required: true
      },
      section_id: 'upload_title',
      section_title: '_PROD_document_upload_section_title'
    };
  }

  public ngAfterViewInit(): void {
    this._sessionQuery.uploadedFiles$.subscribe(files => {
      this.uploadedFiles = files;

      setTimeout(() => {
        const fileInput = this.parentGroup.get('file');
        if (this.content_data.config.is_required && !this.uploadedFiles?.length) {
          fileInput.setValidators([Validators.required]);
          fileInput.updateValueAndValidity();
        } else {
          fileInput.clearValidators();
        }
      }, 200);
    });

    this._cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.content_data = {
      ...this._defaultContentData,
      ...this.content_data,
      config: { ...this._defaultContentData.config, ...this.content_data.config }
    };
    this._monitorLocaleChanges();
  }

  // eslint-disable-next-line
  public onFileSelected(event): void {
    if (event) {
      this.content_data.constructFile(event);
    }
  }

  private _monitorLocaleChanges(): void {
    this._sessionQuery.locale$.subscribe(() => {
      this.fileUploadOptions = {
        ...this._updateTranslations(),
        ...this.content_data.config.file_upload_options
      };
    });
  }

  private _updateTranslations() {
    const maximumUploadSize = 75;
    const fileMaxSize = 10;
    const maximumUploadLimit = 10;
    return {
      allowedFileTypes: ProductValidations.allowedFileTypes,
      disabled: this.content_data.disabled,
      inputFieldText: {
        label: translate('_GEN_select_file'),
        secondLabel: translate('_GEN_drag_drop_file'),
        subText: translate('_ST_MOTOR_upload_combine_docs')
      },
      limitErrorMessage: translate('_GEN_max_upload_limit', { placeHolders: [`${maximumUploadLimit}`] }),
      maximumUploadLimit,
      maximumUploadSize,
      multipleFiles: true,
      sizeErrorMessage: translate('_PROD_combined_file_size_limit_error_NEW', { value: [`${maximumUploadSize}`] }),
      singleSizeErrorMessage: translate('_PROD_single_file_max_size_error', { value: [`${fileMaxSize}`] }),
      fileMaxSize
    };
  }
}
