import { Injectable } from '@angular/core';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { ConcludeResponse } from '@wefoxGroupOneBPCore/interfaces/switch-tariff.interface';
import * as S3 from 'aws-sdk/clients/s3';
import * as AWS from 'aws-sdk/global';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AWSService {
  public uploadingProgress: BehaviorSubject<Partial<ConcludeResponse>> = new BehaviorSubject<Partial<ConcludeResponse>>(
    null
  );
  private _awsS3Bucket;
  private _s3: S3;

  constructor() {
    this._awsS3Bucket = environment.thirdParty.awsS3Bucket;
  }

  public init(): void {
    const { awsCognitoIdentityPoolId, awsRegion } = environment.thirdParty;

    AWS.config.region = awsRegion;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: `${awsRegion}:${awsCognitoIdentityPoolId}`
    });

    this._s3 = new S3({
      params: {
        Bucket: this._awsS3Bucket
      }
    });
  }

  // eslint-disable-next-line
  public upload(pathNames: string[], options: { [key: string]: File[] | string }): Observable<any> {
    const { file, contentType } = options;
    const requestList = [];
    if (file && file.length) {
      const s3Request = (url: S3.ManagedUpload) =>
        url
          .on('httpUploadProgress', (event) => {
            this.uploadingProgress.next({ uploadingProgress: event });
          })
          .promise()
          .then((data: S3.ManagedUpload) => {
            return data;
          });

      // eslint-disable-next-line
      const spreadRequest = (fileElement: any, pathName: string) => {
        return this._s3.upload(
          {
            Body: fileElement,
            Bucket: this._awsS3Bucket,
            ContentType: fileElement ? fileElement['type'] : contentType,
            Key: pathName
          },
          {
            partSize: 25 * 1024 * 1024,
            queueSize: 1
          }
        );
      };

      for (let a = 0; a < file.length; a++) {
        requestList.push(spreadRequest(file[a], pathNames[a]));
      }

      const startS3Upload = () => from(requestList).pipe(concatMap(url => s3Request(url)));

      return startS3Upload();
    }
  }
}
