import { Injectable } from '@angular/core';
import { AllListInfo } from '@wefoxGroupOneBPCore/interfaces/list-page-options.interface';
import {
  CountLeadStageResponse,
  LeadDetails,
  LeadsListOptions,
  LeadStageResponse
} from '@wefoxGroupOneBPPrivate/modules/leads/interfaces';
import { Observable } from 'rxjs';
import { LeadsDataService } from './leads-data.service';

@Injectable({ providedIn: 'root' })
export class LeadsService {
  constructor(private _leadsDataService: LeadsDataService) {}

  public getListOfLeads$(options: LeadsListOptions): Observable<AllListInfo> {
    return this._leadsDataService.getListOfLeads$(options);
  }

  public getLead$(id: string, lang: string): Observable<LeadDetails> {
    return this._leadsDataService.getLead$(id, lang);
  }

  public getComparisonUrl$(data: LeadDetails, site: string): Observable<{ url: string }> {
    return this._leadsDataService.getComparisonUrl$(data, site);
  }

  // eslint-disable-next-line
  public getRiskAnalysisProfileByleadId$(adapterLeadId: string): Observable<any> {
    return this._leadsDataService.getRiskAnalysisProfileByLeadId$(adapterLeadId);
  }

  public markAsFavorite$(lead: Partial<LeadDetails>): Observable<void> {
    return this._leadsDataService.markAsFavorite$(lead);
  }

  public unMarkAsFavorite$(lead: Partial<LeadDetails>): Observable<void> {
    return this._leadsDataService.unMarkAsFavorite$(lead);
  }

  public getLeadStage$(adapterLeadId: string): Observable<LeadStageResponse> {
    return this._leadsDataService.getLeadStage$(adapterLeadId);
  }

  public setLeadStage$(adapterLeadId: string, stage: string): Observable<void> {
    return this._leadsDataService.setLeadStage$(adapterLeadId, stage);
  }

  public getCountLeadStage$(): Observable<CountLeadStageResponse[]> {
    return this._leadsDataService.getCountLeadStage$();
  }

  public patchArchive$(adapterLeadId: string): Observable<void> {
    return this._leadsDataService.patchArchive$(adapterLeadId);
  }
}
