import { NgModule } from '@angular/core';
import { BaseAuthenticationService } from '@wefoxGroupOneBPCore/services/base.authentication.service';
import { AuthModule, AUTH_SERVICE, PROTECTED_FALLBACK_PAGE_URI, PUBLIC_FALLBACK_PAGE_URI } from 'ngx-auth';
import { AuthenticationService } from './services/authentication.service';
import { TokenStorageService } from '@wefoxGroupOneBPCore/services';

@NgModule({
  imports: [AuthModule],
  providers: [
    TokenStorageService,
    BaseAuthenticationService,
    AuthenticationService,
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/dashboard' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/login' },
    { provide: AUTH_SERVICE, useClass: BaseAuthenticationService }
  ]
})
export class AuthenticationModule {}
