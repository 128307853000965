import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateFormat } from '@wefoxGroupOneBPShared/constants';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

export interface DateComparisonComponentConfig {
  compare_to_self: boolean;
  control_name: string;
  mask: string;
  format: string;
  greater_than?: DateComparisonConfig;
  i18n_prefix_key: string;
  label: string;
  less_than?: DateComparisonConfig;
  max: any; // eslint-disable-line
  min: any; // eslint-disable-line
  placeholder: string;
  required: boolean;
}
export interface DateComparisonConfig {
  error_message?: string;
  input_name: string;
  time_amount: number;
  time_tense: string;
  time_type: any; // eslint-disable-line
}
@Component({
  selector: 'one-date-comparison-adapter',
  templateUrl: './date-comparison-adapter.component.html'
})
export class DateComparisonAdapterComponent implements OnInit {
  @Input() public content_data: DateComparisonComponentConfig;
  @Input() public dateFormat = DateFormat.default;
  public dateGreaterThan: string;
  public dateLessThan = dayjs().format(DateFormat.default);
  @Input() public parentGroup: UntypedFormGroup;

  private _maxDateConfig: any = 'today'; // eslint-disable-line
  private _minDateConfig;

  public ngOnInit(): void {
    const inputName = this.content_data?.greater_than?.input_name || this.content_data?.less_than?.input_name;
    const controlToCompare = this.parentGroup.controls[inputName];
    this._compareDates(controlToCompare.value);
    this._monitorDateChanges();

    this._maxDateConfig = this.content_data.max;
    this._minDateConfig = this.content_data.min;

    if (!this.content_data.compare_to_self) {
      this._checkMaxDate();
      this._checkMinDate();
    }
  }

  /**
   * Compares two dates, and add or subtract an amount of time, depending on the time tense.
   * @param {DateComparisonConfig} dateToCompare is the data to compare.
   * @param {string} dateValue is the value that the user enters in the input field.
   * If the time tense is future, it @returns the processed date plus the time amount specified in the new-motor-model.ts file.
   * If the time tense is past, it @returns the processed date. As we don't have this specification yet, we don't use it.
   */
  private _checkDates(dateToCompare: DateComparisonConfig, dateValue: string): string {
    if (dateToCompare.time_tense === 'future') {
      return dayjs(dateValue, this.dateFormat)
        .add(dateToCompare.time_amount || 1, dateToCompare.time_type)
        .format(this.dateFormat);
    }
    if (dateToCompare.time_tense === 'past') {
      return dayjs(dateValue, this.dateFormat)
        .subtract(dateToCompare.time_amount || 1, dateToCompare.time_type)
        .format(this.dateFormat);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _checkMaxDate(date?: string): void {
    if (this._maxDateConfig?.type === 'today') {
      this.dateLessThan = dayjs(new Date()).format(DateFormat.default);
      return;
    }

    if (this._maxDateConfig?.type === 'future') {
      if (this._maxDateConfig?.start_of) {
        this.dateLessThan = dayjs()
          .startOf(this._maxDateConfig?.start_of)
          .add(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(DateFormat.default);
      } else {
        this.dateLessThan = dayjs()
          .add(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(DateFormat.default);
      }
      return;
    }

    if (this._maxDateConfig?.type === 'past') {
      if (this._maxDateConfig?.start_of) {
        this.dateLessThan = dayjs()
          .startOf(this._maxDateConfig?.start_of)
          .subtract(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(DateFormat.default);
      } else {
        this.dateLessThan = dayjs()
          .subtract(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(DateFormat.default);
      }
      return;
    }
  }

  private _checkMinDate(date?: string): void {
    if (this._minDateConfig?.type === 'today') {
      this.dateGreaterThan = dayjs(new Date()).format(DateFormat.default);
      if (this.content_data.compare_to_self) {
      }
      return;
    }

    if (this._minDateConfig?.type === 'future') {
      if (this._minDateConfig?.start_of) {
        this.dateGreaterThan = dayjs()
          .startOf(this._minDateConfig?.start_of)
          .add(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(DateFormat.default);
      } else {
        this.dateGreaterThan = dayjs()
          .add(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(DateFormat.default);
      }
      this.parentGroup.get(this.content_data.control_name).setErrors({ min: true });
      return;
    }

    if (this._minDateConfig?.type === 'past') {
      if (this.content_data.compare_to_self) {
        const dateToCompare = dayjs()
          .startOf(this._minDateConfig?.start_of)
          .subtract(this._minDateConfig?.duration, this._minDateConfig?.increment);

        const diff = dayjs(date, DateFormat.default).diff(dateToCompare, this._minDateConfig.increment);

        if (this._compareWithOperation(diff, this._minDateConfig.operation)) {
          this.parentGroup.get(this.content_data.control_name).setErrors({ min: true });
        }
        return;
      }

      if (this._minDateConfig?.start_of) {
        this.dateGreaterThan = dayjs()
          .startOf(this._minDateConfig?.start_of)
          .subtract(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(DateFormat.default);
      } else {
        this.dateGreaterThan = dayjs()
          .subtract(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(DateFormat.default);
      }

      return;
    }
  }

  private _compareWithOperation(value, operation) {
    switch (operation) {
      case 'greaterThan':
        return value > 0;
    }
  }

  private _compareDates(dateValue: string): void {
    if (!!dateValue) {
      if (this.content_data?.greater_than) {
        this.dateGreaterThan = this._checkDates(this.content_data.greater_than, dateValue);
      }
      if (this.content_data?.less_than) {
        this.dateLessThan = this._checkDates(this.content_data.less_than, dateValue);
      } else {
        this.dateLessThan = null;
      }
    } else {
      this.dateLessThan = null;
      this.dateGreaterThan = null;
    }
  }

  /**
   * Checks if control exists in form group and subscribes to the value.
   * The ValueChanges is an event raised by the Angular forms whenever the @param value of the FormGroup changes.
   * It @returns an observable so that you can subscribe to it. The observable gets the latest @param value of the control.
   * It allows us to track changes made to the @param value in real-time and respond to it.
   * If the status of the control is valid, the function _checkDates is called.
   */
  private _monitorDateChanges(): void {
    const inputNameToCompare = this.content_data?.greater_than?.input_name || this.content_data?.less_than?.input_name;
    const controlToCompare = this.parentGroup.controls[inputNameToCompare];
    controlToCompare.valueChanges.subscribe(value => {
      if (controlToCompare.status === 'VALID' || controlToCompare.status === 'DISABLED') {
        this._compareDates(value);
      }
    });
    const currentInput = this.content_data.control_name;
    this.parentGroup.get(currentInput).valueChanges.subscribe(value => {
      if (this.parentGroup.get(currentInput).status === 'VALID') {
        const dateToCompare = this.parentGroup.get(inputNameToCompare);
        if (dateToCompare?.value && dateToCompare?.status === 'VALID') {
          this._compareDates(dateToCompare.value);
        }
        if (value) {
          this._checkMaxDate(value);
          this._checkMinDate(value);
        }
      }
    });
  }
}
