import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateFormat } from '@wefoxGroupOneBPShared/constants';
import * as dayjs from 'dayjs';

export interface DateInputAdapterComponentOptions {
  control_name: string,
  format?: string,
  i18n_prefix_key: string,
  label: string,
  mask?: boolean,
  max: { duration: number, increment: string, start_of: string, type: string },
  min: { duration: number, increment: string, start_of: string, type: string },
  placeholder?: string,
  required: boolean,
  validators: [{ type: string }]
}
@Component({
  selector: 'one-date-input-adapter',
  templateUrl: './date-input-adapter.component.html'
})
export class DateInputAdapterComponent implements OnInit {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;
  public dateGreaterThan: string;
  public dateLessThan: string;
  private _format = DateFormat.default;
  private _maxDateConfig: any = { type:'today' }; // eslint-disable-line
  private _minDateConfig; // eslint-disable-line

  public ngOnInit(): void {
    this._format = this.content_data.format || DateFormat.default;
    this._maxDateConfig = this.content_data.max;
    this._minDateConfig = this.content_data.min;

    this._checkMaxDate();
    this._checkMinDate();
  }

  private _checkMaxDate() {
    if (this._maxDateConfig?.type === 'today') {
      this.dateLessThan = dayjs(new Date()).format(this._format);
      return;
    }

    if (this._maxDateConfig?.type === 'future') {
      if (this._maxDateConfig?.start_of) {
        this.dateLessThan = dayjs()
          .startOf(this._maxDateConfig?.start_of)
          .add(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(this._format);
      } else {
        this.dateLessThan = dayjs()
          .add(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(this._format);
      }
      return;
    }

    if (this._maxDateConfig?.type === 'past') {
      if (this._maxDateConfig?.start_of) {
        this.dateLessThan = dayjs()
          .startOf(this._maxDateConfig?.start_of)
          .subtract(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(this._format);
      } else {
        this.dateLessThan = dayjs()
          .subtract(this._maxDateConfig?.duration, this._maxDateConfig?.increment)
          .format(this._format);
      }
      return;
    }
  }

  private _checkMinDate() {
    if (this._minDateConfig?.type === 'today') {
      this.dateGreaterThan = dayjs(new Date()).format(this._format);
      return;
    }

    if (this._minDateConfig?.type === 'future') {
      if (this._minDateConfig?.start_of) {
        this.dateGreaterThan = dayjs()
          .startOf(this._minDateConfig?.start_of)
          .add(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(this._format);
      } else {
        this.dateGreaterThan = dayjs()
          .add(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(this._format);
      }
      return;
    }

    if (this._minDateConfig?.type === 'past') {
      if (this._minDateConfig?.start_of) {
        this.dateGreaterThan = dayjs()
          .startOf(this._minDateConfig?.start_of)
          .subtract(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(this._format);
      } else {
        this.dateGreaterThan = dayjs()
          .subtract(this._minDateConfig?.duration, this._minDateConfig?.increment)
          .format(this._format);
      }
      return;
    }
  }
}
