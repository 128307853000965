import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { CustomHttpParamEncoder } from '@wefoxGroupOneBPCore/utils/custom-http-param-encoder';
import { SelfJourneyToken } from '@wefoxGroupOneBP/app/public/modules/self-journey/interfaces/self-journey.interface';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseAuthenticationService } from '@wefoxGroupOneBPCore/services/base.authentication.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AUTH_GRANT_TYPES, PERMISSIONS } from '@wefoxGroupOneBPCore/constants/auth.constants';
import { ForgotData } from '../interfaces/forgot-data.interface';
import { ForgotResetData } from '../interfaces/forgot-reset-data-interface';
import { LoginData } from '../interfaces/login-data.interface';
import jwt_decode from 'jwt-decode';
import { COUNTRIES } from '@wefoxGroupOneBPCore/constants';
import { LoginResponse } from '@wefoxGroupOneBP/app/private/modules/auth/interfaces/login-response.interface';

@Injectable()
export class AuthenticationService extends BaseDataService {
  // FIXME: [ONEBP-345] Refactor AuthenticationService for use the BaseDataServier and update endpoints
  private _activationEndpoint = 'users/create-password';
  private _authHeaders: HttpHeaders;
  private _authTokenEndpoint = 'oauth/token';
  private _authUrl = environment.authUrl;
  private _basicAuthToken = environment.basicAuthToken;
  private _forgotPasswordEmailEndpoint = 'users/forgotten-password-email';
  private _forgotPasswordEndpoint = 'users/forgotten-password';
  private _validateTokenEndpoint = 'users/token-status';

  constructor(
    protected httpClient: HttpClient,
    protected sessionQuery: SessionQuery,
    private _baseAuth: BaseAuthenticationService
  ) {
    super(httpClient, sessionQuery);
    this._authHeaders = new HttpHeaders({
      Authorization: `Basic ${this._basicAuthToken}`
    });
  }

  // eslint-disable-next-line
  public forgotPassword(forgotPasswordData: ForgotData): Observable<any> {
    const url = this.getUrl({
      path: `${this._forgotPasswordEmailEndpoint}`
    });

    return this.post$(url, { email: forgotPasswordData.email }, { headers: this._authHeaders });
  }

  public getAccessToken(): Observable<string> {
    return this._baseAuth.getAccessToken();
  }

  public getPermissions(): string[] {
    let permissions = [];
    this._baseAuth.getAccessToken().subscribe(token => {
      permissions = jwt_decode(token)['authorities'];
    });
    return permissions;
  }

  public hasPermissions(permissions: PERMISSIONS[], link?: string): boolean {
    let decodedPermissions = [];
    if (this.sessionQuery.getCountry() === COUNTRIES.it) {
      // TODO: Improve this method, once we have several permissions for italian broker

      const itBrokerPermissions = this.sessionQuery.getBrokerPermissions();
      if (link === 'broker-roles' && !itBrokerPermissions?.intermediary_admin) {
        return false;
      } else {
        return true;
      }
    } else {
      if (!permissions) {
        return true;
      }
      this._baseAuth.getAccessToken().subscribe(token => {
        if (token) {
          decodedPermissions = jwt_decode(token)['authorities'];
        }
      });
    }
  
    return decodedPermissions?.some(e => permissions.includes(e));
  }

  public hasLeads(): boolean {
    let decodedPermissions = [];
    this._baseAuth.getAccessToken().subscribe(token => {
      decodedPermissions = jwt_decode(token)['authorities'];
    });

    return decodedPermissions?.some(p => {
      return p.includes('EXTERNAL_TOOL');
    });
  }

  public isAuthorized(): Observable<boolean> {
    return this._baseAuth.isAuthorized();
  }

  public login(loginData: LoginData): Observable<LoginResponse> {
    const body = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .set('grant_type', AUTH_GRANT_TYPES.password)
      .set('username', loginData.username)
      .set('password', loginData.password);
    const headers = new HttpHeaders({
      Authorization: `Basic ${this._basicAuthToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
      Country: this.sessionQuery.getCountry()
    });
    return this.post$(`${this._authUrl}/${this._authTokenEndpoint}`, body.toString(), {
      headers
    }).pipe(
      tap((tokens: LoginResponse) => {
        this._baseAuth.setAccessData(tokens);
      })
    );
  }

  public logout(): void {
    this._baseAuth.logout();
  }

  public refreshShouldHappen(response: HttpErrorResponse): boolean {
    return this._baseAuth.refreshShouldHappen(response);
  }

  // FIXME Include interface response
  public requestSelfJourneyToken$(campaignUUID: string, recaptchaToken: string): Observable<SelfJourneyToken> {
    const url = this.getUrlWithParams({
      options: { responseToken: recaptchaToken },
      path: `${this._authTokenEndpoint}/${campaignUUID}`
    });
    const options: RequestInit = {
      headers: {
        Authorization: `Basic ${this._basicAuthToken}`
      }
    };

    return this.get$(url, options);
  }

  // eslint-disable-next-line
  public resetPassword$(setPasswordData: ForgotResetData): Observable<any> {
    const url = this.getUrl({
      path: `${this._forgotPasswordEndpoint}`
    });
    return this.patch$(url, setPasswordData, {
      headers: this._authHeaders
    });
  }
  /**
   * New user create a new password and activate the account
   * @param data TBD
   */
  // eslint-disable-next-line
  public setNewPassword$(data: ForgotResetData): Observable<any> {
    const url = this.getUrl({
      path: `${this._activationEndpoint}`
    });
    return this.patch$(url, data, { headers: this._authHeaders });
  }

  // eslint-disable-next-line
  public validateToken$(email: string, token: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._validateTokenEndpoint}/${token}`
    });

    return this.post$(
      url,
      { email: email },
      {
        headers: this._authHeaders
      }
    );
  }
}
