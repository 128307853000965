import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@wefoxGroupOneBPCore/environments/environment';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { Observable, switchMap } from 'rxjs';
import { OfferCreation } from '../models/offer-creation';

@Injectable()
export class OfferCreationDataService extends BaseDataService {
  private _sendOfferCreation = 'application';
  private _getStaticDocuments = 'application/documents/static';
  private _preSignedDocument = 'application/documents/pre-signed-url/private/policies';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  // eslint-disable-next-line
  public uploadSignedDocument$(file: File): Observable<any> {
    const firstEndpointUrl = `${environment.termLifeApiUrl}/api/v1/${this._preSignedDocument}`;

    const body = new FormData();

    return this.get$(firstEndpointUrl).pipe(
      // eslint-disable-next-line
      switchMap((response: any) => {
        Object.keys(response.form_data).forEach(key => {
          body.append(key, response.form_data[key]);
        });

        body.append('file', file);

        const path = response.url;

        return this.post$(path, body, {});
      })
    );
  }

  public submitOfferCreation$(body: OfferCreation, options?: RequestInit): Observable<OfferCreation> {
    const url = `${environment.termLifeApiUrl}/api/v1/${this._sendOfferCreation}`;
    return this.post$(url, body, options);
  }

  public updateOfferCreation$(body: OfferCreation, options?: RequestInit): Observable<OfferCreation> {
    const url = `${environment.termLifeApiUrl}/api/v1/${this._sendOfferCreation}`;

    return this.put$(url, body, options);
  }

  // eslint-disable-next-line
  public getStaticDocuments$(insuranceCompanyData): Observable<any> {
    const url = `${environment.termLifeApiUrl}/api/v1/${this._getStaticDocuments}`;

    const options: { params: HttpParams } = {
      params: insuranceCompanyData
    };

    return this.get$(url, options);
  }
}
