import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { PrivateConfig } from '@wefoxGroupOneBPCore/interfaces';
import { Product, ProductDocument } from '@wefoxGroupOneBPCore/interfaces/product.interface';

export interface ProductState extends EntityState<Product>, ActiveState {
  config: PrivateConfig;
  productDocuments: ProductDocument[];
}

const initialState = {
  active: undefined, // TODO Temporal active product set by default
  config: null,
  productDocuments: []
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'product', idKey: 'id', resettable: true })
export class ProductStore extends EntityStore<ProductState, Product> {
  constructor() {
    super(initialState);
  }
}
