export enum CustomerKeys {
  Female = 1,
  Male = 0
}

export const NoteCategoriesTranslations = {
  [CustomerKeys.Female]: '_PROD_gender_values_male',
  [CustomerKeys.Male]: '_PROD_gender_values_female'
};

export const CustomerAddressFields = ['place', 'postal_code', 'street', 'house_number']; // Be sure that the address index correlates.

export const CustomerNameFields = ['firstname', 'lastname', 'legal_name'];

export const InsuredHouseAddressFields = ['object_city', 'object_postal_code', 'object_street', 'object_street_number'];

export const AccountParameter = {
  entity: {
    legal: 'legalentity'
  }
};

export const GenderKeyToValue = {
  0: 'male',
  1: 'female',
  d: 'diverse',
  m: 'male',
  w: 'female'
};
