import { InsuranceCardData } from '@wefoxGroupOneBPShared/components/carrier-excerpt/interfaces/carrier-excerpt.interface';
import { CarrierCardOption } from '../interfaces/third-party-products.interface';

export const mapCarriersIntoCarrierOptions = (data: InsuranceCardData[]): CarrierCardOption[] => {
  return data?.map(item => ({
    carrier_name: item.name,
    carrier_id: item.id,
    carrier_logo_icon: item.logo_icon,
    total_products: item.total_products
  }));
};
