import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { CustomHttpParamEncoder } from '@wefoxGroupOneBPCore/utils/custom-http-param-encoder';
import { CommmissionsDatesDTO } from '@wefoxGroupOneBPPrivate/modules/account/components/commissions-table.component';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CommissionsDataService extends BaseDataService {
  private _commissionsEndpoint = 'commission-statement';

  constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
    super(httpClient, sessionQuery);
  }

  // eslint-disable-next-line
  public downloadApplicationZipFiles$(year: string, month: string): Observable<any> {
    const url = this.getUrl({
      path: `${this._commissionsEndpoint}/files`
    });
    const params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('year', year)
      .append('month', month);
    return this.get$(url, { params, responseType: 'blob' as 'json' });
  }

  public getFilteredCommissions$(
    pageIndex: number,
    pageSize: number,
    sortField: string | null,
    sortOrder: string | null,
    filterDate: { year: string; month: string } | null
  ): Observable<unknown> {
    let params = new HttpParams({ encoder: new CustomHttpParamEncoder() })
      .append('page', `${pageIndex - 1}`)
      .append('size', `${pageSize}`);
    if (sortField && sortOrder) {
      sortOrder = sortOrder === 'ascend' ? 'asc' : 'desc';
      params = params.append('sort', `${sortField},${sortOrder}`);
    }
    if (filterDate) {
      params = params.append('year', filterDate.year);
      params = params.append('month', filterDate.month);
    }
    const url = this.getUrl({
      path: this._commissionsEndpoint
    });
    return this.get$(url, { params }).pipe(catchError(() => of({ content: [] })));
  }

  public getStartingDate(): Observable<CommmissionsDatesDTO> {
    const url = this.getUrl({
      path: `${this._commissionsEndpoint}/dates`
    });
    return this.get$(url);
  }
}
