import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wg-owner-data-card',
  templateUrl: './owner-data-card.component.html'
})
export class OwnerDataCardComponent implements OnInit {
  @Input() public parentGroup: UntypedFormGroup;
  public isLegalEntity = false;
  public vehicleHolderIsPolicyHolder = true;
  public vehicleHolderIsPolicyHolderValue = '';
  public vehicleHolderName = '-';

  public policyHolderName = '-';
  public policyHolderTaxId = '-';
  public policyHolderEmail = '-';
  public policyHolderPhone = '-';

  public fields = [
    {
      control: 'vehicle_holder_email',
      label: '_CT_MOTOR_driver_email'
    },
    {
      control: 'vehicle_holder_mobile_phone',
      label: '_CT_MOTOR_driver_mobile_phone_label'
    }
  ];

  ngOnInit(): void {
    this.isLegalEntity = this.parentGroup.get('is_legal_entity').value === 'yes';
    this.vehicleHolderIsPolicyHolder = this.parentGroup.get('vehicle_holder_is_policy_holder').value.includes('yes');
    this.vehicleHolderIsPolicyHolderValue = this.vehicleHolderIsPolicyHolder ? '_PROD_yes' : '_PROD_no';

    if (this.isLegalEntity) {
      this.vehicleHolderName = this.parentGroup.get('vehicle_holder_business_name').value;
      this.fields.unshift({
        control: 'vehicle_holder_vat_number',
        label: '_CT_MOTOR_vat_number'
      });
      if (!this.vehicleHolderIsPolicyHolder) {
        this.policyHolderName = this.parentGroup.get('policy_holder_business_name').value;
        this.policyHolderTaxId = this.parentGroup.get('policy_holder_vat_number').value;
        this.policyHolderEmail = this.parentGroup.get('policy_holder_email').value;
        this.policyHolderPhone = this.parentGroup.get('policy_holder_mobile_phone').value;
      }
    } else {
      this.setHolderName('vehicle');
      if (!this.vehicleHolderIsPolicyHolder) {
        this.setPolicyHolderInfo();
      }
      this.fields.unshift({
        control: 'vehicle_holder_tax_id',
        label: '_CT_MOTOR_tax_id'
      });
    }
  }

  setHolderName(holder: 'vehicle' | 'policy'): void {
    const name = this.parentGroup.get(holder + '_holder_first_name').value;
    const lastName = this.parentGroup.get(holder + '_holder_last_name').value;
    const fullName = `${name} ${lastName}`;
    if (holder === 'vehicle') this.vehicleHolderName = fullName;
    if (holder === 'policy') this.policyHolderName = fullName;
  }

  setPolicyHolderInfo(): void {
    this.setHolderName('policy');
    this.policyHolderEmail = this.parentGroup.get('policy_holder_email').value;
    this.policyHolderPhone = this.parentGroup.get('policy_holder_mobile_phone').value;
    this.policyHolderTaxId = this.parentGroup.get('policy_holder_tax_id').value;
  }
}
