import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ConfigOptions } from '@wefoxGroupOneBPCore/interfaces';
import { ConfigStore } from '@wefoxGroupOneBPCore/stores';

@Injectable({ providedIn: 'root' })
// eslint-disable-next-line
export class ConfigQuery extends Query<any> {
  public privateFooters$ = this.select(state => state.privateFooters);
  public privateHeaders$ = this.select(state => state.privateHeaders);
  public privateSidebar$ = this.select(state => state.privateSidebar);

  constructor(protected store: ConfigStore) {
    super(store);
  }

  public getPrivateConfig(): ConfigOptions {
    return this.getValue().config?.priv;
  }
}
