import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SessionQuery } from '@wefoxGroupOneBPCore/queries/session.query';
import { BaseDataService } from '@wefoxGroupOneBPCore/services';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PerformanceDataService extends BaseDataService {
    private _getPoliciesSoldEndpoint = 'portfolio/dashboard/contract';
    private _getActiveCustomersEndpoint = 'portfolio/dashboard/customer';
    private _getCommissionsAmountCurrentMonth = 'portfolio/dashboard/commission/month';
    private _getCommissionsAmountLastWeek = 'portfolio/dashboard/commission/week'

    constructor(protected httpClient: HttpClient, protected sessionQuery: SessionQuery) {
        super(httpClient, sessionQuery);
    }

    public getPoliciesSold$(): Observable<number> {
        const url = this.getUrl({
            path: `${this._getPoliciesSoldEndpoint}`
        });
        return this.get$(url) as Observable<number>;
    }

    public getActiveCustomers$(): Observable<number> {
        const url = this.getUrl({
            path: `${this._getActiveCustomersEndpoint}`
        });
        return this.get$(url) as Observable<number>;
    }

    public getCommissionsAmountCurrentMonth$(): Observable<number> {
        const url = this.getUrl({
            path: `${this._getCommissionsAmountCurrentMonth}`
        });
        return this.get$(url) as Observable<number>;
    }

    public getCommissionsAmountLastWeek$(): Observable<number> {
        const url = this.getUrl({
            path: `${this._getCommissionsAmountLastWeek}`
        });
        return this.get$(url) as Observable<number>;
    }

}