import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'one-info-banner-adapter',
  templateUrl: './info-banner-adapter.component.html'
})
export class InfoBannerAdapterComponent implements AfterViewInit {
  @ViewChild('bannerLink') public bannerLink: ElementRef;
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: UntypedFormGroup;

  public getTheme(type: string): string {
    switch (type) {
      case 'danger':
        return 'bg-danger-light color-danger-dark';
      case 'info':
        return 'bg-info-light color-info-dark';
      case 'success':
        return 'bg-success-light color-success-dark';
      case 'warning':
        return 'bg-warning-light color-warning-dark';
    }
  }

  public ngAfterViewInit(): void {
    const linkId = this.content_data.linkId;

    if (this.bannerLink?.nativeElement?.querySelector(`#${linkId}`)) {
      this.bannerLink.nativeElement.addEventListener('click', event => {
        if (event.target.id === linkId) {
          this.content_data.linkAction();
        }
      });
    }
  }
}
