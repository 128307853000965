import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { DropdownComponentOptions } from '@wefoxGroupOneBPCore/interfaces/dropdown-options.interface';
import { CustomersService } from '@wefoxGroupOneBPPrivate/modules/customers/services/customers.service';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
@Component({
  selector: 'one-carriers-adapter',
  templateUrl: './carriers-adapter.component.html',
  styles: `
    .horizontal-layout {
      display: flex;
      justify-content: space-between;
      gap: var(--huge);
      div {
        flex-grow: 1;
        margin-top: 0px !important;
      }
      #loading-content, #error-content {
        align-self: center;
      }
    }
  `
})
export class CarriersAdapterComponent implements OnInit, OnDestroy {
  @Input() public content_data: any; // eslint-disable-line
  @Input() public parentGroup: FormGroup;
  public carriers: DropdownComponentOptions;
  public productType: DropdownComponentOptions;
  public categories: DropdownComponentOptions;
  public error = null;
  public loading = null;
  public isPortfolioTransfer: boolean = false;

  protected _unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private _customersService: CustomersService, private _fb: UntypedFormBuilder) {}

  public ngOnInit() {
    this.parentGroup.addControl('category', this._fb.control(null));
    this._buildInsuranceCarrierOptions();

    this.parentGroup
      .get('insurance_carrier')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this._unsubscribe$))
      .subscribe({
        next: value => {
          this.categories = null;
          this.productType = null;
          if (value?.key) {
            this._buildCarrierProductTypesOptions(value.key);
          }
        }
      });

    this.parentGroup.get('category').disable({ emitEvent: false });
    this.parentGroup
      .get('product_type')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this._unsubscribe$))
      .subscribe({
        next: value => {
          this.categories = null;
          this._buildCarriersCategoriesOptions(value.key);
          this.parentGroup.get('category').enable({ emitEvent: false });
          this.parentGroup.get('category').setValue('');
        }
      });

    this.parentGroup
      .get('category')
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this._unsubscribe$))
      .subscribe();
  }

  public ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  private _buildCarriersCategoriesOptions(productTypeKey?: string): void {
    const sourceValues = [];
    this.loading = 'categories';
    if (productTypeKey) {
      this.parentGroup.get('category').setValue('');
      this._customersService
        .getCarriersCategories$(productTypeKey, this.parentGroup.get('insurance_carrier').value.key)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe({
          next: (data: { product_type_categories: { key: string; value: string }[] }) => {
            data.product_type_categories.forEach(cat => {
              sourceValues.push({
                key: cat.key,
                value: cat.value
              });
            });

            this.categories = this._getOptionsStructure('category', sourceValues);
            this.loading = null;
            this.error = null;
          },
          error: () => {
            this.loading = null;
            this.error = 'categories';
          }
        });
    }
  }

  private _buildCarrierProductTypesOptions(insurerId: string): void {
    const sourceValues = [];
    this.parentGroup.get('product_type').setValue('');
    this.loading = 'product_types';
    this._customersService
      .getCarriersProductTypes$(insurerId)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: (data: { product_types: { key: string; value: string }[] }) => {
          this.categories = null;
          this.productType = null;
          data.product_types.forEach(pt => {
            sourceValues.push({
              key: pt.key,
              value: pt.value
            });
          });

          this.productType = this._getOptionsStructure('product_type', sourceValues);
          this.loading = null;
          this.error = null;
        },
        error: () => {
          this.loading = null;
          this.error = 'product_types';
        }
      });
  }

  private _buildInsuranceCarrierOptions(): void {
    const sourceValues = [];
    this.loading = 'insurance_carriers';
    this.isPortfolioTransfer = !!this.parentGroup.root.get('p_transfer_insurance');
    this._getInsuranceCarrierOptions$()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe({
        next: (data: { insurers: { key: string; value: string }[] }) => {
          if (data) {
            data.insurers.forEach(insurer => {
              sourceValues.push({
                key: insurer.key,
                value: insurer.value
              });
            });
            this.carriers = this._getOptionsStructure('insurance_carrier', sourceValues);
            this.error = null;
            this.loading = null;
          }
        },
        error: () => {
          this.error = 'insurance_carriers';
          this.loading = 'insurance_carriers';
        }
      });
  }

  // eslint-disable-next-line
  private _getInsuranceCarrierOptions$(): Observable<any> {
    return this._customersService.getInsuranceCarriers$();
  }

  private _getOptionsStructure(name: string, sourceValues) {
    return {
      controlName: name,
      defaultValue: '',
      label: `_FKS_${name}_label`,
      optionsPanelModifiers: '-compact',
      required: true,
      sourceName: name,
      sources: [
        {
          name: name,
          validations: [],
          values: sourceValues.sort((a, b) => a.value.localeCompare(b.value))
        }
      ]
    };
  }
}
