export type WgTooltipPosition = 'top' | 'right' | 'bottom' | 'left' | 'topLeft' | 'bottomLeft';

export enum WgTooltipPositionEnum {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  TopLeft = 'topLeft',
  BottomLeft = 'bottomLeft'
}
