import { TariffTypes } from '@wefoxGroupOneBPCore/constants/contracts.constants';

export const ContractSectionPrefix = {
  contracts: '_COL_contracts_'
};

export const StatusContractsKey = {
  Active: '_GEN_active',
  Cancelled: '_GEN_cancelled',
  Inactive: '_GEN_inactive',
  Pending: '_GEN_pending'
};

export const Origin = {
  bp: 'BP',
  bpb2b: 'BP-B2B',
  bpb2c: 'BP-B2C',
  brokerPortal: 'Broker Portal'
};

export const OriginTranslations = {
  bp: '_GEN_onboarding_direct',
  bpb2b: '_GEN_onboarding_direct',
  bpb2c: '_GEN_onboarding_b2c',
  brokerPortal: '_GEN_onboarding_direct'
};

export const OriginTranslationsMapping = {
  [Origin.bpb2c]: OriginTranslations.bpb2c,
  [Origin.bp]: OriginTranslations.bp,
  [Origin.bpb2b]: OriginTranslations.bpb2b,
  [Origin.brokerPortal]: OriginTranslations.brokerPortal
};

export const OriginFilters = {
  b2c: [Origin.bpb2c],
  portal: [Origin.bp, Origin.bpb2b, Origin.brokerPortal]
};

export const ProductType = {
  external: 'external',
  home: 'home',
  homeowner: 'homeowner',
  household: 'household',
  householdAndLiability: 'householdAndLiability',
  liability: 'liability',
  motor: 'motor'
};

export const ProductTypeSnakeCase = {
  [ProductType.external]: 'external',
  [ProductType.homeowner]: 'homeowner',
  [ProductType.household]: 'household',
  [ProductType.householdAndLiability]: 'household_and_liability',
  [ProductType.liability]: 'liability',
  [ProductType.motor]: 'motor'
};

export const ProductTypeTranslations = {
  home: '_COL_product_type_home',
  homeowner: '_COL_product_type_homeowner',
  household: '_COL_product_type_household',
  householdAndLiability: '_COL_product_type_home',
  liability: '_COL_product_type_liability',
  motor: '_COL_product_type_motor'
};

export const ProductTypeTranslationsReverse = {
  _COL_product_type_home: 'householdAndLiability',
  _COL_product_type_homeowner: 'homeowner',
  _COL_product_type_household: 'household',
  _COL_product_type_liability: 'liability',
  _COL_product_type_motor: 'motor'
};

export const ProductTypeTranslationsMapping = {
  [ProductType.homeowner]: ProductTypeTranslations.homeowner,
  [ProductType.household]: ProductTypeTranslations.household,
  [ProductType.householdAndLiability]: ProductTypeTranslations.householdAndLiability,
  [ProductType.liability]: ProductTypeTranslations.liability,
  [ProductType.motor]: ProductTypeTranslations.motor
};

export const TariffTypeTranslations = {
  [TariffTypes.carSwitch]: '_PROD_tariff_type_switch',
  [TariffTypes.privateLiabilitySwitch]: '_PROD_tariff_type_switch',
  [TariffTypes.switchHH]: '_PROD_tariff_type_switch',
  [TariffTypes.switchPL]: '_PROD_tariff_type_switch',
  [TariffTypes.carSwitchGreen]: '_PROD_tariff_type_switch_green',
  [TariffTypes.switchGreenHH]: '_PROD_tariff_type_switch_green',
  [TariffTypes.switchGreenPL]: '_PROD_tariff_type_switch_green',
  [TariffTypes.privateLiabilitySwitchGreen]: '_PROD_tariff_type_switch_green',
  [TariffTypes.compact]: '_PROD_tariff_type_compact',
  [TariffTypes.comfort]: '_PROD_tariff_type_comfort',
  [TariffTypes.comfortPlus]: '_PROD_tariff_type_comfort_plus'
};

export const PAYMENT_FREQUENCY_SHORT_KEY = {
  'half-yearly': '_PROD_payment_frequency_shortname_half_yearly',
  monthly: '_PROD_payment_frequency_shortname_monthly',
  quarterly: '_PROD_payment_frequency_shortname_quarterly',
  yearly: '_PROD_payment_frequency_shortname_yearly'
};
