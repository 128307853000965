import { Injectable } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { UxIntervalTimes } from '@wefoxGroupOneBPCore/constants/ux-types.constants';
import { ToasterType } from '@wefoxGroupOneBPCore/interfaces/wg-toaster.interface';
import { WgToasterService } from '@wefoxGroupOneBPCore/services/wg-toaster.service';
import { SessionService } from '@wefoxGroupOneBPCore/services';

@Injectable({
  providedIn: 'root',
})
export class InfoToasterService {

  constructor(private _sessionService: SessionService, private _toaster: WgToasterService) {}

  public errorMessage(message: string, lifeTime = UxIntervalTimes.verySlow): void {
    const traslatedMessage = translate(message);
    this._toaster.show({ title: traslatedMessage, type: ToasterType.error, lifeTime});
    this._sessionService.setToasterRoleOptions({ textKey: traslatedMessage, type: ToasterType.error });
  }

  public successMessage(message: string, lifeTime = UxIntervalTimes.slower): void {
    const traslatedMessage = translate(message);
    this._toaster.show({ title: traslatedMessage, type: ToasterType.success, lifeTime });
    this._sessionService.setToasterRoleOptions({ textKey: traslatedMessage, type: ToasterType.success });
  }

  public infoMessage(message: string, lifeTime = UxIntervalTimes.slower): void {
    const traslatedMessage = translate(message);
    this._toaster.show({ title: traslatedMessage, type: ToasterType.info, lifeTime });
  }

  public warningMessage(message: string, lifeTime = UxIntervalTimes.slower): void {
    const traslatedMessage = translate(message);
    this._toaster.show({ title: traslatedMessage, type: ToasterType.warning, lifeTime });
  }
}
