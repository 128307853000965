// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  width: 100%;
}

.onebp-a-phone-code label {
  position: absolute;
  width: max-content;
}
.onebp-a-phone-code ::ng-deep input {
  margin-top: 32px;
}`, "",{"version":3,"sources":["webpack://./libs/shared/src/lib/components/phone/phone.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,WAAA;AACF;;AAGE;EACE,kBAAA;EACA,kBAAA;AAAJ;AAII;EACE,gBAAA;AAFN","sourcesContent":[":host {\n  display: flex;\n  width: 100%;\n}\n\n.onebp-a-phone-code {\n  label {\n    position: absolute;\n    width: max-content;\n  }\n\n  ::ng-deep {\n    input {\n      margin-top: 32px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
